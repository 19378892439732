import useStyles from "Hooks/useStyles";

import ScreenNavStyles from "./ScreenNavStyles";
import ScreenNavView from "./ScreenNavView";

export default ({
  selectedValue,
  onValueChange,
  buttons,
  itemLeft,
  itemRight,
}) => {
  const { theme, styles } = useStyles({ Styles: ScreenNavStyles });

  return (
    <ScreenNavView
      theme={theme}
      styles={styles}
      selectedValue={selectedValue}
      onValueChange={onValueChange}
      buttons={buttons}
      itemLeft={itemLeft}
      itemRight={itemRight}
    />
  );
};
