import gql from "graphql-tag";
import environment from "environment";
import Promotion from "./Promotion";

const Redemption = {};

Redemption.fragments = {
  public: gql`
    fragment RedemptionPublic on Redemption {
      id
      redemptionPromotionId
      redemptionUserId
      redemptionCampaignId
      createdAt
      updatedAt
      promotion {
        ...PromotionPublic
      }
    }
    ${Promotion.fragments.public}
  `,
};

Redemption.mutations = {
  update: gql`
    mutation UpdateRedemption($input: UpdateRedemptionInput!) {
      updateRedemption(input: $input) {
        ...RedemptionPublic
      }
    }
    ${Redemption.fragments.public}
  `,
  create: gql`
    mutation CreateRedemption($input: CreateRedemptionInput!) {
      createRedemption(input: $input) {
        ...RedemptionPublic
      }
    }
    ${Redemption.fragments.public}
  `,
};

Redemption.queries = {
  getByUserIdAndPromotionId: gql`
    query GetRedemptionByUserIdAndPromotionId(
      $redemptionUserId: ID${environment.v2_bang}
      $redemptionPromotionId: ModelIDKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelRedemptionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      getRedemptionByUserIdAndPromotionId(
        redemptionUserId: $redemptionUserId
        redemptionPromotionId: $redemptionPromotionId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...RedemptionPublic
        }
        nextToken
      }
    }
    ${Redemption.fragments.public}
  `,
  listByPromotionIdCreatedAt: gql`
    query ListRedemptionsByPromotionIdCreatedAt(
      $redemptionPromotionId: ID${environment.v2_bang}
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelRedemptionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listRedemptionsByPromotionIdCreatedAt(
        redemptionPromotionId: $redemptionPromotionId
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...RedemptionPublic
        }
        nextToken
      }
    }
    ${Redemption.fragments.public}
  `,
  getByUserIdAndCampaignIdAndPromotionId: gql`
    query GetRedemptionByUserIdAndCampaignIdAndPromotionId(
      $redemptionUserId: ID
      $redemptionPromotionIdRedemptionCampaignId: ModelRedemptionByUserIdAndCampaignIdIdAndPromotionIdCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelRedemptionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      getRedemptionByUserIdAndCampaignIdAndPromotionId(
        redemptionUserId: $redemptionUserId
        redemptionPromotionIdRedemptionCampaignId: $redemptionPromotionIdRedemptionCampaignId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...RedemptionPublic
        }
        nextToken
      }
    }
    ${Redemption.fragments.public}
  `,
  list: gql`
    query ListRedemptions(
      $filter: ModelRedemptionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listRedemptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...RedemptionPublic
        }
      }
    }
    ${Redemption.fragments.public}
  `,
  get: gql`
    query GetRedemption($id: ID!) {
      getRedemption(id: $id) {
        ...RedemptionPublic
      }
    }
    ${Redemption.fragments.public}
  `,
};

export default Redemption;
