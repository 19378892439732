import { TutorialSwipperContext } from "Contexts/TutorialSwipper";
import { useContext, useEffect } from "react";

const TutorialReset = ({ navigation }) => {
  const { restartTutorial } = useContext(TutorialSwipperContext);
  useEffect(() => {
    navigation.popToTop();
    setTimeout(() => restartTutorial(), 10);
  }, [navigation, restartTutorial]);

  return <></>;
};

export default TutorialReset;
