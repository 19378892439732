import React from "react";
import { useTheme } from "react-native-paper";
import Screen from "Components/Screen";
import { useContext } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import Section from "Components/Section";
import TextListItemView from "Components/TextListItem";

export default ({ navigation }) => {
  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);

  return (
    <Screen
      gatedTo="member"
      title="How Do I Earn Points"
      fullHeight
      hasScroll={false}
      backgroundColor={theme.colors.surface}
    >
      <Section theme={theme} heading="Sales">
        <TextListItemView theme={theme}>
          1 point for every $1 transacted
        </TextListItemView>
        <TextListItemView theme={theme}>
          2x multiplier if you resell a purchase
        </TextListItemView>
        <TextListItemView theme={theme}>
          3x multiplier if you are the original seller of a ticket
        </TextListItemView>
      </Section>
      <Section theme={theme} heading="Examples">
        <TextListItemView theme={theme}>
          If you purchase a ticket for $100, you earn 100 points
        </TextListItemView>
        <TextListItemView theme={theme}>
          If you purchase a ticket and then resell it for $75, you earn 150
          points
        </TextListItemView>
        <TextListItemView theme={theme}>
          If you upload and sell a ticket of your own for $200, you earn 600
          points
        </TextListItemView>
      </Section>
      {/* <Section
        theme={theme}
        heading="Other Ways to Earn"
      >
        <TextListItemView theme={theme}>Login to your account: Earn 5 points (max 1 per day)</TextListItemView>
        <TextListItemView theme={theme}>Post a ticket on social: Earn 10 points (1 max per day)</TextListItemView>
        <TextListItemView theme={theme}>Share a ticket on social: Earn 10 points (3 max per day)</TextListItemView>
        <TextListItemView theme={theme}>Refer a friend: Earn 250 points + $25 in PropSwap Cash</TextListItemView>
      </Section> */}
    </Screen>
  );
};
