// import BottomSheetModalProvider from 'Components/BottomSheet/BottomSheetModalProvider'
import { RegistrationProvider } from "Contexts/Registration";
import { TabBarProvider } from "Contexts/TabBar";
import { EventsProvider } from "Contexts/Events";
import { OptionsProvider } from "Contexts/Options";
import { Platform, View } from "react-native";
import { TicketProvider } from "Contexts/Ticket";
import { AvatarProvider } from "Contexts/Avatar";
import { BluesnapEncrypterProvider } from "Contexts/BluesnapEncrypter";
import { ScrollDirectionProvider } from "Contexts/ScrollDirection";
import { Headline, Subheading } from "react-native-paper";

const byPassGeo = false;

export default ({ geoAllowed, theme, children, styles }) =>
  !geoAllowed && !byPassGeo ? (
    <View style={styles.stateNotAllowedWrapper}>
      <Headline style={styles.stateNotAllowedText}>State Not Allowed</Headline>
      <Subheading style={styles.stateNotAllowedText}>
        It has been determined your current location is not in one of PropSwap’s
        approved states.
      </Subheading>
    </View>
  ) : (
    <ScrollDirectionProvider>
      <TabBarProvider>
        <RegistrationProvider>
          <TicketProvider>
            <EventsProvider>
              <OptionsProvider>
                <AvatarProvider>
                  <BluesnapEncrypterProvider>
                    {/* <BottomSheetModalProvider> */}
                    {Platform.OS === "web" && (
                      <View
                        style={{
                          flex: 1,
                        }}
                      >
                        {children}
                      </View>
                    )}
                    {Platform.OS !== "web" && (
                      <View style={styles.f1}>{children}</View>
                    )}
                    {/* </BottomSheetModalProvider> */}
                  </BluesnapEncrypterProvider>
                </AvatarProvider>
              </OptionsProvider>
            </EventsProvider>
          </TicketProvider>
        </RegistrationProvider>
      </TabBarProvider>
    </ScrollDirectionProvider>
  );
