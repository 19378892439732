import * as React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Button, Text } from "react-native-paper";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation }) => {
  return (
    <>
      <ScrollView>
        <View style={styles.buttons}>
          <Button
            mode="contained"
            onPress={() => navigation.navigate("PasswordResetSuccess")}
            style={styles.button}
          >
            Save
          </Button>
        </View>
        <Text>Register</Text>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  buttons: {
    padding: 8,
  },
  button: {
    margin: 8,
  },
});
