import Screen from "Components/Screen";
import useStyles from "Hooks/useStyles";
import PhotoUploadStyles from "Components/PhotoUpload/PhotoUploadStyles";
import { Button, Text, Title } from "react-native-paper";

import { View } from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";

const TicketNewMobileConfirmStep3 = ({ navigation }) => {
  const { theme } = useStyles({ Styles: PhotoUploadStyles });

  return (
    <Screen
      gatedTo="member"
      title="Mobile Ticket Approval"
      hasKeyboard={false}
      backgroundColor={theme.colors.surface}
      fullWidth
      fullHeight
    >
      <View
        style={{
          marginHorizontal: theme.spacing.xl,
          marginTop: theme.spacing.xl * 2,
          flex: 1,
        }}
      >
        <Title style={{ textAlign: "center", color: theme.colors.primary }}>
          Application Received!
        </Title>
        <Text style={{ textAlign: "center", marginTop: theme.spacing.xl }}>
          A member of our support staff will review your information and update
          your status as soon as possible.
        </Text>
        <View
          style={{
            width: "100%",
            alignItems: "center",
            marginTop: theme.spacing.xl * 4,
            marginBottom: theme.spacing.xl * 2,
          }}
        >
          <FontAwesome5
            name="check-circle"
            size={172}
            color={theme.colors.primary}
          />
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-end",
            marginBottom: theme.spacing.xl,
          }}
        >
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1, alignItems: "center" }}>
              <Button
                mode="contained"
                onPress={() => navigation.popToTop()}
                style={{ width: "100%" }}
              >
                Close
              </Button>
            </View>
          </View>
        </View>
      </View>
    </Screen>
  );
};

export default TicketNewMobileConfirmStep3;
