import * as Updates from "expo-updates";
import { useEffect } from "react";
import { Alert } from "react-native";

const debug = false;

const useUpdater = () => {
  const dataString = `
    Updates.channel: ${Updates.channel}-
    Updates.isEmergencyLaunch: ${Updates.isEmergencyLaunch}-
    Updates.releaseChannel: ${Updates.releaseChannel}-
    Updates.updateId: ${Updates.updateId}
  `;

  useEffect(() => {
    !!debug && Alert.alert(dataString);
  }, [dataString]);

  useEffect(() => {
    !!debug &&
      Updates.checkForUpdateAsync().then(result =>
        Alert.alert(`Updates.checkForUpdateAsync: ${result.isAvailable}`)
      );
  }, []);

  useEffect(() => {
    !!debug &&
      setInterval(
        () => {
          Alert.alert("Checking....");
          Updates.fetchUpdateAsync()
            .then(update => {
              Alert.alert(`Updates.fetchUpdateAsync: ${update.isNew}`);
              !!update.isNew &&
                Updates.reloadAsync().then(() => Alert.alert("RELOAD!"));
            })
            .catch(e => Alert.alert(e.message));
        },

        10000
      );
    !!debug &&
      Updates.checkForUpdateAsync().then(result =>
        Alert.alert(`Updates.checkForUpdateAsync: ${result.isAvailable}`)
      );
  }, []);

  useEffect(() => {
    !!debug &&
      Updates.addListener(event =>
        Alert.alert(`${event.type}: ${event.message}`)
      );
  }, []);

  return null;
};

export default useUpdater;
