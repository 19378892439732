import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import Event from "shared/api/Event";

const EventsContext = React.createContext();

const EventsProvider = ({ children }) => {
  const {
    error,
    fetchMore,
    data: { listEventsByIsActive: { nextToken, items } = {} } = {},
  } = useQuery(Event.queries.listByIsActive, {
    variables: {
      isActive: "x",
      limit: 100,
    },
  });

  useEffect(() => {
    !!nextToken &&
      !!fetchMore &&
      fetchMore({ variables: { isActive: "x", limit: 100, nextToken } });
  }, [nextToken, fetchMore]);

  !!error && console.error(error);

  return (
    <EventsContext.Provider value={items}>{children}</EventsContext.Provider>
  );
};

export { EventsContext, EventsProvider };
