import React from "react";
import { View } from "react-native";
import { Divider, Subheading } from "react-native-paper";

export default ({ theme, heading, children, skipDivider }) => (
  <View style={{ marginBottom: theme.spacing.xl }}>
    {!!heading && <Subheading>{heading}</Subheading>}
    {Array.isArray(children) ? [...children] : children}
    {!skipDivider && <Divider />}
  </View>
);
