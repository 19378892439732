import ActivityLatest from "Components/Activity/Latest";
import FocusAwareStatusBar from "Components/FocusAwareStatusBar";
import { View } from "react-native";
import GuestHeaderView from "./GuestHeaderView";
import MemberHeaderView from "./MemberHeaderView";
import React from "react";

const MemoizedActivityLatest = React.memo(ActivityLatest);

export default ({
  isLoggedIn,
  theme,
  styles,
  onNotificationIconPress,
  onWatchlistIconPress,
  onBalancePress,
  onRegisterPress,
  onLoginPress,
  onPointsPress,
  onUserPress,
  insets,
  showUserInfo,
}) => (
  <>
    <View style={[styles.wrapper, { paddingTop: insets.top }]}>
      <MemoizedActivityLatest />
      {!!showUserInfo && (
        <View style={styles.contents}>
          {!!isLoggedIn && (
            <MemberHeaderView
              theme={theme}
              styles={styles}
              onNotificationIconPress={onNotificationIconPress}
              onWatchlistIconPress={onWatchlistIconPress}
              onBalancePress={onBalancePress}
              onPointsPress={onPointsPress}
              onUserPress={onUserPress}
            />
          )}
          {!isLoggedIn && (
            <GuestHeaderView
              theme={theme}
              styles={styles}
              onLoginPress={onLoginPress}
              onRegisterPress={onRegisterPress}
            />
          )}
        </View>
      )}
    </View>
    <FocusAwareStatusBar style="light" />
  </>
);
