export default theme => ({
  wrapper: {
    marginTop: theme.spacing.xl,
  },
  input: {
    // height: "100%",
    backgroundColor: theme.colors.background,
    paddingVertical: theme.spacing.sm,
  },
  inputWrapper: {
    flex: 1,
    marginRight: theme.spacing.xl,
  },
  codeWrapper: {
    justifyContent: "center",
    marginRight: theme.spacing.xl,
  },
});
