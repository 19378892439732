import * as React from "react";
import { useCallback } from "react";
import {
  Button,
  Title,
  useTheme,
  TextInput,
  HelperText,
} from "react-native-paper";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { View, Platform } from "react-native";
import Auth from "@aws-amplify/auth";
import ScreenBottom from "Components/Screen/Bottom";
import Screen from "Components/Screen";
import AuthWrapper from "Components/AuthWrapper";

// const scrollEnabled = Platform.select({ web: true, default: false });

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid email address")
    .required("Can't be blank"),
});

const Form = ({ style, theme, navigation }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setError,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const inputs = React.useRef({});

  const handleSubmitError = useCallback(
    error =>
      setError("email", {
        ...error,
        message: `${error.code} - ${error.message}`,
      }),
    [setError]
  );

  const onSubmit = useCallback(
    ({ email }) =>
      Auth.forgotPassword(email)
        .then(() => navigation.push("PasswordResetConfirmation", { email }))
        .catch(handleSubmitError),
    [navigation, handleSubmitError]
  );

  const doSubmit = handleSubmit(onSubmit);

  return (
    <View
      style={[
        {
          flex: 1,
        },
        style ?? {},
      ]}
    >
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              autoFocus
              autoCorrect={false}
              autoCapitalize="none"
              ref={e => (inputs.current["email"] = e)}
              autoCompleteType={
                Platform.OS === "web" ? "new-password" : "email"
              }
              keyboardType="email-address"
              textContentType="emailAddress"
              label="Email"
              error={!!errors.email}
              returnKeyLabel="Next"
              returnKeyType="next"
              onSubmitEditing={doSubmit}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value.toLowerCase())}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />

            <HelperText type="error" visible={!!errors.email}>
              {errors.email?.message}
            </HelperText>
            <HelperText type="info" visible={!errors.email}>
              Enter the email you registered with and we'll email you a code.
            </HelperText>
          </>
        )}
        name="email"
        defaultValue=""
      />

      <ScreenBottom theme={theme}>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          Request Code
        </Button>
      </ScreenBottom>
    </View>
  );
};

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen
      gatedTo="guest"
      title="Password Reset"
      hasKeyboard
      backgroundColor={theme.colors.surface}
      fullHeight
      fullWidth
    >
      {Platform.OS === "web" ? (
        <AuthWrapper>
          <>
            <Title style={{ textAlign: "center", color: theme.colors.primary }}>
              Forgot Password?
            </Title>
            <Form
              style={{
                marginTop: theme.spacing.xl,
                marginHorizontal: theme.spacing.xl,
              }}
              theme={theme}
              navigation={navigation}
            />
          </>
        </AuthWrapper>
      ) : (
        <>
          <Title style={{ textAlign: "center", color: theme.colors.primary }}>
            Forgot Password?
          </Title>
          <Form
            style={{
              marginTop: theme.spacing.xl,
              marginHorizontal: theme.spacing.xl,
            }}
            theme={theme}
            navigation={navigation}
          />
        </>
      )}
    </Screen>
  );
};
