import { useQuery } from "@apollo/client";
import User from "shared/api/User";
import useStyles from "Hooks/useStyles";
import { useMemo } from "react";
import UserInfoStyles from "./UserInfoStyles";
import UserInfoView from "./UserInfoView";

export default ({
  userId,
  userObj,
  Extra,
  textColor,
  showState,
  onPress,
  skipIcons,
}) => {
  const { theme, styles } = useStyles({ Styles: UserInfoStyles });

  const { data: { getUser: user } = {} } = useQuery(User.queries.get, {
    skip: !userId,
    variables: {
      id: userId,
    },
  });

  const memoizedUser = useMemo(() => user ?? userObj, [user, userObj]);

  return (
    <UserInfoView
      skipIcons={skipIcons}
      onPress={onPress}
      Extra={Extra}
      textColor={textColor}
      theme={theme}
      styles={styles}
      user={memoizedUser}
      showState={showState}
    />
  );
};
