import gql from "graphql-tag";
// import Ticket from "./Ticket"

const Sport = {};

Sport.fragments = {
  public: gql`
    fragment SportPublic on Sport {
      id
      name
      abbrev
      feedId
      position
      fanDuelCompetitionId
    }
  `,
};

Sport.queries = {
  list: gql`
    query ListSports(
      $filter: ModelSportFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSports(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...SportPublic
        }
      }
    }
    ${Sport.fragments.public}
  `,
  get: gql`
    query GetSport($id: ID!) {
      getSport(id: $id) {
        ...SportPublic
      }
    }
    ${Sport.fragments.public}
  `,
};

Sport.mutations = {
  create: gql`
    mutation CreateSport($input: CreateSportInput!) {
      createSport(input: $input) {
        ...SportPublic
      }
    }
    ${Sport.fragments.public}
  `,
  update: gql`
    mutation UpdateSport($input: UpdateSportInput!) {
      updateSport(input: $input) {
        ...SportPublic
      }
    }
    ${Sport.fragments.public}
  `,
};

export default Sport;
