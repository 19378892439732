import React from "react";
import { useContext, useCallback } from "react";
import { Divider, List, useTheme } from "react-native-paper";

import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import { useQuery } from "@apollo/client";
import SportsbookLocale from "shared/api/SportsbookLocale";
import { ScrollView } from "react-native";

export default ({ navigation }) => {
  const theme = useTheme();
  const { setTicketValues, ticketValues } = useContext(TicketContext);

  const {
    error,
    data: { listSportsbookLocalesByLocaleId: { items = [] } = {} } = {},
  } = useQuery(SportsbookLocale.queries.listByLocaleId, {
    skip: !ticketValues?.ticketLocaleId,
    variables: {
      limit: 100,
      localeId: ticketValues?.ticketLocaleId,
    },
  });

  const onSubmit = useCallback(
    ({ ticketSportsbookId }) =>
      () =>
        [
          setTicketValues(ticketValues => ({
            ...ticketValues,
            ticketSportsbookId,
          })),
          navigation.navigate("TicketNewStep3"),
        ],
    [navigation, setTicketValues]
  );

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={2}
        title={"Choose Sportsbook"}
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />

      <ScrollView style={{ flex: 1 }}>
        {items
          .slice()
          .map(sl => sl.sportsbook)
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map(sportsbook => (
            <React.Fragment key={sportsbook.id}>
              <List.Item
                title={`${sportsbook.name}`}
                right={props =>
                  ticketValues.ticketSportsbookId === sportsbook.id ? (
                    <List.Icon {...props} icon="check-circle-outline" />
                  ) : null
                }
                onPress={onSubmit({ ticketSportsbookId: sportsbook.id })}
              />
              <Divider />
            </React.Fragment>
          ))}
      </ScrollView>
    </Screen>
  );
};
