import UserAvatar from "Components/User/Avatar";
import moment from "moment";
import { View } from "react-native";
import { Card, Paragraph, Text } from "react-native-paper";
import { activityBodies } from "shared/Config";

export default ({ activity, theme, onPress, styles }) => (
  <Card testID="activityCard" onPress={onPress(activity)}>
    <Card.Content>
      <View style={styles.contentWrapper}>
        <View style={styles.avatarWrapper}>
          <UserAvatar
            user={
              activity.type === "BID_EXPIRED"
                ? activity?.user
                : activity?.subject ?? activity?.user
            }
            theme={theme}
          />
        </View>
        <View style={{ flexShrink: 1 }}>
          <Text style={{ fontWeight: "bold" }}>
            {activity?.subjectId === activity?.userId ||
            activity.type === "BID_EXPIRED"
              ? "You"
              : activity?.subject?.displayName ?? "..."}
          </Text>
          <Paragraph style={{ flex: 1, flexWrap: "wrap" }}>
            {!!activity.type
              ? activityBodies[activity.type]?.(activity) ?? activity.type
              : activity.type}
            .
          </Paragraph>
          <Text>{moment(activity.createdAt).fromNow()}</Text>
        </View>
      </View>
    </Card.Content>
  </Card>
);
