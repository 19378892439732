import InterchecksWithdrawSummary from "Components/Interchecks/WithdrawSummary";

export default ({ navigation, route }) => {
  return (
    <InterchecksWithdrawSummary
      navigation={navigation}
      type="ACH_STANDARD"
      amount={route?.params?.amount ?? "0.00"}
      accountType={route?.params?.accountType}
      accountNumber={route?.params?.accountNumber}
      routingNumber={route?.params?.routingNumber}
    />
  );
};
