import { View } from "react-native";
import { Text, Title } from "react-native-paper";
import StepIndicator from "react-native-step-indicator";

export default ({ theme, currentPosition, title, labels }) => (
  <View>
    <StepIndicator
      customStyles={{
        stepStrokeFinishedColor: theme.colors.primary,
        stepStrokeUnFinishedColor: theme.colors.primary,
        stepStrokeCurrentColor: theme.colors.primary,
        currentStepStrokeWidth: 2,
        stepStrokeWidth: 2,
        stepIndicatorCurrentColor: "#FFFFFF",
        stepIndicatorFinishedColor: theme.colors.primary,
        stepIndicatorUnFinishedColor: "#FFFFFF",
        separatorUnFinishedColor: theme.colors.primary,
        separatorFinishedColor: theme.colors.primary,
        currentStepIndicatorSize: 20,
        stepIndicatorSize: 20,
      }}
      stepCount={labels.length}
      currentPosition={currentPosition}
      labels={labels}
      renderStepIndicator={({ position, stepStatus }) =>
        stepStatus === "current" ? (
          <View
            style={{
              borderRadius: 7,
              width: 10,
              height: 10,
              backgroundColor: theme.colors.primary,
            }}
          />
        ) : stepStatus === "finished" ? (
          <Text style={{ color: "#FFFFFF" }}>{position + 1}</Text>
        ) : (
          <View />
        )
      }
      renderLabel={({ position, stepStatus, label, currentPosition }) => (
        <Text style={{ ...theme.fonts.caption }}>{label}</Text>
      )}
    />
    <Title
      style={{
        marginTop: theme.spacing.xl,
        textAlign: "center",
        color: theme.colors.primary,
      }}
    >
      {title}
    </Title>
  </View>
);
