import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import environment from "environment";
import { Auth } from "aws-amplify";
import { AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { useMemo } from "react";

// import AWSAppSyncClient from "aws-appsync";

const defaultPagination = (additionalKeyArgs = []) => ({
  // merge(existing, incoming, { readField, variables }) {
  //   const items = existing ? { ...existing.items } : {};
  //   incoming?.items?.forEach?.(item => {
  //     items[readField("id", item)] = item;
  //   });
  //   return !!variables?.nextToken ? {
  //     ...incoming,
  //     items,
  //   } : {
  //     items,
  //     ...incoming,
  //   };
  // },

  // read(existing) {
  //   if (existing) {
  //     return {
  //       ...existing,
  //       items: Object.values(existing.items),
  //     };
  //   }
  // },
  keyArgs: [...["type", "filter", "sort"], ...additionalKeyArgs],
  merge(existing, incoming, { readField }) {
    const items = existing?.items ? { ...existing.items } : {};
    (incoming?.items ?? []).forEach(item => {
      items[readField("id", item)] = item;
    });
    return {
      nextToken: incoming?.nextToken,
      items,
    };
  },

  read(existing) {
    if (existing) {
      return {
        nextToken: existing.nextToken,
        items: Object.values(existing.items),
      };
    }
  },
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        listListings: defaultPagination(),
        listOptions: defaultPagination(),
        searchListingsRaw: defaultPagination(),
        ...(environment.use_v2
          ? { searchListingsRaw2: defaultPagination() }
          : {}),
        searchListings: defaultPagination(),
        listEventsByIsActive: defaultPagination(),
        listTicketsByIsSettledAndResult: defaultPagination(["isSettled"]),
        listActivitiesByTypeAndUserId: defaultPagination([
          "type",
          "userIdCreatedAt",
        ]),
      },
    },
  },
});

export default ({ isLoggedIn, appSyncConfig }) => {
  const httpLink = useMemo(
    () =>
      new HttpLink({
        uri: appSyncConfig.aws_appsync_graphqlEndpoint,
      }),
    [appSyncConfig]
  );

  const iamClient = useMemo(
    () =>
      new ApolloClient({
        cache,
        link: from([
          createAuthLink({
            auth: {
              type: AUTH_TYPE.AWS_IAM,
              credentials: async () => await Auth.currentCredentials(),
            },
            region: appSyncConfig.aws_appsync_region,
            url: appSyncConfig.aws_appsync_graphqlEndpoint,
          }),
          split(
            op => {
              const { operation } = op.query.definitions[0];
              if (operation === "subscription") {
                return false;
              }

              return true;
            },
            httpLink,
            createSubscriptionHandshakeLink(
              {
                auth: {
                  type: AUTH_TYPE.AWS_IAM,
                  credentials: async () => await Auth.currentCredentials(),
                },
                region: appSyncConfig.aws_appsync_region,
                url: appSyncConfig.aws_appsync_graphqlEndpoint,
              },
              httpLink
            )
          ),
        ]),
      }),
    [appSyncConfig, httpLink]
  );

  const cognitoClient = useMemo(
    () =>
      new ApolloClient({
        cache,
        link: from([
          createAuthLink({
            auth: {
              type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
              jwtToken: async () =>
                (await Auth.currentSession()).getIdToken().getJwtToken(),
            },
            region: appSyncConfig.aws_appsync_region,
            url: appSyncConfig.aws_appsync_graphqlEndpoint,
          }),
          split(
            op => {
              const { operation } = op.query.definitions[0];

              if (operation === "subscription") {
                return false;
              }

              return true;
            },
            httpLink,
            createSubscriptionHandshakeLink(
              {
                auth: {
                  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
                  jwtToken: async () =>
                    (await Auth.currentSession()).getIdToken().getJwtToken(),
                },
                region: appSyncConfig.aws_appsync_region,
                url: appSyncConfig.aws_appsync_graphqlEndpoint,
              },
              httpLink
            )
          ),
        ]),
      }),
    [appSyncConfig, httpLink]
  );

  const client = useMemo(
    () =>
      isLoggedIn === undefined
        ? null
        : !!isLoggedIn
        ? cognitoClient
        : iamClient,
    [cognitoClient, iamClient, isLoggedIn]
  );

  return client;
};
