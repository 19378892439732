// import RegisterStepper from 'Components/Register/Stepper';

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useState, useEffect, useCallback, useRef } from "react";
import AccountFormView from "./AccountFormView";
import { useApolloClient, useMutation, useReactiveVar } from "@apollo/client";
import User from "shared/api/User";
import { Alert, Platform, ScrollView } from "react-native";
import { Auth } from "aws-amplify";
import { removeEmpty } from "Util/";
import { STATES } from "shared/Config";
import { gidxRefresSessionVar } from "Hooks/useGidxSession";
import useStyles from "Hooks/useStyles";

const canadianProvinces = [
  ["BC", "British Columbia"],
  ["ON", "Ontario"],
  ["NL", "Newfoundland and Labrador"],
  ["NS", "Nova Scotia"],
  ["PE", "Prince Edward Island"],
  ["NB", "New Brunswick"],
  ["QC", "Quebec"],
  ["MB", "Manitoba"],
  ["SK", "Saskatchewan"],
  ["AB", "Alberta"],
  ["NT", "Northwest Territories"],
  ["NU", "Nunavut"],
  ["YT", "Yukon Territory"],
];

// const scrollEnabled = Platform.select({ web: true, default: false });

const schema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.'-]+$/i, "Must be a valid first name")
    .required("Can't be blank"),
  lastName: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.'-]+$/i, "Must be a valid last name")
    .required("Can't be blank"),
  birthDate: yup
    .string()
    .matches(/^\d\d\/\d\d\/\d\d\d\d$/i, "Must be a valid date")
    .required("Can't be blank"),
  mobileNumber: yup
    .string()
    .transform((_, val) => (!val ? null : `+${(val ?? "").replace(/\D/g, "")}`))
    .matches(/^\+1\d\d\d\d\d\d\d\d\d\d$/i, "Must be a valid US phone number")
    .required("Can't be blank"),
  address: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(55, "Too long - should be 55 chars maximum.")
    .required("Can't be blank"),
  city: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.'-]+$/i, "Must be a valid last name")
    .required("Can't be blank"),
  state: yup
    .string()
    .matches(/^[A-Z][A-Z]$/, "Must be a valid state abbrev")
    .when("country", country =>
      yup
        .string()
        .oneOf(
          country === "CA"
            ? canadianProvinces.map(([abbrev, _name]) => abbrev)
            : STATES.map(([_name, abbrev]) => abbrev),
          "Must be a valid state abbrev"
        )
    )
    .oneOf(
      STATES.map(([_name, abbrev]) => abbrev),
      "Must be a valid state abbrev"
    )
    .required("Can't be blank"),
  zip: yup
    .string()
    // .matches(/^\d\d\d\d\d+$/i, "Must be a valid zip code")
    .required("Can't be blank")
    .when("country", country =>
      yup
        .string()
        .matches(
          country === "CA" ? /^\w\w\w\w\w\w$/ : /^\d\d\d\d\d+$/,
          "Must be a valid zip code"
        )
    ),
  country: yup
    .string()
    .matches(/^\w\w$/i, "Must be a valid country")
    .required("Can't be blank"),
  email: yup
    .string()
    .email("Must be a valid email address")
    .required("Can't be blank"),
  displayName: yup
    .string()
    .min(3, "Too short - should be 3 chars minimum.")
    .max(12, "Too long - should be 12 chars maximum.")
    .required("Can't be blank"),
  password: yup
    .string()
    // .required("Can't be blank")
    .min(8, "Too short - should be 8 chars minimum.")
    .max(48, "Too long - should be 48 chars maximum.")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .nullable(true)
    .transform((_, val) => (!val ? null : val)),
});

const mobileApprovalSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.'-]+$/i, "Must be a valid first name")
    .required("Can't be blank"),
  lastName: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.'-]+$/i, "Must be a valid last name")
    .required("Can't be blank"),
  birthDate: yup
    .string()
    .matches(/^\d\d\/\d\d\/\d\d\d\d$/i, "Must be a valid date")
    .required("Can't be blank"),
  mobileNumber: yup
    .string()
    .transform((_, val) => (!val ? null : `+${(val ?? "").replace(/\D/g, "")}`))
    .matches(/^\+1\d\d\d\d\d\d\d\d\d\d$/i, "Must be a valid US phone number")
    .required("Can't be blank"),
  address: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(55, "Too long - should be 55 chars maximum.")
    .required("Can't be blank"),
  city: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.'-]+$/i, "Must be a valid last name")
    .required("Can't be blank"),
  state: yup
    .string()
    .matches(/^[A-Z][A-Z]$/, "Must be a valid state abbrev")
    .when("country", country =>
      yup
        .string()
        .oneOf(
          [
            ...canadianProvinces.map(([abbrev, _name]) => abbrev),
            ...STATES.map(([_name, abbrev]) => abbrev),
          ],
          "Must be a valid state abbrev"
        )
    )
    .oneOf(
      STATES.map(([_name, abbrev]) => abbrev),
      "Must be a valid state abbrev"
    )
    .required("Can't be blank"),
  zip: yup
    .string()
    // .matches(/^\d\d\d\d\d+$/i, "Must be a valid zip code")
    .required("Can't be blank")
    .when("country", country =>
      yup.string().matches(/^(?:\w{6}|\d{5,})$/, "Must be a valid zip code")
    ),
  country: yup
    .string()
    .matches(/^\w\w$/i, "Must be a valid country")
    .required("Can't be blank"),
  agree: yup
    .boolean()
    .required()
    .oneOf([true], "Must agree to terms and conditions"),
});

export default ({
  view,
  theme,
  currentUser,
  readOnly,
  skipScroll,
  onSubmit: passedOnSubmit,
}) => {
  const { styles } = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setError,
    setValue,
    watch,
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(
      view === "MOBILE_APPROVAL" ? mobileApprovalSchema : schema
    ),
    defaultValues:
      view === "MOBILE_APPROVAL"
        ? {
            agree: false,
          }
        : undefined,
  });
  const ScrollElement = skipScroll ? React.Fragment : ScrollView;
  const country = watch("country");

  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const cognitoUserCountry = cognitoUser?.attributes?.["custom:country"];

  const inputs = useRef({});
  const [updateDisplayName] = useMutation(User.mutations.update);
  const [updateAttributes] = useMutation(User.mutations.updateAttributes);
  const gidxRefreshSession = useReactiveVar(gidxRefresSessionVar);

  const handleSubmitError = useCallback(
    (field, error) =>
      setError(field, {
        ...error,
        message: error.message,
      }),
    [setError]
  );

  const client = useApolloClient();
  const onSubmit = useCallback(
    ({
      // email,
      firstName,
      lastName,
      birthDate,
      mobileNumber,
      address,
      address2,
      city,
      state,
      zip,
      displayName,
      password,
      country,
    }) =>
      client
        .query({
          fetchPolicy: "network-only",
          query: User.queries.listByDisplayName,
          variables: {
            displayName,
          },
        })
        .then(
          ({
            data: {
              listUsersByDisplayName: { items },
            },
          }) => !!items.length
        )
        .then(displayNameTaken =>
          !!displayNameTaken && displayName !== currentUser?.displayName
            ? Promise.all([
                handleSubmitError("displayName", {
                  message: "Display Name has been taken",
                }),
                Promise.reject(new Error("Display Name has been taken")),
              ])
            : Promise.all([
                updateDisplayName({
                  variables: {
                    input: {
                      id: currentUser?.id,
                      displayName: displayName.toLowerCase(),
                    },
                  },
                }),
                Auth.currentAuthenticatedUser({ bypassCache: true })
                  .then(cognitoUser =>
                    Auth.updateUserAttributes(cognitoUser, {
                      ...removeEmpty({
                        given_name: firstName,
                        family_name: lastName,
                        "custom:mobile": mobileNumber,
                        address,
                        "custom:city": city,
                        "custom:state": state,
                        "custom:zip": zip,
                        birthdate: birthDate,
                        "custom:country": country,
                      }),
                      "custom:address2": address2 ?? null,
                    })
                  )
                  .catch(console.error),
                updateAttributes({
                  variables: {
                    input: {
                      id: currentUser?.id,
                      firstName,
                      lastName,
                      mobileNumber,
                      address,
                      city,
                      state,
                      zip,
                      password,
                    },
                  },
                }),
              ])
        )
        .then(() => {
          gidxRefreshSession(true);
          return Auth.currentAuthenticatedUser({ bypassCache: true }).then(
            setCognitoUser
          );
        })
        .then(() =>
          Platform.OS === "web"
            ? window.alert("Information Updated!")
            : Alert.alert("Information Updated!")
        )
        .catch(console.error),
    [
      client,
      currentUser?.displayName,
      currentUser?.id,
      gidxRefreshSession,
      handleSubmitError,
      updateAttributes,
      updateDisplayName,
    ]
  );

  const doSubmit = handleSubmit(passedOnSubmit ?? onSubmit);

  const focusField = field => () => inputs.current[field].focus();

  useEffect(() => {
    setValue("country", cognitoUserCountry);
  }, [setValue, cognitoUserCountry]);

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(setCognitoUser);
  }, []);

  useEffect(() => {
    !!country && trigger("zip");
    !!country && trigger("state");
  }, [country, trigger]);

  return !cognitoUser?.attributes ? null : (
    <AccountFormView
      country={country}
      control={control}
      theme={theme}
      inputs={inputs}
      errors={errors}
      currentUser={currentUser}
      cognitoUser={cognitoUser}
      isValid={isValid}
      isSubmitting={isSubmitting}
      onSubmit={doSubmit}
      onNext={focusField}
      secureTextEntry={secureTextEntry}
      setSecureTextEntry={setSecureTextEntry}
      readOnly={readOnly}
      setShowDropDown={setShowDropDown}
      showDropDown={showDropDown}
      ScrollElement={ScrollElement}
      view={view}
      styles={styles}
    />
  );
};
