import gql from "graphql-tag";
import environment from "environment";

const Provider = {};

Provider.fragments = {
  public: gql`
    fragment ProviderPublic on Provider {
      id
      createdAt
      updatedAt
      name
      allowOnPropSwap
      allowOnOtherProviders
      allowAcrossLocales
      balance
      managerGroup
      sellUrl
      buyUrl
      winUrl
      creditUrl
      oauthUrl
      sellerFee
      buyerFee
    }
  `,
};

Provider.queries = {
  listEmailsOfManagersForProvider: gql`
    query ListEmailsOfManagersForProvider($id: ID!) {
      listEmailsOfManagersForProvider(id: $id)
    }
  `,
  list: gql`
    query ListProviders(
      $filter: ModelProviderFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...ProviderPublic
        }
      }
    }
    ${Provider.fragments.public}
  `,
  get: gql`
    query GetProvider($id: ID!) {
      getProvider(id: $id) {
        ...ProviderPublic
      }
    }
    ${Provider.fragments.public}
  `,
};

Provider.mutations = {
  sendOauthCodeToProvider: gql`
    mutation SendOauthCodeToProvider($code: String!) {
      sendOauthCodeToProvider(code: $code) {
        ...ProviderPublic
      }
    }
    ${Provider.fragments.public}
  `,
  assignUserToProvider: gql`
    mutation AssignUserToProvider($email: String!, $id: ID!) {
      assignUserToProvider(email: $email, id: $id) {
        ...ProviderPublic
      }
    }
    ${Provider.fragments.public}
  `,
  create: gql`
    mutation CreateProvider($input: CreateProviderInput!) {
      createProvider(input: $input) {
        ...ProviderPublic
      }
    }
    ${Provider.fragments.public}
  `,
  update: gql`
    mutation UpdateProvider($input: UpdateProviderInput!) {
      updateProvider(input: $input) {
        ...ProviderPublic
      }
    }
    ${Provider.fragments.public}
  `,
  delete: gql`
    mutation DeleteProvider($input: DeleteProviderInput!) {
      deleteProvider(input: $input) {
        ...ProviderPublic
      }
    }
    ${Provider.fragments.public}
  `,
};

export default Provider;
