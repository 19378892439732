import { MaterialIcons } from "@expo/vector-icons";
import FilterScreen from "Components/FilterScreen";

export default ({
  theme,
  onPress,
  filterItems,
  showBottomSheet,
  onBottomSheetDismiss,
  selectedValue,
  onValueChange,
  generatingPoll,
}) => (
  <>
    <MaterialIcons
      name="share"
      size={24}
      color={theme.colors.secondary}
      onPress={onPress}
    />
    <FilterScreen
      items={filterItems}
      theme={theme}
      visible={showBottomSheet}
      onDismiss={onBottomSheetDismiss}
      title={generatingPoll ? "Creating Poll..." : "Share Ticket"}
      selectedValue={null}
      onValueChange={onValueChange}
      fullScreen
    />
  </>
);
