import { Modal, Portal, useTheme } from "react-native-paper";
import { View, useWindowDimensions, Platform } from "react-native";
import BottomSheetHeader from "./Header/BottomSheetHeaderContainer";

export default ({
  children,
  visible,
  height: passedHeight,
  onDismiss,
  fullWidth,
  fullScreen,
  withHeader,
  title,
  containerStyle: passedContainerStyle,
}) => {
  const theme = useTheme();
  const height = useWindowDimensions().height;
  const containerStyle = {
    backgroundColor: theme.colors.surface,
    paddingVertical: 20,
    paddingHorizontal: !!fullWidth ? 0 : theme.spacing.xl,
    bottom: 0,
    position: "absolute",
    width: "100%",
    // ...(passedContainerStyle??{})
  };

  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={onDismiss}
        contentContainerStyle={[
          containerStyle,
          !!withHeader
            ? {
                paddingVertical: 0,
                borderTopLeftRadius: theme.roundness,
                borderTopRightRadius: theme.roundness,
                height: Platform.OS === "web" ? "auto" : "height",
                justifyContent: "flex-start",
                backgroundColor: theme.colors.background,
              }
            : {},
          passedContainerStyle ?? {},
        ]}
      >
        {!!withHeader && (
          <BottomSheetHeader onDismiss={onDismiss} title={title} />
        )}
        {!!fullScreen && (
          <View
            style={{
              height: !!passedHeight
                ? (parseFloat(passedHeight) / 100) * height
                : height,
            }}
          >
            {children}
          </View>
        )}
        {!fullScreen && children}
      </Modal>
    </Portal>
  );
};
