import React from "react";
import Screen from "Components/Screen";
import PhotoUpload from "Components/PhotoUpload";
import { useTheme } from "react-native-paper";

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen
      gatedTo="member"
      title="My Photo"
      backgroundColor={theme.colors.surface}
    >
      <PhotoUpload />
    </Screen>
  );
};
