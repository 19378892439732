import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { useNavigation } from "@react-navigation/core";
import BottomSheet from "Components/BottomSheet";
import Confirmation from "Components/Confirmation";
import { CurrentUserContext } from "Contexts/CurrentUser";
import useHighBidForListing from "Hooks/useHighBidForListing";
import useRecordTicketView from "Hooks/useRecordTicketView";
import useStyles from "Hooks/useStyles";
import { useCallback, useMemo, useContext, useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { formatPennies } from "shared/Util/money";
import Bid from "shared/api/Bid";
import Event from "shared/api/Event";
import Listing from "shared/api/Listing";
import Option from "shared/api/Option";
import Ticket from "shared/api/Ticket";

import TicketDetailStyles from "./TicketDetailStyles";
import TicketDetailView from "./TicketDetailView";

export default ({ ticketObj, ticketId, listingObj, listingId }) => {
  const currentUser = useContext(CurrentUserContext);
  const { theme, styles } = useStyles({ Styles: TicketDetailStyles });
  const navigation = useNavigation();
  const [cancelledBidData, setCancelledBidData] = useState(null);
  const isLoggedIn = !!currentUser;

  const handleBottomSheetDismiss = useCallback(
    () => setCancelledBidData(null),
    []
  );

  const [mode, setMode] = useState(null);

  const handleModeChange = useCallback(
    mode => () => {
      if (isLoggedIn) {
        setMode(mode);
      } else {
        navigation.push("AuthStack", { screen: "Login" });
      }
    },
    [isLoggedIn, navigation]
  );

  const { data: { getListing } = {} } = useQuery(Listing.queries.get, {
    skip: !listingId,
    pollInterval: 6000,
    variables: {
      id: listingId,
      withSeller: true,
    },
  });

  const { data: { bidsByListingId: { items: bids = [] } = {} } = {} } =
    useQuery(Bid.queries.listByListingId, {
      skip: !listingId,
      pollInterval: 6000,
      variables: {
        limit: 10,
        bidListingId: listingId,
        sortDirection: "DESC",
        filter: {
          status: {
            eq: "PENDING",
          },
        },
      },
    });

  const { data: { getTicket } = {} } = useQuery(Ticket.queries.get, {
    fetchPolicy: "network-only",
    skip: !ticketId && !getListing?.listingTicketId,
    variables: {
      id: ticketId ?? getListing?.listingTicketId,
    },
  });

  const memoizedListing = useMemo(
    () => getListing ?? listingObj,
    [getListing, listingObj]
  );
  const cachedTicket = useMemo(
    () => memoizedListing?.cachedTicket,
    [memoizedListing]
  );

  const memoizedTicket = useMemo(
    () => getTicket ?? ticketObj ?? cachedTicket,
    [getTicket, ticketObj, cachedTicket]
  );
  const existingBid = useMemo(
    () =>
      (currentUser?.pendingBids ?? []).find(
        bid => bid.bidListingId === memoizedListing?.id
      ),
    [currentUser, memoizedListing]
  );
  const client = useApolloClient();
  const eventId =
    memoizedTicket?.cachedBets?.[0]?.eventId ?? memoizedTicket?.ticketEventId;
  const optionId =
    memoizedTicket?.cachedBets?.[0]?.optionId ?? memoizedTicket?.ticketOptionId;

  const { data: { getOption } = {} } = useQuery(Option.queries.get, {
    skip: !optionId || currentUser?.id !== memoizedListing?.listingSellerId,
    variables: {
      id: optionId,
    },
  });

  const { data: { getEvent } = {} } = useQuery(Event.queries.get, {
    skip: !eventId || currentUser?.id !== memoizedListing?.listingSellerId,
    variables: {
      id: eventId,
    },
  });

  const fetchUntilNew = useCallback(
    ticketId =>
      client
        .query({
          fetchPolicy: "network-only",
          query: Listing.queries.listByTicketId,
          limit: 100,
          variables: {
            listingTicketId: ticketId,
            status: {
              eq: "NEW",
            },
          },
        })
        .then(
          ({
            data: {
              listingsByTicketId: { items },
            },
          }) => (!!items.length ? items[0] : fetchUntilNew(ticketId))
        ),
    [client]
  );

  const handleEditPress = useCallback(
    () => navigation.navigate("ListingEdit", { id: memoizedListing?.id }),
    [navigation, memoizedListing]
  );

  const handleUserPress = useCallback(
    () =>
      navigation.navigate("UserDetail", {
        id: memoizedListing?.listingSellerId,
      }),
    [navigation, memoizedListing]
  );

  const handleSubmit = useCallback(
    entry =>
      entry?.data?.createNewBid
        ? handleModeChange(null)()
        : fetchUntilNew(entry.data.buyListing.listingTicketId).then(listing =>
            navigation.replace("ListingDetail", {
              id: listing.id,
              purchasedListingId: entry.data.buyListing.id,
            })
          ),
    [handleModeChange, navigation, fetchUntilNew]
  );

  const [cancelBid] = useMutation(Bid.mutations.updateBidStatus, {
    variables: {
      input: {
        id: existingBid?.id,
        status: "CANCELLED",
      },
    },
    refetchQueries: [
      {
        query: Bid.queries.listByBidderId,
        variables: {
          bidBidderId: currentUser?.id,
          statusUpdatedAt: {
            beginsWith: {
              status: "PENDING",
            },
          },
          sortDirection: "DESC",
          limit: 100,
        },
      },
    ],
  });

  const handleCancel = useCallback(
    () => cancelBid().then(({ data }) => setCancelledBidData(data)),
    [cancelBid]
  );

  const handleNavigateToBid = useCallback(
    ({ bidId, action }) =>
      () =>
        navigation.push("BidDecide", { id: bidId, action }),
    [navigation]
  );

  const handleBidderPress = useCallback(
    bidder => navigation.push("UserDetail", { id: bidder?.id }),
    [navigation]
  );

  const minBid = !!bids[0]?.offer
    ? bids[0]?.offer
    : memoizedListing?.minimumBidPrice ?? 100;
  const highBid = useHighBidForListing({ listing: memoizedListing });

  useRecordTicketView({
    ticketId: memoizedTicket?.id,
    skip: !memoizedTicket?.id,
  });

  return !memoizedTicket ? null : (
    <>
      <TicketDetailView
        hasBids={!!bids[0]?.offer}
        option={getOption}
        event={getEvent}
        ticket={memoizedTicket}
        listing={memoizedListing}
        currentUser={currentUser}
        theme={theme}
        styles={styles}
        onModeButtonPress={handleModeChange}
        onEditButtonPress={handleEditPress}
        onUserPress={handleUserPress}
        onCancelButtonPress={handleCancel}
        mode={mode}
        minBid={minBid}
        pendingBid={existingBid}
        onSubmit={handleSubmit}
        highBid={highBid}
        onNavigateToBid={handleNavigateToBid}
        onBidderPress={handleBidderPress}
      />
      <BottomSheet
        visible={!!cancelledBidData}
        onDismiss={handleBottomSheetDismiss}
        fullScreen
      >
        <Confirmation
          title="Bid Cancelled"
          type="success"
          primaryButtonLabel="Close"
          onPrimaryButtonPress={handleBottomSheetDismiss}
          headline={memoizedTicket.subject}
          subheading={memoizedTicket.eventDescription}
          More={
            <ConfirmationBody
              theme={theme}
              styles={styles}
              listing={memoizedListing}
              ticket={memoizedTicket}
            />
          }
        />
      </BottomSheet>
    </>
  );
};

const ConfirmationBody = ({ theme, listing, ticket }) => (
  <View style={{ flexDirection: "row" }}>
    <View style={{ flexDirection: "row", marginRight: theme.spacing.sm }}>
      <Text style={{ fontWeight: "bold" }}>Odds: </Text>
      <Text>{!!listing.askingPrice ? listing.newOdds : ticket.odds}</Text>
    </View>
    <View style={{ flexDirection: "row", marginLeft: theme.spacing.sm }}>
      <Text style={{ fontWeight: "bold" }}>Price: </Text>
      <Text>
        $
        {formatPennies(
          !!listing.askingPrice
            ? listing.salesPrice ?? listing.askingPrice
            : ticket.betAmount
        )}
      </Text>
    </View>
  </View>
);
