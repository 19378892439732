import { useContext } from "react";
import { gql, makeVar, useMutation } from "@apollo/client";
import useGidxGeoInfo from "./useGidxGeoInfo";
import { CurrentUserContext } from "Contexts/CurrentUser";

const gidxCreateSession = gql`
  mutation GidxCreateSession($input: GidxCreateSessionInput!) {
    gidxCreateSession(input: $input) {
      ApiKey
      ApiVersion
      MerchantID
      MerchantSessionID
      ResponseCode
      ResponseMessage
      SessionID
      ReasonCodes
      PaymentAmounts {
        BonusAmount
        PaymentAmount
      }
      PaymentMethods {
        Token
        DisplayName
        Type
        NameOnAccount
        PhoneNumber
        CVV
        CardNumber
        ExpirationDate
        Network
        AVSResult
        CVVResult
        AccountNumber
        RoutingNumber
      }
    }
  }
`;

export const gidxSessionVar = makeVar(null);
export const gidxRefresSessionVar = makeVar(true);

const useGidxPrepareSession = ({ ip, latitude, longitude } = {}) => {
  const currentUser = useContext(CurrentUserContext);
  const gidxInfo = useGidxGeoInfo();

  const input = {
    CustomerIpAddress: ip ?? gidxInfo?.ip,
    DeviceGPS: {
      Latitude: latitude ?? gidxInfo?.latitude,
      Longitude: longitude ?? gidxInfo?.longitude,
    },
    CustomerRegistration: {
      CustomerIpAddress: ip ?? gidxInfo?.ip,
      MerchantCustomerID: currentUser?.id,
      FirstName: currentUser?.attributes?.given_name,
      LastName: currentUser?.attributes?.family_name,
      DateOfBirth: currentUser?.attributes?.birthdate,
      EmailAddress: currentUser?.email,
      CitizenshipCountryCode: currentUser?.attributes?.["custom:country"],
      AddressLine1: currentUser?.attributes?.address,
      City: currentUser?.attributes?.["custom:city"],
      StateCode: currentUser?.attributes?.["custom:state"],
      PostalCode: currentUser?.attributes?.["custom:zip"],
      CountryCode: currentUser?.attributes?.["custom:country"],
    },
  };

  return useMutation(gidxCreateSession, {
    variables: {
      input,
    },
  });
};

export default useGidxPrepareSession;
