import { View } from "react-native";

import { promoRightDark, promoRightLight } from "assets";

export default ({
  screenWidth,
  centerColumnWidth,
  rightColumnWidth,
  theme,
}) => (
  <View
    nativeID="sidebar-right"
    style={{
      backgroundColor: !!theme.dark
        ? theme.colors.surface
        : theme.colors.background,
      // zIndex: 2000,
      // position: "fixed",
      // left:
      //   screenWidth -
      //   rightColumnWidth -
      //   (screenWidth / 2 - centerColumnWidth + 36),
      // top: 0,
      width: rightColumnWidth,
      // paddingHorizontal: 16,
      // paddingVertical: 32,
      // height: "100vh",
      borderLeftColor: theme.colors.border,
      borderLeftStyle: "solid",
      borderLeftWidth: 1,
    }}
  >
    <img
      src={theme.dark ? promoRightDark : promoRightLight}
      style={{
        width: "100%",
        height: "auto",
      }}
    />
  </View>
);
