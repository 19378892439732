import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import Home from "Screens/Home";
import WatchList from "Screens/WatchList";
import Notifications from "Screens/Notifications";
import Activity from "Screens/Activity";
import ListingModalStack from "./ListingModalStack";
import ProfileStack from "./ProfileStack";
import PointsStack from "./PointsStack";
import DepositStack from "./DepositStack";
import { getPartialModalOptions, headerNavOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator screenOptions={headerNavOptions}>
      <Stack.Screen name="Home" component={Home} />
      <Stack.Screen name="Activity" component={Activity} />
      <Stack.Screen name="WatchList" component={WatchList} />
      <Stack.Screen name="Notifications" component={Notifications} />
      <Stack.Screen
        name="ListingModalStack"
        component={ListingModalStack}
        options={getPartialModalOptions({ screenOptions, navigation })}
      />
      <Stack.Screen name="ProfileStack" component={ProfileStack} />
      <Stack.Screen name="PointsStack" component={PointsStack} />
      <Stack.Screen name="DepositStack" component={DepositStack} />
    </Stack.Navigator>
  );
};
