import Screen from "Components/Screen";
import { useTheme } from "react-native-paper";
import { useCallback, useMemo, useState, useContext } from "react";
import TicketItem from "Components/Ticket/Item";
import Listing from "shared/api/Listing";
import DataList from "Components/DataList";
import { Platform, View } from "react-native";
import EmptySign from "Components/EmptySign";
import ScreenNav from "Components/Screen/Nav";
import { AntDesign } from "@expo/vector-icons";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { ScrollDirectionContext } from "Contexts/ScrollDirection";
import Spacer from "Components/Spacer";
import { handleScroll } from "Util/";

let init = 0;

export default ({ navigation, route }) => {
  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);
  const [subNavValue, setSubNavValue] = useState("Awaiting");
  // const [counter, setCounter] = useState(0);
  const { setScrollDirection } = useContext(ScrollDirectionContext);
  const [hasItems, setHasItems] = useState(null);

  const handleSubNavChange = useCallback(value => setSubNavValue(value), []);

  const handleItems = useCallback(items => setHasItems(!!items?.length), []);

  const dataListQuery = useMemo(
    () => ({
      dataKey: "searchListings",
      query: Listing.queries.search,
      variables: {
        sort: {
          direction: "desc",
          field: "soldAt",
        },
        filter: {
          and: [
            {
              listingSellerId: {
                eq: currentUser?.id ?? "<>",
              },
            },
            {
              status: {
                eq: subNavValue === "Awaiting" ? "PENDING" : "SOLD",
              },
            },
          ],
        },
      },
    }),
    [subNavValue, currentUser]
  );

  // const dataListQuery = useMemo(() => ({
  //   dataKey: "listingsBySellerId",
  //   query: Listing.queries.listBySellerId,
  //   variables: {
  //     sortDirection: "DESC",
  //     listingSellerId: currentUser?.id ?? "<>",
  //     status: {
  //       eq: subNavValue === 'Awaiting' ? 'PENDING' : 'SOLD'
  //     }
  //   }
  // }), [
  //   subNavValue,
  //   currentUser
  // ])

  const dataListRenderItem = useCallback(
    ({ item, index }) => (
      <View
        style={{
          marginBottom: theme.spacing.md,
        }}
      >
        <TicketItem ticketObj={item.cachedTicket} listingObj={item} />
      </View>
    ),
    [theme]
  );

  // useEffect(() => {
  //   setCounter(counter => counter + 1);
  // }, [subNavValue])

  return (
    <Screen fullHeight fullWidth gatedTo="member">
      <ScreenNav
        itemLeft={<Spacer />}
        // itemLeft={
        //   <MaterialCommunityIcons
        //     name="information"
        //     size={18}
        //     color={theme.colors.onSurface}
        //     onPress={() => navigation.push("TicketsWarning")}
        //   />
        // }
        itemRight={
          <AntDesign
            name="pluscircleo"
            size={18}
            color={theme.colors.onSurface}
            onPress={() =>
              navigation.push("TicketNewStack", {
                screen:
                  !!currentUser?.bluesnapVaultedShopperId &&
                  !!currentUser?.verifiedAt
                    ? "TicketNewStep1"
                    : "TicketNewVerification",
              })
            }
          />
        }
        theme={theme}
        selectedValue={subNavValue}
        onValueChange={handleSubNavChange}
        buttons={[{ label: "Awaiting" }, { label: "Sold" }]}
      />

      <DataList
        fetchPolicy="network-only"
        onScroll={
          Platform.OS !== "web"
            ? null
            : handleScroll(
                () => setScrollDirection("up"),
                () => setScrollDirection("down")
              )
        }
        onScrollBeginDrag={
          Platform.OS === "web"
            ? null
            : event => {
                init = event.nativeEvent.contentOffset.y;
              }
        }
        onScrollEndDrag={
          Platform.OS === "web"
            ? null
            : event =>
                init > event.nativeEvent.contentOffset.y
                  ? setScrollDirection("up")
                  : setScrollDirection("down")
        }
        // counter={counter}
        query={dataListQuery}
        renderItem={dataListRenderItem}
        onFetchItems={handleItems}
        contentContainerStyle={{
          flex: !!hasItems ? undefined : 1,
          marginHorizontal: theme.spacing.xl,
          paddingTop: theme.spacing.xl,
        }}
        ListEmptyComponent={
          <EmptySign
            title="Learn How To Sell"
            subheading={
              subNavValue === "Awaiting"
                ? "You don't have any pending sales."
                : "You Have Not Sold Any Tickets Yet."
            }
            navLabel="Learn Now"
            onNavPress={() => navigation.push("ListingLearn")}
          />
        }
      />
    </Screen>
  );
};
