import { useTheme } from "react-native-paper";
import Screen from "Components/Screen";
import { useContext } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import AccountForm from "Components/Account/Form";

export default ({ navigation }) => {
  const currentUser = useContext(CurrentUserContext);
  const theme = useTheme();

  return (
    <Screen
      gatedTo="member"
      title="My Account"
      backgroundColor={theme.colors.surface}
      fullHeight
      fullWidth
      hasKeyboard
      keyboardAdjust={theme.spacing.xl * 4}
    >
      {!!currentUser?.email && (
        <AccountForm theme={theme} currentUser={currentUser} />
      )}
    </Screen>
  );
};
