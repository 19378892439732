import { useQuery } from "@apollo/client";
import User from "shared/api/User";
import Screen from "Components/Screen";
import UserInfo from "Components/User/Info";
import { TabBarContext } from "Contexts/TabBar";
import moment from "moment";
import { useContext, useMemo, useCallback, useLayoutEffect } from "react";
import { Platform, View, TouchableWithoutFeedback } from "react-native";
import { useTheme, Text, Subheading, Paragraph } from "react-native-paper";
import DataList from "Components/DataList";
import Listing from "shared/api/Listing";

const scrollEnabled = Platform.select({ web: false, default: true });

export default ({ navigation, route, ...rest }) => {
  const theme = useTheme();
  const tabBar = useContext(TabBarContext);

  const { data: { getUser = {} } = {} } = useQuery(User.queries.get, {
    pollInterval: 6000,
    variables: {
      id: route.params?.id,
    },
  });

  const listingsDataListQuery = useMemo(
    () => ({
      dataKey: "searchListings",
      query: Listing.queries.search,
      variables: {
        // limit: 5,
        sort: {
          direction: "desc",
          field: "createdAt",
        },
        filter: {
          and: [
            {
              status: {
                eq: "ACTIVE",
              },
            },
            {
              listingSellerId: {
                eq: getUser?.id,
              },
            },
          ],
        },
      },
    }),
    [getUser]
  );
  const handleListingNavigation = useCallback(
    listing =>
      navigation.push("ListingModalStack", {
        screen: "ListingDetail",
        params: { id: listing.id },
      }),
    [navigation]
  );

  const listingsDataListRenderItem = useCallback(
    ({ item, index }) => (
      <TouchableWithoutFeedback onPress={() => handleListingNavigation(item)}>
        <View
          style={{
            flexDirection: "row",
            marginBottom: theme.spacing.lg,
            ...Platform.select({
              web: {
                cursor: "pointer",
              },
              default: {},
            }),
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <Text>{item.cachedTicket?.subject}</Text>
          </View>
          <View
            style={{
              flex: 1,
            }}
          >
            <Text style={{ textTransform: "capitalize" }}>
              {item.cachedTicket?.eventDescription}
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    ),
    [handleListingNavigation, theme.spacing.lg]
  );

  useLayoutEffect(() => {
    tabBar.setVisible(false);

    return () => tabBar.setVisible(true);
  }, [tabBar]);

  return (
    <Screen backgroundColor={theme.colors.surface} hasScroll>
      <UserInfo userObj={getUser} textColor={theme.colors.text} />

      {!!getUser?.bio && (
        <Paragraph style={{ flexShrink: 1 }}>{getUser.bio}</Paragraph>
      )}

      <View
        style={{
          marginTop: theme.spacing.xl,
          paddingBottom: theme.spacing.xl,
          borderColor: theme.colors.background,
          borderBottomWidth: 4,
          borderStyle: "dotted",
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text style={{ fontWeight: "bold", width: 80 }}>Joined: </Text>
          <Text>{moment(getUser?.createdAt).format("YYYY")}</Text>
        </View>
      </View>

      <View
        style={{
          marginTop: theme.spacing.xl,
          paddingBottom: theme.spacing.xl,
          borderColor: theme.colors.background,
          borderBottomWidth: 4,
          borderStyle: "dotted",
        }}
      >
        <Subheading>Tickets</Subheading>
        <View style={{ flexDirection: "row" }}>
          <Text style={{ fontWeight: "bold", width: 80 }}>Bought: </Text>
          <Text>{getUser?.ticketsBoughtCount || 0}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={{ fontWeight: "bold", width: 80 }}>Sold: </Text>
          <Text>{getUser?.ticketsSoldCount || 0}</Text>
        </View>
      </View>

      {/* <View 
        style={{
          marginTop: theme.spacing.xl, 
          paddingBottom: theme.spacing.xl, 
          borderColor: theme.colors.background, 
          borderBottomWidth: 4, 
          borderStyle: "dotted"
        }}
      >
        <Subheading>Activity</Subheading>
        <View style={{flexDirection: "row", marginBottom: theme.spacing.lg}}>
          <View
            style={{
              flex: 1
            }}
          >
            <Text style={{fontWeight: "bold"}}>Date</Text>
          </View>
          <View
            style={{
              flex: 1
            }}
          >
            <Text style={{fontWeight: "bold"}}>Type</Text>
          </View>
        </View>
        <DataList 
          query={dataListQuery}
          renderItem={dataListRenderItem}
          scrollEnabled={scrollEnabled}
        />
      </View> */}

      <View
        style={{
          marginTop: theme.spacing.xl,
          paddingBottom: theme.spacing.xl,
          borderColor: theme.colors.background,
          borderBottomWidth: 4,
          borderStyle: "dotted",
        }}
      >
        <Subheading>Tickets Currently For Sale</Subheading>
        <DataList
          query={listingsDataListQuery}
          renderItem={listingsDataListRenderItem}
          scrollEnabled={scrollEnabled}
        />
      </View>

      <View style={{ height: 100 }} />
    </Screen>
  );
};
