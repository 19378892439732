// import BlueSnapEncrypter from 'Components/BluesnapEncrypter';
import React, { useState, useEffect, useRef, useContext } from "react";
// import BlueSnapEncrypter from "react-native-bluesnap-encrypter";
import ENV from "../environment";
import { CurrentUserContext } from "./CurrentUser";
import { injectBluesnapJs, injectBluesnapJsForWebView } from "Util/";
import { Platform } from "react-native";
import WebView from "react-native-webview";

//"react-native-web-webview": "^1.0.2",

const key = ENV?.bluesnap_encryption_key;

const BluesnapEncrypterContext = React.createContext();

const BluesnapEncrypterProvider = ({ children }) => {
  const [encryptedCreditCard, setEncryptedCreditCard] = useState(null);
  const [creditCard, setCreditCard] = useState(null);
  const currentUser = useContext(CurrentUserContext);
  const creditCardNumber = creditCard?.number;
  const creditCardCvv = creditCard?.cvv;
  const runFirst = injectBluesnapJsForWebView({
    bluesnapVersion: "1.0.3",
    clientEncryptionKey: key,
    fraudSessionId: currentUser?.id ?? "guest",
  });
  const webview = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !!creditCardNumber && !!creditCardCvv
      ? Platform.OS === "web"
        ? window
            .bluesnapencrypt({
              data: {
                creditCardNumber,
                cvvNumber: creditCardCvv,
              },
            })
            .then(encrypted => setEncryptedCreditCard(encrypted))
            .catch(e => console.error(e))
        : webview.current.injectJavaScript(`
          window.bluesnapencrypt({data: {
            creditCardNumber: "${creditCardNumber}",
            cvvNumber: "${creditCardCvv}"
          }});
          true;
        `)
      : setEncryptedCreditCard(null);
  }, [creditCardNumber, creditCardCvv]);

  Platform.OS === "web" &&
    injectBluesnapJs({
      bluesnapVersion: "1.0.3",
      clientEncryptionKey: key,
      fraudSessionId: currentUser?.id ?? "guest",
    });

  return (
    <BluesnapEncrypterContext.Provider
      value={{ setCreditCard, encryptedCreditCard }}
    >
      <>
        {Platform.OS !== "web" && (
          <WebView
            containerStyle={{ position: "absolute", width: 0, height: 0 }}
            javaScriptEnabled
            domStorageEnabled
            ref={webview}
            source={{
              baseUrl: "https://propswap.com",
              html: `<html><head></head><body></body></html>`,
            }}
            injectedJavaScript={runFirst}
            onMessage={event => {
              setEncryptedCreditCard(JSON.parse(event.nativeEvent.data));
            }}
            originWhitelist={["*"]}
          />
        )}
        {children}
      </>
    </BluesnapEncrypterContext.Provider>
  );
};

export { BluesnapEncrypterContext, BluesnapEncrypterProvider };
