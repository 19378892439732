import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { getExpoPushTokenAsync } from "expo-notifications";
// import * as Permissions from 'expo-permissions';
import { Platform } from "react-native";
import Device from "shared/api/Device";
import * as Notifications from "expo-notifications";

const normalizeToken = token =>
  !!token?.data
    ? token.data
    : /\{type=/.test(token ?? "")
    ? token.match(/data=([^\}]+)/)[1]
    : token;

/* Custom imports end here */

export default currentUser => {
  const [gavePermission, setGavePermission] = useState(null);
  const [token, setToken] = useState(null);
  const type = Platform.OS === "ios" ? "APNS" : "GCM";

  const [createDevice, { error: createError }] = useMutation(
    Device.mutations.create,
    {
      variables: {
        input: {
          token: normalizeToken(token),
          type,
        },
      },
      refetchQueries: [
        {
          query: Device.queries.listByTokenAndType,
          variables: {
            token: normalizeToken(token),
            type: {
              eq: type,
            },
          },
        },
      ],
    }
  );

  const {
    error: listError,
    data: { listDevicesByTokenAndType: { items: devices } = {} } = {},
  } = useQuery(Device.queries.listByTokenAndType, {
    skip: !token,
    variables: {
      token: normalizeToken(token),
      type: {
        eq: type,
      },
    },
  });

  const isLoggedIn = !!currentUser?.id;
  const shouldCreateNewDevice =
    !!devices &&
    !!token &&
    !devices.find(
      device => normalizeToken(device.token) === normalizeToken(token)
    );

  useEffect(() => {
    isLoggedIn &&
      // ExpoDevice.isDevice &&
      Notifications.getPermissionsAsync()
        .then(({ status: existingStatus }) =>
          existingStatus !== "granted"
            ? Notifications.requestPermissionsAsync().then(({ status }) =>
                setGavePermission(status === "granted")
              )
            : setGavePermission(existingStatus === "granted")
        )
        .catch(e => console.error(e) || setGavePermission(false));
  }, [isLoggedIn]);

  !!createError && console.error(createError);
  !!listError && console.error(listError);

  useEffect(() => {
    !!gavePermission &&
      getExpoPushTokenAsync()
        .then(token => setToken(token))
        .catch(e => console.error(e) || setToken(null));
  }, [gavePermission]);

  useEffect(() => {
    !!shouldCreateNewDevice && createDevice();
  }, [shouldCreateNewDevice, createDevice]);

  return gavePermission;
};
