import { useContext } from "react";
import { Divider, List, Subheading, useTheme } from "react-native-paper";

import Screen from "Components/Screen";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { Platform, View } from "react-native";
import useGidxSession from "Hooks/useGidxSession";

export default ({ navigation }) => {
  const currentUser = useContext(CurrentUserContext);
  const theme = useTheme();
  const gidxSession = useGidxSession();
  const hasPaymentMethod = (gidxSession?.PaymentMethods ?? []).length > 0;

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      hasKeyboard
      backgroundColor={theme.colors.surface}
    >
      <Subheading style={{ color: theme.colors.primary }}>
        Before you can begin selling, please complete the following items:
      </Subheading>

      <View style={{ marginHorizontal: theme.spacing.xl * -1 }}>
        <List.Item
          style={{
            ...Platform.select({
              web: {
                cursor: !hasPaymentMethod ? "pointer" : "default",
              },
              default: {},
            }),
          }}
          title="Add a Payment Method to your Wallet"
          onPress={
            !hasPaymentMethod && (() => navigation.push("AchAddAccount"))
          }
          right={props => (
            <List.Icon
              {...props}
              color={!hasPaymentMethod ? props.color : theme.colors.primary}
              icon={
                !hasPaymentMethod ? "chevron-right" : "check-circle-outline"
              }
            />
          )}
          description="This is for insurance purposes only. You do not need to deposit any funds into your account in order to sell."
          descriptionNumberOfLines={3}
        />
        <Divider />
        <List.Item
          style={{
            ...Platform.select({
              web: {
                cursor: !currentUser?.verifiedAt ? "pointer" : "default",
              },
              default: {},
            }),
          }}
          title="Verify your Age and ID"
          description=" "
          right={props => (
            <List.Icon
              {...props}
              color={
                !currentUser?.verifiedAt ? props.color : theme.colors.primary
              }
              icon={
                !currentUser?.verifiedAt
                  ? "chevron-right"
                  : "check-circle-outline"
              }
            />
          )}
          onPress={() =>
            !currentUser?.verifiedAt && navigation.push("Verification")
          }
        />
        <Divider />
      </View>
    </Screen>
  );
};
