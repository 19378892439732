import { useQuery, useMutation } from "@apollo/client";
import { useNavigation } from "@react-navigation/core";
import Listing from "shared/api/Listing";
import Ticket from "shared/api/Ticket";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { useCallback, useMemo, useContext } from "react";
import ListingEditView from "./ListingEditView";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import useMarketRatePrices from "Hooks/useMarketRatePrices";
import useStyles from "Hooks/useStyles";
import ListingEditStyles from "./ListingEditStyles";
import { calculateValueRating } from "Util/";

export default ({
  ticketObj,
  ticketId,
  listingObj,
  listingId,
  onSubmit: passedOnSubmit = (mutationResult, originalListing) => null,
}) => {
  const currentUser = useContext(CurrentUserContext);
  const { theme, styles } = useStyles({ Styles: ListingEditStyles });
  const navigation = useNavigation();

  const { data: { getListing } = {} } = useQuery(Listing.queries.get, {
    skip: !listingId,
    pollInterval: 6000,
    variables: {
      id: listingId,
      withSeller: true,
    },
  });

  const { data: { getTicket } = {} } = useQuery(Ticket.queries.get, {
    fetchPolicy: "network-only",
    skip: !ticketId && !getListing?.listingTicketId,
    variables: {
      id: ticketId ?? getListing?.listingTicketId,
    },
  });

  const memoizedListing = useMemo(
    () => getListing ?? listingObj,
    [getListing, listingObj]
  );
  const cachedTicket = useMemo(
    () => memoizedListing?.cachedTicket,
    [memoizedListing]
  );
  const memoizedTicket = useMemo(
    () => getTicket ?? ticketObj ?? cachedTicket,
    [getTicket, ticketObj, cachedTicket]
  );

  const schema = useMemo(
    () =>
      yup.object().shape({
        askingPrice: yup
          .number()
          .transform(value => (isNaN(value) ? undefined : parseFloat(value)))
          .moreThan(0, `Must be more than $0`)
          .lessThan(
            memoizedTicket?.collectAmount / 100.0,
            `Must be less than the Collect Amount`
          )
          .required("Can't be blank"),
        minimumBidPrice: yup
          .number()
          .transform((value, originalValue) =>
            !value || isNaN(value) ? null : parseFloat(value)
          )
          .positive()
          .optional()
          .nullable()
          .lessThan(yup.ref("askingPrice"), "Must be less than price"),
        notes: yup
          .string()
          .optional()
          .nullable()
          .max(280, "Must be 280 characters or less"),
        expiresAt: yup
          .date()
          .transform((value, originalValue) =>
            !originalValue
              ? undefined
              : !!originalValue?._isAMomentObject
              ? originalValue.toDate()
              : moment(originalValue, "YYYY-MM-DDTHH:mm", true).isValid()
              ? moment(originalValue, "YYYY-MM-DDTHH:mm", true).toDate()
              : null
          )
          .typeError("Must be a valid date (MM/DD/YYYY HH:MM am/pm)")
          .min(
            moment().add("2", "minutes").toDate(),
            "Must be at least 2 minutes ahead"
          )
          .optional(),
      }),
    [memoizedTicket]
  );

  const {
    control,
    handleSubmit,
    formState: { errors, /*isValid,*/ isSubmitting },
    setError,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const askingPrice = watch("askingPrice", memoizedListing?.askingPrice);
  const askingPriceInPennies = parseInt(parseFloat(askingPrice) * 100.0);

  const isValid = !Object.keys(errors).length;

  const [updateListing] = useMutation(
    memoizedListing?.status === "ACTIVE"
      ? Listing.mutations.pause
      : Listing.mutations.reactivate
  );
  const [saveListing] = useMutation(
    memoizedListing?.status === "ACTIVE"
      ? Listing.mutations.reactivate
      : Listing.mutations.pause
  );

  const handleSubmitError = useCallback(
    error =>
      setError("askingPrice", {
        ...error,
        message: `${error.code ?? ""} - ${error.message}`,
      }),
    [setError]
  );

  const onSave = useCallback(
    ({
      askingPrice,
      minimumBidPrice,
      expiresAt,
      notes,
      commissionWaiverApplied,
    }) => {
      const askingPriceInPennies = parseInt(parseFloat(askingPrice) * 100.0);
      const minimumBidPriceInPennies = !minimumBidPrice
        ? 0
        : parseInt(parseFloat(minimumBidPrice) * 100.0);

      return saveListing({
        variables: {
          input: {
            id: memoizedListing?.id,
            askingPrice: askingPriceInPennies,
            minimumBidPrice: minimumBidPriceInPennies,
            expiresAt: expiresAt || null,
            notes: notes || null,
            commissionWaiverApplied: !!commissionWaiverApplied,
          },
        },
      })
        .then(mutationResults =>
          passedOnSubmit(mutationResults, memoizedListing)
        )
        .catch(handleSubmitError);
    },
    [handleSubmitError, passedOnSubmit, memoizedListing, saveListing]
  );

  const onSubmit = useCallback(
    ({
      askingPrice,
      minimumBidPrice,
      expiresAt,
      notes,
      commissionWaiverApplied,
    }) => {
      const askingPriceInPennies = parseInt(parseFloat(askingPrice) * 100.0);
      const minimumBidPriceInPennies = !minimumBidPrice
        ? 0
        : parseInt(parseFloat(minimumBidPrice) * 100.0);

      return updateListing({
        variables: {
          input: {
            id: memoizedListing?.id,
            askingPrice: askingPriceInPennies,
            minimumBidPrice: minimumBidPriceInPennies,
            expiresAt: expiresAt || null,
            notes: notes || null,
            commissionWaiverApplied: !!commissionWaiverApplied,
          },
        },
      })
        .then(mutationResults =>
          passedOnSubmit(mutationResults, memoizedListing)
        )
        .catch(handleSubmitError);
    },
    [handleSubmitError, passedOnSubmit, memoizedListing, updateListing]
  );

  const doSubmit = handleSubmit(onSubmit);
  const doSave = handleSubmit(onSave);

  const marketRatePrices = useMarketRatePrices({
    eventId: memoizedTicket?.ticketEventId,
    optionId: memoizedTicket?.ticketOptionId,
    collectAmountInPennies: memoizedTicket?.collectAmount,
  });

  const valueRating =
    !!marketRatePrices &&
    calculateValueRating({
      marketValuePrice: marketRatePrices[0],
      askingPrice: askingPriceInPennies,
    });

  const handleUserPress = useCallback(
    () =>
      navigation.push("UserDetail", { id: memoizedListing?.listingSellerId }),
    [navigation, memoizedListing]
  );

  const handleCancel = useCallback(
    () => navigation.navigate("ListingDetail", { id: memoizedListing?.id }),
    [navigation, memoizedListing]
  );

  return !memoizedTicket || !memoizedListing ? null : (
    <>
      <ListingEditView
        errors={errors}
        control={control}
        ticket={memoizedTicket}
        listing={memoizedListing}
        currentUser={currentUser}
        theme={theme}
        onUserPress={handleUserPress}
        onCancelButtonPress={handleCancel}
        onSubmit={doSubmit}
        onSave={doSave}
        valueRating={valueRating}
        isValid={isValid}
        isSubmitting={isSubmitting}
        askingPriceInPennies={askingPriceInPennies}
        styles={styles}
      />
    </>
  );
};
