import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import DirectDepositSummary from "Screens/DirectDepositSummary";
import WithdrawAmount from "Screens/WithdrawAmount";
import WithdrawAchInfo from "Screens/WithdrawAchInfo";
import AchWithdrawSelect from "Screens/AchWithdrawSelect";
import { getStandardScreenOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={getStandardScreenOptions({ screenOptions, navigation })}
    >
      <Stack.Screen
        name="DirectDepositSummary"
        component={DirectDepositSummary}
      />
      <Stack.Screen name="WithdrawAmount" component={WithdrawAmount} />
      <Stack.Screen name="AchWithdrawSelect" component={AchWithdrawSelect} />
      <Stack.Screen name="WithdrawAchInfo" component={WithdrawAchInfo} />
    </Stack.Navigator>
  );
};
