import * as React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Button } from "react-native-paper";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation }) => {
  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <>
      <ScrollView>
        <View style={styles.buttons}>
          <Button
            mode="contained"
            onPress={() => navigation.navigate("MyTickets")}
            style={styles.button}
          >
            Go to my tickets
          </Button>
          <Button
            mode="contained"
            onPress={() => navigation.navigate("TicketNewStep1")}
            style={styles.button}
          >
            Upload a Similar Ticket
          </Button>
          <Button
            mode="contained"
            onPress={() => navigation.navigate("TicketNewStep1")}
            style={styles.button}
          >
            Upload a New Ticket
          </Button>
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  buttons: {
    padding: 8,
  },
  button: {
    margin: 8,
  },
});
