import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import CreditCardSummary from "Screens/CreditCardSummary";
import DepositAmount from "Screens/DepositAmount";
import CreditCardAddAccount from "Screens/CreditCardAddAccount";
import CreditCardSelect from "Screens/CreditCardSelect";
import Verification from "Screens/Verification";
import MyEmail from "Screens/MyEmail";
import { getStandardScreenOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator screenOptions={getStandardScreenOptions({ navigation })}>
      <Stack.Screen name="CreditCardSummary" component={CreditCardSummary} />
      <Stack.Screen name="DepositAmount" component={DepositAmount} />
      <Stack.Screen name="CreditCardSelect" component={CreditCardSelect} />
      <Stack.Screen
        name="CreditCardAddAccount"
        component={CreditCardAddAccount}
      />
      <Stack.Screen name="Verification" component={Verification} />
      <Stack.Screen name="MyEmail" component={MyEmail} />
    </Stack.Navigator>
  );
};
