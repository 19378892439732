import User from "shared/api/User";
import Bid from "shared/api/Bid";
import Watching from "shared/api/Watching";
import { useMutation, useQuery } from "@apollo/client";
import { useMemo, useEffect } from "react";
import Activity from "shared/api/Activity";
import moment from "moment";
import { Auth } from "aws-amplify";
//

export default ({ cognitoUser }) => {
  const isCurrent =
    !cognitoUser ||
    !!cognitoUser?.signInUserSession?.idToken?.payload?.realUserId;

  // const { loading, error, data: {usersByEmail: {items: users} = {}} = {}} = useQuery(User.queries.listByEmail, {
  //   skip: !cognitoUser?.attributes?.email,
  //   variables: {
  //     email: cognitoUser?.attributes?.email,
  //     withPrivate: true,
  //     limit: 1
  //   },
  //   pollInterval: 6000
  // });

  // const user = !!users && Array.isArray(users) ? users[0] : users;

  const {
    loading,
    error,
    data: { getUser: user } = {},
  } = useQuery(User.queries.get, {
    skip: !cognitoUser?.signInUserSession?.idToken?.payload?.realUserId,
    variables: {
      id: cognitoUser?.signInUserSession?.idToken?.payload?.realUserId,
      withPrivate: true,
    },
    pollInterval: 6000,
  });

  const isDisabled = !!user?.disabled;

  const {
    data: { listActivitiesByTypeAndUserId: { items: activities } = {} } = {},
  } = useQuery(Activity.queries.listByTypeAndUserId, {
    skip: !user?.id,
    pollInterval: 30000,
    variables: {
      type: "USER_ACTIVE",
      limit: 1,
      sortDirection: "DESC",
      userIdCreatedAt: {
        beginsWith: {
          userId: user?.id,
        },
      },
    },
  });

  const {
    data: { listWatchingsByUserId: { items: watchings = [] } = {} } = {},
  } = useQuery(Watching.queries.listByUserId, {
    skip: !user?.id,
    pollInterval: 6000,
    variables: {
      userId: user?.id,
      limit: 200,
      sortDirection: "DESC",
    },
  });

  const { data: { bidsByBidderId: { items: pendingBids = [] } = {} } = {} } =
    useQuery(Bid.queries.listByBidderId, {
      skip: !user?.id,
      pollInterval: 6000,
      variables: {
        bidBidderId: user?.id,
        statusUpdatedAt: {
          beginsWith: {
            status: "PENDING",
          },
        },
        sortDirection: "DESC",
        limit: 100,
      },
    });

  const shouldCreateActivity =
    (!!activities && activities.length === 0) ||
    (!!activities &&
      moment(activities[0].createdAt).diff(moment(), "hours", true) > 24);

  const [createActivity] = useMutation(Activity.mutations.create, {
    variables: {
      input: {
        subjectId: user?.id,
        type: "USER_ACTIVE",
        model: "User",
        modelId: user?.id,
        title: "Was Active",
        body: "Was Active",
        data: JSON.stringify(user),
        notificationCenterEligible: "NO",
        globalEligible: "NO",
      },
    },
  });

  const memoizedUser = useMemo(
    () => ({
      ...user,
      watchings,
      pendingBids,
      shouldCreateActivity,
    }),
    [user, watchings, pendingBids, shouldCreateActivity]
  );

  useEffect(() => {
    !!shouldCreateActivity && createActivity().catch(console.log);
  }, [createActivity, shouldCreateActivity]);

  useEffect(() => {
    !isCurrent &&
      Auth.signOut()
        .then(_data => window.location.reload(true))
        .catch(_err => window.location.reload(true));
  }, [isCurrent]);

  useEffect(() => {
    !!isDisabled &&
      Auth.signOut()
        .then(_data => window.location.reload(true))
        .catch(_err => window.location.reload(true));
  }, [isDisabled]);

  return {
    loading,
    error,
    user: memoizedUser,
  };
};
