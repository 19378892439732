import gql from "graphql-tag";
const Bet = {};

Bet.fragments = {
  public: gql`
    fragment BetPublic on Bet {
      id
      createdAt
      updatedAt
      ticketId
      optionId
      eventId
      sportId

      odds
      oddsCalculated
      eventDescription
      subject

      result

      # spread

      sport {
        id
        name
      }
      option {
        id
      }
      event {
        id
      }
      ticket {
        id
      }
    }
  `,
};

Bet.queries = {
  get: gql`
    query GetBet($id: ID!) {
      getBet(id: $id) {
        ...BetPublic
      }
    }
    ${Bet.fragments.public}
  `,
  listByTicketId: gql`
    query ListBetsByTicketId(
      $ticketId: ID!
      $createdAt: ModelStringKeyConditionInput
      $filter: ModelBetFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listBetsByTicketId(
        ticketId: $ticketId
        createdAt: $createdAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...BetPublic
        }
        nextToken
      }
    }
    ${Bet.fragments.public}
  `,
  listByUserId: gql`
    query BetsByUserId(
      $ticketUserId: ID!
      $updatedAt: ModelStringKeyConditionInput
      $filter: ModelBetFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      ticketsByUserId(
        ticketUserId: $ticketUserId
        updatedAt: $updatedAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...BetPublic
        }
        nextToken
      }
    }
    ${Bet.fragments.public}
  `,
  listByResultUpdatedAt: gql`
    query BetsByResultUpdatedAt(
      $result: BetResult!
      $updatedAt: ModelStringKeyConditionInput
      $filter: ModelBetFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      ticketsByResultUpdatedAt(
        result: $result
        updatedAt: $updatedAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...BetPublic
        }
        nextToken
      }
    }
    ${Bet.fragments.public}
  `,
  list: gql`
    query ListBets(
      $filter: ModelBetFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listBets(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...BetPublic
        }
        nextToken
      }
    }
    ${Bet.fragments.public}
  `,
};

Bet.mutations = {
  update: gql`
    mutation UpdateBet($input: UpdateBetInput!) {
      updateBet(input: $input) {
        ...BetPublic
      }
    }
    ${Bet.fragments.public}
  `,
  create: gql`
    mutation CreateBet($input: CreateBetInput!) {
      createBet(input: $input) {
        ...BetPublic
      }
    }
    ${Bet.fragments.public}
  `,
};

Bet.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateBet {
      onUpdateBet {
        ...BetPublic
      }
    }
    ${Bet.fragments.public}
  `,
  onCreate: gql`
    subscription OnCreateBet {
      onCreateBet {
        ...BetPublic
      }
    }
    ${Bet.fragments.public}
  `,
};

export default Bet;
