import * as React from "react";
import { useState, useCallback } from "react";
import {
  Button,
  Title,
  useTheme,
  TextInput,
  HelperText,
  Text,
} from "react-native-paper";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { View, Platform } from "react-native";
import Auth from "@aws-amplify/auth";
import ScreenBottom from "Components/Screen/Bottom";
import Screen from "Components/Screen";
import FormValidationRow from "Components/Form/ValidationRow";
import AuthWrapper from "Components/AuthWrapper";

// const scrollEnabled = Platform.select({ web: true, default: false });

const schema = yup.object().shape({
  code: yup
    .string()
    .matches(/^\d\d\d\d\d\d$/i, "Must be a valid 6-digit code")
    .required("Can't be blank"),
  password: yup
    .string()
    .required("Can't be blank")
    .min(8, "Too short - should be 8 chars minimum.")
    .max(48, "Too long - should be 48 chars maximum.")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
});

const Form = ({ style, theme, navigation, email }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, dirtyFields },
    setError,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const password = watch("password", "");
  const inputs = React.useRef({});

  const handleSubmitError = useCallback(
    error =>
      setError("code", {
        ...error,
        message: `${error.code} - ${error.message}`,
      }),
    [setError]
  );

  const onSubmit = useCallback(
    ({ code, password }) =>
      Auth.forgotPasswordSubmit(email, code, password)
        .then(() => navigation.push("PasswordResetSuccess"))
        .catch(handleSubmitError),
    [navigation, handleSubmitError, email]
  );

  const doSubmit = handleSubmit(onSubmit);

  return (
    <View
      style={[
        {
          flex: 1,
        },
        style ?? {},
      ]}
    >
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
          <>
            <TextInput
              autoFocus
              autoCorrect={false}
              autoCapitalize="none"
              ref={e => (inputs.current["code"] = e)}
              autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
              keyboardType="number-pad"
              textContentType="oneTimeCode"
              label="Enter Your Code"
              error={!!errors.code}
              returnKeyLabel="Done"
              returnKeyType="done"
              onSubmitEditing={doSubmit}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.code}>
              {errors.code?.message}
            </HelperText>
            <HelperText type="info" visible={!errors.code}>
              Enter the six digit code we sent to your email address
            </HelperText>
          </>
        )}
        name="code"
        defaultValue=""
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              secureTextEntry={secureTextEntry}
              autoCorrect={false}
              ref={e => (inputs.current["password"] = e)}
              autoCompleteType={
                Platform.OS === "web" ? "new-password" : "password"
              }
              textContentType="password"
              label="Password"
              autoCapitalize="none"
              returnKeyLabel="done"
              returnKeyType="done"
              onSubmitEditing={() => inputs.current.displayName.focus()}
              error={!!errors.password}
              right={
                <TextInput.Icon
                  name={!!secureTextEntry ? "eye-off" : "eye"} // or can be a node where <Icon /> is any component from vector-icons or anything else
                  onPress={() =>
                    setSecureTextEntry(secureTextEntry => !secureTextEntry)
                  }
                />
              }
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.password}>
              {/* {errors.password?.message} */}
            </HelperText>
          </>
        )}
        name="password"
        defaultValue=""
      />

      {!!dirtyFields?.password && (
        <View>
          <FormValidationRow
            theme={theme}
            text="Between 8 and 25 characters"
            doesPass={/^.{8,25}$/.test(password)}
          />
          <FormValidationRow
            theme={theme}
            text="At least one number"
            doesPass={/[0-9]/.test(password)}
          />
          <FormValidationRow
            theme={theme}
            text="At least one capital letter"
            doesPass={/[A-Z]/.test(password)}
          />
          <FormValidationRow
            theme={theme}
            text="At least one lowercase letter"
            doesPass={/[a-z]/.test(password)}
          />
          <FormValidationRow
            theme={theme}
            text="At least one special character (i.e. !$%^)"
            doesPass={/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)}
          />
        </View>
      )}

      <ScreenBottom theme={theme}>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          Save
        </Button>
        <View style={{ flexDirection: "row", marginTop: theme.spacing.lg }}>
          <Text
            onPress={() => Auth.forgotPassword(email).catch(handleSubmitError)}
            style={{ color: theme.colors.secondary }}
          >
            Resend Code
          </Text>
          <Text style={{ color: theme.colors.secondary }}> | </Text>
          <Text
            onPress={() => navigation.navigate("Main")}
            style={{ color: theme.colors.secondary }}
          >
            Cancel
          </Text>
        </View>
      </ScreenBottom>
    </View>
  );
};

export default ({ navigation, route }) => {
  const theme = useTheme();
  const email = route?.params?.email;
  return (
    <Screen
      fullHeight
      fullWidth
      gatedTo="guest"
      title="Password Reset"
      hasKeyboard
      backgroundColor={theme.colors.surface}
    >
      {Platform.OS === "web" ? (
        <AuthWrapper>
          <>
            <Title style={{ textAlign: "center", color: theme.colors.primary }}>
              Enter Code and Password
            </Title>
            <Form
              style={{
                marginTop: theme.spacing.xl,
                marginHorizontal: theme.spacing.xl,
              }}
              theme={theme}
              navigation={navigation}
              email={email}
            />
          </>
        </AuthWrapper>
      ) : (
        <>
          <Title style={{ textAlign: "center", color: theme.colors.primary }}>
            Enter Code and Password
          </Title>
          <Form
            style={{
              marginTop: theme.spacing.xl,
              marginHorizontal: theme.spacing.xl,
            }}
            theme={theme}
            navigation={navigation}
            email={email}
          />
        </>
      )}
    </Screen>
  );
};
