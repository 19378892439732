import { useEffect } from "react";
import "react-native-gesture-handler";
import Constants from "expo-constants";
import { Platform } from "react-native";

const isRunningInExpoGo = Constants.appOwnership === "expo";

const InitializeSDKHandler = props => {
  const { displayConversionData, useDeepLink } = props;

  useEffect(() => {
    (async () => {
      if (isRunningInExpoGo || Platform.OS === "web") return;
      const appsFlyer = await import("react-native-appsflyer");
      appsFlyer.initSdk(
        {
          devKey: "sQ84wpdxRTR4RMCaE9YqS4",
          isDebug: true,
          appId: "154785576",
          onInstallConversionDataListener: displayConversionData, //Optional
          onDeepLinkListener: useDeepLink, //Optional
          timeToWaitForATTUserAuthorization: 10, //for iOS 14.5
        },
        result => {
          console.log(result);
        },
        error => {
          console.error(error);
        }
      );
    })();
  }, [displayConversionData, useDeepLink]);

  return null;
};

export default InitializeSDKHandler;
