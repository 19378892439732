import useStyles from "Hooks/useStyles";
import React from "react";
import AdItemStyles from "./AdItemStyles";
import AdItemView from "./AdItemView";
import { Platform, useWindowDimensions } from "react-native";

export default props => {
  const { theme, styles } = useStyles({ Styles: AdItemStyles });
  const { width } = useWindowDimensions();
  const imageWidth = Math.min(width, Platform.OS === "web" ? 601 : 50000);

  return (
    <AdItemView
      imageWidth={imageWidth}
      theme={theme}
      styles={styles}
      {...props}
    />
  );
};
