import React, { useCallback, useState } from "react";
import { List, TextInput, useTheme, Divider } from "react-native-paper";

import Screen from "Components/Screen";
import {
  formatValue,
  addCommas,
  convertMoneyTextToPennies,
} from "shared/Util/money";
import { Platform, View } from "react-native";
import SegmentedControl from "@react-native-segmented-control/segmented-control";
import useHeaderFormSaveButton from "Hooks/useHeaderFormSaveButton";

const options = ["Select"]; //["Select", "Enter"];

export default ({ navigation, route }) => {
  const backScreen = route?.params?.backTo;
  const amount = route?.params?.amount;
  const lastFour = route?.params?.lastFour;
  const promotionId = route?.params?.promotionId;
  const theme = useTheme();
  const [value, setValue] = useState(amount);
  const [mode, setMode] = useState("Select");

  const onChange = useCallback(text => setValue(text), []);

  const onSubmit = useCallback(
    ({ amount } = {}) =>
      navigation.navigate(backScreen, {
        amount: amount ?? value,
        lastFour,
        promotionId,
      }),
    [navigation, backScreen, value, lastFour, promotionId]
  );

  useHeaderFormSaveButton({ onSubmit, isValid: true, skip: mode === "Select" });

  return (
    <Screen
      gatedTo="member"
      title="Deposit Funds"
      backgroundColor={theme.colors.surface}
    >
      <View style={{ marginVertical: theme.spacing.lg }}>
        <SegmentedControl
          appearance={theme.dark ? "dark" : "light"}
          values={options}
          selectedIndex={options.indexOf(mode)}
          onChange={event =>
            setMode(options[event.nativeEvent.selectedSegmentIndex])
          }
        />
      </View>

      {mode === "Select" &&
        [
          // ...["25.00", "50.00", "100.00", "250.00", "500.00", "1,000.00"],
          ...[
            "10.00",
            "25.00",
            "50.00",
            "75.00",
            "100.00",
            "150.00",
            "250.00",
            "500.00",
          ],
        ]
          .slice()
          .sort((a, b) =>
            Number(a.replace(",", "")) > Number(b.replace(",", "")) ? 1 : -1
          )
          .map(option => (
            <React.Fragment key={option}>
              <List.Item
                title={`$${option}`}
                right={props =>
                  convertMoneyTextToPennies(option) ===
                  convertMoneyTextToPennies(amount) ? (
                    <List.Icon {...props} icon="check-circle-outline" />
                  ) : null
                }
                onPress={() => onSubmit({ amount: option })}
              />
              <Divider />
            </React.Fragment>
          ))}

      {mode === "Enter" && (
        <TextInput
          mode="outlined"
          label="Amount"
          left={<TextInput.Icon name="currency-usd" />}
          // right={!!value && <TextInput.Icon color={theme.colors.primary} onPress={onSubmit} name="check-circle-outline" />}
          selectTextOnFocus
          autoCorrect={false}
          autoCapitalize="none"
          autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
          keyboardType="decimal-pad"
          textContentType="none"
          returnKeyLabel="Done"
          returnKeyType="done"
          onSubmitEditing={onSubmit}
          onChangeText={value =>
            onChange(formatValue((value ?? "").replace(/[^0-9.]/gi, "") || 0))
          }
          value={`${
            value.toString() === "0" ? "" : addCommas(value.toString())
          }`}
        />
      )}
    </Screen>
  );
};
