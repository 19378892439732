import gql from "graphql-tag";

const Bluesnap = {};

Bluesnap.queries = {
  getTransaction: gql`
    query GetBluesnapTransaction($transactionId: String!) {
      getBluesnapTransaction(transactionId: $transactionId) {
        amount
        transactionId
        vaultedShopperId
        currency
        cardTransactionType
        processingInfo {
          avsResponseCodeAddress
          cvvResponseCode
          avsResponseCodeName
          avsResponseCodeZip
          processingStatus
        }
      }
    }
  `,
  getVaultedShopper: gql`
    query GetBluesnapVaultedShopper($vaultedShopperId: Int!) {
      getBluesnapVaultedShopper(vaultedShopperId: $vaultedShopperId) {
        firstName
        lastName
        vaultedShopperId
        paymentSources {
          ecpDetails {
            billingContactInfo {
              firstName
              lastName
              zip
            }
            ecp {
              accountType
              publicAccountNumber
              publicRoutingNumber
            }
          }
          creditCardInfo {
            billingContactInfo {
              firstName
              lastName
              zip
            }
            processingInfo {
              avsResponseCodeAddress
              cvvResponseCode
              avsResponseCodeName
              avsResponseCodeZip
            }
            creditCard {
              cardLastFourDigits
              cardType
              cardCategory
              expirationYear
              cardFingerprint
              cardSubType
              expirationMonth
            }
          }
        }
        lastPaymentInfo {
          paymentMethod
          creditCard {
            cardLastFourDigits
            cardType
            cardCategory
            expirationYear
            cardFingerprint
            cardSubType
            expirationMonth
          }
        }
      }
    }
  `,
};

Bluesnap.mutations = {
  createPaymentToken: gql`
    mutation CreateBluesnapPaymentToken {
      createBluesnapPaymentToken
    }
  `,
  refundTransaction: gql`
    mutation RefundTransaction($transactionId: String) {
      refundTransaction(transactionId: $transactionId)
    }
  `,
  deleteAch: gql`
    mutation DeleteAch($input: DeleteAchInput!) {
      deleteAch(input: $input)
    }
  `,
  deleteCreditCard: gql`
    mutation DeleteCreditCard($input: DeleteCreditCardInput!) {
      deleteCreditCard(input: $input)
    }
  `,
  createTransaction: gql`
    mutation CreateBluesnapTransaction(
      $input: CreateBluesnapTransactionInput!
    ) {
      createBluesnapTransaction(input: $input) {
        amount
        transactionId
        currency
        cardTransactionType
        transactionPromotionId

        processingInfo {
          avsResponseCodeAddress
          cvvResponseCode
          avsResponseCodeName
          avsResponseCodeZip
          processingStatus
        }
      }
    }
  `,
};

export default Bluesnap;
