import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={20}
      height={20}
      viewBox="0 0 30.928 30.928"
      xmlSpace="preserve"
      enableBackground="new 0 0 30.928 30.928"
      {...props}
    >
      <Path
        d="M24.791 4.451c.02-.948-.016-1.547-.016-1.547l-9.264-.007h-.094l-9.265.007s-.035.599-.015 1.547H0v1.012c0 .231.039 5.68 3.402 8.665C4.805 15.373 6.555 15.999 8.618 16c.312 0 .633-.021.958-.049 1.172 1.605 2.526 2.729 4.049 3.289v4.445H9.154v2.784H7.677v1.561H23.251v-1.56h-1.478v-2.784h-4.471v-4.445c1.522-.56 2.877-1.684 4.049-3.289.327.028.648.048.96.048 2.062-.002 3.812-.627 5.215-1.873 3.363-2.985 3.402-8.434 3.402-8.665V4.451h-6.137zM4.752 12.619c-1.921-1.7-2.489-4.61-2.657-6.144h4.158c.176 1.911.59 4.292 1.545 6.385.175.384.359.748.547 1.104-1.433-.055-2.639-.502-3.593-1.345zm21.424 0c-.953.844-2.16 1.29-3.592 1.345.188-.355.372-.72.547-1.104.955-2.093 1.369-4.474 1.544-6.385h4.158c-.168 1.533-.735 4.443-2.657 6.144z"
        fill={props?.color ?? "#666"}
      />
    </Svg>
  );
}

export default SvgComponent;
