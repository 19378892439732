import { useCallback, useContext, useState } from "react";
import ShareIconView from "./ShareIconView";
import {
  initializePolls,
  OptionType,
  DomainConfigType,
  createPollAsync,
} from "@polls-platform/core";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { formatPennies } from "shared/Util/money";
import environment from "environment";
import openUrl from "Util/openUrl";
import { Alert, Platform, Share } from "react-native";

try {
  initializePolls({
    // apiKey: "v1YAnu6aMo3YwqF9U7w0kyUGziQxAQJ5HidX7KL7",
    apiKey: "lRmSQfd5Ch7Nf0uOOwFtD3BEx35o6uFy3L3IYjJE",
    domainConfig: {
      type: DomainConfigType.subdomain,
      subdomain: "propswap",
    },
  });
} catch (error) {
  console.log({
    // apiKey: "v1YAnu6aMo3YwqF9U7w0kyUGziQxAQJ5HidX7KL7",
    apiKey: "lRmSQfd5Ch7Nf0uOOwFtD3BEx35o6uFy3L3IYjJE",
    domainConfig: {
      type: DomainConfigType.subdomain,
      subdomain: "propswap",
    },
  });
  console.log(error);
}

function copyToClp(txt) {
  const m = document;
  txt = m.createTextNode(txt);
  const w = window;
  const b = m.body;
  b.appendChild(txt);
  if (b.createTextRange) {
    const d = b.createTextRange();
    d.moveToElementText(txt);
    d.select();
    m.execCommand("copy");
  } else {
    const d = m.createRange();
    const g = w.getSelection;
    d.selectNodeContents(txt);
    g().removeAllRanges();
    g().addRange(d);
    m.execCommand("copy");
    g().removeAllRanges();
  }
  txt.remove();
}

const filterItems = [
  { label: "Copy Link", value: "share" },
  { label: "Generate Poll", value: "poll" },
];

export default ({ theme, _isFeatured, listing, ticket }) => {
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [selectedValue, setSelectedValue] = useState(false);
  const [generatingPoll, setGeneratingPoll] = useState(false);
  const handleBottomSheetDismiss = useCallback(
    () => setShowBottomSheet(false),
    []
  );
  const handleValueChange = useCallback(
    value => {
      setSelectedValue(value);
      handleBottomSheetDismiss();
      setTimeout(value === "poll" ? handlePollPress : handleSharePress, 100);
    },
    [handleBottomSheetDismiss, handlePollPress, handleSharePress]
  );
  const currentUser = useContext(CurrentUserContext);
  const handlePress = useCallback(() => setShowBottomSheet(true), []);
  // const [universalObject, setUniversalObject] = useState(null);
  const universalObject = false;
  const handleSharePress = useCallback(
    () =>
      !!universalObject
        ? universalObject.showShareSheet({
            messageHeader: `PropSwap - ${ticket.subject} ${ticket.eventDescription}`,
            messageBody: `PropSwap - ${ticket.subject} ${ticket.eventDescription}`,
          })
        : Promise.resolve(
            Platform.OS === "web"
              ? `${window.location.protocol}//${window.location.host}/listings/${listing.id}`
              : `${environment.base_url}/listings/${listing.id}`
          ).then(uri =>
            Share.share({
              message: `PropSwap - ${ticket.subject} ${ticket.eventDescription}`,
              url: uri,
            }).catch(
              e =>
                /Share is not supported in this browser/.test(e.toString()) &&
                Promise.resolve(copyToClp(uri)).then(() =>
                  Platform.OS === "web"
                    ? window.alert("URL copied to clipboard")
                    : Alert.alert("URL copied to clipboard")
                )
            )
          ),
    [listing.id, ticket.eventDescription, ticket.subject, universalObject]
  );
  const handlePollPress = useCallback(
    () =>
      Promise.resolve(setGeneratingPoll(true))
        .then(() =>
          createPollAsync({
            title: `${ticket.subject} ${ticket.eventDescription} at ${
              listing.newOdds
            } Odds for ${formatPennies(listing.askingPrice, "$")}:  `,
            ownerId: currentUser?.id ?? "<>",
            settings: {
              multipleVotes: false,
              postVoteAction: "none",
            },
            options: [
              {
                title: "Buy it",
                url: `${environment.base_url}/listings/${listing.id}`,
                imageUrl: `${
                  environment.base_url
                }/polls/${ticket.sport.abbrev.toUpperCase()}.png`,
                type: OptionType.defaultTemplate,
              },
              {
                title: "Don't buy it",
                url: `${environment.base_url}/listings/${listing.id}`,
                imageUrl: `${
                  environment.base_url
                }/polls/${ticket.sport.abbrev.toUpperCase()}.png`,
                type: OptionType.defaultTemplate,
              },
            ],
          })
        )
        .then(
          poll => openUrl(poll.url)

          // Share.share({
          //   message: `PropSwap - ${ticket.subject} ${ticket.eventDescription}`,
          //   url: poll.url,
          // }).catch(
          //   e =>
          //     /Share is not supported in this browser/.test(e.toString()) &&
          //     Promise.resolve(copyToClp(poll.url)).then(() =>
          //       Platform.OS === "web"
          //         ? window.alert("URL copied to clipboard")
          //         : Alert.alert("URL copied to clipboard")
          //     )
          // )
        ),
    [
      currentUser?.id,
      listing.askingPrice,
      listing.id,
      listing.newOdds,
      ticket.eventDescription,
      ticket.sport.abbrev,
      ticket.subject,
    ]
  );

  // useEffect(() => {
  //   Constants.appOwnership === "standalone" &&
  //     import("expo-branch").then(({ default: Branch }) =>
  //       Branch.createBranchUniversalObject(`listing_${listing.id}`, {
  //         title: `PropSwap - ${ticket.subject} ${ticket.eventDescription}`,
  //         contentImageUrl:
  //           "https://www.legalsportsreport.com/wp-content/uploads/2019/10/PropSwap-BetX.png",
  //         contentDescription: `PropSwap - ${ticket.subject} ${ticket.eventDescription}`,
  //         // This metadata can be used to easily navigate back to this screen
  //         // when implementing deep linking with `Branch.subscribe`.
  //         metadata: {
  //           screen: "ListingDetail",
  //           params: JSON.stringify({
  //             id: listing.id,
  //             uri: `listings/${listing.id}`,
  //           }),
  //         },
  //       }).then(setUniversalObject)
  //     );
  // }, [listing, ticket]);

  return (
    <ShareIconView
      theme={theme}
      onPress={handlePress}
      filterItems={filterItems}
      showBottomSheet={showBottomSheet}
      onBottomSheetDismiss={handleBottomSheetDismiss}
      selectedValue={selectedValue}
      onValueChange={handleValueChange}
      generatingPoll={generatingPoll}
    />
  );
};
