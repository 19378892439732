import { useContext, useCallback, useState, useMemo, useEffect } from "react";
import {
  Button,
  Divider,
  List,
  Text,
  TextInput,
  useTheme,
} from "react-native-paper";
import SegmentedControl from "@react-native-segmented-control/segmented-control";
import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import { Platform, View } from "react-native";
import DataList from "Components/DataList";
import { useQuery } from "@apollo/client";
import Event from "shared/api/Event";
import OptionGroup from "shared/api/OptionGroup";
import useHeaderFormSaveButton from "Hooks/useHeaderFormSaveButton";

const options = ["Select", "Enter"];
const field = "subject";
const itemFilter = item => !!item.option.active;
const sortItems = (a, b) => (a.option.name > b.option.name ? 1 : -1);

export default ({ navigation, route }) => {
  const leg = route?.params?.leg;
  const hasLeg = leg !== undefined && leg !== null;
  const theme = useTheme();
  const { setTicketValues, ticketValues } = useContext(TicketContext);
  const [value, setValue] = useState(
    (hasLeg ? ticketValues?.parlay?.[leg]?.subject : ticketValues[field]) ?? ""
  );
  const [id, setId] = useState(ticketValues.ticketOptionId);
  const [mode, setMode] = useState("Select");
  const eventId =
    ticketValues?.cachedBets?.[leg ?? 0]?.eventId ??
    (hasLeg
      ? ticketValues?.parlay?.[leg]?.ticketEventId
      : ticketValues.ticketEventId);
  const { data: { getEvent } = {} } = useQuery(Event.queries.get, {
    skip: !eventId,
    variables: {
      id: eventId,
    },
  });

  const onChange = useCallback(
    (text, id) => [setValue(text), setId(id ?? null)],
    []
  );

  const onSubmit = useCallback(
    (subject, optionId) => () => {
      if (hasLeg) {
        setTicketValues(ticketValues => ({
          ...ticketValues,
          parlay: {
            ...(ticketValues.parlay ?? {}),
            [leg]: {
              ...(ticketValues.parlay?.[leg] ?? {}),
              [field]: (subject ?? value ?? "").trim(),
              ticketOptionId: optionId ?? id,
            },
          },
        }));
      } else {
        setTicketValues(ticketValues => ({
          ...ticketValues,
          [field]: (subject ?? value ?? "").trim(),
          ticketOptionId: optionId ?? id,
        }));
      }
      navigation.navigate("TicketNewStep3");
    },
    [hasLeg, navigation, setTicketValues, leg, value, id]
  );

  const groupId = getEvent?.groupId;

  const dataListQuery = useMemo(
    () => ({
      dataKey: "listOptionGroupsByGroupId",
      query: OptionGroup.queries.listByGroupId,
      variables: {
        limit: 2000,
        sortDirection: "DESC",
        groupId: groupId ?? "<>",
      },
    }),
    [groupId]
  );

  const dataListRenderItem = useCallback(
    ({ item, index }) => (
      <>
        <List.Item
          title={item.option.name}
          onPress={onSubmit(item.option.name, item.option.id)}
          right={props =>
            ticketValues.ticketOptionId === item.option.id ? (
              <List.Icon {...props} icon="check-circle-outline" />
            ) : null
          }
        />
        <Divider />
      </>
    ),
    [onSubmit, ticketValues.ticketOptionId]
  );

  useEffect(() => {
    setMode(!!getEvent ? "Select" : "Enter");
  }, [getEvent]);

  useHeaderFormSaveButton({
    onSubmit: onSubmit(),
    isValid: !!value && mode === "Enter",
  });

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={2}
        title={`${mode} Team / Player`}
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />

      <View style={{ marginHorizontal: theme.spacing.xl }}>
        {!!getEvent && (
          <View style={{ marginVertical: theme.spacing.lg }}>
            <SegmentedControl
              values={options}
              selectedIndex={options.indexOf(mode)}
              onChange={event =>
                setMode(options[event.nativeEvent.selectedSegmentIndex])
              }
            />
          </View>
        )}

        {mode === "Enter" && (
          <TextInput
            autoFocus
            mode="outlined"
            label="Team / Player"
            right={
              !!value && (
                <TextInput.Icon
                  color={theme.colors.primary}
                  onPress={onSubmit()}
                  name="check-circle-outline"
                />
              )
            }
            selectTextOnFocus
            autoCorrect
            autoCapitalize="words"
            autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
            textContentType="none"
            returnKeyLabel="Done"
            returnKeyType="done"
            onSubmitEditing={onSubmit()}
            onChangeText={value => onChange(value)}
            value={value ?? ""}
          />
        )}
      </View>

      {mode === "Select" && (
        <DataList
          sortItems={sortItems}
          filterItems={itemFilter}
          query={dataListQuery}
          renderItem={dataListRenderItem}
          ListEmptyComponent={
            <View style={{ marginHorizontal: theme.spacing.xl }}>
              <Text>No Teams / Players to Choose From</Text>
              <Button mode="outlined" onPress={() => setMode("Enter")}>
                Enter Team / Player
              </Button>
            </View>
          }
        />
      )}
    </Screen>
  );
};
