import * as React from "react";
import { useContext, useCallback } from "react";
import { Button, Text } from "react-native-paper";

import { TextInput, HelperText } from "react-native-paper";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { View, Platform, ScrollView } from "react-native";
import ScreenBottom from "Components/Screen/Bottom";
import { useMemo } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import User from "shared/api/User";
import { useMutation } from "@apollo/client";
import Confirmation from "Components/Confirmation";
import BottomSheet from "Components/BottomSheet";
import { useState } from "react";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ style, theme, navigation, type = "hat" }) => {
  const pointsNeeded = type === "hat" ? 4000 : 2500;
  const currentUser = useContext(CurrentUserContext);
  const availablePoints = currentUser?.availablePoints ?? 0;
  const inputs = React.useRef({});
  const hasEnoughPoints = availablePoints >= pointsNeeded;
  const [submitted, setSubmitted] = useState(false);

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup
          .string()
          .min(2, "Too short - should be 2 chars minimum.")
          .max(25, "Too long - should be 25 chars maximum.")
          .matches(/^[a-z ,.'-]+$/i, "Must be a valid first name")
          .required("Can't be blank"),
        lastName: yup
          .string()
          .min(2, "Too short - should be 2 chars minimum.")
          .max(25, "Too long - should be 25 chars maximum.")
          .matches(/^[a-z ,.'-]+$/i, "Must be a valid last name")
          .required("Can't be blank"),
        address: yup
          .string()
          .min(2, "Too short - should be 2 chars minimum.")
          .max(55, "Too long - should be 55 chars maximum.")
          .required("Can't be blank"),
        city: yup
          .string()
          .min(2, "Too short - should be 2 chars minimum.")
          .max(25, "Too long - should be 25 chars maximum.")
          .matches(/^[a-z ,.'-]+$/i, "Must be a valid city")
          .required("Can't be blank"),
        state: yup
          .string()
          .matches(/^[A-Z][A-Z]$/, "Must be a valid state abbrev")
          .required("Can't be blank"),
        zip: yup
          .string()
          .matches(/^\d\d\d\d\d+$/i, "Must be a valid zip code")
          .required("Can't be blank"),
        ...(type === "hat"
          ? {
              style: yup
                .string()
                .oneOf(["baseball cap", "snapback"])
                .required("Can't be blank"),
            }
          : {
              size: yup
                .string()
                .oneOf(["M", "L", "XL", "XXL", "XXXL"])
                .required("Can't be blank"),
            }),
      }),
    [type]
  );

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
      isSubmitting,
      touchedFields,
      dirtyFields,
      ...rest
    },
    setError,
    setFocus,
    watch,
    ...others
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [convertPointsToMerch] = useMutation(
    User.mutations.convertPointsToMerch,
    {
      variables: {
        input: {},
      },
      refetchQueries: [
        {
          query: User.queries.get,
          variables: {
            id: currentUser?.id,
            withPrivate: true,
          },
        },
      ],
    }
  );

  const handleSubmitError = useCallback(
    error =>
      setError("zip", {
        ...error,
        message: `${error.code} - ${error.message}`,
      }),
    [setError]
  );

  const onSubmit = useCallback(
    ({ firstName, lastName, address, address2, city, state, zip, size }) =>
      convertPointsToMerch({
        variables: {
          input: {
            firstName,
            lastName,
            address,
            address2,
            city,
            state,
            zip,
            size,
            type,
          },
        },
      })
        .then(data => setSubmitted(true))
        .catch(handleSubmitError),
    [navigation, convertPointsToMerch, handleSubmitError, type]
  );

  const doSubmit = handleSubmit(onSubmit);

  const handleConfirmationClose = useCallback(
    () => [setSubmitted(false), navigation.replace("Main")],
    [navigation]
  );

  return (
    <>
      <ScrollView
        contentContainerStyle={{ marginHorizontal: theme.spacing.xl }}
        style={[
          {
            flex: 1,
          },
          style ?? {},
        ]}
      >
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize={"words"}
                ref={e => (inputs.current["firstName"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "name"
                }
                keyboardType="default"
                textContentType="givenName"
                label="First Name"
                error={!!errors.firstName}
                returnKeyLabel={"Next"}
                returnKeyType={"next"}
                onSubmitEditing={() => inputs.current.lastName.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.firstName}>
                {errors.firstName?.message}
              </HelperText>
            </>
          )}
          name="firstName"
          defaultValue={currentUser?.attributes.given_name}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize={"words"}
                ref={e => (inputs.current["lastName"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "name"
                }
                keyboardType="default"
                textContentType="familyName"
                label="Last Name"
                error={!!errors.lastName}
                returnKeyLabel={"Next"}
                returnKeyType={"next"}
                onSubmitEditing={() => inputs.current.address.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.lastName}>
                {errors.lastName?.message}
              </HelperText>
            </>
          )}
          name="lastName"
          defaultValue={currentUser?.attributes.family_name}
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize={"words"}
                ref={e => (inputs.current["address"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "street-address"
                }
                keyboardType="default"
                textContentType="streetAddressLine1"
                label="Address"
                error={!!errors.address}
                returnKeyLabel={"Next"}
                returnKeyType={"next"}
                onSubmitEditing={() => inputs.current.city.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.address}>
                {errors.address?.message}
              </HelperText>
            </>
          )}
          name="address"
          defaultValue={currentUser?.attributes.address}
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize={"words"}
                ref={e => (inputs.current["address2"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "street-address"
                }
                keyboardType="default"
                textContentType="streetAddressLine2"
                label="Address 2"
                error={!!errors.address2}
                returnKeyLabel={"Next"}
                returnKeyType={"next"}
                onSubmitEditing={() => inputs.current.city.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.address2}>
                {errors.address2?.message}
              </HelperText>
            </>
          )}
          name="address2"
          defaultValue={currentUser?.attributes["custom:address2"] ?? ""}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect
                autoCapitalize={"words"}
                ref={e => (inputs.current["city"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="default"
                textContentType="addressCity"
                label="City"
                error={!!errors.city}
                returnKeyLabel={"Next"}
                returnKeyType={"next"}
                onSubmitEditing={() => inputs.current.city.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.city}>
                {errors.city?.message}
              </HelperText>
            </>
          )}
          name="city"
          defaultValue={currentUser?.attributes["custom:city"]}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect
                autoCapitalize={"words"}
                ref={e => (inputs.current["state"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="default"
                textContentType="addressState"
                label="State"
                error={!!errors.state}
                returnKeyLabel={"Next"}
                returnKeyType={"next"}
                onSubmitEditing={() => inputs.current.zip.focus()}
                maxLength={2}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value.toUpperCase())}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.state}>
                {errors.state?.message}
              </HelperText>
            </>
          )}
          name="state"
          defaultValue={currentUser?.attributes["custom:state"]}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize={"none"}
                ref={e => (inputs.current["zip"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "postal-code"
                }
                keyboardType="default"
                textContentType="postalCode"
                label="Zip"
                error={!!errors.zip}
                returnKeyLabel={type === "hat" ? "Done" : "Next"}
                returnKeyType={type === "hat" ? "done" : "next"}
                onSubmitEditing={
                  type === "hat" ? doSubmit : () => inputs.current.size.focus()
                }
                maxLength={5}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.zip}>
                {errors.zip?.message}
              </HelperText>
            </>
          )}
          name="zip"
          defaultValue={currentUser?.attributes["custom:zip"]}
        />

        {type !== "hat" && (
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <TextInput
                  autoCorrect={false}
                  autoCapitalize={"none"}
                  ref={e => (inputs.current["size"] = e)}
                  autoCompleteType={
                    Platform.OS === "web" ? "new-password" : "off"
                  }
                  keyboardType="default"
                  label="Size"
                  error={!!errors.size}
                  returnKeyLabel={"Done"}
                  returnKeyType={"done"}
                  onSubmitEditing={doSubmit}
                  maxLength={4}
                  mode="flat"
                  onBlur={onBlur}
                  onChangeText={value => onChange(value.toUpperCase())}
                  value={value}
                  style={{
                    backgroundColor: "transparent",
                  }}
                />
                {!!errors.size && (
                  <HelperText type="error" visible={!!errors.size}>
                    {errors.size?.message}
                  </HelperText>
                )}
                {!errors.size && (
                  <HelperText type="info" visible={!errors.size}>
                    M, L, M, XL, XXL, XXXL
                  </HelperText>
                )}
              </>
            )}
            name="size"
            defaultValue={""}
          />
        )}
        {type === "hat" && (
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <TextInput
                  autoCorrect={false}
                  autoCapitalize={"none"}
                  ref={e => (inputs.current["style"] = e)}
                  autoCompleteType={
                    Platform.OS === "web" ? "new-password" : "off"
                  }
                  keyboardType="default"
                  label="Style"
                  error={!!errors.style}
                  returnKeyLabel={"Done"}
                  returnKeyType={"done"}
                  onSubmitEditing={doSubmit}
                  // maxLength={3}
                  mode="flat"
                  onBlur={onBlur}
                  onChangeText={value => onChange(value.toLowerCase())}
                  value={value}
                  style={{
                    backgroundColor: "transparent",
                  }}
                />
                {!!errors.style && (
                  <HelperText type="error" visible={!!errors.style}>
                    {errors.style?.message}
                  </HelperText>
                )}
                {!errors.style && (
                  <HelperText type="info" visible={!errors.style}>
                    Baseball Cap or Snapback
                  </HelperText>
                )}
              </>
            )}
            name="style"
            defaultValue={""}
          />
        )}
      </ScrollView>

      <ScreenBottom
        adjustPadding
        style={{ marginHorizontal: theme.spacing.xl }}
      >
        <Button
          disabled={!isValid || !!isSubmitting || !hasEnoughPoints}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          {!!isSubmitting ? "Please Wait..." : "Redeem"}
        </Button>
        {!hasEnoughPoints && <Text>You don't have enough points</Text>}
      </ScreenBottom>

      <BottomSheet
        visible={!!submitted}
        onDismiss={handleConfirmationClose}
        fullScreen
      >
        {!!submitted && (
          <Confirmation
            title={`Order Received!`}
            subtitle={"Please allow 10-14 days to receive your shipment"}
            type="success"
            primaryButtonLabel={"Close"}
            onPrimaryButtonPress={handleConfirmationClose}
          />
        )}
      </BottomSheet>
    </>
  );
};
