import ListingPurchaseConfirmationView from "./ListingPurchaseConfirmationView";
import { useQuery } from "@apollo/client";
import Listing from "shared/api/Listing";
import Ticket from "shared/api/Ticket";
import { useMemo } from "react";
import useStyles from "Hooks/useStyles";
import ListingPurchaseConfirmationStyles from "./ListingPurchaseConfirmationStyles";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ listingId, onClosePress }) => {
  const { theme, styles } = useStyles({
    Styles: ListingPurchaseConfirmationStyles,
  });

  const { data: { getListing } = {} } = useQuery(Listing.queries.get, {
    skip: !listingId,
    pollInterval: 6000,
    variables: {
      id: listingId,
    },
  });

  const { data: { getTicket } = {} } = useQuery(Ticket.queries.get, {
    skip: !getListing,
    pollInterval: 6000,
    variables: {
      id: getListing?.listingTicketId,
    },
  });

  const memoizedTicket = useMemo(
    () => getTicket ?? getListing?.cachedTicket,
    [getTicket, getListing]
  );

  return !getListing || !memoizedTicket ? null : (
    <ListingPurchaseConfirmationView
      listing={getListing}
      ticket={memoizedTicket}
      theme={theme}
      onClosePress={onClosePress}
      styles={styles}
    />
  );
};
