import { useQuery } from "@apollo/client";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import environment from "environment";
import DataList from "Components/DataList";
import EmptySign from "Components/EmptySign";
import FilterScreen from "Components/FilterScreen";
import Sports from "Components/Icons/Sports";
import ListingSearchbar from "Components/Listing/Searchbar/ListingSearchbarContainer";
import Screen from "Components/Screen";
import ScreenNav from "Components/Screen/Nav";
import Spacer from "Components/Spacer";
import TicketItem from "Components/Ticket/Item";
import TicketPersonalizedList from "Components/Ticket/PersonalizedList";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { ScrollDirectionContext } from "Contexts/ScrollDirection";
import { handleScroll } from "Util/";
import {
  useCallback,
  useMemo,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import { Platform, ScrollView, View } from "react-native";
import * as reactNativePaper from "react-native-paper";
import { STATES } from "shared/Config";
import Listing from "shared/api/Listing";
import Locale from "shared/api/Locale";
import Sport from "shared/api/Sport";

const randomSortItems = [
  "id",
  "createdAt",
  "updatedAt",
  "listingTicketId",
  "bidCount",
  // "watchingsCount",
];

const randomSortItem =
  randomSortItems[Math.floor(Math.random() * randomSortItems.length)];

const sortItems = [
  { label: "Most Recent", value: "updatedAt-desc" },
  { label: "Most Viewed", value: "cachedTicket.views-desc" },
  { label: "Expiring Soonest", value: "expiresAt-asc" },
  { label: "Price High to Low", value: "askingPrice-desc" },
  { label: "Price Low to High", value: "askingPrice-asc" },
  {
    label: "Collect Amount High to Low",
    value: "cachedTicket.collectAmount-desc",
  },
  {
    label: "Collect Amount Low to High",
    value: "cachedTicket.collectAmount-asc",
  },
  { label: "Alphabetical A-Z", value: "cachedTicket.subject-asc" },
  { label: "Alphabetical Z-A", value: "cachedTicket.subject-desc" },
];

const filterItems = [
  { label: "Best Value", value: "great-value", group: "value" },
  { label: "Expires Soon", value: "expires-soon", group: "expires" },
  // {label: "Featured", value: "featured", group: "featured"},
  // {label: "Has Bids", value: "has-bids", group: "bids"},
  // {label: "No Bids", value: "no-bids", group: "bids"},
  { label: "Parlays Only", value: "parlays-only", group: "parlays" },
  { label: "Parlays Excluded", value: "parlays-excluded", group: "parlays" },
  // {label: "Price: $0-$100", value: "$0-$100", group: "price"},
  // {label: "Price: $101-$1,000", value: "$101-$1,000", group: "price"},
  // {label: "Price: $1,001+", value: "$1,001+", group: "price"},
];

let init = 0; //TODO

export default ({ navigation, route }) => {
  const theme = reactNativePaper.useTheme();
  const currentUser = useContext(CurrentUserContext);
  const searchExact = route?.params?.searchExact?.toString?.() === "true";
  const initialSearchString = route?.params?.searchString ?? "";
  const [searchStringQueryParam, setSearchStringQueryParam] =
    useState(initialSearchString);
  const [searchString, setSearchString] = useState(searchStringQueryParam);
  const [subNavValue, setSubNavValue] = useState("Tickets");
  const [sortFilterValue, setSortFilterValue] = useState("-");
  const [showSortFilters, setShowSortFilters] = useState(false);
  const [filterFilterValue, setFilterFilterValue] = useState([]);
  const [showFilterFilters, setShowFilterFilters] = useState(false);
  const [sportAbbrev, setSportAbbrev] = useState(null);
  const dataListRef = useRef();

  const [sortField, sortDirection] = sortFilterValue.split("-");

  const handleSubNavChange = useCallback(value => setSubNavValue(value), []);

  const handleSortFilterDismiss = useCallback(
    () => setShowSortFilters(false),
    []
  );

  const handleSortFilterValueChange = useCallback(
    value => [setSortFilterValue(value), setShowSortFilters(false)],
    []
  );

  const handleFilterFilterDismiss = useCallback(
    () => setShowFilterFilters(false),
    []
  );

  const handleFilterFilterValueChange = useCallback(
    value => [setFilterFilterValue(value)],
    []
  );

  const { data: { listSports: { items: sportsUnsorted = [] } = {} } = {} } =
    useQuery(Sport.queries.list, {
      variables: {
        limit: 50,
      },
    });

  const { data: { listLocales: { items: locales = [] } = {} } = {} } = useQuery(
    Locale.queries.list,
    {
      variables: {
        limit: 100,
      },
    }
  );

  const targetStateName = STATES.find(
    ([name, abbrev]) =>
      abbrev.toLowerCase() === currentUser?.state?.toLowerCase?.()
  )?.name;
  const targetLocaleId = locales.find(
    locale => locale.state === targetStateName
  )?.id;

  const sports = useMemo(
    () =>
      sportsUnsorted.slice().sort((a, b) => (a.position > b.position ? 1 : -1)),
    [sportsUnsorted]
  );

  /*Filters*/
  const priceLow = filterFilterValue.includes("$101-$1,000")
    ? 10100
    : filterFilterValue.includes("$1,001+")
    ? 1010000
    : null;
  const priceHigh = filterFilterValue.includes("$0-$100")
    ? 10000
    : filterFilterValue.includes("$101-$1,000")
    ? 100000
    : null;
  const rating = filterFilterValue.includes("great-value") ? 5 : null;
  const betType = filterFilterValue.includes("parlays-only")
    ? "PARLAY"
    : filterFilterValue.includes("parlays-excluded")
    ? "NO-PARLAY"
    : null;
  const featuredOnly = filterFilterValue.includes("featured") ? true : null;
  const expiresSoon = filterFilterValue.includes("expires-soon") ? true : null;
  const bidsFilter = filterFilterValue.includes("has-bids")
    ? "yes"
    : filterFilterValue.includes("no-bids")
    ? "no"
    : null;
  const { scrollDirection, setScrollDirection } = useContext(
    ScrollDirectionContext
  );

  const searchFilterItems = useMemo(
    () =>
      [
        {
          or: [
            {
              "cachedTicket.providerId": {
                exists: false,
              },
            },
            {
              "cachedTicket.localeId": {
                eq: targetLocaleId ?? "<>",
              },
            },
            {
              "cachedTicket.allowAcrossLocales": {
                eq: true,
              },
            },
          ],
        },
        {
          status: {
            eq: "ACTIVE",
          },
        },
        !rating
          ? null
          : {
              valueRating: {
                eq: rating,
              },
            },
        !priceLow
          ? null
          : {
              askingPrice: {
                gt: priceLow,
              },
            },
        !priceHigh
          ? null
          : {
              askingPrice: {
                lt: priceHigh,
              },
            },
        !searchString
          ? null
          : {
              searchString: {
                [!!searchExact ? "matchPhrase" : "match"]:
                  decodeURIComponent(searchString),
              },
            },
        !sportAbbrev
          ? null
          : {
              "cachedTicket.sport.abbrev": {
                eq: sportAbbrev,
              },
            },
        !betType || betType === "All"
          ? null
          : {
              "cachedTicket.betType": {
                [/NO/.test(betType) ? "ne" : "eq"]: betType.replace("NO-", ""),
              },
            },
        !featuredOnly
          ? null
          : {
              featuredAt: {
                exists: true,
              },
            },
        !expiresSoon
          ? null
          : {
              expiresAt: {
                exists: true,
              },
            },
        !bidsFilter
          ? null
          : {
              pendingBidsCount: {
                [bidsFilter === "yes" ? "gt" : "eq"]: 0,
              },
            },
        subNavValue !== "Live"
          ? null
          : {
              "cachedTicket.isLive": {
                eq: true,
              },
            },
      ].filter(item => !!item),
    [
      priceLow,
      priceHigh,
      rating,
      searchExact,
      // otherSportAbbrevs,
      betType,
      sportAbbrev,
      featuredOnly,
      searchString,
      expiresSoon,
      bidsFilter,
      subNavValue,
      targetLocaleId,
    ]
  );

  const dataListQuery = useMemo(
    () => ({
      dataKey: `searchListingsRaw${environment.use_v2 ? "2" : ""}`,
      query: Listing.queries.searchRaw,
      variables: {
        limit: 20,
        sort: !sortField
          ? [
              {
                field: "valueRating",
                direction: "desc",
              },
              {
                field: randomSortItem,
                direction: "desc",
              },
            ]
          : [
              {
                direction: sortDirection,
                field: sortField,
              },
            ],
        ...(!searchFilterItems?.length
          ? {}
          : {
              filter: JSON.stringify({
                and: searchFilterItems,
              }),
            }),
      },
    }),
    [searchFilterItems, sortDirection, sortField]
  );

  const handleSportSelect = useCallback(
    sport => () => setSportAbbrev(sport.abbrev),
    []
  );

  const dataListRenderItem = useCallback(
    ({ item, index }) => (
      <View
        style={{
          marginBottom: theme.spacing.md,
        }}
      >
        <TicketItem ticketObj={item.cachedTicket} listingObj={item} />
      </View>
    ),

    [theme]
  );

  useEffect(() => {
    setSearchStringQueryParam(initialSearchString);
  }, [initialSearchString]);

  useEffect(() => {
    !!dataListRef?.current?.refetch && dataListRef.current.refetch();
  }, [dataListQuery]);

  useEffect(() => {
    setSearchString(searchStringQueryParam);
  }, [searchStringQueryParam]);

  // useEffect(() => {
  //   !!dataListRef?.current?.refetch && dataListRef.current.refetch();
  // }, [dataListRef]);

  // useFocusEffect(
  //   useCallback(() => {
  //     !!dataListRef?.current?.refetch && dataListRef.current.refetch();
  //   }, [])
  // );

  useFocusEffect(
    useCallback(
      () => setSearchString(searchStringQueryParam),
      [searchStringQueryParam]
    )
  );

  return (
    <Screen fullHeight fullWidth title="Buy">
      {scrollDirection !== "down" && (
        <ListingSearchbar
          onChangeText={text => [
            setSearchString(text),
            setSearchStringQueryParam(text),
          ]}
          value={searchString}
          enabled={false}
          onPress={() => navigation.push("Search")}
        />
      )}

      <ScreenNav
        itemLeft={
          subNavValue === "Picks for U" ? (
            <Spacer />
          ) : (
            <MaterialCommunityIcons
              name="sort"
              size={18}
              color={theme.colors.onSurface}
              onPress={() => setShowSortFilters(true)}
            />
          )
        }
        itemRight={
          subNavValue === "Picks for U" ? (
            <Spacer />
          ) : (
            <MaterialCommunityIcons
              name="filter-variant"
              size={18}
              color={theme.colors.onSurface}
              onPress={() => setShowFilterFilters(true)}
            />
          )
        }
        theme={theme}
        selectedValue={subNavValue}
        onValueChange={handleSubNavChange}
        buttons={[
          { label: "Tickets" },
          { label: "By Sport" },
          { label: "Live" },
          currentUser &&
            currentUser?.groups.includes("Admins") && { label: "Picks for U" },
        ].filter(item => !!item)}
      />

      {!!sportAbbrev && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <reactNativePaper.Text>{sportAbbrev} Tickets</reactNativePaper.Text>
          <reactNativePaper.Button
            uppercase={false}
            mode="text"
            onPress={() => [setSubNavValue("Tickets"), setSportAbbrev(null)]}
          >
            Clear
          </reactNativePaper.Button>
        </View>
      )}

      {subNavValue === "By Sport" && !sportAbbrev && (
        <ScrollView
          contentContainerStyle={{
            marginTop: theme.spacing.xl,
            backgroundColor: theme.colors.surface,
          }}
        >
          {sports.map(sport => (
            <reactNativePaper.List.Item
              titleStyle={{ color: theme.colors.secondary }}
              style={{
                paddingLeft: theme.spacing.xl,
                backgroundColor: theme.colors.surface,
                borderBottomWidth: 1,
                borderColor: theme.colors.border,
                borderStyle: "solid",
              }}
              key={sport.id}
              onPress={handleSportSelect(sport)}
              left={({
                color,
                style: { marginLeft, marginRight, marginVertical },
              }) => (
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft,
                    marginRight,
                    marginVertical,
                  }}
                >
                  <Sports
                    sportAbbrev={sport.abbrev}
                    color={theme.colors.secondary}
                  />
                </View>
              )}
              title={sport.abbrev}
            />
          ))}
        </ScrollView>
      )}

      {(["Tickets", "Live"].includes(subNavValue) || !!sportAbbrev) && (
        <View style={{ flex: 1 }}>
          <DataList
            onScroll={
              Platform.OS !== "web"
                ? null
                : handleScroll(
                    () => setScrollDirection("up"),
                    () => setScrollDirection("down")
                  )
            }
            onScrollBeginDrag={
              Platform.OS === "web"
                ? null
                : event => {
                    init = event.nativeEvent.contentOffset.y;
                  }
            }
            onScrollEndDrag={
              Platform.OS === "web"
                ? null
                : event =>
                    init > event.nativeEvent.contentOffset.y
                      ? setScrollDirection("up")
                      : setScrollDirection("down")
            }
            dataListRef={dataListRef}
            query={dataListQuery}
            renderItem={dataListRenderItem}
            contentContainerStyle={{
              paddingTop: theme.spacing.xl,
              marginHorizontal: theme.spacing.xl,
            }}
            ListEmptyComponent={
              <EmptySign
                title={
                  subNavValue === "Live"
                    ? "Please check back later."
                    : "No Tickets"
                }
                subheading={
                  subNavValue === "Live"
                    ? "There are no live events at this time."
                    : "No Tickets Match Your Criteria."
                }
              />
            }
          />
        </View>
      )}

      {subNavValue === "Picks for U" && !!currentUser?.id && (
        <TicketPersonalizedList userId={currentUser?.id} horizontal={false} />
      )}

      <FilterScreen
        items={sortItems}
        theme={theme}
        visible={showSortFilters}
        onDismiss={handleSortFilterDismiss}
        title="Sort By"
        selectedValue={sortFilterValue}
        onValueChange={handleSortFilterValueChange}
      />

      <FilterScreen
        items={filterItems}
        multiple
        theme={theme}
        visible={showFilterFilters}
        onDismiss={handleFilterFilterDismiss}
        title="Filter By"
        selectedValue={filterFilterValue}
        onValueChange={handleFilterFilterValueChange}
        height="90%"
      />
    </Screen>
  );
};
