import React from "react";

import Baseball from "./Baseball";
import Basketball from "./Basketball";
import Boxing from "./Boxing";
import Football from "./Football";
import Golf from "./Golf";
import Hockey from "./Hockey";
import Mma from "./Mma";
import Soccer from "./Soccer";
import Tennis from "./Tennis";
import Other from "./Other";
import Horse from "./Horse";
import Motor from "./Motor";

const sports = {
  nba: Basketball,
  mlb: Baseball,
  nhl: Hockey,
  golf: Golf,
  nfl: Football,
  cfb: Football,
  cbb: Basketball,
  wnba: Basketball,
  mma: Mma,
  tennis: Tennis,
  soccer: Soccer,
  boxing: Boxing,
  nascar: Motor, //NO ICON
  horse: Horse, //NO ICON
  other: Other, //NO ICON
};

export default ({ sportAbbrev, ...props }) => {
  const Icon = !!sports[(sportAbbrev ?? "").toLowerCase()]
    ? sports[(sportAbbrev ?? "").toLowerCase()]
    : sports["other"];

  return <Icon {...props} />;
};
