import { useNavigation } from "@react-navigation/native";
import useStyles from "Hooks/useStyles";
import React from "react";
import { useCallback } from "react";
import BackButtonStyles from "./BackButtonStyles";
import BackButtonView from "./BackButtonView";

export default ({ useSimple }) => {
  const { theme, styles } = useStyles({ Styles: BackButtonStyles });
  const navigation = useNavigation();
  const handlePress = useCallback(
    () => (!!useSimple ? window.history.back() : navigation.goBack()),
    [navigation, useSimple]
  );

  return <BackButtonView theme={theme} styles={styles} onPress={handlePress} />;
};
