import React, { useContext } from "react";
import {
  Button,
  HelperText,
  useTheme,
  TextInput,
  RadioButton,
} from "react-native-paper";
import { useCallback } from "react";

import * as yup from "yup";
import Screen from "Components/Screen";

import { HeaderButtons, Item } from "react-navigation-header-buttons";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Platform, View } from "react-native";
import ScreenBottom from "Components/Screen/Bottom";
import { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Interchecks from "shared/api/Interchecks";
import { CurrentUserContext } from "Contexts/CurrentUser";

const schema = yup.object().shape({
  accountNumber: yup
    .string()
    .min(6, "Too short - should be 6 chars minimum.")
    .max(17, "Too long - should be 17 chars maximum.")
    .matches(/^[0-9]{6,17}$/i, "Must be a valid bank account number")
    .required(),
  routingNumber: yup
    .string()
    .min(9, "Too short - should be 2 chars minimum.")
    .max(9, "Too long - should be 25 chars maximum.")
    .matches(/^[0-9]{9}$/i, "Must be a valid routing number")
    .required(),
});

export default ({
  navigation,
  route: {
    params: {
      accountType: passedAccountType = "CHECKING",
      accountNumber,
      routingNumber,
      amount,
      mailingAddress1,
      mailingAddress2,
      mailingCity,
      mailingState,
      mailingZip,
    } = {},
  } = {},
}) => {
  const theme = useTheme();
  const inputs = React.useRef({});

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
      isSubmitting,
      touchedFields,
      dirtyFields,
      ...rest
    },
    setError,
    setFocus,
    watch,
    ...others
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const currentUser = useContext(CurrentUserContext);
  const [accountType, setAccountType] = React.useState(
    passedAccountType ?? "CHECKING"
  );
  const [createAchAccount] = useMutation(
    Interchecks.mutations.createAchAccount
  );

  const onSubmit = useCallback(
    ({ accountNumber, routingNumber }) =>
      createAchAccount({
        variables: {
          input: {
            userId: currentUser?.id,
            account: {
              account_type: accountType,
              account_number: accountNumber,
              routing_number: routingNumber,
            },
            recipient: {
              recipient_email: currentUser?.email,
              recipient_first_name: currentUser?.attributes?.given_name,
              recipient_last_name: currentUser?.attributes?.family_name,
              recipient_address: {
                address_1: currentUser?.attributes?.address,
                address_2:
                  currentUser?.attributes?.["custom:address2"] ?? undefined,
                city: `${currentUser?.attributes?.["custom:city"]}`,
                state:
                  `${currentUser?.attributes?.["custom:state"]}`?.toUpperCase?.(),
                zip: `${currentUser?.attributes?.["custom:zip"]}`,
              },
            },
          },
        },
      })
        .then(() =>
          navigation.navigate("AchWithdrawSelect", {
            accountType,
            accountNumber,
            routingNumber,
            amount,
            mailingAddress1,
            mailingAddress2,
            mailingCity,
            mailingState,
            mailingZip,
          })
        )
        .catch(console.error),
    [
      navigation,
      accountType,
      amount,
      mailingAddress1,
      mailingAddress2,
      mailingCity,
      mailingState,
      mailingZip,
      createAchAccount,
      currentUser,
    ]
  );

  const doSubmit = handleSubmit(onSubmit);

  useEffect(() => {
    setAccountType(passedAccountType);
  }, [passedAccountType]);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons size={48}>
          <Item
            title="Save"
            disabled={!isValid || !!isSubmitting}
            onPress={doSubmit}
          />
        </HeaderButtons>
      ),
    });
  }, [navigation, doSubmit, isValid]);

  return (
    <Screen
      gatedTo="member"
      title="Account Info"
      fullHeight
      hasKeyboard
      backgroundColor={theme.colors.surface}
    >
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
          <>
            <TextInput
              autoFocus
              autoCorrect={false}
              autoCapitalize={"none"}
              ref={e => (inputs.current["accountNumber"] = e)}
              autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
              keyboardType="number-pad"
              textContentType="none"
              label="Account Number"
              error={!!errors.accountNumber}
              returnKeyLabel={"Next"}
              returnKeyType={"next"}
              onSubmitEditing={() => inputs.current.routingNumber.focus()}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.accountNumber}>
              {errors.accountNumber?.message}
            </HelperText>
          </>
        )}
        name="accountNumber"
        defaultValue={accountNumber ?? ""}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
          <>
            <TextInput
              autoCorrect={false}
              autoCapitalize={"none"}
              ref={e => (inputs.current["routingNumber"] = e)}
              autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
              keyboardType="number-pad"
              textContentType="none"
              label="Routing Number"
              error={!!errors.routingNumber}
              returnKeyLabel={"Done"}
              returnKeyType={"done"}
              onSubmitEditing={doSubmit}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.routingNumber}>
              {errors.routingNumber?.message}
            </HelperText>
          </>
        )}
        name="routingNumber"
        defaultValue={routingNumber ?? ""}
      />

      <RadioButton.Group onValueChange={setAccountType} value={accountType}>
        <RadioButton.Item
          color={theme.colors.primary}
          label="Checking"
          value="CHECKING"
        />
        <RadioButton.Item
          color={theme.colors.primary}
          label="Savings"
          value="SAVINGS"
        />
      </RadioButton.Group>

      <ScreenBottom theme={theme}>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          {!!isSubmitting ? "Please Wait..." : "Save"}
        </Button>
      </ScreenBottom>
    </Screen>
  );
};
