import { View } from "react-native";
import "react-native-gesture-handler";
import OnInstallConversionData from "./OnInstallConversionData";
import InitializeSDKHandler from "./InitializeSDKHandler";

const AppsFlyerHandler = () => {
  const displayConversionData = true; // set to true if you wish to log to the console the Conversion Data
  const useDeepLink = true; //set to true if you wish to use Deep Link

  return (
    <View>
      {displayConversionData && <OnInstallConversionData />}

      <InitializeSDKHandler
        displayConversionData={displayConversionData}
        useDeepLink={useDeepLink}
      />
    </View>
  );
};

export default AppsFlyerHandler;
