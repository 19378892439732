import { Platform } from "react-native";

export default theme => ({
  f1: {
    flex: 1,
  },
  highlight: {
    borderColor: "green",
    borderStyle: "dashed",
    borderWidth: 2,
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  textError: {
    color: theme.colors.error,
  },
  textPrimary: {
    color: theme.colors.primary,
  },
  textSecondary: {
    color: theme.colors.secondary,
  },
  textUpper: {
    textTransform: "uppercase",
  },
  textNotification: {
    color: theme.colors.notification,
  },
  textReverse: {
    color: theme.colors.textReverse,
  },
  textDefault: {
    color: theme.colors.text,
  },
  textBold: {
    fontWeight: "bold",
  },
  textNormal: {
    fontWeight: "normal",
  },
  row: {
    flexDirection: "row",
  },
  rowCenter: {
    flexDirection: "row",
    justifyContent: "center",
  },
  fullScreen: {
    marginHorizontal: theme.spacing.xl * -1,
  },
  fullWidth: {
    width: "100%",
  },
  headingWrapper: {
    marginBottom: theme.spacing.md,
    justifyContent: "space-between",
    flex: 1,
    flexDirection: "row",
  },
  forceWrap: {
    flexShrink: 1,
  },
  textWrap: {
    flex: 1,
    flexWrap: "wrap",
  },
  textTruncate: {
    overflow: "hidden",
    ...Platform.select({
      web: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      default: {},
    }),
  },
  clearInput: {
    backgroundColor: "transparent",
  },
  summaryWrapper: {
    marginTop: theme.spacing.xl * 2,
  },
  pointer: {
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
      default: {},
    }),
  },
});
