import React from "react";
import { useCallback } from "react";
import { Divider, List, Title, useTheme } from "react-native-paper";
import Screen from "Components/Screen";
import { userStatusLabels } from "shared/Config";
import { useContext } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import Confirmation from "Components/Confirmation";
import { View } from "react-native";
import { addCommas } from "shared/Util/money";

export default ({ navigation }) => {
  const navigateTo = useCallback(
    screen => () => navigation.push(screen),
    [navigation]
  );

  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);

  return (
    <Screen
      gatedTo="member"
      title="My Points"
      fullHeight
      hasScroll={false}
      backgroundColor={theme.colors.surface}
    >
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Title style={{ textAlign: "center", marginTop: theme.spacing.xl }}>
          Lifetime Points
        </Title>
        <Title
          style={{
            color: theme.colors.primary,
            textAlign: "center",
            padding: theme.spacing.md,
            borderColor: theme.colors.primary,
            borderWidth: 1,
            borderStyle: "solid",
            width: "100%",
          }}
        >
          {addCommas(currentUser?.points ?? 0)}
        </Title>
        <Title style={{ textAlign: "center", marginTop: theme.spacing.xl }}>
          Available Points
        </Title>
        <Title
          style={{
            color: theme.colors.primary,
            textAlign: "center",
            padding: theme.spacing.md,
            borderColor: theme.colors.primary,
            borderWidth: 1,
            borderStyle: "solid",
            width: "100%",
          }}
        >
          {addCommas(currentUser?.availablePoints || 0)}
        </Title>
      </View>
      <View
        style={{
          justifyContent: "flex-end",
          flex: 1,
          marginHorizontal: theme.spacing.xl * -1,
        }}
      >
        {[
          ["Redeem Points", "PointsRedeem"],
          ["How do I earn points?", "PointsEarn"],
          ["How much are my points worth?", "PointsWorth"],
        ].map(([label, screen]) => (
          <React.Fragment key={label}>
            <List.Item
              title={label}
              right={props => <List.Icon {...props} icon="chevron-right" />}
              onPress={navigateTo(screen)}
            />
            <Divider />
          </React.Fragment>
        ))}
      </View>
      {/* <Confirmation
        type="na"
        theme={theme}
        // headline={userStatusLabels[(currentUser?.status ?? 1)-1].label}
        More={<>
          <Title style={{marginTop: theme.spacing.xl}}>Lifetime Points</Title>
          <Title 
            style={{
              color: theme.colors.primary, 
              textAlign: "center", 
              padding: theme.spacing.md, 
              borderColor: theme.colors.primary, 
              borderWidth: 1, 
              borderStyle: "solid", 
              width: "100%"}}
            >{currentUser?.points ?? "0"}</Title>
            <Title style={{marginTop: theme.spacing.xl}}>Available Points</Title>
            <Title 
              style={{
                color: theme.colors.primary, 
                textAlign: "center", 
                padding: theme.spacing.md, 
                borderColor: theme.colors.primary, 
                borderWidth: 1, 
                borderStyle: "solid", 
                width: "100%"}}
              >{currentUser?.availablePoints ?? "0"}</Title>
          </>}
        Footer={
          [
            ["Redeem Points", "PointsRedeem"],
            ["How do I earn points?", "PointsEarn"],
            ["How much are my points worth?", "PointsWorth"],
          ].map(([label, screen]) => 
            <React.Fragment key={label}>
              <List.Item 
                title={label}
                right={props => <List.Icon {...props} icon="chevron-right" />} 
                onPress={navigateTo(screen)} 
              />
              <Divider />
            </React.Fragment>
          )
        }
      /> */}
    </Screen>
  );
};
