import React from "react";
import { Platform, TouchableWithoutFeedback, View } from "react-native";
import { Searchbar } from "react-native-paper";

export default React.forwardRef(
  (
    {
      theme,
      enabled,
      onChange,
      searchText,
      onPress,
      placeholder = "Search",
      styles,
      ...rest
    },
    ref
  ) =>
    !!enabled ? (
      <View style={styles.wrapper}>
        <Searchbar
          ref={ref}
          placeholder={placeholder}
          editable={!!enabled || Platform.OS !== "web"}
          onFocus={() => !enabled && onPress()}
          style={styles.searchbar}
          inputStyle={styles.input}
          numberOfLines={1}
          {...rest}
        />
      </View>
    ) : (
      <TouchableWithoutFeedback onPress={onPress}>
        <View>
          <View style={styles.wrapper}>
            <Searchbar
              ref={ref}
              placeholder={placeholder}
              editable={!!enabled || Platform.OS !== "web"}
              onFocus={() => !enabled && onPress()}
              style={styles.searchbar}
              inputStyle={styles.input}
              {...rest}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    )
);
