import * as React from "react";
import Svg, { Defs, G, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 245.98 255.1"
      height={props.size}
      width={props.size}
      {...props}
    >
      <Defs></Defs>
      <G id="Layer_2" data-name="Layer 2">
        <G id="Layer_1-2" data-name="Layer 1">
          <Path
            fill={props?.color ?? "#666"}
            className="cls-1"
            d="M98.31 250.94C91.32 215.87 84 180.88 77 145.81c-.86-4.32-2.45-4.8-6.39-4.07-21.41 4-42.89 7.71-64.34 11.53-1.94.34-3.86.81-6.27 1.32 7.21 33.57 14.36 66.8 21.61 100.51h78.71c-1-.79-1.59-2.1-2.01-4.16zm-41.8-11.66a12.64 12.64 0 01-12.69-12.6A12.11 12.11 0 0156.1 214.9a12.68 12.68 0 0112.73 12.59 12.18 12.18 0 01-12.32 11.79zM198.84 85.41c-.25 9.17-8.32 15.82-19 15.62-10.31-.2-18.21-7.85-17.95-17.37.26-9.28 8.25-15.87 18.89-15.59 10.46.28 18.32 7.85 18.06 17.34z"
          />
          <Path
            fill={props?.color ?? "#666"}
            className="cls-1"
            d="M240.84 173c7.87-13.21 6-23.41-5.38-29.58.31-3.88 1.1-7.82.82-11.68-.44-6-3.61-10.88-9.26-13.25-3.51-1.47-3.62-3.6-3.15-6.63q5.74-36.74 11.39-73.48c.69-4.44 1.33-8.89 2.07-13.32 1-6.08-1.22-9.59-7.37-10.73-20.12-3.73-40.26-7.34-60.4-11C166 2.68 156.07.1 153 .1c0 0-4-2-8.5 11.75-1.51 4.62-2.21 5.74-6.44 5.54-6.49-.3-13-.11-19.5-.07-8.5.06-11.52 3.54-10.24 11.87 3.09 20.06 6.52 40.07 9.2 60.18 1 7.7.48 15.63.33 23.45a4.63 4.63 0 01-2.84 3.3c-8.22 2.08-12.9 7.91-17.73 14.39-6.32 8.46-7.9 16.55-5.4 27.08 5.83 24.58 10.21 49.51 15.1 74.31.45 2.29.78 3.86 3.78 4.15 11.92 1.16 23.84 2.4 35.69 4.11s23.53-.26 35.09-3c14.4-3.4 28.87-6.56 43.23-10.13 11.43-2.83 15.57-10.37 12.87-21.81a6.21 6.21 0 011.48-4.89c8.45-8.62 9.18-17.68 1.72-27.33zm-106.59-72.37c-2.26-16.33-4.49-32.1-6.55-47.9a7.46 7.46 0 011.19-4.73c3.27-4.68 6.83-9.17 10.3-13.71a11.45 11.45 0 011.33-1.29l1.4.44c-2.55 22.28-5.09 44.56-7.67 67.19zm83.92-51.82c-2.89 18.22-5.69 36.45-8.52 54.69-.84 5.42-1.31 10.94-2.7 16.21-.62 2.34-2.72 5.38-4.82 6.06-15.56 5.09-31.44 8.63-48 6.51-2.44-.32-4.85-.9-7.84-1.46 2.73-23.93 5.38-47.21 8-70.49.87-7.6 1.85-15.19 2.5-22.8.28-3.3 1.29-5.12 4.57-6.7 4.05-2 7.33-5.48 11.09-8.11 1.55-1.08 3.62-2.45 5.23-2.22 8.22 1.19 16.4 2.72 24.51 4.49 1.51.33 2.91 2.1 3.93 3.51 3.17 4.35 5.63 9.37 9.37 13.13 2.36 2.37 3.16 4.16 2.68 7.18z"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
