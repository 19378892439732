import SidebarRightView from "./SidebarRightView";
import useStyles from "Hooks/useStyles";
import NavHeaderStyles from "Components/NavHeader/NavHeaderStyles";
import { useWindowDimensions } from "react-native";

export default () => {
  const { styles, theme } = useStyles({ Styles: NavHeaderStyles });
  const { width: screenWidth } = useWindowDimensions();
  const centerColumnWidth = 601;
  const rightColumnWidth = 264;

  return (
    <SidebarRightView
      theme={theme}
      styles={styles}
      screenWidth={screenWidth}
      centerColumnWidth={centerColumnWidth}
      rightColumnWidth={rightColumnWidth}
    />
  );
};
