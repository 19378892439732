import { useState, useEffect } from "react";
import * as Location from "expo-location";

const useLocation = ({ skip }) => {
  const [coords, setCoords] = useState(null);
  const [response, requestPermission] = Location.useForegroundPermissions();
  const status = response?.status;

  useEffect(() => {
    if (!skip && status === "undetermined") {
      requestPermission();
    } else if (!skip && status === "granted") {
      Location.getCurrentPositionAsync({
        accuracy: Location.Accuracy.Highest,
        maximumAge: 10000,
      })
        .then(setCoords)
        .catch(console.error);
    }
  }, [status, requestPermission, skip]);

  return coords;
};

export default useLocation;
