import { Platform, ScrollView, useWindowDimensions, View } from "react-native";
import { Headline, useTheme } from "react-native-paper";

import Screen from "Components/Screen";

import YoutubePlayer from "react-native-youtube-iframe";
import Section from "Components/Section";
import TextListItem from "Components/TextListItem";
// import useWindowDimensions from 'react-native/Libraries/Utilities/useWindowDimensions';

export default () => {
  const theme = useTheme();
  const videoWidth = 314;
  const { width } = useWindowDimensions();
  const availableWidth =
    Platform.OS === "web" ? Math.min(601, width) - 2 : width;

  const marginLeft = (availableWidth - videoWidth) / 2;

  return (
    <Screen
      title="How To Sell"
      fullHeight
      backgroundColor={theme.colors.background}
      fullWidth
    >
      <ScrollView
        contentContainerStyle={{ paddingHorizontal: theme.spacing.xl }}
      >
        <View style={{ flex: 1, paddingTop: theme.spacing.lg }}>
          <Headline>Selling Is Easy On PropSwap</Headline>

          <Section skipDivider theme={theme} heading="Step 1: Want to Sell?">
            <TextListItem theme={theme}>
              Click the "+" button to list your ticket for sale. If you are a
              new seller, you will need to verify your account and add a credit
              card on file (this is for insurance purposes only).
            </TextListItem>
          </Section>

          <Section skipDivider theme={theme} heading="Step 2: List Your Ticket">
            <TextListItem theme={theme}>
              Snap a photo of your slip, enter the ticket info and set your Sale
              Price. We will provide a Suggested Price for you, but you are able
              to list the ticket for any price you like.
            </TextListItem>
          </Section>

          <Section
            skipDivider
            theme={theme}
            heading="Step 3: Control Your Ticket"
          >
            <TextListItem theme={theme}>
              You can change the Sale Price or Unlist your ticket at any time.
              Just go to the My Tickets page, select the ticket you wish to
              change, and click the "EDIT" button.
            </TextListItem>
          </Section>

          <Section skipDivider theme={theme} heading="Step 4: Get Paid">
            <TextListItem theme={theme}>
              Once your ticket sells, you will receive an automated email with
              directions on where to mail your ticket. In order to receive your
              money, we must receive your ticket within 7 calendar days.
            </TextListItem>
          </Section>

          <View
            style={{
              marginLeft,
              marginTop: theme.spacing.xl,
              marginBottom: theme.spacing.xl * 2,
            }}
          >
            <YoutubePlayer
              height={177}
              play
              width={videoWidth}
              videoId="speGSaNAiOo"
            />
          </View>
        </View>
      </ScrollView>
    </Screen>
  );
};
