import React from "react";

import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import ProfileNav from "Screens/ProfileNav";
import MyEmail from "Screens/MyEmail";
import MyPhoto from "Screens/MyPhoto";
import MyRating from "Screens/MyRating";
import { getStandardScreenOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={getStandardScreenOptions({ screenOptions, navigation })}
    >
      <Stack.Screen name="ProfileNav" component={ProfileNav} />
      <Stack.Screen name="MyEmail" component={MyEmail} />
      <Stack.Screen name="MyPhoto" component={MyPhoto} />
      <Stack.Screen name="MyRating" component={MyRating} />
    </Stack.Navigator>
  );
};
