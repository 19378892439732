import gql from "graphql-tag";
import environment from "environment";
import User from "./User";
import Listing from "./Listing";
import Ticket from "./Ticket";

const Watching = {};

Watching.fragments = {
  public: gql`
    fragment WatchingPublic on Watching {
      id
      createdAt
      updatedAt
      listingId
      userId
    }
  `,
};

Watching.queries = {
  listByUserIdAndListingId: gql`
    query ListWatchingsByUserIdAndListingId(
      $userId: ID${environment.v2_bang}
      $listingIdCreatedAt: ModelWatchingByUserIdAndListingIdCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelWatchingFilterInput
      $limit: Int
      $nextToken: String
      $withListing: Boolean = false
      $withUser: Boolean = false
    ) {
      listWatchingsByUserIdAndListingId(
        userId: $userId
        listingIdCreatedAt: $listingIdCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...WatchingPublic
          user @include(if: $withUser) {
            ...UserPublic
          }
          listing @include(if: $withListing) {
            ...ListingPublic
          }
        }
        nextToken
      }
    }
    ${Watching.fragments.public}
    ${User.fragments.public}
    ${Listing.fragments.public}
  `,
  listByUserId: gql`
    query ListWatchingsByUserId(
      $userId: ID${environment.v2_bang}
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelWatchingFilterInput
      $limit: Int
      $nextToken: String
      $withListing: Boolean = false
      $withTicket: Boolean = false
      $withUser: Boolean = false
    ) {
      listWatchingsByUserId(
        userId: $userId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...WatchingPublic
          user @include(if: $withUser) {
            ...UserPublic
          }
          listing @include(if: $withListing) {
            ...ListingPublic
            ticket @include(if: $withTicket) {
              ...TicketPublic
            }
          }
        }
        nextToken
      }
    }
    ${Watching.fragments.public}
    ${User.fragments.public}
    ${Listing.fragments.public}
    ${Ticket.fragments.public}
  `,
  listByListingId: gql`
    query ListWatchingsByListingId(
      $listingId: ID${environment.v2_bang}
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelWatchingFilterInput
      $limit: Int
      $nextToken: String
      $withListing: Boolean = false
      $withUser: Boolean = false
    ) {
      listWatchingsByListingId(
        listingId: $listingId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...WatchingPublic
          user @include(if: $withUser) {
            ...UserPublic
          }
          listing @include(if: $withListing) {
            ...ListingPublic
          }
        }
        nextToken
      }
    }
    ${Watching.fragments.public}
    ${User.fragments.public}
    ${Listing.fragments.public}
  `,
  list: gql`
    query ListWatchings(
      $filter: ModelWatchingFilterInput
      $limit: Int
      $nextToken: String
      $withListing: Boolean = false
      $withUser: Boolean = false
    ) {
      listWatchings(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...WatchingPublic
          user @include(if: $withUser) {
            ...UserPublic
          }
          listing @include(if: $withListing) {
            ...ListingPublic
          }
        }
        nextToken
      }
    }
    ${Watching.fragments.public}
    ${User.fragments.public}
    ${Listing.fragments.public}
  `,
  get: gql`
    query GetWatching($id: ID!) {
      getWatching(id: $id) {
        ...WatchingPublic
      }
    }
    ${Watching.fragments.public}
  `,
};

Watching.mutations = {
  delete: gql`
    mutation DeleteWatching($input: DeleteWatchingInput!) {
      deleteWatching(input: $input) {
        ...WatchingPublic
      }
    }
    ${Watching.fragments.public}
  `,
  create: gql`
    mutation CreateWatching($input: CreateWatchingInput!) {
      createWatching(input: $input) {
        ...WatchingPublic
      }
    }
    ${Watching.fragments.public}
  `,
  update: gql`
    mutation UpdateWatching($input: UpdateWatchingInput!) {
      updateWatching(input: $input) {
        ...WatchingPublic
      }
    }
    ${Watching.fragments.public}
  `,
};

export default Watching;
