import { Button, HelperText, Subheading, Text } from "react-native-paper";
import formatOdds from "Util/formatOdds";
import { Keyboard, View, Platform, TextInput } from "react-native";

import {
  formatPennies,
  formatValue,
  addCommas,
  convertMoneyTextToPennies,
  formatMoney,
} from "shared/Util/money";
import { Controller } from "react-hook-form";
import calculatePayout from "shared/Util/calculatePayout";

export default ({
  styles,
  bid,
  onSubmit,
  theme,
  listing,
  counter,
  control,
  currentUser,
  errors,
  action,
  isValid,
  isSubmitting,
}) => (
  <View style={styles.wrapper}>
    <View style={styles.summaryWrapper}>
      <Subheading>Buyer's Offer: {formatPennies(bid?.offer, "$")}</Subheading>
      <Subheading>
        Odds: {formatOdds(listing?.cachedTicket?.collectAmount, bid?.offer)}
      </Subheading>
      {action === "ACCEPTED" && (
        <Subheading>
          Payout $
          {formatMoney(
            calculatePayout(
              currentUser,
              listing?.commissionWaiverApplied,
              bid?.offer
            )
          )}
        </Subheading>
      )}
    </View>

    {action === "ACCEPTED" && (
      <>
        <Subheading style={[styles.textCenter, styles.confirmationText]}>
          Are you sure you want to Accept this offer?
        </Subheading>
        <Button mode="contained" onPress={onSubmit}>
          Accept
        </Button>
      </>
    )}

    {action === "REJECTED" && (
      <>
        <Subheading style={[styles.textCenter, styles.confirmationText]}>
          Are you sure you want to Reject this offer?
        </Subheading>
        <Button mode="contained" onPress={onSubmit}>
          Reject
        </Button>
      </>
    )}

    {action === "COUNTERED" && (
      <>
        <View style={[styles.row, { marginBottom: theme.spacing.md }]}>
          <View style={styles.inputWrapper}>
            <Text>Counter Offer: </Text>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value, ...mre },
                ...rest
              }) => (
                <>
                  <TextInput
                    autoFocus
                    // selectTextOnFocus
                    autoCorrect={false}
                    autoCapitalize="none"
                    autoCompleteType={
                      Platform.OS === "web" ? "new-password" : "off"
                    }
                    keyboardType="decimal-pad"
                    textContentType="none"
                    returnKeyLabel="Done"
                    returnKeyType="done"
                    onSubmitEditing={Keyboard.dismiss}
                    onBlur={onBlur}
                    onChangeText={value =>
                      onChange(
                        formatValue((value ?? "").replace(/[^0-9.]/gi, "") || 0)
                      )
                    }
                    value={`$${
                      value.toString() === "0"
                        ? ""
                        : addCommas(value.toString())
                    }`}
                    style={[
                      styles.input,
                      {
                        borderColor: !!errors.askingPrice
                          ? theme.colors.error
                          : theme.colors.secondary,
                      },
                    ]}
                  />
                </>
              )}
              name="counter"
              defaultValue=""
            />
          </View>

          <View style={styles.inputWrapper}>
            <Text>New Odds: </Text>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value, ...mre },
                ...rest
              }) => (
                <>
                  <TextInput
                    disabled
                    selectTextOnFocus
                    autoCorrect={false}
                    autoCapitalize="none"
                    autoCompleteType={
                      Platform.OS === "web" ? "new-password" : "off"
                    }
                    textContentType="none"
                    value={formatOdds(
                      listing?.cachedTicket?.collectAmount,
                      convertMoneyTextToPennies(counter)
                    )}
                    style={styles.input}
                  />
                </>
              )}
              name="odds"
              defaultValue=""
            />
          </View>
        </View>

        {!!errors.counter && (
          <View style={[styles.row, { marginBottom: theme.spacing.sm }]}>
            <HelperText type="error" visible={!!errors.counter}>
              {errors.counter?.message}
            </HelperText>
          </View>
        )}

        <Button
          loading={!!isSubmitting}
          disabled={!!isSubmitting || !isValid}
          mode="contained"
          onPress={onSubmit}
        >
          Submit
        </Button>
        <Text style={styles.textCenter}>
          By clicking Submit, this will automatically Reject the Buyer's offer.
        </Text>
      </>
    )}
  </View>
);
