import Storage from "@aws-amplify/storage";
import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useMemo, useState, useCallback } from "react";

import ENV from "../environment";

/**
 * Function to convert a URI to a Blob object
 * @param {string} uri - The URI of the file
 * @returns {Promise} - Returns a promise that resolves with the Blob object
 */
export function uriToBlob(uri) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // If successful -> return with blob
    xhr.onload = function () {
      resolve(xhr.response);
    };

    // reject on error
    xhr.onerror = function () {
      reject(new Error("uriToBlob failed"));
    };

    // Set the response type to 'blob' - this means the server's response
    // will be accessed as a binary object
    xhr.responseType = "blob";

    // Initialize the request. The third argument set to 'true' denotes
    // that the request is asynchronous
    xhr.open("GET", uri, true);

    // Send the request. The 'null' argument means that no body content is given for the request
    xhr.send(null);
  });
}

const TicketContext = React.createContext();

const parlayOptions = parlay =>
  !parlay
    ? ""
    : `${Object.values(parlay)
        .map(p => p.subject)
        .join(", ")}`;

const parlayDescription = parlay =>
  !parlay
    ? ""
    : `${Object.keys(parlay).length} Leg Parlay (${Object.values(parlay)
        .map(p => p.eventDescription)
        .join(", ")})`;

const initialState = {
  /*ListingData*/
  expiresAt: null,
  askingPrice: 0,
  minimumBidPrice: 0,
  notes: "",
  listingTicketId: null,
  commissionWaiverApplied: false,

  listNow: true,
  localPhotoUri: "",
  started: false,

  /*TicketData*/
  ticketEventId: null,
  ticketOptionId: null,
  photoUrl: null,
  betType: null,
  eventDescription: "",
  subject: "",
  winAmount: 0,
  betAmount: 0,
  ticketSportId: null,
  ticketLocaleId: null,
  additionalInfo: "",
  isMobile: false,
  ticketSportsbookId: null,
  parlayLegs: 2,
  parlay: null,
  reset: false,
  isSharpSports: false,
};

const TicketProvider = ({ children }) => {
  const [ticketValues, setTicketValues] = useState(initialState);
  const [loaded, setLoaded] = useState(false);
  const resetTicket = useCallback(
    partial =>
      !!partial
        ? Promise.resolve({
            ...initialState,
            isMobile: ticketValues.isMobile,
            ticketLocaleId: ticketValues.ticketLocaleId,
            ticketSportsbookId: ticketValues.ticketSportsbookId,
            ticketSportId: ticketValues.ticketSportId,
            betType: ticketValues.betType,
            eventDescription: ticketValues.eventDescription,
            ticketEventId: ticketValues.ticketEventId,
            reset: true,
          })
            .then(data =>
              AsyncStorage.setItem("@ticketValues", JSON.stringify(data)).then(
                () => data
              )
            )
            .then(setTicketValues)
        : AsyncStorage.setItem(
            "@ticketValues",
            JSON.stringify(initialState)
          ).then(() => setTicketValues(initialState)),
    [ticketValues]
  );

  // const parlayString = JSON.stringify(ticketValues.parlay);

  const handleSetTicketValues = useCallback(
    values => setTicketValues(values),
    []
  );

  const ticketString = JSON.stringify(ticketValues);

  const memoizedValues = useMemo(
    () => JSON.parse(ticketString),
    [ticketString]
  );

  useEffect(() => {
    setTicketValues(ticketValues => ({
      ...ticketValues,
      eventDescription:
        Object.keys(ticketValues.parlay ?? []).length > 1
          ? parlayDescription(ticketValues.parlay)
          : ticketValues.eventDescription,
      subject:
        Object.keys(ticketValues.parlay ?? []).length > 1
          ? parlayOptions(ticketValues.parlay)
          : ticketValues.subject,
      ticketSportId:
        ticketValues.parlay?.["1"]?.ticketSportId ?? ticketValues.ticketSportId,
    }));
  }, [memoizedValues]);

  useEffect(() => {
    !!loaded &&
      AsyncStorage.setItem(
        "@ticketValues",
        JSON.stringify(memoizedValues)
      ).catch(console.error);
  }, [memoizedValues, loaded]);

  useEffect(() => {
    AsyncStorage.getItem("@ticketValues")
      .then(value => (!!value ? JSON.parse(value) : initialState))
      .then(setTicketValues)
      .then(() => setLoaded(true));
  }, []);

  useEffect(() => {
    !!ticketValues.localPhotoUri &&
      // fetch(ticketValues.localPhotoUri)
      //   .then(imageData => imageData.blob())
      uriToBlob(ticketValues.localPhotoUri)
        .then(blobData => [
          blobData,
          ticketValues.localPhotoUri.match(/\.gif$/)
            ? "gif"
            : ticketValues.localPhotoUri.match(/\.gif$/)
            ? "png"
            : "jpeg",
        ])
        .then(([blobData, fileType]) =>
          Storage.put(
            `${new Date().getTime().toString()}.${fileType}`,
            blobData,
            {
              contentType: `image/${fileType}`,
            }
          )
            .then(({ key }) => Storage.get(key))
            .then(
              url =>
                url
                  .replace(/^.+\.s3.*\.amazonaws\.com/, `https://${ENV?.cdn}`)
                  .split("?")[0]
            )
            .then(url =>
              setTicketValues(ticketValues => ({
                ...ticketValues,
                photoUrl: url,
              }))
            )
        )
        .catch(console.error);
  }, [ticketValues.localPhotoUri]);

  return !loaded ? null : (
    <TicketContext.Provider
      value={{
        ticketValues: memoizedValues,
        setTicketValues: handleSetTicketValues,
        resetTicket,
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};

export { TicketContext, TicketProvider };
