import { userStatusLabels } from "shared/Config";
import React from "react";
import { View } from "react-native";
import { Avatar } from "react-native-paper";

export default ({ user, theme, size = 40, uri, styles }) => (
  <View
    style={{
      borderWidth: !uri ? 0 : 3,
      borderStyle: "solid",
      borderRadius: size,
      borderColor:
        theme.colors[userStatusLabels[(user?.status ?? 1) - 1].color],
    }}
  >
    {!!uri && (
      <View style={[{ borderRadius: size }, styles.imageWrapper]}>
        <Avatar.Image size={size} source={{ uri }} />
      </View>
    )}
    {!uri && (
      <Avatar.Text
        style={{
          backgroundColor:
            theme.colors[userStatusLabels[(user?.status ?? 1) - 1].color],
        }}
        size={size}
        label={user?.displayName?.substring?.(0, 1)?.toUpperCase() ?? "..."}
      />
    )}
  </View>
);
