import { formatPennies } from "shared/Util/money";
import { Text } from "react-native-paper";
import { TouchableWithoutFeedback, View } from "react-native";
import UserAvatar from "Components/User/Avatar";
import moment from "moment";

export default ({ bid, showUser, theme, onPress, styles }) => (
  <>
    {!!showUser && (
      <TouchableWithoutFeedback
        onPress={!!onPress && (() => onPress(bid.bidder))}
      >
        <View style={[styles.row, styles.pointer]}>
          <View style={styles.avatarWrapper}>
            <UserAvatar theme={theme} user={bid.bidder} />
          </View>
          <View style={{ flexShrink: 1 }}>
            <Text style={[styles.f1, styles.textError]}>
              You have a bid of {formatPennies(bid.offer, "$")}
            </Text>
            <Text style={[styles.f1]}>
              <Text style={{ fontWeight: "bold" }}>From:</Text>{" "}
              {bid.bidder?.displayName}
            </Text>
            {!!bid.expiresAt && (
              <Text style={[styles.f1]}>
                <Text style={{ fontWeight: "bold" }}>Expires: </Text>
                {moment(bid.expiresAt).format("MM/DD/YYYY hh:mm a")}
              </Text>
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>
    )}
    {!showUser && (
      <Text style={styles.textError}>
        You have a bid of {formatPennies(bid.offer, "$")}
      </Text>
    )}
  </>
);
