import React from "react";
import {
  Text,
  Title,
  useTheme,
  Subheading,
  Button,
  Paragraph,
} from "react-native-paper";
import Screen from "Components/Screen";
import ScreenBottom from "Components/Screen/Bottom";
import { View } from "react-native";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen
      gatedTo="member"
      title="Redeem Points"
      hasKeyboard={true}
      hasScroll
      backgroundColor={theme.colors.surface}
    >
      <View style={{ marginBottom: theme.spacing.xl }}>
        <Title style={{ fontWeight: "bold", textAlign: "center" }}>
          Redeem your Points for Commission-free sales!
        </Title>
      </View>
      <View style={{ marginBottom: theme.spacing.xl }}>
        <Subheading>
          When you reach 5,000 Available Points, you will have the ability to
          waive PropSwap’s 10% commission, up to $100.
        </Subheading>
      </View>
      <Title style={{ fontWeight: "bold" }}>How It Works</Title>

      <Paragraph>
        You can redeem your Commission-free sale one of two ways:
      </Paragraph>
      <Paragraph>
        1) On the Sell page when you list your ticket for sale
      </Paragraph>
      <Paragraph>
        2) On the Edit Ticket page after your ticket has already been listed
      </Paragraph>
      <Paragraph>
        You can turn ON or OFF the Commission Waiver at any time, as long as you
        have at least 5,000 Available Points.
      </Paragraph>
      <Paragraph>
        You must have the Commission Waiver turned ON before the sale occurs in
        order for your account to be credited.
      </Paragraph>

      <ScreenBottom adjustPadding style={{ marginTop: theme.spacing.xl }}>
        <Button
          mode="contained"
          style={{ width: "100%" }}
          onPress={() => navigation.navigate("Sell")}
        >
          Start Selling
        </Button>
      </ScreenBottom>
    </Screen>
  );
};
