import React from "react";

import { Button, Title, Subheading } from "react-native-paper";
import { View } from "react-native";

export default ({ onNavPress, navLabel, title, subheading, theme, styles }) => (
  <View style={styles.wrapper}>
    <View style={styles.headingsOuterWrapper}>
      <View style={styles.headingsWrapper}>
        <Subheading
          style={[styles.textUpper, styles.textCenter, styles.textReverse]}
        >
          {subheading}
        </Subheading>
        {!!title && (
          <Title
            style={[styles.textUpper, styles.textPrimary, styles.textCenter]}
          >
            {title}
          </Title>
        )}
      </View>
    </View>
    {!!navLabel && (
      <View style={styles.buttonWrapper}>
        <Button mode="contained" onPress={onNavPress} style={styles.fullWidth}>
          {navLabel}
        </Button>
      </View>
    )}
  </View>
);
