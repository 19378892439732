import { Divider, List } from "react-native-paper";
import Screen from "Components/Screen";
import { useContext, useCallback, useState, useEffect } from "react";
import { Platform, useColorScheme } from "react-native";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { ThemeContext } from "Contexts/Theme";
import { DefaultTheme, DarkTheme } from "@react-navigation/native";
import { injectThemeStyles } from "Util/";

export default ({ navigation }) => {
  const colorScheme = useColorScheme();
  const [themeName, setThemeName] = useState(null);
  const { theme, setTheme } = useContext(ThemeContext);
  const changeTheme = useCallback(
    name => () =>
      AsyncStorage.setItem(
        "THEME_TYPE",
        name === "dark" ? "dark" : name === "light" ? "light" : "auto"
      )
        .then(() =>
          setTheme(
            name === "dark"
              ? DarkTheme
              : name === "light"
              ? DefaultTheme
              : colorScheme === "dark"
              ? DarkTheme
              : DefaultTheme
          )
        )
        .then(() => setThemeName(name))
        .then(() => {
          const themeToUseName =
            name === "dark" ? "dark" : name === "light" ? "light" : colorScheme;
          return (
            Platform.OS === "web" &&
            injectThemeStyles(themeToUseName === "dark")
          );
        }),
    // .then(() => navigation.goBack())
    [setTheme, colorScheme]
  );

  useEffect(() => {
    AsyncStorage.getItem("THEME_TYPE").then(setThemeName);
  }, []);

  return (
    <Screen
      title="Theme Settings"
      fullHeight
      fullWidth
      hasScroll
      backgroundColor={theme.colors.surface}
    >
      <List.Item
        title="Light"
        right={props =>
          themeName === "light" && (
            <List.Icon
              style={{ margin: 0, padding: 0 }}
              icon="check-circle-outline"
            />
          )
        }
        onPress={changeTheme("light")}
      />
      <Divider />
      <List.Item
        title="Dark"
        right={props =>
          themeName === "dark" && (
            <List.Icon
              style={{ margin: 0, padding: 0 }}
              icon="check-circle-outline"
            />
          )
        }
        onPress={changeTheme("dark")}
      />
      <Divider />
      <List.Item
        title="Device Preferences"
        right={props =>
          themeName !== "dark" &&
          themeName !== "light" && (
            <List.Icon
              style={{ margin: 0, padding: 0 }}
              icon="check-circle-outline"
            />
          )
        }
        onPress={changeTheme("auto")}
      />
      <Divider />
    </Screen>
  );
};
