import React from "react";
import { useTheme } from "react-native-paper";
import Screen from "Components/Screen";
import { useContext } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import Section from "Components/Section";
import TextListItemView from "Components/TextListItem";

export default ({ navigation }) => {
  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);

  return (
    <Screen
      gatedTo="member"
      title="How Much Are My Points Worth"
      fullHeight
      hasScroll={false}
      backgroundColor={theme.colors.surface}
    >
      <Section theme={theme} heading="PS Credits">
        <TextListItemView theme={theme}>
          250 Points = $1 in PropSwap Cash
        </TextListItemView>
        <TextListItemView theme={theme}>
          5,000 Points = 0% PropSwap Seller Fee on a ticket
        </TextListItemView>
      </Section>
      <Section theme={theme} heading="Swag">
        <TextListItemView theme={theme}>
          2,500 Points = Free PropSwap T-Shirt
        </TextListItemView>
        <TextListItemView theme={theme}>
          4,000 Points = Free PropSwap Hat
        </TextListItemView>
        <TextListItemView theme={theme}>
          5,000 Points = Free PropSwap Polo
        </TextListItemView>
      </Section>
    </Screen>
  );
};
