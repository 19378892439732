import React, { useMemo, useContext, useCallback } from "react";
import { List, Divider, useTheme, TouchableRipple } from "react-native-paper";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { useMutation, useQuery } from "@apollo/client";

import Screen from "Components/Screen";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { HeaderButtons, Item } from "react-navigation-header-buttons";
import { Alert, Platform } from "react-native";
import Interchecks from "shared/api/Interchecks";

export default ({ navigation, route }) => {
  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);
  const amount = route?.params?.amount;
  const { data: { listInterchecksAchAccounts: accountsString } = {} } =
    useQuery(Interchecks.queries.listAchAccounts, {
      skip: !currentUser?.interchecksRecipientId,
      pollInterval: 6000,
      variables: {
        recipientId: currentUser?.interchecksRecipientId,
      },
    });

  const accounts = useMemo(
    () => (!!accountsString ? JSON.parse(accountsString) : null),
    [accountsString]
  );

  const navigateTo = useCallback(
    (screen, params) => () => [navigation.push(screen, params)],
    [navigation]
  );

  const navigateBackTo = useCallback(
    (screen, params) => () => [navigation.navigate(screen, params)],
    [navigation]
  );

  const [deleteAch] = useMutation(Interchecks.mutations.deleteAchAccount, {
    refetchQueries: [
      {
        query: Interchecks.queries.listAchAccounts,
        variables: {
          recipientId: currentUser?.interchecksRecipientId,
        },
      },
    ],
  });

  const doDelete = useCallback(
    id =>
      deleteAch({
        variables: {
          input: {
            accountId: id,
          },
        },
      }).catch(console.error),
    [deleteAch]
  );

  const handleDelete = useCallback(
    id => () =>
      Platform.OS === "web"
        ? confirm("Remove this account?") && doDelete(id)
        : Alert.alert("Remove this account?", "This will remove the account.", [
            {
              text: "Cancel",
              onPress: () => null,
              style: "cancel",
            },
            {
              text: "Delete",
              onPress: doDelete(id),
              style: "destructive",
            },
          ]),
    [doDelete]
  );

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons size={48}>
          <Item
            title="Add Account"
            IconComponent={MaterialCommunityIcons}
            iconSize={24}
            iconName="plus"
            onPress={navigateTo("WithdrawAchInfo")}
          />
        </HeaderButtons>
      ),
    });
  }, [navigateTo, navigation]);

  return (
    <Screen
      gatedTo="member"
      title="Select Account"
      fullHeight
      backgroundColor={theme.colors.surface}
    >
      {(accounts?.accounts ?? []).map((account, i) => (
        <React.Fragment key={i}>
          <List.Item
            title={account.account_type.toLowerCase()}
            titleStyle={{ textTransform: "capitalize" }}
            description={`Ending in ${account.account_number.slice(-4)}`}
            right={props => (
              <TouchableRipple onPress={handleDelete(account.id)}>
                <List.Icon {...props} icon="trash-can-outline" />
              </TouchableRipple>
            )}
            onPress={navigateBackTo("DirectDepositSummary", {
              accountNumber: account.account_number,
              routingNumber: account.routing_number,
              accountType: account.account_type,
              amount,
            })}
          />
          <Divider />
        </React.Fragment>
      ))}
    </Screen>
  );
};
