import React from "react";
// import { Card } from 'react-native-paper';
import ResponsiveImage from "Components/ResponsiveImage";
import { View } from "react-native";
import { TouchableRipple } from "react-native-paper";

// export default ({
//   ad,
//   theme,
//   onPress,
//   adCount,
//   styles,
//   imageWidth
// }) => (
//   <Card
//     style={[
//       {marginRight: adCount > 1 ? 0 : -1 * theme.spacing.xl},
//       styles.pointer
//     ]}
//     onPress={onPress(ad)}
//   >
//     <Card.Cover source={{ uri: !!theme.dark ? ad.darkModeImageUrl : ad.lightModeImageUrl }} />
//   </Card>
// )

export default ({ ad, theme, onPress, adCount, styles, imageWidth }) => (
  <TouchableRipple onPress={onPress(ad)}>
    <View
      style={[
        styles.fullWidth,
        styles.pointer,
        { marginRight: adCount > 1 ? 0 : -1 * theme.spacing.xl },
      ]}
    >
      <ResponsiveImage
        source={{
          uri: !!theme.dark ? ad.darkModeImageUrl : ad.lightModeImageUrl,
        }}
        maxWidth={imageWidth - theme.spacing.xl * 2}
        width={imageWidth}
      />
    </View>
  </TouchableRipple>
);
