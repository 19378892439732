import { Platform, ScrollView, View } from "react-native";
import {
  Button,
  Text,
  Checkbox,
  Paragraph,
  TextInput,
  HelperText,
} from "react-native-paper";

import { Controller } from "react-hook-form";

import ScreenBottom from "Components/Screen/Bottom";
import FormValidationRow from "Components/Form/ValidationRow";
import environment from "environment";
import openUrl from "Util/openUrl";

const displayNamePasswordTest = (value, password) =>
  !password || !new RegExp(password.replace(/\$/, "\\$")).test(value);

const displayNameLengthTest = value => /^.{3,12}$/.test(value);

export default ({
  styles,
  theme,
  control,
  inputs,
  errors,
  registrationValues,
  secureTextEntry,
  setSecureTextEntry,
  dirtyFields,
  password,
  doSubmit,
  displayName,
  navigation,
  isValid,
  isSubmitting,
}) => (
  <>
    <ScrollView styles={styles.f1}>
      <View
        style={{
          marginTop: theme.spacing.xl,
          marginHorizontal: theme.spacing.xl,
        }}
      >
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["email"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "email"
                }
                keyboardType="email-address"
                textContentType="emailAddress"
                label="Email"
                error={!!errors.email}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.password.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value.toLowerCase())}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.email}>
                {errors.email?.message}
              </HelperText>
            </>
          )}
          name="email"
          defaultValue={registrationValues.email}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                secureTextEntry={secureTextEntry}
                autoCorrect={false}
                ref={e => (inputs.current["password"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "password"
                }
                textContentType="password"
                label="Password"
                autoCapitalize="none"
                returnKeyLabel="done"
                returnKeyType="done"
                onSubmitEditing={() => inputs.current.displayName.focus()}
                error={!!errors.password}
                right={
                  <TextInput.Icon
                    name={!!secureTextEntry ? "eye-off" : "eye"} // or can be a node where <Icon /> is any component from vector-icons or anything else
                    onPress={() =>
                      setSecureTextEntry(secureTextEntry => !secureTextEntry)
                    }
                  />
                }
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.password}>
                {/* {errors.password?.message} */}
              </HelperText>
            </>
          )}
          name="password"
          defaultValue={registrationValues.password}
        />

        {!!dirtyFields?.password && (
          <View>
            <FormValidationRow
              theme={theme}
              text="Between 8 and 25 characters"
              doesPass={/^.{8,25}$/.test(password)}
            />
            <FormValidationRow
              theme={theme}
              text="At least one number"
              doesPass={/[0-9]/.test(password)}
            />
            <FormValidationRow
              theme={theme}
              text="At least one capital letter"
              doesPass={/[A-Z]/.test(password)}
            />
            <FormValidationRow
              theme={theme}
              text="At least one lowercase letter"
              doesPass={/[a-z]/.test(password)}
            />
            <FormValidationRow
              theme={theme}
              text="At least one special character (i.e. !$%^)"
              doesPass={/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)}
            />
          </View>
        )}

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect={false}
                ref={e => (inputs.current["displayName"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "username"
                }
                textContentType="nickname"
                label="Display Name"
                autoCapitalize="none"
                returnKeyLabel="Next"
                returnKeyType="next"
                error={!!errors.displayName}
                onSubmitEditing={doSubmit}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value.toLowerCase())}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="info" visible={!errors.displayName}>
                Your Display Name is what other users will see.
              </HelperText>
              <HelperText type="error" visible={!!errors.displayName}>
                {errors.displayName?.message}
              </HelperText>
            </>
          )}
          name="displayName"
          defaultValue={registrationValues.displayName}
        />

        {!!dirtyFields?.displayName && (
          <View>
            <FormValidationRow
              theme={theme}
              text="Between 3 and 12 characters"
              doesPass={displayNameLengthTest(displayName)}
            />
            <FormValidationRow
              theme={theme}
              text="Do not use your password"
              doesPass={displayNamePasswordTest(displayName, password)}
            />
            <FormValidationRow
              theme={theme}
              text="Do not use your birthday"
              doesPass
            />
          </View>
        )}

        {/* <Controller
        control={control}
        render={({field: {onChange, onBlur, value}}) => (
          <View style={{alignItems: "center", flexDirection: "row"}}>
            <Checkbox
              color={theme.colors.primary}
              status={!!value ? 'checked' : 'unchecked'}
              onPress={() => onChange(!value)}
            />
            <Text style={{cursor: "pointer"}} onPress={() => onChange(!value)}>I would like to receive your newsletter and other promotional information.</Text>
          </View>
        )}
        name="receivePromotions"
        defaultValue={true}
      /> */}

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
                marginBottom: theme.spacing.xl,
              }}
            >
              <Checkbox.Android
                color={theme.colors.primary}
                status={!!value ? "checked" : "unchecked"}
                onPress={() => onChange(!value)}
              />
              <Text>
                I have read and agree to PropSwap's&nbsp;
                <Text
                  onPress={() =>
                    openUrl(
                      `${environment.base_url}/pages/terms-and-conditions.html`
                    )
                  }
                  style={[styles.textPrimary, styles.pointer]}
                >
                  Terms & Conditions
                </Text>
                &nbsp;and&nbsp;
                <Text
                  onPress={() =>
                    openUrl(`${environment.base_url}/pages/privacy-policy.html`)
                  }
                  style={[styles.textPrimary, styles.pointer]}
                >
                  Privacy Policy
                </Text>
                .
              </Text>
            </View>
          )}
          name="agree"
          defaultValue={!!registrationValues.agree}
        />
      </View>
    </ScrollView>
    <ScreenBottom adjustPadding>
      <View style={{ paddingHorizontal: theme.spacing.xl, width: "100%" }}>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          Continue
        </Button>
      </View>

      <Paragraph>
        Already have an account?{" "}
        <Text
          style={{ color: theme.colors.secondary }}
          onPress={() => navigation.navigate("Login")}
        >
          Log In
        </Text>
      </Paragraph>
      <Paragraph>
        Have a code?{" "}
        <Text
          style={{ color: theme.colors.secondary }}
          onPress={() =>
            navigation.navigate("RegistrationStack", {
              screen: "RegistrationConfirmation",
            })
          }
        >
          Click Here
        </Text>
      </Paragraph>
    </ScreenBottom>
  </>
);
