import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import { useMemo } from "react";
import { Platform, useWindowDimensions } from "react-native";
import { useTheme } from "react-native-paper";
import Listing from "shared/api/Listing";
import OptionEventOdds from "shared/api/OptionEventOdds";
import Ticket from "shared/api/Ticket";

import EventOptionOddsGraphView from "./EventOptionOddsGraphView";

const spacer = arr => (arr.length > 100 ? 6 : arr.length > 20 ? 3 : 1);

const suggestedPriceInPennies = ({
  ticketCollectsAmount,
  currentCalculatedOdds,
}) => ticketCollectsAmount / currentCalculatedOdds;

export default ({ listingId, ticketId, style }) => {
  const { data: { getListing } = {} } = useQuery(Listing.queries.get, {
    skip: !listingId,
    variables: {
      id: listingId,
    },
  });

  const { data: { getTicket } = {} } = useQuery(Ticket.queries.get, {
    skip: !ticketId && !getListing?.listingTicketId,
    variables: {
      id: ticketId ?? getListing?.listingTicketId,
    },
  });

  const optionId =
    getTicket?.cachedBets?.[0]?.optionId ?? getTicket?.ticketOptionId;
  const eventId =
    getTicket?.cachedBets?.[0]?.eventId ?? getTicket?.ticketEventId;

  const {
    data: {
      listOptionEventOddsByEventIdAndOptionId: { items: optionEventOdds } = {},
    } = {},
  } = useQuery(OptionEventOdds.queries.listByEventIdAndOptionId, {
    skip: !eventId || !optionId,
    variables: {
      limit: 365,
      sortDirection: "DESC",
      eventId: eventId ?? "<>",
      optionIdCreatedAt: {
        beginsWith: {
          optionId: optionId ?? "<>",
        },
      },
    },
  });

  const windowWidth = useWindowDimensions().width;
  const width =
    Platform.OS === "web" ? Math.min(601, windowWidth) - 2 : windowWidth;
  const theme = useTheme();

  const sortedOptionEventOdds = useMemo(
    () =>
      (optionEventOdds ?? [])
        .slice(0, 30)
        .filter((item, index, arr) => index % spacer(arr) === 0)
        .reverse()
        .filter(item => !!item),
    [optionEventOdds]
  );

  const labels = useMemo(
    () =>
      sortedOptionEventOdds.map(item =>
        moment(item.createdAt).tz("America/New_York").format("M/D")
      ),
    [sortedOptionEventOdds]
  );

  const ticketCollectsAmount = getTicket?.collectAmount;

  const data = useMemo(
    () =>
      sortedOptionEventOdds.map(item =>
        !!ticketCollectsAmount
          ? Math.round(
              parseFloat(
                suggestedPriceInPennies({
                  ticketCollectsAmount,
                  currentCalculatedOdds: item.oddsCalculated + 1,
                }) / 100
              )
            )
          : item.oddsCalculated
      ),
    [sortedOptionEventOdds, ticketCollectsAmount]
  );

  return !data.length ? null : (
    <EventOptionOddsGraphView
      ticketCollectsAmount={ticketCollectsAmount}
      width={width}
      theme={theme}
      style={style}
      labels={labels}
      data={data}
    />
  );
};
