import gql from "graphql-tag";
import environment from "environment";
import Listing from "./Listing";

const Bid = {};

Bid.fragments = {
  public: gql`
    fragment BidPublic on Bid {
      id
      createdAt
      updatedAt
      offer
      counter
      status
      bidListingId
      bidBidderId
      expiresAt
    }
  `,
};

Bid.mutations = {
  update: gql`
    mutation UpdateBid($input: UpdateBidInput!) {
      updateBid(input: $input) {
        ...BidPublic
      }
    }
    ${Bid.fragments.public}
  `,
  counter: gql`
    mutation CounterBid($input: CounterBidInput!) {
      counterBid(input: $input) {
        ...BidPublic
      }
    }
    ${Bid.fragments.public}
  `,
  updateBidStatus: gql`
    mutation UpdateBidStatus($input: UpdateBidStatusInput!) {
      updateBidStatus(input: $input) {
        ...BidPublic
      }
    }
    ${Bid.fragments.public}
  `,
  createRaw: gql`
    mutation CreateBid($input: CreateBidInput!) {
      createBid(input: $input) {
        ...BidPublic
      }
    }
    ${Bid.fragments.public}
  `,
  create: gql`
    mutation CreateNewBid($input: CreateNewBidInput!) {
      createNewBid(input: $input) {
        ...BidPublic
      }
    }
    ${Bid.fragments.public}
  `,
};

Bid.subscriptions = {
  onCreate: gql`
    subscription OnMyCreateBid {
      onMyCreateBid {
        id
      }
    }
  `,
  onUpdate: gql`
    subscription OnMyCreateBid {
      onMyCreateBid {
        id
      }
    }
  `,
};

Bid.queries = {
  get: gql`
    query GetBid($id: ID!) {
      getBid(id: $id) {
        ...BidPublic
        listing {
          ...ListingPublic
        }
      }
    }
    ${Bid.fragments.public}
    ${Listing.fragments.public}
  `,
  listByListingId: gql`
    query BidsByListingId(
      $bidListingId: ID${environment.v2_bang}
      $sortDirection: ModelSortDirection
      $offer: ModelIntKeyConditionInput
      $filter: ModelBidFilterInput
      $limit: Int
      $nextToken: String
    ) {
      bidsByListingId(
        bidListingId: $bidListingId
        sortDirection: $sortDirection
        offer: $offer
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...BidPublic
          bidder {
            displayName
            id
            profilePicUri
            status
          }
        }
        nextToken
      }
    }
    ${Bid.fragments.public}
  `,
  listByBidderId: gql`
    query BidsByBidderId(
      $bidBidderId: ID${environment.v2_bang}
      $statusUpdatedAt: ModelBidByBidderIdStatusUpdatedAtCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelBidFilterInput
      $limit: Int
      $nextToken: String
      $withListing: Boolean = false
    ) {
      bidsByBidderId(
        bidBidderId: $bidBidderId
        statusUpdatedAt: $statusUpdatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...BidPublic
          listing @include(if: $withListing) {
            ...ListingPublic
          }
        }
        nextToken
      }
    }
    ${Bid.fragments.public}
    ${Listing.fragments.public}
  `,
  listByListingIdAndBidderIdAndUpdatedAt: gql`
    query ListBidsByListingIdAndBidderIdAndUpdatedAt(
      $bidListingId: ID${environment.v2_bang}
      $bidBidderIdUpdatedAt: ModelBidByListingIdAndBidderIdAndUpdatedAtCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelBidFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listBidsByListingIdAndBidderIdAndUpdatedAt(
        bidListingId: $bidListingId
        bidBidderIdUpdatedAt: $bidBidderIdUpdatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...BidPublic
        }
        nextToken
      }
    }
    ${Bid.fragments.public}
  `,
};

export default Bid;
