import useStyles from "Hooks/useStyles";
import BottomSheetHeaderStyles from "./BottomSheetHeaderStyles";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { View } from "react-native";
import { Subheading } from "react-native-paper";
import Spacer from "Components/Spacer";

export default ({ onDismiss, title }) => {
  const { theme, styles } = useStyles({ Styles: BottomSheetHeaderStyles });
  return (
    <View style={styles.wrapper}>
      <Spacer />
      <View style={styles.f1}>
        <Subheading
          style={[styles.textCenter, styles.textUpper, styles.textReverse]}
        >
          {title}
        </Subheading>
      </View>
      <View style={{ width: 20 }}>
        <MaterialCommunityIcons
          name="close"
          size={18}
          color={theme.colors.textReverse}
          onPress={onDismiss}
        />
      </View>
    </View>
  );
};
