import { useContext, useCallback, useState, useEffect, useRef } from "react";
import { Button, IconButton, TouchableRipple } from "react-native-paper";

import { Camera } from "expo-camera";
import { View, Platform, useWindowDimensions } from "react-native";
import { TabBarContext } from "Contexts/TabBar";
import { useIsFocused, useNavigation } from "@react-navigation/core";

export default ({ onImageChange, theme, styles }) => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const tabBar = useContext(TabBarContext);
  const camera = useRef(null);
  const [hasPermission, setHasPermission] = useState(null);
  const [type] = useState(
    Platform.OS === "web"
      ? Camera.Constants.Type.back
      : Camera.Constants.Type.back
  );
  const [wantsCamera, setWantsCamera] = useState(false);
  const { height, width: windowWidth } = useWindowDimensions();
  const width =
    Platform.OS === "web" ? Math.min(601, windowWidth) - 2 : windowWidth;
  const [cameraDimensions, setCameraDimensions] = useState([width, height]);
  const hasCamera = !!camera?.current;
  const [cameraWidth, cameraHeight] = cameraDimensions;

  const takePhoto = useCallback(
    () => camera.current.takePictureAsync().then(onImageChange),
    [camera, onImageChange]
  );

  useEffect(() => {
    !!hasCamera &&
      camera.current
        .getAvailablePictureSizesAsync()
        .then(sizes =>
          sizes
            .map(size => size.split("x").map(s => parseInt(s)))
            .find(([_width, _height]) => _width <= width && _height <= height)
        )
        .then(setCameraDimensions)
        .catch(console.error);
  }, [hasCamera, camera, height, width]);

  useEffect(() => {
    Promise.all([
      Platform.OS !== "web" || Camera.isAvailableAsync(),
      Camera.requestCameraPermissionsAsync(),
    ]).then(([available, { status }]) =>
      setHasPermission(!!available && status === "granted")
    );
  }, []);

  useEffect(() => {
    tabBar.setVisible(!wantsCamera);
    navigation.setOptions({ headerShown: !wantsCamera });

    return () => [
      tabBar.setVisible(true),
      navigation.setOptions({ headerShown: true }),
    ];
  }, [navigation, tabBar, wantsCamera]);

  return !hasPermission ? null : !wantsCamera ? (
    <Button
      style={styles.button}
      mode="outlined"
      onPress={() => setWantsCamera(true)}
    >
      Take Photo
    </Button>
  ) : !isFocused ? null : (
    <View
      style={[
        styles.camera,
        // StyleSheet.absoluteFillObject,
        { width: width + 12, height },
      ]}
    >
      <View
        style={[
          {
            width: cameraWidth,
            height: cameraHeight,
            position: "absolute",
            left: (width - cameraWidth) / 2,
            top: (height - cameraHeight) / 2,
          },
        ]}
      >
        <TouchableRipple onPress={takePhoto}>
          <Camera ref={camera} type={type}>
            <View style={styles.cameraIconButtonWrapper}>
              <IconButton
                icon="camera"
                size={80}
                onPress={takePhoto}
                color={theme.colors.primary}
              />
            </View>
          </Camera>
        </TouchableRipple>
      </View>
    </View>
  );
};
