import { useCallback, useEffect, useState } from "react";
import { Button, Text, Title } from "react-native-paper";
import Screen from "Components/Screen";
import { uriToBlob } from "Contexts/Ticket";
import useStyles from "Hooks/useStyles";
import PhotoUploadStyles from "Components/PhotoUpload/PhotoUploadStyles";
import Picker from "Components/PhotoUpload/Picker";
import ENV from "../environment";
import { Storage } from "aws-amplify";
import ResponsiveImage from "Components/ResponsiveImage";
import { Platform, useWindowDimensions, View } from "react-native";
import { imageUrlsVar } from "./shared";

const uploadPhoto = uri =>
  uriToBlob(uri)
    .then(blobData => [
      blobData,
      uri.match(/\.gif$/) ? "gif" : uri.match(/\.gif$/) ? "png" : "jpeg",
    ])
    .then(([blobData, fileType]) =>
      Storage.put(`${new Date().getTime().toString()}.${fileType}`, blobData, {
        contentType: `image/${fileType}`,
      })
        .then(({ key }) => Storage.get(key))
        .then(
          url =>
            url
              .replace(/^.+\.s3.*\.amazonaws\.com/, `https://${ENV?.cdn}`)
              .split("?")[0]
        )
    );

const MobileImagePicker = ({ onUpload, number }) => {
  const { styles, theme } = useStyles({ Styles: PhotoUploadStyles });
  const [isUploading, setIsUploading] = useState(false);
  const [url, setUrl] = useState(null);
  const { height, width } = useWindowDimensions();
  const maxWidth = Math.min(width, Platform.OS === "web" ? 601 : 50000);

  const handleUpload = useCallback(
    ({ assets }) => {
      setIsUploading(true);
      return uploadPhoto(assets[0].uri)
        .then(url => {
          setUrl(url);
          onUpload(url);
        })
        .catch(() => {
          setUrl(null);
          onUpload(null);
        })
        .finally(() => setIsUploading(false));
    },
    [onUpload]
  );

  return (
    <View
      style={{
        marginTop: 20,
        marginRight: 20,
        marginLeft: 20,
      }}
    >
      <Text>Mobile Ticket #{number}</Text>
      {url ? (
        <>
          <ResponsiveImage
            source={{ uri: url }}
            height={height - 350}
            maxWidth={
              maxWidth - theme.spacing.xl * 2 - 2 - theme.spacing.sm * 2
            }
          />
          <Button
            mode="outlined"
            style={{ marginTop: 20 }}
            onPress={() => {
              setUrl(null);
              onUpload(null);
            }}
          >
            Remove
          </Button>
        </>
      ) : (
        <Picker
          loading={isUploading}
          loadingText="Uploading..."
          theme={theme}
          styles={styles}
          onImageChange={handleUpload}
        />
      )}
    </View>
  );
};

const TicketNewMobileConfirmStep1 = ({ navigation }) => {
  const { styles, theme } = useStyles({ Styles: PhotoUploadStyles });
  const [urls, setUrls] = useState([]);

  const handleUpload = useCallback(
    (index, url) =>
      setUrls(urls =>
        [url, ...urls.filter((_, i) => i !== index)].filter(Boolean)
      ),
    []
  );

  const urlsString = JSON.stringify(urls);

  useEffect(() => {
    imageUrlsVar(JSON.parse(urlsString));
  }, [urlsString]);

  return (
    <Screen
      gatedTo="member"
      title="Mobile Ticket Approval"
      hasKeyboard={false}
      backgroundColor={theme.colors.surface}
      fullWidth
      fullHeight
      hasScroll
    >
      {/* <ScrollView
        contentContainerStyle={{
          alignContent: "flex-start",
          alignItems: "flex-start",
        }}
      > */}
      <View style={{ marginRight: 20, marginLeft: 20 }}>
        <Title
          style={[styles.textPrimary, styles.textCenter, { marginBottom: 20 }]}
        >
          Step 1 of 2: Upload Screenshot(s)
        </Title>
        <Text style={[styles.textCenter]}>
          In order to sell Mobile tickets on PropSwap, your account must be
          approved to do so. Please upload the screenshot(s) of the Mobile
          ticket(s) you would like to list for sale. Maximum 5 screenshots.
        </Text>
      </View>
      <View
        style={{
          width: "100%",
        }}
      >
        <View style={{ width: "100%", flex: 1 }}>
          {[...Array(5)].map((_, index) => (
            <MobileImagePicker
              key={index}
              onUpload={url => handleUpload(index, url)}
              number={index + 1}
            />
          ))}
        </View>
      </View>

      {urls.length > 0 && (
        <View style={{ width: "100%", marginTop: 20, flex: 1 }}>
          <Button
            style={{ marginLeft: 20, marginRight: 20 }}
            disabled={urls.length < 1}
            mode="contained"
            onPress={() => navigation.push("TicketNewMobileConfirmStep2")}
          >
            Continue
          </Button>
        </View>
      )}
      {/* </ScrollView> */}
    </Screen>
  );
};

export default TicketNewMobileConfirmStep1;
