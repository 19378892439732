export default theme => ({
  wrapper: {
    backgroundColor: theme.colors.surface,
    padding: theme.spacing.sm,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.border,
    borderStyle: "solid",
  },
  searchbar: {
    // marginHorizontal: theme.spacing.xl,
    backgroundColor: theme.colors.background,
    shadowColor: "transparent",
    borderRadius: theme.roundness * 2,
  },
  input: {
    padding: 0,
  },
});
