import { userStatusLabels } from "shared/Config";
import React from "react";
import { View, TouchableWithoutFeedback, Platform } from "react-native";
import { Text } from "react-native-paper";
import UserAvatar from "../Avatar";

export default ({
  user,
  Extra,
  onPress,
  theme,
  textColor,
  showState,
  styles,
  skipIcons,
}) => (
  <TouchableWithoutFeedback onPress={onPress}>
    <View style={[styles.wrapper, !!onPress ? styles.pointer : {}]}>
      <View style={styles.avatarWrapper}>
        <UserAvatar theme={theme} user={user} />
      </View>
      <View style={styles.infoWrapper}>
        {!!Extra && <Extra />}
        <Text style={{ color: textColor }}>{user?.displayName}</Text>
        {!showState && (
          <Text style={{ color: textColor }}>
            {userStatusLabels[(user?.status ?? 1) - 1].label.replace(
              /Hall of Fame/,
              "HOF"
            )}{" "}
            ({user?.buyOrSellTransactionCount ?? 0})
          </Text>
        )}
        {!!showState && <Text style={{ color: textColor }}>{user?.state}</Text>}
      </View>
    </View>
  </TouchableWithoutFeedback>
);
