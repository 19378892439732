import { Platform } from "react-native";

export default theme => ({
  wrapper: {
    alignItems: "center",
    flexDirection: "row",
  },
  avatarWrapper: {
    marginRight: theme.spacing.xs,
  },
  infoWrapper: {
    marginLeft: theme.spacing.xs,
  },
});
