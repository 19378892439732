import React, { useCallback, useContext, useState } from "react";
import {
  Button,
  Divider,
  List,
  Subheading,
  Text,
  useTheme,
} from "react-native-paper";
import Screen from "Components/Screen";
import { CurrentUserContext } from "Contexts/CurrentUser";
import BottomSheet from "Components/BottomSheet";
import { View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Spacer from "Components/Spacer";
import FilterScreenStyles from "Components/FilterScreen/FilterScreenStyles";
import useStyles from "Hooks/useStyles";

export default ({ navigation }) => {
  const [showBottomScreen, setShowBottomScreen] = useState(false);
  const navigateTo = useCallback(
    screen => () => navigation.push(screen),
    [navigation]
  );

  const handleCanadianPress = useCallback(() => setShowBottomScreen(true), []);
  const handleDismiss = useCallback(() => setShowBottomScreen(false), []);

  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);
  const userIsCanadian = currentUser?.attributes?.["custom:country"] === "CA";
  const { styles } = useStyles({ Styles: FilterScreenStyles });

  return (
    <Screen
      gatedTo="member"
      title="My Wallet"
      fullHeight
      fullWidth
      hasScroll
      backgroundColor={theme.colors.surface}
    >
      {[
        ["Direct Deposit", "DirectDepositWithdrawStack"],
        ["Paper Check", "PaperWithdrawStack"],
      ].map(([label, screen]) => (
        <React.Fragment key={label}>
          <List.Item
            title={label}
            right={props => <List.Icon {...props} icon="chevron-right" />}
            onPress={
              userIsCanadian && label === "Direct Deposit"
                ? handleCanadianPress
                : navigateTo(screen)
            }
          />
          <Divider />
        </React.Fragment>
      ))}
      <BottomSheet
        visible={!!showBottomScreen}
        onDismiss={handleDismiss}
        fullScreen
        fullWidth
        height="50%"
      >
        <View style={styles.wrapper}>
          <Spacer />
          <View style={styles.f1}>
            <Subheading
              style={[styles.textCenter, styles.textUpper, styles.textReverse]}
            >
              Direct Deposit Withdrawal
            </Subheading>
          </View>
          <View style={{ width: 20 }}>
            <MaterialCommunityIcons
              name="close"
              size={18}
              color={theme.colors.textReverse}
              onPress={handleDismiss}
            />
          </View>
        </View>
        <View
          style={{
            backgroundColor: theme.colors.background,
            padding: 20,
            marginBottom: -20,
            flex: 1,
          }}
        >
          <Text style={{ textAlign: "center" }}>
            Direct Deposit Withdrawals are not supported for Canadian customers
            at this time. Please select "Paper Check" to move forward with your
            withdrawal request. Thank you!
          </Text>
          <Button
            mode="contained"
            onPress={handleDismiss}
            style={{ width: "100%", marginTop: 20 }}
          >
            Close
          </Button>
        </View>
      </BottomSheet>
    </Screen>
  );
};
