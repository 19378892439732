import { useTheme } from "react-native-paper";
import { useCallback } from "react";
import CircleIndicatorItemView from "./CircleIndicatorItemView";

const CircleIndicatorItemContainer = ({ selected, index, onPress }) => {
  const theme = useTheme();

  const handlePress = useCallback(() => onPress(index), [index, onPress]);

  return (
    <CircleIndicatorItemView
      theme={theme}
      selected={selected}
      onPress={handlePress}
    />
  );
};

export default CircleIndicatorItemContainer;
