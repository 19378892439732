import { Platform, ScrollView, View } from "react-native";

export default ({
  theme,
  children,
  Wrapper,
  fullWidth,
  fullHeight,
  backgroundColor,
  hasScroll,
  screenHeight,
  headerHeight,
  styles,
  keyboardHeight,
}) => (
  <Wrapper>
    {!!hasScroll && (
      <ScrollView
        contentContainerStyle={{
          paddingBottom: headerHeight,
          paddingHorizontal: !!fullWidth ? 0 : theme.spacing.xl,
          paddingTop: !!fullHeight ? 0 : theme.spacing.xl * 2,
          backgroundColor: backgroundColor ?? "transparent",
          ...Platform.select({
            web: {
              flex: 1,
            },
            default: {},
          }),
        }}
        style={{
          height: screenHeight,
          // flex: 1,
          backgroundColor: backgroundColor ?? "transparent",
          paddingBottom: keyboardHeight,
        }}
      >
        {Array.isArray(children) ? [...children] : children}
      </ScrollView>
    )}
    {!hasScroll && (
      <View
        style={{
          paddingHorizontal: !!fullWidth ? 0 : theme.spacing.xl,
          paddingTop: !!fullHeight ? 0 : theme.spacing.xl * 2,
          flex: 1,
          backgroundColor: backgroundColor ?? "transparent",
          // borderColor: "green",
          // borderStyle: "dashed",
          // borderWidth: 2,
          ...(Platform.OS === "android"
            ? {}
            : { paddingBottom: keyboardHeight }),
        }}
      >
        {Array.isArray(children) ? [...children] : children}
      </View>
    )}
  </Wrapper>
);
