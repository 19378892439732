import * as React from "react";
import { useCallback, useContext, useState, useEffect } from "react";
import gql from "graphql-tag";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { Keyboard, Platform, ScrollView, View } from "react-native";
import BottomSheet from "Components/BottomSheet";

import {
  Button,
  Checkbox,
  HelperText,
  TextInput,
  Title,
  useTheme,
  Text,
} from "react-native-paper";
import Screen from "Components/Screen";
import { CurrentUserContext } from "Contexts/CurrentUser";
import ScreenBottom from "Components/Screen/Bottom";
import Confirmation from "Components/Confirmation";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid email address")
    .required("Can't be blank"),
  subject: yup.string().required("Can't be blank"),
  sellUrl: yup.string().url(),
  buyUrl: yup.string().url(),
  winUrl: yup.string().url(),
  creditUrl: yup.string().url(),
  oauthUrl: yup.string().url(),
  // message: yup.string()
  //   .required("Can't be blank")
  // ,
});

export default ({ navigation }) => {
  const theme = useTheme();

  const currentUser = useContext(CurrentUserContext);
  const inputs = React.useRef({});
  const [submitted, setSubmitted] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [sendEmail] = useMutation(
    gql`
      mutation SendEmail($input: SendEmailInput!) {
        sendEmail(input: $input)
      }
    `,
    {
      variables: {
        input: {},
      },
    }
  );

  const handleSubmitError = useCallback(error => console.error(error), []);

  const onSubmit = useCallback(
    ({
      email,
      subject,
      allowOnPropSwap,
      allowOnOtherProviders,
      allowAcrossLocales,
      sellUrl,
      buyUrl,
      winUrl,
      creditUrl,
      oauthUrl,
    }) =>
      sendEmail({
        variables: {
          input: {
            email,
            subject: `SDK Request for: ${subject}`,
            message: `
          <p>allowOnPropSwap: ${!!allowOnPropSwap ? "Yes" : "No"}</p>

          <p>allowOnOtherProviders: ${
            !!allowOnOtherProviders ? "Yes" : "No"
          }</p>

          <p>allowAcrossLocales: ${!!allowAcrossLocales ? "Yes" : "No"}</p>

          <p>sellUrl: ${sellUrl}</p>

          <p>buyUrl: ${buyUrl}</p>

          <p>winUrl: ${winUrl}</p>

          <p>creditUrl: ${creditUrl}</p>

          <p>oauthUrl: ${oauthUrl}</p>
          `,
          },
        },
      })
        .then(() => setSubmitted(true))
        .catch(handleSubmitError),
    [sendEmail, handleSubmitError]
  );

  const doSubmit = handleSubmit(onSubmit);
  const onNext = field => () => inputs.current[field].focus();

  const handleDismiss = useCallback(
    () => !!submitted && navigation.goBack(),
    [submitted, navigation]
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener("blur", () =>
      setSubmitted(false)
    );

    return unsubscribe;
  }, [navigation]);

  return (
    <Screen
      fullHeight
      fullWidth
      title="SDK Access Request"
      hasKeyboard
      backgroundColor={theme.colors.surface}
      gatedTo="member"
    >
      <ScrollView
        keyboardShouldPersistTaps="handled"
        style={{ flex: 1 }}
        contentContainerStyle={{ marginHorizontal: theme.spacing.xl }}
      >
        <Title style={{ textAlign: "center", color: theme.colors.primary }}>
          Request SDK Access
        </Title>
        {/* <Subheading style={{textAlign: "center"}}>Have questions or feedback? We'd love to hear from you!</Subheading> */}
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect={false}
                disabled
                autoCapitalize="none"
                ref={e => (inputs.current["email"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "email"
                }
                keyboardType="email-address"
                textContentType="emailAddress"
                label="Email for account"
                error={!!errors.email}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={onNext("subject")}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value.toLowerCase())}
                value={currentUser?.email}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="info" visible={!errors.email}>
                Please enter the email address you will use to sign into the SDK
              </HelperText>
              <HelperText type="error" visible={!!errors.email}>
                {errors.email?.message}
              </HelperText>
            </>
          )}
          name="email"
          defaultValue={currentUser?.email ?? ""}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize="sentences"
                ref={e => (inputs.current["subject"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "name"
                }
                keyboardType="default"
                // textContentType="familyName"
                label="Organization"
                error={!!errors.subject}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={onNext("sellUrl")}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.subject}>
                {errors.subject?.message}
              </HelperText>
            </>
          )}
          name="subject"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["sellUrl"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="default"
                label="Sell Webhook URL"
                error={!!errors.sellUrl}
                returnKeyLabel="Next"
                returnKeyType="next"
                // onSubmitEditing={Keyboard.dismiss}
                onSubmitEditing={onNext("buyUrl")}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="info" visible={!errors.sellUrl}>
                The exchange will send a JSON POST request to this url whenever
                a user buys one of your users' Tickets
              </HelperText>
              <HelperText type="error" visible={!!errors.sellUrl}>
                {errors.sellUrl?.message}
              </HelperText>
            </>
          )}
          name="sellUrl"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["buyUrl"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="default"
                label="Buy Webhook URL"
                error={!!errors.buyUrl}
                returnKeyLabel="Next"
                returnKeyType="next"
                // onSubmitEditing={Keyboard.dismiss}
                onSubmitEditing={onNext("winUrl")}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="info" visible={!errors.buyUrl}>
                The exchange will send a JSON POST request to this url whenever
                one of its users buys a Ticket
              </HelperText>
              <HelperText type="error" visible={!!errors.buyUrl}>
                {errors.buyUrl?.message}
              </HelperText>
            </>
          )}
          name="buyUrl"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["winUrl"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="default"
                label="Win Webhook URL"
                error={!!errors.winUrl}
                returnKeyLabel="Next"
                returnKeyType="next"
                // onSubmitEditing={Keyboard.dismiss}
                onSubmitEditing={onNext("creditUrl")}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="info" visible={!errors.winUrl}>
                The exchange will send a JSON POST request to this url whenever
                a ticket listed by one of your users wins
              </HelperText>
              <HelperText type="error" visible={!!errors.winUrl}>
                {errors.winUrl?.message}
              </HelperText>
            </>
          )}
          name="winUrl"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["creditUrl"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="default"
                label="Credit Webhook URL"
                error={!!errors.creditUrl}
                returnKeyLabel="Next"
                returnKeyType="next"
                // onSubmitEditing={Keyboard.dismiss}
                onSubmitEditing={onNext("oauthUrl")}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="info" visible={!errors.creditUrl}>
                The exchange will send a JSON POST request to this url whenever
                a ticket bought by one of your users wins
              </HelperText>
              <HelperText type="error" visible={!!errors.creditUrl}>
                {errors.creditUrl?.message}
              </HelperText>
            </>
          )}
          name="creditUrl"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["oauthUrl"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="default"
                label="oAuth Token URL"
                error={!!errors.oauthUrl}
                returnKeyLabel="Done"
                returnKeyType="done"
                onSubmitEditing={Keyboard.dismiss}
                // onSubmitEditing={onNext("oauthUrl")}

                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="info" visible={!errors.oauthUrl}>
                This is the url you want the app to redirect to with your code
                that you use to obtain an oAuth access and refresh token
              </HelperText>
              <HelperText type="error" visible={!!errors.oauthUrl}>
                {errors.oauthUrl?.message}
              </HelperText>
            </>
          )}
          name="oauthUrl"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Checkbox.Android
                color={theme.colors.primary}
                status={!!value ? "checked" : "unchecked"}
                onPress={() => onChange(!value)}
              />
              <Text
                style={
                  ({
                    ...Platform.select({
                      web: {
                        cursor: "pointer",
                      },
                      default: {},
                    }),
                  },
                  {
                    flex: 1,
                    flexWrap: "wrap",
                  })
                }
                onPress={() => onChange(!value)}
              >
                Allow tickets posted on the exchange to appear on PropSwap?
              </Text>
            </View>
          )}
          name="allowOnPropSwap"
          defaultValue={false}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Checkbox.Android
                color={theme.colors.primary}
                status={!!value ? "checked" : "unchecked"}
                onPress={() => onChange(!value)}
              />
              <Text
                style={
                  ({
                    ...Platform.select({
                      web: {
                        cursor: "pointer",
                      },
                      default: {},
                    }),
                  },
                  {
                    flex: 1,
                    flexWrap: "wrap",
                  })
                }
                onPress={() => onChange(!value)}
              >
                Allow tickets posted on the exchange to be available to users
                from other providers?
              </Text>
            </View>
          )}
          name="allowOnOtherProviders"
          defaultValue={false}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Checkbox.Android
                color={theme.colors.primary}
                status={!!value ? "checked" : "unchecked"}
                onPress={() => onChange(!value)}
              />
              <Text
                style={
                  ({
                    ...Platform.select({
                      web: {
                        cursor: "pointer",
                      },
                      default: {},
                    }),
                  },
                  {
                    flex: 1,
                    flexWrap: "wrap",
                  })
                }
                onPress={() => onChange(!value)}
              >
                Allow tickets posted on the exchange to be to users from other
                states?
              </Text>
            </View>
          )}
          name="allowAcrossLocales"
          defaultValue={false}
        />
      </ScrollView>

      <ScreenBottom adjustPadding inset>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          {!!isSubmitting ? "Please Wait..." : "Submit"}
        </Button>
      </ScreenBottom>
      <BottomSheet visible={!!submitted} onDismiss={handleDismiss} fullScreen>
        {!!submitted && (
          <Confirmation
            title="Message Received!"
            subtitle="A member of our support staff will respond with insturctions on how to continue as soon as possible."
            type="success"
            primaryButtonLabel="Close"
            onPrimaryButtonPress={handleDismiss}
          />
        )}
      </BottomSheet>
    </Screen>
  );
};
