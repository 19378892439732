export default theme => ({
  wrapper: {
    // marginTop: theme.spacing.lg,
    paddingBottom: theme.spacing.lg,
    borderColor: theme.colors.border,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderStyle: "solid",
    paddingHorizontal: theme.spacing.md,
  },
  headline: {
    marginLeft: theme.spacing.xl,
  },
});
