import { useEffect } from "react";
import { makeVar, useReactiveVar } from "@apollo/client";
import useGidxGeoInfo from "./useGidxGeoInfo";
import useGidxPrepareSession from "./useGidxPrepareSession";

export const gidxSessionVar = makeVar(null);
export const gidxRefresSessionVar = makeVar(true);

const useGidxSession = from => {
  const gidxSession = useReactiveVar(gidxSessionVar);
  const gidxRefreshSession = useReactiveVar(gidxRefresSessionVar);
  const gidxInfo = useGidxGeoInfo();
  const ip = gidxInfo?.ip;
  const latitude = gidxInfo?.latitude;
  const longitude = gidxInfo?.longitude;
  const shouldCreateSession =
    !!ip && !!latitude && !!longitude && (!gidxSession || gidxRefreshSession);

  const [createSession, { loading }] = useGidxPrepareSession({
    ip,
    latitude,
    longitude,
  });

  useEffect(() => {
    if (shouldCreateSession && !loading) {
      createSession()
        .then(({ data }) => {
          gidxRefresSessionVar(false);
          gidxSessionVar(data?.gidxCreateSession);
        })
        .then(() => console.log("Created a new session", from))
        .catch(console.error);
    }
  }, [
    createSession,
    from,
    loading,
    shouldCreateSession,
    ip,
    latitude,
    longitude,
  ]);

  return gidxSession;
};

export default useGidxSession;
