import BidHighBidLabelView from "./BidHighBidLabelView";
import useHighBidForListing from "Hooks/useHighBidForListing";
import useStyles from "Hooks/useStyles";
import BidHighLabelStyles from "./BidHighLabelStyles";

export default ({ listing, showUser, onPress }) => {
  const { styles, theme } = useStyles({ Styles: BidHighLabelStyles });
  const bid = useHighBidForListing({ listing });

  return !bid ? null : (
    <BidHighBidLabelView
      bid={bid}
      showUser={showUser}
      theme={theme}
      onPress={onPress}
      styles={styles}
    />
  );
};
