export const tutorialCacheKey = "@seenTutorial";
export const tutorialCacheVersion = "3";

export const withoutKeys = (obj, keys) =>
  Object.keys(obj ?? {}).reduce(
    (object, key) =>
      keys.includes(key)
        ? {
            ...object,
          }
        : {
            ...object,
            [key]: obj[key],
          },
    {}
  );
