export default theme => ({
  contentWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  avatarWrapper: {
    justifyContent: "flex-start",
    marginRight: theme.spacing.md,
  },
});
