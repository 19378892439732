import { useEffect } from "react";
import { Button, Title, useTheme } from "react-native-paper";

import { View } from "react-native";
import DismissKeyboard from "Components/DismissKeyboard";
import { FontAwesome5 } from "@expo/vector-icons";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation }) => {
  const theme = useTheme();

  useEffect(() => {
    navigation.setOptions({ title: "Password Reset" });
  }, [navigation]);

  return (
    <DismissKeyboard>
      <View
        style={{
          marginHorizontal: theme.spacing.xl,
          marginTop: theme.spacing.xl * 2,
          flex: 1,
        }}
      >
        <Title style={{ textAlign: "center", color: theme.colors.primary }}>
          Your New Password Has Been Saved
        </Title>
        <View
          style={{
            width: "100%",
            alignItems: "center",
            marginTop: theme.spacing.xl * 4,
            marginBottom: theme.spacing.xl * 2,
          }}
        >
          <FontAwesome5
            name="check-circle"
            size={172}
            color={theme.colors.primary}
          />
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-end",
            marginBottom: theme.spacing.xl,
          }}
        >
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1, alignItems: "center" }}>
              <Button
                mode="contained"
                onPress={() => navigation.push("AuthStack")}
                style={{ width: "100%" }}
              >
                Log In
              </Button>
            </View>
          </View>
        </View>
      </View>
    </DismissKeyboard>
  );
};
