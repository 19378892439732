import AsyncStorage from "@react-native-async-storage/async-storage";
import TutorialList from "Components/Tutorial/List";
import React, { useState, useEffect, useCallback } from "react";
import { Platform } from "react-native";
import { tutorialCacheKey, tutorialCacheVersion } from "shared/Util";

const TutorialSwipperContext = React.createContext();

const TutorialSwipperProvider = ({ children }) => {
  const [showTutorialswipper, setShowTutorialSwipper] = useState(null);
  const endTutorial = useCallback(
    () =>
      AsyncStorage.setItem(tutorialCacheKey, tutorialCacheVersion).then(() =>
        setShowTutorialSwipper(false)
      ),
    []
  );
  const restartTutorial = useCallback(
    () =>
      AsyncStorage.removeItem(tutorialCacheKey).then(() =>
        setShowTutorialSwipper(true)
      ),
    []
  );

  useEffect(() => {
    if (Platform.OS === "web") {
      setShowTutorialSwipper(false);
    } else {
      AsyncStorage.getItem(tutorialCacheKey)
        .then(value => {
          console.log("value", value, tutorialCacheVersion);
          setShowTutorialSwipper(!value || value < tutorialCacheVersion);
        })
        .catch(() => setShowTutorialSwipper(true));
    }
  }, []);

  return (
    <TutorialSwipperContext.Provider value={{ endTutorial, restartTutorial }}>
      {showTutorialswipper === null ? null : !!showTutorialswipper ? (
        <TutorialList />
      ) : (
        children
      )}
    </TutorialSwipperContext.Provider>
  );
};

export { TutorialSwipperContext, TutorialSwipperProvider };
