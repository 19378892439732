import { useState, useEffect } from "react";
import * as Location from "expo-location";
import { ALLOWED_STATES, STATES } from "shared/Config";

export default () => {
  const [location, setLocation] = useState(null);
  const [response, requestPermission] = Location.useForegroundPermissions();
  const status = response?.status;

  useEffect(() => {
    status === "undetermined" && requestPermission();
    status === "granted" &&
      Location.getLastKnownPositionAsync()
        .then(result =>
          !!result?.coords ? Location.reverseGeocodeAsync(result.coords) : [{}]
        )
        .then(
          ([{ region, city, isoCountryCode }]) =>
            !!region &&
            !!city &&
            setLocation({
              region,
              city,
              isAllowedToDeposit:
                isoCountryCode?.toLowerCase?.() === "CAN" ||
                !!ALLOWED_STATES.find(
                  as =>
                    as === (STATES.find(state => state[1] === region) || [])[0]
                ) ||
                ALLOWED_STATES.includes(region),
            })
        );
  }, [status, requestPermission]);

  return location;
};
