import React from "react";
import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import Transactions from "Screens/Transactions";
import TransactionDetail from "Screens/TransactionDetail";
import ListingModalStack from "./ListingModalStack";
import { useTheme } from "react-native-paper";
import { getPartialModalOptions, getStandardScreenOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  const theme = useTheme();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={getStandardScreenOptions({ screenOptions, navigation })}
    >
      <Stack.Screen name="Transactions" component={Transactions} />
      <Stack.Screen
        name="TransactionDetail"
        component={TransactionDetail}
        options={{
          headerBackTitleVisible: false,
          headerLeft: () => null,
          // presentation: "transparentModal",
          headerStyle: {
            height: 40,
            borderTopLeftRadius: theme.roundness,
            borderTopRightRadius: theme.roundness,
            backgroundColor: theme.colors.secondary,
            borderBottomWidth: 0,
          },
          headerTitleStyle: {
            ...theme.fonts.h4,
            color: theme.colors.textReverse,
            textTransform: "uppercase",
          },
          headerStatusBarHeight: 0,
          headerTitleAlign: "center",
          headerShown: true,
          cardOverlayEnabled: true,
          animationEnabled: true,
          cardStyleInterpolator: CardStyleInterpolators.forModalPresentationIOS,
          // cardShadowEnabled: false,
          cardStyle: {
            borderTopLeftRadius: theme.roundness,
            borderTopRightRadius: theme.roundness,
            marginTop: 280,
          },
        }}
      />
      <Stack.Screen
        name="ListingModalStack"
        component={ListingModalStack}
        options={getPartialModalOptions({ offset: -40 })}
      />
    </Stack.Navigator>
  );
};
