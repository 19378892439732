import React from "react";

import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import AchSummary from "Screens/AchSummary";
import DepositAmount from "Screens/DepositAmount";
import AchAddAccount from "Screens/AchAddAccount";
import AchSelect from "Screens/AchSelect";
import Verification from "Screens/Verification";
import MyEmail from "Screens/MyEmail";
import { getStandardScreenOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={getStandardScreenOptions({ screenOptions, navigation })}
    >
      <Stack.Screen name="AchSummary" component={AchSummary} />
      <Stack.Screen name="DepositAmount" component={DepositAmount} />
      <Stack.Screen name="AchSelect" component={AchSelect} />
      <Stack.Screen name="AchAddAccount" component={AchAddAccount} />
      <Stack.Screen name="Verification" component={Verification} />
      <Stack.Screen name="MyEmail" component={MyEmail} />
    </Stack.Navigator>
  );
};
