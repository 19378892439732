import { useContext, useCallback, useState, useEffect } from "react";
import { List, TextInput, useTheme, Divider, Text } from "react-native-paper";

import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import {
  formatValue,
  addCommas,
  convertMoneyTextToPennies,
  formatPennies,
} from "shared/Util/money";
import { Platform, View } from "react-native";
import useMarketRatePrices from "Hooks/useMarketRatePrices";
import SegmentedControl from "@react-native-segmented-control/segmented-control";
import formatOdds from "Util/formatOdds";
// import { HeaderButtons, Item } from 'react-navigation-header-buttons';
import useHeaderFormSaveButton from "Hooks/useHeaderFormSaveButton";
import { calculateValueRating } from "Util/";
import ListingValueMeter from "Components/Listing/ValueMeter";

const field = "askingPrice";
const options = ["Select", "Enter"];

export default ({ navigation }) => {
  const theme = useTheme();
  const { setTicketValues, ticketValues } = useContext(TicketContext);
  const [value, setValue] = useState(ticketValues[field]);
  const [mode, setMode] = useState("Enter");

  const onChange = useCallback(text => setValue(text), []);

  const onSubmit = useCallback(
    ({ askingPrice } = {}) => [
      setTicketValues(ticketValues => ({
        ...ticketValues,
        [field]: askingPrice ?? value,
      })),
      navigation.navigate("TicketNewStep4"),
    ],
    [value, navigation, setTicketValues]
  );

  const marketRatePrices = useMarketRatePrices({
    eventId:
      ticketValues.ticketEventId ?? ticketValues.cachedBets?.[0]?.eventId,
    optionId:
      ticketValues.ticketOptionId ?? ticketValues.cachedBets?.[0]?.optionId,
    collectAmountInPennies:
      convertMoneyTextToPennies(ticketValues.betAmount) +
      convertMoneyTextToPennies(ticketValues.winAmount),
  });

  const hasPrices = !!marketRatePrices;

  const valueRating =
    !!marketRatePrices &&
    calculateValueRating({
      marketValuePrice: marketRatePrices[0],
      askingPrice: convertMoneyTextToPennies(value),
    });

  useEffect(() => {
    setMode(!!hasPrices ? "Select" : "Enter");
  }, [hasPrices]);

  useHeaderFormSaveButton({ onSubmit, isValid: !!value && mode === "Enter" });

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={3}
        title={`${mode} Price`}
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />

      {!!marketRatePrices && (
        <View
          style={{
            marginVertical: theme.spacing.lg,
            marginHorizontal: theme.spacing.xl,
          }}
        >
          <SegmentedControl
            values={options}
            selectedIndex={options.indexOf(mode)}
            onChange={event =>
              setMode(options[event.nativeEvent.selectedSegmentIndex])
            }
          />
        </View>
      )}

      {mode === "Select" && (
        <>
          <List.Item
            title={`$${formatPennies(marketRatePrices[3])}`}
            description={`Good (${formatOdds(
              convertMoneyTextToPennies(ticketValues.winAmount) +
                convertMoneyTextToPennies(ticketValues.betAmount),
              marketRatePrices[3]
            )})`}
            descriptionStyle={{
              color: theme.colors.primary,
              fontWeight: "bold",
            }}
            right={props =>
              convertMoneyTextToPennies(ticketValues.askingPrice) ===
              marketRatePrices[3] ? (
                <List.Icon {...props} icon="check-circle-outline" />
              ) : null
            }
            onPress={() =>
              onSubmit({ askingPrice: formatPennies(marketRatePrices[3]) })
            }
          />
          <Divider />
          <List.Item
            title={`$${formatPennies(marketRatePrices[4])}`}
            description={`Great (${formatOdds(
              convertMoneyTextToPennies(ticketValues.winAmount) +
                convertMoneyTextToPennies(ticketValues.betAmount),
              marketRatePrices[4]
            )})`}
            descriptionStyle={{
              color: theme.colors.primary,
              fontWeight: "bold",
            }}
            right={props =>
              convertMoneyTextToPennies(ticketValues.askingPrice) ===
              marketRatePrices[4] ? (
                <List.Icon {...props} icon="check-circle-outline" />
              ) : null
            }
            onPress={() =>
              onSubmit({ askingPrice: formatPennies(marketRatePrices[4]) })
            }
          />
          <Divider />
          <List.Item
            title={`$${formatPennies(marketRatePrices[5])}`}
            description={`Best (${formatOdds(
              convertMoneyTextToPennies(ticketValues.winAmount) +
                convertMoneyTextToPennies(ticketValues.betAmount),
              marketRatePrices[5]
            )})`}
            descriptionStyle={{
              color: theme.colors.primary,
              fontWeight: "bold",
            }}
            right={props =>
              convertMoneyTextToPennies(ticketValues.askingPrice) ===
              marketRatePrices[5] ? (
                <List.Icon {...props} icon="check-circle-outline" />
              ) : null
            }
            onPress={() =>
              onSubmit({ askingPrice: formatPennies(marketRatePrices[5]) })
            }
          />
          <Divider />
        </>
      )}

      {mode === "Enter" && (
        <View style={{ marginHorizontal: theme.spacing.xl }}>
          <TextInput
            mode="outlined"
            label="Price"
            left={<TextInput.Icon name="currency-usd" />}
            right={
              !!value && (
                <TextInput.Icon
                  color={theme.colors.primary}
                  onPress={onSubmit}
                  name="check-circle-outline"
                />
              )
            }
            selectTextOnFocus
            autoCorrect={false}
            autoCapitalize="none"
            autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
            keyboardType="decimal-pad"
            textContentType="none"
            returnKeyLabel="Done"
            returnKeyType="done"
            onSubmitEditing={onSubmit}
            onChangeText={value =>
              onChange(formatValue((value ?? "").replace(/[^0-9.]/gi, "") || 0))
            }
            value={`${
              value.toString() === "0" ? "" : addCommas(value.toString())
            }`}
          />
          {!!value && (
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginTop: theme.spacing.xl,
              }}
            >
              <View style={{ flex: 1 }}>
                <Text>
                  Odds:{" "}
                  {formatOdds(
                    parseFloat(ticketValues.betAmount) +
                      parseFloat(ticketValues.winAmount),
                    parseFloat(value)
                  )}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                {!!valueRating && (
                  <ListingValueMeter valueRating={valueRating} />
                )}
              </View>
            </View>
          )}
        </View>
      )}
    </Screen>
  );
};
