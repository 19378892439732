import gql from "graphql-tag";

import Listing from "./Listing";

const Ticket = {};

Ticket.fragments = {
  public: gql`
    fragment TicketPublic on Ticket {
      id
      createdAt
      updatedAt
      odds
      oddsCalculated
      photoUrl
      betType
      eventDescription
      subject
      winAmount
      betAmount
      collectAmount
      additionalInfo
      ticketId
      bookName
      isMobile
      isSettled
      creatorId
      source
      result
      inPropswapsPossession
      reviewed
      ticketUserId
      ticketSportId
      ticketOriginalOwnerId
      ticketLocaleId
      ticketSportsbookId
      ticketEventId
      ticketOptionId
      hasShipped
      disableValues
      event {
        id
        description
        active
        featuredAt
        createdAt
        updatedAt
        bovadoGroupId
        bovadoEventId
        fanDuelMarketId
        fanDuelEventId
        rebateMessage
        rebateActive
      }

      option {
        id
        name
        active
        createdAt
        bovadoParticipantId
        bovadoParticipantName
        fanDuelSelectionId
      }

      sportsbook {
        id
        name
      }

      sport {
        id
        abbrev
        name
      }

      locale {
        id
        name
        state
        mailingAddress
      }

      views
    }
  `,
};

Ticket.queries = {
  get: gql`
    query GetTicket(
      $id: ID!
      $listingsFilter: ModelListingFilterInput
      $listingsLimit: Int
      $listingsNextToken: String
    ) {
      getTicket(id: $id) {
        ...TicketPublic
        cachedBets {
          id
          result
          optionId
          eventId
          sportId
          eventDescription
          subject
          sport {
            name
          }
        }
        listings(
          filter: $listingsFilter
          limit: $listingsLimit
          nextToken: $listingsNextToken
        ) {
          items {
            ...ListingPublic
          }
          nextToken
        }
      }
    }
    ${Ticket.fragments.public}
    ${Listing.fragments.public}
  `,
  listBySportsbookId: gql`
    query ListTicketsBySportsbookId(
      $ticketSportsbookId: ID!
      $createdAt: ModelStringKeyConditionInput
      $filter: ModelTicketFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listTicketsBySportsbookId(
        ticketSportsbookId: $ticketSportsbookId
        createdAt: $createdAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...TicketPublic
        }
        nextToken
      }
    }
    ${Ticket.fragments.public}
  `,
  listByUserId: gql`
    query TicketsByUserId(
      $ticketUserId: ID!
      $updatedAt: ModelStringKeyConditionInput
      $filter: ModelTicketFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      ticketsByUserId(
        ticketUserId: $ticketUserId
        updatedAt: $updatedAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...TicketPublic
        }
        nextToken
      }
    }
    ${Ticket.fragments.public}
  `,
  listByResultUpdatedAt: gql`
    query TicketsByResultUpdatedAt(
      $result: BetResult!
      $updatedAt: ModelStringKeyConditionInput
      $filter: ModelTicketFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      ticketsByResultUpdatedAt(
        result: $result
        updatedAt: $updatedAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...TicketPublic
        }
        nextToken
      }
    }
    ${Ticket.fragments.public}
  `,
  listByResultAndViews: gql`
    query ListTicketsByResultAndViews(
      $result: BetResult!
      $views: ModelIntKeyConditionInput
      $filter: ModelTicketFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listTicketsByResultAndViews(
        result: $result
        views: $views
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...TicketPublic
        }
        nextToken
      }
    }
    ${Ticket.fragments.public}
  `,
  list: gql`
    query ListTickets(
      $filter: ModelTicketFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...TicketPublic
        }
        nextToken
      }
    }
    ${Ticket.fragments.public}
  `,
};

Ticket.mutations = {
  update: gql`
    mutation UpdateTicket($input: UpdateTicketInput!) {
      updateTicket(input: $input) {
        ...TicketPublic
      }
    }
    ${Ticket.fragments.public}
  `,
  create: gql`
    mutation CreateTicket($input: CreateTicketInput!) {
      createTicket(input: $input) {
        ...TicketPublic
      }
    }
    ${Ticket.fragments.public}
  `,
  delete: gql`
    mutation DeleteTicket($input: DeleteTicketInput!) {
      deleteTicket(input: $input) {
        ...TicketPublic
      }
    }
    ${Ticket.fragments.public}
  `,
};

Ticket.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateTicket {
      onUpdateTicket {
        ...TicketPublic
      }
    }
    ${Ticket.fragments.public}
  `,
  onCreate: gql`
    subscription OnCreateTicket {
      onCreateTicket {
        ...TicketPublic
      }
    }
    ${Ticket.fragments.public}
  `,
};

export default Ticket;
