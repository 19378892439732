import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";

import Screen from "Components/Screen";
import { useTheme } from "react-native-paper";
import MyTicketsNav from "Components/MyTicketsNav";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { Platform, View } from "react-native";
import TicketItem from "Components/Ticket/Item";
import DataList from "Components/DataList";
import Bid from "shared/api/Bid";
import EmptySign from "Components/EmptySign";
import Spacer from "Components/Spacer";
import ScreenNav from "Components/Screen/Nav";
import { ScrollDirectionContext } from "Contexts/ScrollDirection";
import { useFocusEffect } from "@react-navigation/native";
import { handleScroll } from "Util/";

let init = 0; //TODO: REFACTOR INTO DATALIST

export default ({ navigation }) => {
  const theme = useTheme();
  const [subNavValue, setSubNavValue] = useState("Active");
  const { setScrollDirection } = useContext(ScrollDirectionContext);
  const currentUser = useContext(CurrentUserContext);
  const status =
    subNavValue === "Active"
      ? "PENDING"
      : subNavValue === "Rejected"
      ? "REJECTED"
      : "ACCEPTED";
  const dataListRef = useRef();
  const [hasItems, setHasItems] = useState(null);

  const handleSubNavChange = useCallback(value => setSubNavValue(value), []);

  const handleItems = useCallback(items => setHasItems(!!items?.length), []);

  const dataListQuery = useMemo(
    () => ({
      dataKey: "bidsByBidderId",
      query: Bid.queries.listByBidderId,
      variables: {
        sortDirection: "DESC",
        withListing: true,
        limit: 20,
        bidBidderId: currentUser?.id,
        statusUpdatedAt: {
          beginsWith: {
            status,
          },
        },
      },
    }),
    [currentUser, status]
  );

  const dataListRenderItem = useCallback(
    ({ item }) => (
      <View
        style={{
          marginBottom: theme.spacing.md,
        }}
      >
        <TicketItem
          ticketObj={item?.listing?.cachedTicket}
          listingObj={item?.listing}
        />
      </View>
    ),
    [theme]
  );

  useEffect(() => {
    !!dataListRef?.current?.refetch && dataListRef.current.refetch();
  }, [dataListRef]);

  useFocusEffect(
    React.useCallback(() => {
      !!dataListRef?.current?.refetch && dataListRef.current.refetch();
    }, [])
  );

  useEffect(() => {
    !!dataListRef?.current?.refetch && dataListRef.current.refetch();
  }, [dataListQuery]);

  return (
    <Screen fullHeight fullWidth gatedTo="member" title="My Bids">
      <MyTicketsNav navigation={navigation} selected="My Bids" />

      <ScreenNav
        itemLeft={<Spacer />}
        itemRight={<Spacer />}
        theme={theme}
        selectedValue={subNavValue}
        onValueChange={handleSubNavChange}
        buttons={[
          { label: "Active" },
          { label: "Rejected" },
          { label: "Accepted" },
        ]}
      />

      <DataList
        dataListRef={dataListRef}
        onScroll={
          Platform.OS !== "web"
            ? null
            : handleScroll(
                () => setScrollDirection("up"),
                () => setScrollDirection("down")
              )
        }
        onScrollBeginDrag={
          Platform.OS === "web"
            ? null
            : event => {
                init = event.nativeEvent.contentOffset.y;
              }
        }
        onScrollEndDrag={
          Platform.OS === "web"
            ? null
            : event =>
                init > event.nativeEvent.contentOffset.y
                  ? setScrollDirection("up")
                  : setScrollDirection("down")
        }
        query={dataListQuery}
        renderItem={dataListRenderItem}
        onFetchItems={handleItems}
        contentContainerStyle={{
          flex: !!hasItems ? undefined : 1,
          marginHorizontal: theme.spacing.xl,
          paddingTop: theme.spacing.xl,
        }}
        ListEmptyComponent={
          <EmptySign
            // title="Learn How To Bid"
            subheading={`You do not have any ${subNavValue} bids at this time`}
            navLabel="Start Bidding"
            onNavPress={() => navigation.push("Main", { screen: "Buy" })}
          />
        }
      />
    </Screen>
  );
};
