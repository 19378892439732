export default theme => ({
  wrapper: { marginTop: theme.spacing.xl },
  summaryWrapper: { marginBottom: theme.spacing.xl },
  inputWrapper: {
    marginRight: theme.spacing.md,
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing.sm,
  },
  input: {
    color: theme.colors.text,
    flex: 1,
    borderWidth: 1,
    padding: theme.spacing.xs,
    borderStyle: "solid",
    minWidth: 0,
  },
  confirmationText: {
    marginBottom: theme.spacing.lg,
  },
});
