import * as React from "react";
import Svg, { Defs, G, Path, Circle, Line } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1007.25 924.6"
      height={62}
      width={62}
      {...props}
    >
      <G id="Layer_2" data-name="Layer 2">
        <G id="Layer_1-2" data-name="Layer 1">
          <Path
            fill="none"
            stroke={props.stroke ?? "#000"}
            strokeLinejoin="round"
            strokeWidth={34}
            strokeLinecap="round"
            d="M990.25,361.08S814,97,790.31,83.15,629.35,17,629.35,17c-62.19,43.37-57.73,43.51-125.73,43.51S435.24,60.37,377.9,17c0,0-137.27,52.33-161,66.15S17,361.08,17,361.08l165.88,131.3,75-104.1L248.53,907.6H758.72l-9.37-519.32,75,104.1Z"
          />
          <Line
            fill="none"
            stroke={props.stroke ?? "#000"}
            strokeLinejoin="round"
            strokeWidth={34}
            x1="912.24"
            y1="338.02"
            x2="785.01"
            y2="440.38"
          />
          <Line
            fill="none"
            stroke={props.stroke ?? "#000"}
            strokeLinejoin="round"
            strokeWidth={34}
            x1="220.87"
            y1="439.3"
            x2="93.64"
            y2="336.94"
          />
          <Line
            fill="none"
            stroke={props.stroke ?? "#000"}
            strokeLinejoin="round"
            strokeWidth={34}
            strokeLinecap="round"
            x1="757.72"
            y1="839.94"
            x2="249.53"
            y2="839.94"
          />
          <Circle fill="#000" cx="505.52" cy="316.41" r="118.73" />
          <Path
            fill={props.lineColor ?? "#FFF"}
            d="M502.63,276.49q-3.24-5.12-10.47-5.12h-48l1,9.48-29.57,80.8H436.7l9.64-26.33h19.11a26.3,26.3,0,0,0,11.4-2.78,34.84,34.84,0,0,0,10.38-7.6,30.71,30.71,0,0,0,6.64-10.53l9.36-25.58Q505.87,281.6,502.63,276.49ZM480.4,293.64l-7.1,19.41a5.25,5.25,0,0,1-1.89,2.48,4.68,4.68,0,0,1-2.84,1H453.22l9.64-26.33h15.35a2.31,2.31,0,0,1,2.12,1A2.72,2.72,0,0,1,480.4,293.64Z"
          />
          <Path
            fill="#4cb648"
            d="M531.33,303.76a2.75,2.75,0,0,0,2.69,3.35l29.18.14c5.44,0,9.23,1.72,11.57,5.27s2.56,8,.57,13.27l-5.77,15.33c-5.19,12-3.64,21.76-34,20.45h0l-2.94,9.58H512l4-9.58H499l-4.11-18.52,50.64-.14a3.27,3.27,0,0,0,3.19-2.56l3.77-11.23a2.76,2.76,0,0,0-2.69-3.35H520.39c-5.39,0-9.19-1.73-11.62-5.33s-2.79-8-.94-13.31l5.37-15.49c1.94-5.57,4.59-21.6,28.45-20.38h6.44l.28-.95.29-1,2.24-7.64h21.7l-2.48,5.81-.85,1.94-.77,1.84h26.9l-17.77,18.53-39.34.19a3.29,3.29,0,0,0-3.21,2.56Z"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
