import { useState, useContext, useCallback, useEffect } from "react";
import {
  Button,
  Paragraph,
  Subheading,
  Text,
  useTheme,
} from "react-native-paper";
import Screen from "Components/Screen";
import { CurrentUserContext } from "Contexts/CurrentUser";
import AccountFormContainer from "Components/Account/Form";
import { Platform, TextInput, View } from "react-native";
import { useMutation } from "@apollo/client";
import User from "shared/api/User";
import { Auth } from "aws-amplify";

export default ({ navigation }) => {
  const currentUser = useContext(CurrentUserContext);
  const theme = useTheme();
  const [ssn, setSsn] = useState("");
  const [cognitoUser, setCognitoUser] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isCanadian = cognitoUser?.attributes?.["custom:country"] === "CA";

  const [verifyCanadianUser] = useMutation(User.mutations.verifyCanadian, {
    variables: {
      input: {
        id: currentUser?.id,
        ssn,
        firstName: cognitoUser?.attributes?.given_name,
        lastName: cognitoUser?.attributes?.family_name,
        zip: cognitoUser?.attributes?.["custom:zip"],
      },
    },
    refetchQueries: [
      {
        query: User.queries.get,
        variables: {
          id: currentUser?.id,
          withPrivate: true,
        },
      },
    ],
  });

  const [verifyUser] = useMutation(User.mutations.verify, {
    variables: {
      input: {
        id: currentUser?.id,
        ssn,
        firstName: cognitoUser?.attributes?.given_name,
        lastName: cognitoUser?.attributes?.family_name,
        zip: cognitoUser?.attributes?.["custom:zip"],
      },
    },
    refetchQueries: [
      {
        query: User.queries.get,
        variables: {
          id: currentUser?.id,
          withPrivate: true,
        },
      },
    ],
  });

  const handleSubmit = useCallback(
    () =>
      Promise.resolve(setIsSubmitting(true))
        .then(isCanadian ? verifyCanadianUser : verifyUser)
        .then(() => navigation.goBack())
        .catch(console.log)
        .finally(() => setIsSubmitting(false)),
    [verifyUser, navigation, isCanadian, verifyCanadianUser]
  );

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(setCognitoUser);
  }, []);

  return (
    <Screen
      gatedTo="member"
      title="Verify Your Identity"
      backgroundColor={theme.colors.surface}
      fullHeight
      hasScroll
      hasKeyboard
    >
      <Subheading>
        {isCanadian
          ? "Enter your 9-digit social insurance number*"
          : "Enter the last 4 digits of your social security number*"}
      </Subheading>
      <View style={{ flexDirection: "row", marginVertical: theme.spacing.xl }}>
        <TextInput
          selectTextOnFocus
          autoCorrect={false}
          autoCapitalize="none"
          autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
          keyboardType="number-pad"
          textContentType="none"
          returnKeyLabel="Done"
          returnKeyType="done"
          onSubmitEditing={handleSubmit}
          onChangeText={value => setSsn(value.replace(/\D/g, ""))}
          value={ssn}
          maxLength={isCanadian ? 9 : 4}
          style={{
            color: theme.colors.text,
            flex: 1,
            borderColor: theme.colors.secondary,
            borderWidth: 1,
            padding: theme.spacing.xs,
            borderStyle: "solid",
            minWidth: 0,
            marginRight: theme.spacing.xl,
          }}
        />
        <Button
          onPress={handleSubmit}
          loading={!!isSubmitting}
          disabled={
            !!isSubmitting ||
            (!isCanadian && ssn?.length !== 4) ||
            (isCanadian && ssn?.length !== 9)
          }
          mode="contained"
          compact
        >
          Submit
        </Button>
      </View>

      <Paragraph>
        Before you can Buy or Sell, you must complete this step. * PropSwap does
        NOT store your {isCanadian ? "SIN" : "SSN"}. We only use it to prevent
        fraud by verifying your identity. If any of the below information is
        incorrect, you may change it on your{" "}
        <Text
          onPress={() => navigation.navigate("MyEmail")}
          style={{
            color: theme.colors.secondary,
            ...Platform.select({
              web: {
                cursor: "pointer",
              },
              default: {},
            }),
          }}
        >
          My Account Page
        </Text>
      </Paragraph>
      {!!currentUser?.email && (
        <AccountFormContainer
          theme={theme}
          currentUser={currentUser}
          readOnly
        />
      )}
    </Screen>
  );
};
