import SegmentedControl from "@react-native-segmented-control/segmented-control";
import DataList from "Components/DataList";
import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import useHeaderFormSaveButton from "Hooks/useHeaderFormSaveButton";
import { useContext, useCallback, useState, useMemo } from "react";
import { Platform, View } from "react-native";
import {
  Button,
  Divider,
  List,
  Text,
  TextInput,
  useTheme,
} from "react-native-paper";
import Event from "shared/api/Event";

const options = ["Select", "Enter"];
const field = "eventDescription";
const itemFilter = item => !!item.active;

export default ({ navigation, route }) => {
  const leg = route?.params?.leg;
  const hasLeg = leg !== undefined && leg !== null;
  const theme = useTheme();
  const { setTicketValues, ticketValues } = useContext(TicketContext);
  const [value, setValue] = useState(
    (hasLeg
      ? ticketValues?.parlay?.[leg]?.eventDescription
      : ticketValues[field]) ?? ""
  );
  const [id, setId] = useState(ticketValues.ticketEventId);
  const [mode, setMode] = useState("Select");
  const sportId =
    ticketValues?.cachedBets?.[leg ?? 0]?.sportId ?? hasLeg
      ? ticketValues?.parlay?.[leg]?.ticketSportId
      : ticketValues.ticketSportId;

  const onChange = useCallback(
    (text, id) => [setValue(text), !!id && setId(id)],
    []
  );

  const onSubmit = useCallback(
    (eventDescription, eventId) => () =>
      [
        hasLeg &&
          setTicketValues(ticketValues => ({
            ...ticketValues,
            parlay: {
              ...(ticketValues.parlay ?? {}),
              [leg]: {
                ...(ticketValues.parlay?.[leg] ?? {}),
                [field]: (eventDescription ?? value ?? "").trim(),
                ticketEventId: eventId ?? id,
                subject: "",
                ticketOptionId: null,
              },
            },
          })),
        !hasLeg &&
          setTicketValues(ticketValues => ({
            ...ticketValues,
            [field]: (eventDescription ?? value ?? "").trim(),
            ticketEventId: eventId ?? id,
            subject: "",
            ticketOptionId: null,
          })),
        navigation.navigate("TicketNewStep3"),
      ],
    [hasLeg, setTicketValues, navigation, leg, value, id]
  );

  const dataListQuery = useMemo(
    () => ({
      dataKey: "listEventsBySportIdAndIsActiveCreatedAt",
      query: Event.queries.listBySportIdAndIsActiveCreatedAt,
      variables: {
        limit: 200,
        sortDirection: "ASC",
        sportId,
        isActiveCreatedAt: {
          beginsWith: {
            isActive: "x",
          },
        },
      },
    }),
    [sportId]
  );

  const dataListRenderItem = useCallback(
    ({ item, index }) => (
      <>
        <List.Item
          title={item.description}
          onPress={onSubmit(item.description, item.id)}
          right={props =>
            ticketValues.ticketEventId === item.id ? (
              <List.Icon {...props} icon="check-circle-outline" />
            ) : null
          }
        />
        <Divider />
      </>
    ),
    [ticketValues, onSubmit]
  );

  useHeaderFormSaveButton({
    onSubmit: onSubmit(),
    isValid: !!value && mode === "Enter",
  });

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={2}
        title={`${mode} Event`}
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />

      <View style={{ marginHorizontal: theme.spacing.xl }}>
        <View style={{ marginVertical: theme.spacing.lg }}>
          <SegmentedControl
            values={options}
            selectedIndex={options.indexOf(mode)}
            onChange={event =>
              setMode(options[event.nativeEvent.selectedSegmentIndex])
            }
          />
        </View>

        {mode === "Enter" && (
          <TextInput
            autoFocus
            mode="outlined"
            label="Event"
            right={
              !!value && (
                <TextInput.Icon
                  color={theme.colors.primary}
                  onPress={onSubmit()}
                  name="check-circle-outline"
                />
              )
            }
            selectTextOnFocus
            autoCorrect
            autoCapitalize="words"
            autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
            textContentType="none"
            returnKeyLabel="Done"
            returnKeyType="done"
            onSubmitEditing={onSubmit()}
            onChangeText={value => onChange(value)}
            value={value ?? ""}
          />
        )}
      </View>

      {mode === "Select" && (
        <DataList
          filterItems={itemFilter}
          query={dataListQuery}
          renderItem={dataListRenderItem}
          ListEmptyComponent={
            <View style={{ marginHorizontal: theme.spacing.xl }}>
              <Text>No Events to Choose From</Text>
              <Button mode="outlined" onPress={() => setMode("Enter")}>
                Enter Event
              </Button>
            </View>
          }
        />
      )}
    </Screen>
  );
};
