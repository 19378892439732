import React from "react";
import { useContext, useCallback } from "react";
import { Button, Paragraph, Subheading, useTheme } from "react-native-paper";

import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import LocaleSelect from "Components/Locale/Select";
import BottomSheet from "Components/BottomSheet";
import { useState } from "react";
import { View } from "react-native";

export default ({ navigation }) => {
  const theme = useTheme();
  const { setTicketValues } = useContext(TicketContext);
  const [selectedNevada, setSelectedNevada] = useState(false);

  const handleSubmit = useCallback(
    ({ ticketLocaleId, locale }) =>
      () =>
        locale?.name === "Nevada"
          ? setSelectedNevada(true)
          : [
              setTicketValues(ticketValues => ({
                ...ticketValues,
                ticketLocaleId,
              })),
              navigation.navigate("TicketNewStep3"),
            ],
    [navigation, setTicketValues]
  );

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={2}
        title={"Choose Location"}
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />

      <LocaleSelect onSubmit={handleSubmit} />

      <BottomSheet
        visible={!!selectedNevada}
        onDismiss={() => setSelectedNevada(false)}
        fullScreen={false}
        fullWidth
        withHeader={true}
        title="Notice"
      >
        <View style={{ marginHorizontal: theme.spacing.xl }}>
          <Subheading style={{ marginVertical: theme.spacing.xl * 2 }}>
            Unfortunately due to recents actions from the state of Nevada,
            PropSwap has temporarily suspended the sale of Nevada sportsbook
            tickets. We apologize for any inconvenience this may have caused.
          </Subheading>

          <Button mode="contained" onPress={() => setSelectedNevada(false)}>
            Close
          </Button>
        </View>
      </BottomSheet>
    </Screen>
  );
};
