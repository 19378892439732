import React from "react";
import { Divider, List } from "react-native-paper";

import { ScrollView } from "react-native";

export default ({ theme, styles, onSubmit, items, ticketValues }) => (
  <ScrollView style={styles.f1}>
    {items
      .slice()
      .filter(item => !!item.state && !!item.name)
      .sort((a, b) => (a.state > b.state ? 1 : -1))
      .map(locale => (
        <React.Fragment key={locale.id}>
          <List.Item
            title={
              locale.state === locale.name
                ? locale.state
                : `${locale.state} - ${locale.name}`
            }
            right={props =>
              ticketValues.ticketLocaleId === locale.id ? (
                <List.Icon {...props} icon="check-circle-outline" />
              ) : null
            }
            onPress={onSubmit({ ticketLocaleId: locale.id, locale })}
          />
          <Divider />
        </React.Fragment>
      ))}
  </ScrollView>
);
