import { useEffect } from "react";

import MainView from "./MainView";
import useStyles from "Hooks/useStyles";
import MainStyles from "./MainStyles";
import Redirect from "shared/api/Redirect";
import { useQuery } from "@apollo/client";
import { Platform } from "react-native";

const FullRedirect = ({ destination }) => {
  useEffect(() => {
    if (!!destination) {
      window.location.assign(destination);
    }
  }, [destination]);

  return null;
};

export default ({ children }) => {
  const { theme, styles } = useStyles({ Styles: MainStyles });
  const geoAllowed = true; //useIsGeoAllowed();
  const source =
    Platform.OS === "web" ? window.location.pathname?.toLowerCase?.() : null;

  const { loading, data: { listRedirectsBySource: { items = [] } = {} } = {} } =
    useQuery(Redirect.queries.listBySource, {
      skip: !source,
      variables: {
        source,
      },
    });

  const redirectDestination = items[0]?.destination;

  return !!loading ? null : !!redirectDestination ? (
    <FullRedirect destination={redirectDestination} />
  ) : (
    <MainView geoAllowed={geoAllowed} styles={styles} theme={theme}>
      {children}
    </MainView>
  );
};
