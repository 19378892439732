import React from "react";
import { useContext, useCallback } from "react";
import { TextInput, useTheme } from "react-native-paper";

import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import { useState } from "react";
import { Platform, View } from "react-native";
import useHeaderFormSaveButton from "Hooks/useHeaderFormSaveButton";

const field = "notes";

export default ({ navigation }) => {
  const theme = useTheme();
  const { setTicketValues, ticketValues } = useContext(TicketContext);
  const [value, setValue] = useState(ticketValues[field]);

  const onChange = useCallback(text => setValue(text), []);

  const onSubmit = useCallback(
    () => [
      setTicketValues(ticketValues => ({
        ...ticketValues,
        [field]: value,
      })),
      navigation.navigate("TicketNewStep4"),
    ],
    [value, navigation, setTicketValues]
  );

  const onKeyPress = useCallback(
    ({ nativeEvent }) => nativeEvent.key === "Enter" && onSubmit(),
    [onSubmit]
  );

  useHeaderFormSaveButton({ onSubmit, isValid: !!value });

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={2}
        title={"Enter Additional Info"}
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />

      <View style={{ marginHorizontal: theme.spacing.xl }}>
        <TextInput
          mode="outlined"
          numberOfLines={4}
          placeholder="Seller Notes (optional)"
          maxLength={280}
          blurOnSubmit={true}
          enablesReturnKeyAutomatically={true}
          onKeyPress={onKeyPress}
          multiline
          label="Additional Info"
          right={
            !!value && (
              <TextInput.Icon
                color={theme.colors.primary}
                onPress={onSubmit}
                name="check-circle-outline"
              />
            )
          }
          autoCorrect
          autoCapitalize={"none"}
          autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
          textContentType="none"
          returnKeyLabel={"Done"}
          returnKeyType={"done"}
          onSubmitEditing={onSubmit}
          onChangeText={value => onChange(value)}
          value={value}
        />
      </View>
    </Screen>
  );
};
