import * as React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { Button, Text } from "react-native-paper";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation }) => {
  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: () => null,
    });
  }, [navigation]);

  return (
    <>
      <ScrollView>
        <View style={styles.buttons}>
          <Button
            mode="contained"
            onPress={() => navigation.navigate("TicketNewStep4")}
            style={styles.button}
          >
            Enter Amount
          </Button>
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  buttons: {
    padding: 8,
  },
  button: {
    margin: 8,
  },
});
