import { useContext, useState, useCallback, useEffect } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { convertMoneyTextToPennies, formatPennies } from "shared/Util/money";
import {
  CC_DEPOSIT_FEE,
  ACH_DEPOSIT_FEE,
  minimumDepositInPennies,
} from "shared/Config";
import BluesnapDepositSummaryView from "./BluesnapDepositSummaryView";
import useStyles from "Hooks/useStyles";
import BluesnapDepositSummaryStyles from "./BluesnapDepositSummaryStyles";
// import useIsInAnApprovedStateByIp from "Hooks/useIsInAnApprovedStateByIp";
import useCurrentLocationByIp from "Hooks/useCurrentLocationByIp";
import useCurrentLocationByGeo from "Hooks/useCurrentLocationByGeo";
import { Platform } from "react-native";
import useGidxSession from "Hooks/useGidxSession";
import useGidxCheckout from "Hooks/useGidxCheckout";

// transactionPromotionId: promotion?.id ?? undefined,

// refetchQueries: [
//   {
//     query: User.queries.get,
//     variables: {
//       id: currentUser?.id,
//       withPrivate: true,
//     },
//   },
// ],

export default ({
  navigation,
  amount: amountFromParams,
  lastFour: lastFourFromParams,
  promotionId,
  type,
}) => {
  const { theme, styles } = useStyles({ Styles: BluesnapDepositSummaryStyles });
  const [cvv, setCvv] = useState("");
  const currentUser = useContext(CurrentUserContext);
  const [amount, setAmount] = useState(amountFromParams);
  const [lastFour, setLastFour] = useState(lastFourFromParams);
  const [submitted, setSubmitted] = useState(false);
  const [promotion, setPromotion] = useState(null);
  const locationByIp = useCurrentLocationByIp();
  const locationByGeo = useCurrentLocationByGeo();
  const location = Platform.OS === "web" ? locationByIp : locationByGeo;
  const gidxSession = useGidxSession();
  const isGeographicallyAllowedToDeposit =
    !!currentUser?.groups?.includes?.("Admins") || location?.isAllowedToDeposit;
  const creditCards = (gidxSession?.PaymentMethods ?? []).filter(
    pm => pm.Type === "CC"
  );
  const bankAccounts = (gidxSession?.PaymentMethods ?? []).filter(
    pm => pm.Type !== "CC"
  );

  const locationString = !!location && `${location.city}, ${location.region}`;

  const navigateTo = useCallback(
    (screen, params, type) => () =>
      [
        setSubmitted(false),
        navigation[type === "replace" ? "replace" : "navigate"](screen, params),
      ],
    [navigation]
  );

  const selectedCreditCard = !!lastFour
    ? creditCards.find(pm => pm.Token === lastFour)
    : creditCards?.[0];

  const selectedBankAccount = !!lastFour
    ? bankAccounts.find(pm => pm.Token === lastFour)
    : bankAccounts?.[0];

  const selectedPaymentMethod =
    type === "cc" ? selectedCreditCard : selectedBankAccount;

  const [checkout, { loading, error }] = useGidxCheckout({
    type: type === "cc" ? "CC" : "ACH",
    token: selectedPaymentMethod?.Token,
    cvv,
    amount: Number(amount),
    promotionId: promotion?.id,
  });

  const handleCvvChange = useCallback(cvv => setCvv(cvv), [setCvv]);

  const doSubmit = useCallback(
    () => checkout().then(() => setSubmitted(true)),
    [checkout]
  );

  const fee = type === "cc" ? CC_DEPOSIT_FEE : ACH_DEPOSIT_FEE;
  const bonus = formatPennies(
    promotion
      ? Math.min(
          parseInt(convertMoneyTextToPennies(amount)),
          promotion.type === "DEPOSIT_MATCH_100" ? 10000 : 50000
        )
      : 0
  );

  const handleVerificationPress = useCallback(
    () => navigation.push("Verification"),
    [navigation]
  );

  const errorMessages = [
    convertMoneyTextToPennies(amount) >= minimumDepositInPennies
      ? null
      : "$5 minimum",
    selectedPaymentMethod ? null : "You must select or add a payment method",
  ].filter(i => !!i);

  const isValid =
    !errorMessages.length &&
    !!currentUser?.verifiedAt &&
    (type === "cc" ? !!cvv : true);

  useEffect(() => {
    const unsubscribe = navigation.addListener("blur", () => {
      setSubmitted(false);
    });

    return unsubscribe;
  }, [navigation, setSubmitted]);

  useEffect(() => {
    setLastFour(lastFourFromParams);
  }, [lastFourFromParams]);

  useEffect(() => {
    setAmount(amountFromParams);
  }, [amountFromParams]);

  !!error && console.error(error.message);

  return (
    <BluesnapDepositSummaryView
      locationString={locationString}
      isGeographicallyAllowedToDeposit={isGeographicallyAllowedToDeposit}
      onPromotionApply={setPromotion}
      theme={theme}
      type={type}
      navigateTo={navigateTo}
      selectedPaymentMethod={selectedPaymentMethod}
      currentUser={currentUser}
      errorMessages={errorMessages}
      isSubmitting={loading}
      amount={amount}
      submitted={submitted}
      doSubmit={doSubmit}
      fee={fee}
      bonus={bonus}
      promotionId={promotion?.id ?? promotionId}
      onVerificationPress={handleVerificationPress}
      styles={styles}
      cvv={cvv}
      onCvvChange={handleCvvChange}
      error={error}
      isValid={isValid}
    />
  );
};
