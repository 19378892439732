import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fill={props?.color ?? "#666"}
        d="M256 0C115.39 0 0 115.39 0 256s115.39 256 256 256 256-115.39 256-256S396.61 0 256 0zm0 421c-90.98 0-165-74.02-165-165S165.02 91 256 91s165 74.02 165 165-74.02 165-165 165zm0 0"
      />
      <Path
        fill={props?.color ?? "#666"}
        d="M256 241c-8.277 0-15 6.723-15 15s6.723 15 15 15 15-6.723 15-15-6.723-15-15-15zm0 0"
      />
      <Path
        fill={props?.color ?? "#666"}
        d="M326.5 198.102l24.898-37.5c-16.5-16.504-37.199-28.801-60.296-34.801L271 166h-30l-20.098-40.2c-23.101 6-43.8 18.298-60.3 34.802l24.902 37.5-15 25.796-45-2.699C122.5 232.301 121 244 121 256s1.5 23.7 4.5 34.8l45-2.698 15 25.796-24.898 37.5c16.5 16.5 37.199 28.801 60.296 34.801L241 346h30l20.098 40.2c23.101-6 43.8-18.302 60.3-34.802l-24.902-37.5 15-25.796 45 2.699C389.5 279.699 391 268 391 256s-1.5-23.7-4.5-34.8l-45 2.698zM256 301c-24.813 0-45-20.188-45-45s20.188-45 45-45 45 20.188 45 45-20.188 45-45 45zm0 0"
      />
    </Svg>
  );
}

export default SvgComponent;
