import * as React from "react";
import { useContext, useCallback, useEffect } from "react";
import { Button, useTheme, TextInput, HelperText } from "react-native-paper";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RegistrationContext } from "Contexts/Registration";
import { View, Platform } from "react-native";
import { TextInputMask } from "react-native-masked-text";
import ScreenBottom from "Components/Screen/Bottom";
import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import moment from "moment";

// const scrollEnabled = Platform.select({ web: true, default: false });

const schema = yup.object().shape({
  birthDate: yup
    .date()
    // .matches(/^\d\d\/\d\d\/\d\d\d\d$/i, "Must be a valid date")
    .transform((_, val) =>
      moment(val ?? "", "MM/DD/YYYY", true).isValid()
        ? moment(val, "MM/DD/YYYY").toDate()
        : null
    )
    .max(moment().subtract(21, "years").toDate(), "Must be 21 or older")
    .nullable()
    .required("Invalid Date"),
});

const Form = ({ style, navigation }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const inputRef = React.useRef(null);
  const { setRegistrationValues, registrationValues } =
    useContext(RegistrationContext);
  const initialDateValue = moment(registrationValues?.birthDate, true).isValid()
    ? moment(registrationValues?.birthDate).format("MM/DD/YYYY")
    : "";

  const onSubmit = useCallback(
    ({ birthDate }) => [
      setRegistrationValues(registrationValues => ({
        ...registrationValues,
        birthDate,
      })),
      navigation.push("RegistrationStep3"),
    ],
    [navigation, setRegistrationValues]
  );

  const handleInputAutofocus = useCallback(() => {
    const timeout = setTimeout(() => {
      inputRef.current._inputElement.focus();
    }, 10);

    return () => clearTimeout(timeout);
  }, []);

  const doSubmit = handleSubmit(onSubmit);

  useEffect(() => {
    handleInputAutofocus();
  }, [handleInputAutofocus]);

  return (
    <>
      <View
        style={[
          {
            flex: 1,
          },
          style ?? {},
        ]}
      >
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize="none"
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="number-pad"
                textContentType="none"
                label="MM/DD/YYYY"
                error={!!errors.birthDate}
                returnKeyLabel="Done"
                returnKeyType="done"
                onSubmitEditing={doSubmit}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
                render={props => (
                  <TextInputMask
                    {...props}
                    ref={inputRef}
                    type="datetime"
                    options={{
                      format: "MM/DD/YYYY",
                    }}
                  />
                )}
              />
              <HelperText type="info" visible={!errors.birthDate}>
                Type the numerals that correspond to your birth month, day, and
                year. No need to use slashes.
              </HelperText>
              <HelperText type="error" visible={!!errors.birthDate}>
                {errors.birthDate?.message}
              </HelperText>
            </>
          )}
          name="birthDate"
          defaultValue={initialDateValue}
        />
      </View>
      <ScreenBottom adjustPadding>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          Continue
        </Button>
      </ScreenBottom>
    </>
  );
};

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen
      gatedTo="guest"
      title="Register"
      hasKeyboard
      backgroundColor={theme.colors.surface}
    >
      <Stepper
        theme={theme}
        currentPosition={1}
        title="Enter Your Birthday"
        labels={["", "", "", ""]}
      />
      <Form
        style={{ marginTop: theme.spacing.xl }}
        theme={theme}
        navigation={navigation}
      />
    </Screen>
  );
};
