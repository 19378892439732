import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path d="M0 0h20v20H0z" />
        <Path
          fill={props?.color ?? "#666"}
          d="M9.525 18.335c.637-1.214.82-4.007.591-6.445-.16-1.697.207-3.09 1.06-4.026.163-.178.344-.338.538-.48 1.957 2.31 3.668 5.32 4.093 8.607a8.317 8.317 0 01-6.282 2.345zm-6.13-3.237c.246-1.001 1.769-6.365 6.654-9.468.413.388.829.811 1.237 1.265a4.182 4.182 0 00-.59.53c-.684.751-1.451 2.146-1.228 4.526.321 3.416-.224 5.911-.663 6.312a8.352 8.352 0 01-5.41-3.165zm-1.54-6.93c.063-.193.404-1.037 1.731-1.46.155-.05.32-.102.496-.156 1.395-.436 3.355-1.05 4.706-2.005.25.196.504.406.76.63-4.379 2.864-6.112 7.462-6.637 9.227a8.298 8.298 0 01-1.056-6.235zm3.76-5.27c.44-.02 1.38.35 2.624 1.239-1.256.825-3.057 1.39-4.351 1.793l-.5.158c-.398.127-.721.29-.99.465a8.408 8.408 0 013.218-3.655zm4.086.834c.776-.929.954-1.625.965-2.052a8.277 8.277 0 014.024 1.417c-1.757.354-3.269.96-4.565 1.722a18.447 18.447 0 00-.814-.689c.14-.128.274-.26.39-.398zm.314-2.081c-.006.277-.125.84-.814 1.664-.12.144-.261.281-.415.414-.862-.634-1.645-1.08-2.285-1.307A8.293 8.293 0 0110 1.651h.015zm8.147 6.595c-1.031-1.213-2.832-2.2-4.385-2.2a4.16 4.16 0 00-1.935.498c-.4-.45-.809-.872-1.216-1.26 1.342-.762 2.917-1.352 4.763-1.654a8.351 8.351 0 012.773 4.616zM12.28 7.05c.47-.226.985-.353 1.497-.353 1.898 0 4.41 1.828 4.572 3.322a8.312 8.312 0 01-1.976 5.367c-.539-3.176-2.2-6.076-4.093-8.336zM10 1C5.724 1 2.138 3.998 1.226 8.001l-.017.062h.003A9.045 9.045 0 001 10c0 4.963 4.037 9 9 9s9-4.037 9-9-4.037-9-9-9z"
        />
        <Path
          fill={props?.color ?? "#666"}
          d="M9.525 18.335c.637-1.214.82-4.007.591-6.445-.16-1.697.207-3.09 1.06-4.026.163-.178.344-.338.538-.48 1.957 2.31 3.668 5.32 4.093 8.607a8.317 8.317 0 01-6.282 2.345zm-6.13-3.237c.246-1.001 1.769-6.365 6.654-9.468.413.388.829.811 1.237 1.265a4.182 4.182 0 00-.59.53c-.684.751-1.451 2.146-1.228 4.526.321 3.416-.224 5.911-.663 6.312a8.352 8.352 0 01-5.41-3.165zm-1.54-6.93c.063-.193.404-1.037 1.731-1.46.155-.05.32-.102.496-.156 1.395-.436 3.355-1.05 4.706-2.005.25.196.504.406.76.63-4.379 2.864-6.112 7.462-6.637 9.227a8.298 8.298 0 01-1.056-6.235zm3.76-5.27c.44-.02 1.38.35 2.624 1.239-1.256.825-3.057 1.39-4.351 1.793l-.5.158c-.398.127-.721.29-.99.465a8.408 8.408 0 013.218-3.655zm4.086.834c.776-.929.954-1.625.965-2.052a8.277 8.277 0 014.024 1.417c-1.757.354-3.269.96-4.565 1.722a18.447 18.447 0 00-.814-.689c.14-.128.274-.26.39-.398zm.314-2.081c-.006.277-.125.84-.814 1.664-.12.144-.261.281-.415.414-.862-.634-1.645-1.08-2.285-1.307A8.293 8.293 0 0110 1.651h.015zm8.147 6.595c-1.031-1.213-2.832-2.2-4.385-2.2a4.16 4.16 0 00-1.935.498c-.4-.45-.809-.872-1.216-1.26 1.342-.762 2.917-1.352 4.763-1.654a8.351 8.351 0 012.773 4.616zM12.28 7.05c.47-.226.985-.353 1.497-.353 1.898 0 4.41 1.828 4.572 3.322a8.312 8.312 0 01-1.976 5.367c-.539-3.176-2.2-6.076-4.093-8.336zM10 1C5.724 1 2.138 3.998 1.226 8.001l-.017.062h.003A9.045 9.045 0 001 10c0 4.963 4.037 9 9 9s9-4.037 9-9-4.037-9-9-9z"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
