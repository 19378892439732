import { gql, useMutation } from "@apollo/client";
import { gidxRefresSessionVar } from "./useGidxSession";
import { useCallback } from "react";
import useGidxPrepareSession from "./useGidxPrepareSession";

const gidxDeletePaymentMethod = gql`
  mutation GidxDeletePaymentMethod($input: GidxDeletePaymentMethodInput!) {
    gidxDeletePaymentMethod(input: $input) {
      MerchantID
      MerchantSessionID
      ResponseCode
      ResponseMessage
      PaymentMethod {
        Token
        DisplayName
        Type
        NameOnAccount
        PhoneNumber
        CVV
        CardNumber
        ExpirationDate
        Network
        AVSResult
        CVVResult
        AccountNumber
        RoutingNumber
      }
      ApiVersion
    }
  }
`;

const useGidxDeletePaymentMethod = ({ type, token } = {}) => {
  const [createSession] = useGidxPrepareSession();

  const [deleteMethod] = useMutation(gidxDeletePaymentMethod);

  const handleDeleteCreditCard = useCallback(
    ({ token: passedToken, type: passedType }) =>
      createSession()
        .then(({ data }) =>
          deleteMethod({
            variables: {
              input: {
                MerchantSessionID: data.gidxCreateSession.MerchantSessionID,
                PaymentMethod: {
                  Token: passedToken ?? token,
                  Type: passedType ?? type,
                  CVV: (passedType ?? type) === "CC" ? "888" : undefined,
                },
              },
            },
          }).then(({ data }) => {
            return data?.gidxDeletePaymentMethod;
          })
        )
        .catch(console.error)
        .finally(() => gidxRefresSessionVar(true)),
    [createSession, deleteMethod, token, type]
  );

  return handleDeleteCreditCard;
};

export default useGidxDeletePaymentMethod;
