import { useCallback, useMemo, useContext, useState } from "react";

import { MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons";
import Screen from "Components/Screen";
import { useTheme } from "react-native-paper";
import { View } from "react-native";
import Activity from "shared/api/Activity";
import DataList from "Components/DataList";
import ScreenHeader from "Components/Screen/Header";
import { CurrentUserContext } from "Contexts/CurrentUser";
import ActivityItem from "Components/Activity/Item";
import FilterScreen from "Components/FilterScreen";

const filterItems = [
  { label: "All", value: false },
  { label: "Ticket Sold", value: "LISTING_SOLD" },
  { label: "Bid Received", value: "BID_RECEIVED" },
  { label: "Listing Expired", value: "LISTING_EXPIRED" },
  { label: "Outbid", value: "BID_OUTBID" },
  { label: "Bid Expired", value: "BID_EXPIRED" },
  { label: "Bid Accepted", value: "BID_ACCEPTED" },
  { label: "Bid Rejected", value: "BID_REJECTED" },
  { label: "Bid Countered", value: "BID_COUNTERED" },
  { label: "Bid Cancelled", value: "BID_CANCELLED" },
];

// const scrollEnabled = Platform.select({ web: true, default: false });

const extractListingFromActivity = activity => {
  let parsedData = JSON.parse(activity.data);

  parsedData =
    typeof parsedData === "string" ? JSON.parse(parsedData) : parsedData;

  if (!!parsedData.listingSellerId) {
    return parsedData;
  } else {
    return parsedData?.listing;
  }
};

export default ({ navigation }) => {
  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);
  const [selectedValue, setSelectedValue] = useState(false);

  const handleActivityPress = useCallback(
    activity => () =>
      navigation.push("ListingModalStack", {
        screen: "ListingDetail",
        params: { id: extractListingFromActivity(activity)?.id },
      }),
    [navigation]
  );

  const handleValueChange = useCallback(
    value => [setSelectedValue(value), setShowBottomSheet(false)],
    []
  );

  const dataKey = !!selectedValue
    ? "listActivitiesByTypeAndUserId"
    : "listActivitiesByUserIdAndCreatedAt";
  const query = !!selectedValue
    ? "listByTypeAndUserId"
    : "listByUserIdAndCreatedAt";
  const userId = currentUser?.id;

  const variables = useMemo(
    () =>
      !!selectedValue
        ? {
            sortDirection: "DESC",
            type: selectedValue,
            userIdCreatedAt: {
              beginsWith: {
                userId,
              },
            },
          }
        : {
            sortDirection: "DESC",
            userId,
            notificationCenterEligibleCreatedAt: {
              beginsWith: {
                notificationCenterEligible: "YES",
              },
            },
          },
    [userId, selectedValue]
  );

  const dataListQuery = useMemo(
    () => ({
      dataKey,
      query: Activity.queries[query],
      variables,
    }),
    [query, variables, dataKey]
  );

  const dataListRenderItem = useCallback(
    ({ item }) => (
      <View style={{ marginBottom: theme.spacing.md }}>
        <ActivityItem
          activity={item}
          currentUser={currentUser}
          onPress={handleActivityPress}
        />
      </View>
    ),
    [theme.spacing.md, currentUser, handleActivityPress]
  );

  const [showBottomSheet, setShowBottomSheet] = useState(false);

  const handleBottomSheetDismiss = useCallback(
    () => setShowBottomSheet(false),
    []
  );

  const handleFilterButtonPress = useCallback(
    () => setShowBottomSheet(true),
    []
  );

  return (
    <Screen fullHeight fullWidth>
      <ScreenHeader
        theme={theme}
        title="Notifications"
        icon={
          <FontAwesome5 size={24} name="bell" color={theme.colors.onSurface} />
        }
        rightButton={
          <MaterialCommunityIcons
            name="filter-variant"
            size={24}
            color={theme.colors.onSurface}
            onPress={handleFilterButtonPress}
          />
        }
        useSimple={false}
        navigation={navigation}
      />

      <DataList
        query={dataListQuery}
        renderItem={dataListRenderItem}
        contentContainerStyle={{
          marginHorizontal: theme.spacing.xl,
          paddingTop: theme.spacing.xl,
        }}
      />
      <FilterScreen
        items={filterItems}
        theme={theme}
        visible={showBottomSheet}
        onDismiss={handleBottomSheetDismiss}
        title="Filter Activities"
        selectedValue={selectedValue}
        onValueChange={handleValueChange}
      />
    </Screen>
  );
};
