import UserInfo from "Components/User/Info";
import { TouchableWithoutFeedback, View } from "react-native";
import { Text } from "react-native-paper";
import { FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import { addCommas, formatPennies } from "shared/Util/money";
import IconWithBadgeCount from "./IconWithBadgeCount";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { useContext } from "react";

const MemberHeaderView = ({
  theme,
  styles,
  onWatchlistIconPress,
  onNotificationIconPress,
  onBalancePress,
  onPointsPress,
  onUserPress,
  skipIcons,
}) => {
  const currentUser = useContext(CurrentUserContext);
  return (
    <>
      <UserInfo
        userObj={currentUser}
        textColor={skipIcons ? theme.colors.text : theme.colors.textReverse}
        onPress={onUserPress}
        skipIcons={skipIcons}
      />
      {!skipIcons && (
        <View style={styles.iconsWrapper}>
          <IconWithBadgeCount
            IconSet={FontAwesome5}
            count={
              !!currentUser?.hideNotificationsCount
                ? 0
                : currentUser?.notificationsCount ?? 0
            }
            name="bell"
            size={24}
            color={theme.colors.textReverse}
            wrapperStyles={{ marginRight: theme.spacing.lg * 2 }}
            onPress={onNotificationIconPress}
            theme={theme}
            styles={styles}
          />
          <IconWithBadgeCount
            IconSet={FontAwesome5}
            count={currentUser?.watchingsCount ?? 0}
            name="bookmark"
            size={24}
            color={theme.colors.textReverse}
            onPress={onWatchlistIconPress}
            theme={theme}
            styles={styles}
          />
        </View>
      )}
      <View>
        <TouchableWithoutFeedback onPress={onBalancePress}>
          <View style={[styles.row, styles.pointer, { alignItems: "center" }]}>
            <MaterialCommunityIcons
              name="plus-box"
              size={16}
              color={theme.colors.primary}
            />
            <Text
              style={[
                styles.primaryHeading,
                styles.text,
                !skipIcons
                  ? {}
                  : {
                      color: theme.colors.primary,
                    },
              ]}
            >
              $
              {formatPennies(
                (currentUser?.balance ?? 0) +
                  (currentUser?.promotionalBalance ?? 0)
              )}
            </Text>
          </View>
        </TouchableWithoutFeedback>
        <Text
          onPress={onPointsPress}
          style={[
            styles.secondaryHeading,
            styles.textRight,
            styles.pointer,
            !skipIcons
              ? {}
              : {
                  color: theme.colors.text,
                },
          ]}
        >
          {addCommas(currentUser?.availablePoints ?? 0)} pts
        </Text>
      </View>
    </>
  );
};

export default MemberHeaderView;
