import React from "react";
import { Paragraph, useTheme } from "react-native-paper";

import Screen from "Components/Screen";

import ScreenHeader from "Components/Screen/Header";
import { View } from "react-native";
import ResponsiveImage from "Components/ResponsiveImage";
import { larry } from "assets";

import { useCallback } from "react";
import { useState } from "react";
import ScreenNav from "Components/Screen/Nav";
import { MaterialCommunityIcons, AntDesign } from "@expo/vector-icons";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation }) => {
  const theme = useTheme();
  const [subNavValue, setSubNavValue] = useState("Awaiting");

  const handleSubNavChange = useCallback(value => setSubNavValue(value), []);

  return (
    <Screen fullHeight backgroundColor={theme.colors.background}>
      {/* <ScreenNav 
        itemLeft={
          <MaterialCommunityIcons 
            name="information" 
            size={18} 
            color={theme.colors.onSurface} 
            onPress={() => navigation.navigate("TicketsWarning")} 
          />
        }
        itemRight={
          <AntDesign 
            name="pluscircleo" 
            size={18} 
            color={theme.colors.onSurface} 
            onPress={() => navigation.navigate("TicketNewStack")}
          />
        }
        theme={theme}
        selectedValue={subNavValue}
        onValueChange={handleSubNavChange}
        buttons={[
          {label: "Awaiting"},
          {label: "Sold"}
        ]}
      /> */}
      <ScreenHeader navigation={navigation} />
      <View
        style={{
          flex: 1,
          padding: theme.spacing.xl,
          backgroundColor: theme.colors.surface,
          marginBottom: theme.spacing.xl,
        }}
      >
        <Paragraph>
          You are required by law to take action to complete this transaction:
        </Paragraph>
        <Paragraph>If you don’t comply, Larry will pay you a visit.</Paragraph>
        <View
          style={{ flex: 1, justifyContent: "flex-end", alignItems: "center" }}
        >
          <ResponsiveImage
            style={{
              marginBottom: theme.spacing.xl,
              marginTop: theme.spacing.xl,
            }}
            source={larry}
            height={269}
          />
        </View>
      </View>
    </Screen>
  );
};
