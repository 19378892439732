import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import AccountNav from "Screens/AccountNav";

import { useSafeAreaInsets } from "react-native-safe-area-context";

import About from "Screens/About";
import Faq from "Screens/Faq";
import Settings from "Screens/Settings";
import TutorialReset from "Screens/TutorialReset";
import Logout from "Screens/Logout";
import NotificationSettings from "Screens/NotificationSettings";
import ThemeSettings from "Screens/ThemeSettings";
import PointsStack from "./PointsStack";
import ProfileStack from "./ProfileStack";
import TransactionStack from "./TransactionStack";
import WalletStack from "./WalletStack";

import Activity from "Screens/Activity";
import Contact from "Screens/Contact";
import Notifications from "Screens/Notifications";
import SdkAccessRequest from "Screens/SdkAccessRequest";
import WatchList from "Screens/WatchList";
import DepositStack from "./DepositStack";
import {
  getPartialModalOptions,
  getStandardScreenOptions,
  headerNavOptions,
} from "./index";
import ListingModalStack from "./ListingModalStack";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  const insets = useSafeAreaInsets();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={getStandardScreenOptions({ screenOptions, navigation })}
    >
      <Stack.Screen
        name="AccountNav"
        component={AccountNav}
        options={headerNavOptions}
      />
      <Stack.Screen name="ProfileStack" component={ProfileStack} />
      <Stack.Screen name="WalletStack" component={WalletStack} />
      <Stack.Screen name="TransactionStack" component={TransactionStack} />
      <Stack.Screen name="PointsStack" component={PointsStack} />
      <Stack.Screen
        name="NotificationSettings"
        component={NotificationSettings}
      />
      <Stack.Screen name="ThemeSettings" component={ThemeSettings} />
      <Stack.Screen name="Faq" component={Faq} />
      <Stack.Screen name="Settings" component={Settings} />
      <Stack.Screen name="TutorialReset" component={TutorialReset} />
      <Stack.Screen name="About" component={About} />
      <Stack.Screen name="Logout" component={Logout} />
      <Stack.Screen name="Contact" component={Contact} />
      <Stack.Screen name="SdkAccessRequest" component={SdkAccessRequest} />
      <Stack.Screen
        name="Activity" //TODO: MOve Activity, Watchlist and Notifications to it's own Stack
        component={Activity}
        options={{
          ...headerNavOptions,
          headerBackTitleVisible: false,
          animationEnabled: false,
        }}
      />
      <Stack.Screen
        name="WatchList"
        component={WatchList}
        options={{
          ...headerNavOptions,
          headerBackTitleVisible: false,
          animationEnabled: false,
        }}
      />
      <Stack.Screen
        name="Notifications"
        component={Notifications}
        options={{
          ...headerNavOptions,
          headerBackTitleVisible: false,
          animationEnabled: false,
        }}
      />
      <Stack.Screen
        name="ListingModalStack"
        component={ListingModalStack}
        options={getPartialModalOptions({ offset: insets.top })}
      />
      <Stack.Screen name="DepositStack" component={DepositStack} />
    </Stack.Navigator>
  );
};
