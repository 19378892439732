import { createStackNavigator } from "@react-navigation/stack";
import NavHeader from "Components/NavHeader";
import { useLayoutEffect } from "react";

import Listings from "Screens/Listings";
import Search from "Screens/Search";
import ListingModalStack from "./ListingModalStack";

import Activity from "Screens/Activity";
import Notifications from "Screens/Notifications";
import WatchList from "Screens/WatchList";
import DepositStack from "./DepositStack";
import { modalOptions } from "./index";
import PointsStack from "./PointsStack";
import ProfileStack from "./ProfileStack";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={{
        ...screenOptions,
        header: NavHeader,
        headerBackTitleVisible: false,
      }}
    >
      <Stack.Screen name="Listings" component={Listings} />
      <Stack.Screen name="Search" component={Search} />
      <Stack.Screen
        name="ListingModalStack"
        component={ListingModalStack}
        options={modalOptions}
      />

      <Stack.Screen name="Activity" component={Activity} />
      <Stack.Screen name="WatchList" component={WatchList} />
      <Stack.Screen name="Notifications" component={Notifications} />

      <Stack.Screen name="ProfileStack" component={ProfileStack} />
      <Stack.Screen name="PointsStack" component={PointsStack} />
      <Stack.Screen name="DepositStack" component={DepositStack} />
    </Stack.Navigator>
  );
};
