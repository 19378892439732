import { List, Text, useTheme, Switch } from "react-native-paper";
import Screen from "Components/Screen";
import { useContext, useCallback, useMemo } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { activityConfig, activityTitles } from "shared/Config";
import { View } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import User from "shared/api/User";
import { useMutation, useQuery } from "@apollo/client";
import SpacerView from "Components/Spacer";

export default ({ navigation }) => {
  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);

  const methods = useMemo(
    () => ({
      emailNotifications: JSON.parse(currentUser?.emailNotifications ?? "{}"),
      pushNotifications: JSON.parse(currentUser?.pushNotifications ?? "{}"),
    }),
    [currentUser]
  );

  const [updatePreferences] = useMutation(User.mutations.update, {
    refetchQueries: [
      {
        query: User.queries.get,
        variables: {
          id: currentUser?.id,
          withPrivate: true,
        },
      },
    ],
    variables: {
      input: {
        id: currentUser?.id,
      },
    },
  });

  const { data: { getUser } = {} } = useQuery(User.queries.get, {
    variables: {
      id: currentUser?.id,
      withPrivate: true,
    },
  });

  const handleChange = useCallback(
    (method, type, checked) => () =>
      updatePreferences({
        optimisticResponse: {
          __typename: "Mutation",
          updateUser: {
            ...getUser,
            [method]: JSON.stringify({
              ...methods[method],
              [type]: !!checked ? "YES" : "NO",
            }),
            __typename: "User",
          },
        },
        variables: {
          input: {
            id: getUser?.id,
            [method]: JSON.stringify({
              ...methods[method],
              [type]: !!checked ? "YES" : "NO",
            }),
          },
        },
      }),
    [updatePreferences, getUser, methods]
  );

  const handleToggle = useCallback(
    (field, on) => () =>
      updatePreferences({
        optimisticResponse: {
          __typename: "Mutation",
          updateUser: {
            ...getUser,
            [field]: !!on,
            __typename: "User",
          },
        },
        variables: {
          input: {
            id: getUser?.id,
            [field]: !!on,
          },
        },
      }),
    [updatePreferences, getUser]
  );

  return (
    <Screen
      title="Notification Settings"
      fullHeight
      fullWidth
      hasScroll
      backgroundColor={theme.colors.surface}
    >
      <List.Item
        title="Show Badges"
        right={() => (
          <Switch
            value={!getUser?.hideNotificationsCount}
            color={theme.colors.primary}
            onValueChange={handleToggle(
              "hideNotificationsCount",
              !getUser?.hideNotificationsCount
            )}
          />
        )}
      />

      <List.Item
        title="Allow Push Notifications"
        right={() => (
          <Switch
            value={!!getUser?.allowPushNotifications}
            color={theme.colors.primary}
            onValueChange={handleToggle(
              "allowPushNotifications",
              !getUser?.allowPushNotifications
            )}
          />
        )}
      />
      {Object.entries(activityConfig)
        .filter(
          ([category, items]) =>
            !!items.filter(
              item =>
                !!Object.values(item).find(
                  config => !!config.EMAIL?.mutable || !!config.PUSH?.mutable
                )
            ).length
        )
        .map(([category, items]) => (
          <List.Section key={category}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                marginBottom: -2 * theme.spacing.xl,
              }}
            >
              <Text style={{ marginHorizontal: theme.spacing.sm }}>Email</Text>
              <Text style={{ marginHorizontal: theme.spacing.sm }}>Push</Text>
            </View>
            <List.Subheader>{activityTitles[category]}</List.Subheader>
            {items
              .filter(
                item =>
                  !!Object.values(item).find(
                    config => !!config.EMAIL?.mutable || !!config.PUSH?.mutable
                  )
              )
              .map(item => Object.entries(item))
              .flat(1)
              .map(([slug, config]) => (
                <List.Item
                  style={{ paddingVertical: 0 }}
                  key={slug}
                  title={
                    <View
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "flex-end",
                        paddingBottom: theme.spacing.xs,
                        borderBottomColor: theme.colors.border,
                        borderBottomWidth: 1,
                        borderStyle: "solid",
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text>{config.description}</Text>
                      </View>
                      {
                        !!config.EMAIL?.mutable ? (
                          <MaterialCommunityIcons
                            name={
                              methods.emailNotifications[slug] !== "YES"
                                ? "checkbox-blank-outline"
                                : "checkbox-marked"
                            }
                            size={24}
                            color={
                              methods.emailNotifications[slug] !== "YES"
                                ? theme.colors.text
                                : theme.colors.primary
                            }
                            onPress={handleChange(
                              "emailNotifications",
                              slug,
                              methods.emailNotifications[slug] !== "YES"
                            )}
                          />
                        ) : (
                          <SpacerView size={24} />
                        )
                        //<Checkbox onChange={({target: {checked}}) => handleChange('emailNotifications', slug, !!checked)} checked={methods.emailNotifications[slug] === "YES"} color="primary" />
                      }
                      <SpacerView size={24} />
                      {
                        !!config.PUSH?.mutable ? (
                          <MaterialCommunityIcons
                            name={
                              methods.pushNotifications[slug] !== "YES"
                                ? "checkbox-blank-outline"
                                : "checkbox-marked"
                            }
                            size={24}
                            color={
                              methods.pushNotifications[slug] !== "YES"
                                ? theme.colors.text
                                : theme.colors.primary
                            }
                            onPress={handleChange(
                              "pushNotifications",
                              slug,
                              methods.pushNotifications[slug] !== "YES"
                            )}
                          />
                        ) : (
                          <SpacerView size={24} />
                        )
                        //<Checkbox onChange={({target: {checked}}) => handleChange('pushNotifications', slug, !!checked)} checked={methods.pushNotifications[slug] === "YES"} color="primary" />
                      }
                    </View>
                  }
                />
              ))}
          </List.Section>
        ))}
    </Screen>
  );
};
