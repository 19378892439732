import FilterScreen from "Components/FilterScreen";
import ScreenNav from "Components/Screen/Nav";
import Spacer from "Components/Spacer";
import React, { useState, useCallback, useMemo, useContext } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { useTheme } from "react-native-paper";
import { useWindowDimensions, View } from "react-native";
import { useQuery } from "@apollo/client";
import Sport from "shared/api/Sport";
import { CurrentUserContext } from "Contexts/CurrentUser";

export default ({
  navigation,
  selected,
  onChange,
  filterItems,
  onSportChange,
  clearSportRef,
}) => {
  const theme = useTheme();
  const height = useWindowDimensions().height;

  const [selectedValue, setSelectedValue] = React.useState("updatedAt-desc");
  const [selectedSportValue, setSelectedSportValue] = React.useState(null);

  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [showSportsBottomSheet, setShowSportsBottomSheet] = useState(false);

  const currentUser = useContext(CurrentUserContext);

  const { data: { listSports: { items: sportsUnsorted = [] } = {} } = {} } =
    useQuery(Sport.queries.list, {
      variables: {
        limit: 50,
      },
    });

  const sports = useMemo(
    () =>
      sportsUnsorted
        .slice()
        .sort((a, b) => (a.position > b.position ? 1 : -1))
        .map(sport => ({
          label: sport.name,
          value: sport.id,
        })),
    [sportsUnsorted]
  );

  const handleBottomSheetDismiss = useCallback(
    () => setShowBottomSheet(false),
    []
  );

  const handleFilterButtonPress = useCallback(
    () => setShowBottomSheet(true),
    []
  );

  const handleSportsFilterButtonPress = useCallback(
    () => setShowSportsBottomSheet(true),
    []
  );

  const handleSportsBottomSheetDismiss = useCallback(
    () => setShowSportsBottomSheet(false),
    []
  );

  const handleSportValueChange = useCallback(
    value => [
      setSelectedSportValue(value),
      setShowSportsBottomSheet(false),
      !!onSportChange && onSportChange(value),
    ],
    [onSportChange]
  );

  const handleValueChange = useCallback(
    value => [
      setSelectedValue(value),
      setShowBottomSheet(false),
      onChange(value),
    ],
    [onChange]
  );

  if (!!clearSportRef) {
    clearSportRef.current = {
      clear: () => setSelectedSportValue(null),
    };
  }

  return (
    <>
      <ScreenNav
        itemLeft={
          <MaterialCommunityIcons
            name={
              !!selectedSportValue
                ? "text-box-search"
                : "text-box-search-outline"
            }
            size={18}
            color={theme.colors.onSurface}
            onPress={handleSportsFilterButtonPress}
          />
        }
        itemRight={
          /My Bids/.test(selected) ? (
            <Spacer />
          ) : (
            <MaterialCommunityIcons
              name="filter-variant"
              size={18}
              color={theme.colors.onSurface}
              onPress={handleFilterButtonPress}
            />
          )
        }
        theme={theme}
        selectedValue={selected}
        buttons={[
          {
            label: "For Sale",
            onPress: () => navigation.push("ForSaleModalStack"),
          },
          {
            label: "Not For Sale",
            onPress: () => navigation.push("NotForSaleModalStack"),
          },
          {
            label: `My Bids (${currentUser?.pendingBids?.length ?? 0})`,
            onPress: () => navigation.push("MyBidsModalStack"),
          },
        ]}
      />
      <View style={{ height: theme.spacing.xs / 2 }} />
      <FilterScreen
        items={filterItems}
        theme={theme}
        visible={showBottomSheet}
        onDismiss={handleBottomSheetDismiss}
        title="Sort By"
        selectedValue={selectedValue}
        onValueChange={handleValueChange}
      />
      <FilterScreen
        limitHeight={height - 80}
        items={sports}
        theme={theme}
        visible={showSportsBottomSheet}
        onDismiss={handleSportsBottomSheetDismiss}
        title="Filter By"
        selectedValue={selectedSportValue}
        onValueChange={handleSportValueChange}
      />
    </>
  );
};
