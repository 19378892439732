import { useQuery } from "@apollo/client";

import Transaction from "shared/api/Transaction";

export default user => {
  const {
    error: achError,
    data: { transactionsByUserIdAndType: { items: achItems = [] } = {} } = {},
  } = useQuery(Transaction.queries.listByUserIdAndType, {
    variables: {
      skip: !user?.id,
      transactionUserId: user?.id,
      type: {
        eq: "ACH_STANDARD",
      },
      limit: 200,
    },
  });

  const {
    error: checkError,
    data: { transactionsByUserIdAndType: { items: checkItems = [] } = {} } = {},
  } = useQuery(Transaction.queries.listByUserIdAndType, {
    variables: {
      skip: !user?.id,
      transactionUserId: user?.id,
      type: {
        eq: "CHECK",
      },
      limit: 200,
    },
  });

  const achTotal = achItems
    .map(item => item.amount)
    .reduce((total, currentValue) => total + currentValue, 0);
  const checkTotal = checkItems
    .map(item => item.amount)
    .reduce((total, currentValue) => total + currentValue, 0);

  return Math.abs(achTotal + checkTotal);
};
