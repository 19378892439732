import React from "react";

import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import NotForSale from "Screens/NotForSale";
import ListingModalStack from "./ListingModalStack";
import { getPlainOptions, headerNavOptions, modalOptions } from "./index";
import ListingLearn from "Screens/ListingLearn";
import { useTheme } from "react-native-paper";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  const theme = useTheme();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={{
        ...screenOptions,
        ...headerNavOptions,
      }}
    >
      <Stack.Screen
        name="NotForSale"
        component={NotForSale}
        options={{
          title: "My Tickets Not For Sale",
        }}
      />
      <Stack.Screen
        name="ListingLearn"
        component={ListingLearn}
        options={({ navigation }) => getPlainOptions({ navigation, theme })}
      />
      <Stack.Screen
        name="ListingModalStack"
        component={ListingModalStack}
        options={modalOptions}
      />
    </Stack.Navigator>
  );
};
