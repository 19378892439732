import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path d="M0 0h20v20H0z" />
        <Path
          fill={props?.color ?? "#666"}
          d="M11.572 1c1.407 0 2.552 1.1 2.552 2.453v.986c1.898.555 2.506 1.784 2.672 2.764.306 1.804-.649 3.785-1.245 4.805-.843 1.444-1.942 2.676-2.912 3.278v2.607c0 .58-.49 1.05-1.094 1.05H5.58c-.603 0-1.094-.47-1.094-1.05v-2.711c-.622-1.065-1.054-2.72-1.283-4.924-.168-1.61-.196-3.226-.201-4.2L3 3.453C3 2.101 4.145 1 5.552 1zm-.027 13.738H5.58a.359.359 0 00-.365.35v2.805c0 .193.164.35.365.35h5.966a.358.358 0 00.365-.35v-.35H7.82a.358.358 0 01-.365-.351V15.79c0-.194.163-.35.364-.35h4.09v-.351a.357.357 0 00-.364-.35zm.365 1.402H8.184v.701h3.726v-.7zm1.455-11.16c-1.18-.222-1.87.326-2.015.93-.091.383.022.795.32 1.158.372.455 1.005.806 1.83 1.014.194.05.31.241.26.428a.366.366 0 01-.446.25 6.011 6.011 0 01-.436-.128c-.89.956-2.121 1.48-3.494 1.48H7.74a4.793 4.793 0 01-3.966-2.063c.086 2.014.339 4.579 1.077 6.256.193-.166.448-.268.728-.268h5.966c.394 0 .74.202.933.503 1.77-1.273 4.006-4.819 3.598-7.224-.211-1.252-1.124-2.038-2.711-2.336zm-3.509 5.955a.374.374 0 01.502.115.343.343 0 01-.12.482l-.98.578.98.577a.343.343 0 01.12.482.37.37 0 01-.311.167.371.371 0 01-.19-.051l-1.295-.764-1.294.764a.377.377 0 01-.502-.116.344.344 0 01.12-.482l.98-.577-.98-.578a.343.343 0 01-.12-.482.374.374 0 01.502-.115l1.294.764zM11.572 1.7h-6.02c-.746 0-1.388.433-1.67 1.051h1.644c.201 0 .365.157.365.35a.358.358 0 01-.365.351H3.73v2.103c0 2.126 1.8 3.855 4.01 3.855h1.645c1.076 0 2.048-.379 2.782-1.076a3.356 3.356 0 01-1.07-.833c-.436-.533-.598-1.154-.456-1.75.124-.519.468-.955.97-1.227.5-.271 1.115-.357 1.785-.252v-.82c0-.966-.818-1.752-1.823-1.752zM7.167 2.752c.2 0 .364.157.364.35a.358.358 0 01-.364.351l-.074-.007a.354.354 0 01-.291-.343c0-.194.163-.35.365-.35z"
        />
        <Path
          fill={props?.color ?? "#666"}
          d="M11.572 1c1.407 0 2.552 1.1 2.552 2.453v.986c1.898.555 2.506 1.784 2.672 2.764.306 1.804-.649 3.785-1.245 4.805-.843 1.444-1.942 2.676-2.912 3.278v2.607c0 .58-.49 1.05-1.094 1.05H5.58c-.603 0-1.094-.47-1.094-1.05v-2.711c-.622-1.065-1.054-2.72-1.283-4.924-.168-1.61-.196-3.226-.201-4.2L3 3.453C3 2.101 4.145 1 5.552 1zm-.027 13.738H5.58a.359.359 0 00-.365.35v2.805c0 .193.164.35.365.35h5.966a.358.358 0 00.365-.35v-.35H7.82a.358.358 0 01-.365-.351V15.79c0-.194.163-.35.364-.35h4.09v-.351a.357.357 0 00-.364-.35zm.365 1.402H8.184v.701h3.726v-.7zm1.455-11.16c-1.18-.222-1.87.326-2.015.93-.091.383.022.795.32 1.158.372.455 1.005.806 1.83 1.014.194.05.31.241.26.428a.366.366 0 01-.446.25 6.011 6.011 0 01-.436-.128c-.89.956-2.121 1.48-3.494 1.48H7.74a4.793 4.793 0 01-3.966-2.063c.086 2.014.339 4.579 1.077 6.256.193-.166.448-.268.728-.268h5.966c.394 0 .74.202.933.503 1.77-1.273 4.006-4.819 3.598-7.224-.211-1.252-1.124-2.038-2.711-2.336zm-3.509 5.955a.374.374 0 01.502.115.343.343 0 01-.12.482l-.98.578.98.577a.343.343 0 01.12.482.37.37 0 01-.311.167.371.371 0 01-.19-.051l-1.295-.764-1.294.764a.377.377 0 01-.502-.116.344.344 0 01.12-.482l.98-.577-.98-.578a.343.343 0 01-.12-.482.374.374 0 01.502-.115l1.294.764zM11.572 1.7h-6.02c-.746 0-1.388.433-1.67 1.051h1.644c.201 0 .365.157.365.35a.358.358 0 01-.365.351H3.73v2.103c0 2.126 1.8 3.855 4.01 3.855h1.645c1.076 0 2.048-.379 2.782-1.076a3.356 3.356 0 01-1.07-.833c-.436-.533-.598-1.154-.456-1.75.124-.519.468-.955.97-1.227.5-.271 1.115-.357 1.785-.252v-.82c0-.966-.818-1.752-1.823-1.752zM7.167 2.752c.2 0 .364.157.364.35a.358.358 0 01-.364.351l-.074-.007a.354.354 0 01-.291-.343c0-.194.163-.35.365-.35z"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
