import Svg, { Defs, G, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 255 255"
      {...props}
      height={props.size}
      width={props.size}
    >
      <Defs />
      <G id="Layer_2" data-name="Layer 2">
        <G id="Layer_1-2" data-name="Layer 1">
          <Path
            className="cls-1"
            fill={props?.color ?? "#666"}
            d="M111.81 75.62a14.62 14.62 0 01-5.4 11.5A92.64 92.64 0 0079 83c-1.26 0-2.51 0-3.75.08l1.22-4.74a29.93 29.93 0 0014.1 3.74c4.93 0 8.24-2 8.24-5.27s-2.72-5.26-9.09-7.39c-9.09-3-15.29-7.3-15.29-15.54 0-7.56 5.27-13.42 14.36-15.12v-8.07h8.32v7.48a30.68 30.68 0 0112.4 2.8l-2.51 9.6a26.48 26.48 0 00-12.24-3c-5.52 0-7.3 2.46-7.3 4.84 0 2.71 3 4.58 10.28 7.22 10.07 3.59 14.07 8.27 14.07 15.99z"
          />
          <Path
            className="cls-1"
            fill={props?.color ?? "#666"}
            d="M156.72 51.65a77.89 77.89 0 00-16.05 10.25 46.5 46.5 0 10-88.42 25 90.3 90.3 0 00-16.32 6.7 64.51 64.51 0 11120.79-41.95z"
          />
          <Path
            className="cls-1"
            fill={props?.color ?? "#666"}
            d="M111.81 75.62a14.62 14.62 0 01-5.4 11.5A92.64 92.64 0 0079 83c-1.26 0-2.51 0-3.75.08l1.22-4.74a29.93 29.93 0 0014.1 3.74c4.93 0 8.24-2 8.24-5.27s-2.72-5.26-9.09-7.39c-9.09-3-15.29-7.3-15.29-15.54 0-7.56 5.27-13.42 14.36-15.12v-8.07h8.32v7.48a30.68 30.68 0 0112.4 2.8l-2.51 9.6a26.48 26.48 0 00-12.24-3c-5.52 0-7.3 2.46-7.3 4.84 0 2.71 3 4.58 10.28 7.22 10.07 3.59 14.07 8.27 14.07 15.99zM255 122.5a64.53 64.53 0 01-83.36 61.69q.36-4 .36-8.19a92 92 0 00-2.48-21.33 50 50 0 0014.54 2.87V168H195v-11.34c12.66-2.1 19.49-10.47 19.49-20.27 0-10-5.18-16.08-18.28-20.7-9.47-3.42-13.33-5.84-13.33-9.37 0-3.08 2.31-6.27 9.47-6.27a34.29 34.29 0 0115.86 3.85l3.31-12.44a39.71 39.71 0 00-16.08-3.64v-9.69h-10.8v10.46c-11.81 2.2-18.64 9.8-18.64 19.61 0 10.68 8 16.19 19.83 20.15 8.26 2.76 11.78 5.4 11.78 9.58s-4.29 6.83-10.68 6.83a39 39 0 01-18.29-4.84l-1.58 6.15a93.61 93.61 0 00-36.5-47.43c.57-1.44 1.19-2.84 1.86-4.22a63.33 63.33 0 014.46-7.77A64.5 64.5 0 01255 122.5z"
          />
          <Path
            className="cls-1"
            fill={props?.color ?? "#666"}
            d="M97.71 59.66C90.4 57 87.43 55.15 87.43 52.44c0-2.38 1.78-4.84 7.3-4.84a26.48 26.48 0 0112.24 3l2.54-9.6a30.68 30.68 0 00-12.4-2.8v-7.51h-8.32v8.07c-9.09 1.7-14.36 7.56-14.36 15.12 0 8.24 6.2 12.49 15.29 15.54 6.37 2.13 9.09 4.17 9.09 7.39s-3.31 5.27-8.24 5.27a29.93 29.93 0 01-14.1-3.74l-1.22 4.74C76.49 83 77.74 83 79 83a92.64 92.64 0 0127.41 4.12 14.62 14.62 0 005.4-11.5c0-7.72-4-12.4-14.1-15.96zM103.41 189.84c0 9.66-6.73 17.91-19.22 20V221H73.44v-10.31c-7.27-.33-14.33-2.39-18.44-4.69l3.26-12.71a38.32 38.32 0 0018 4.78c6.3 0 10.53-2.5 10.53-6.73s-3.47-6.73-11.62-9.45C63.56 178 55.64 172.57 55.64 162c0-9.66 6.73-17.16 18.35-19.33V132.4h10.64v9.6a39.28 39.28 0 0115.85 3.59l-3.26 12.27A33.65 33.65 0 0081.59 154c-7.06 0-9.34 3.14-9.34 6.19 0 3.47 3.8 5.86 13.14 9.22 12.92 4.59 18.02 10.59 18.02 20.43z"
          />
          <Path
            className="cls-1"
            fill={props?.color ?? "#666"}
            d="M131.29 116.77c-1.48-1.31-3-2.56-4.6-3.75a76.15 76.15 0 00-6.33-4.34c-.68-.42-1.37-.83-2.06-1.22q-2.14-1.24-4.36-2.33A78.62 78.62 0 0096.77 99a79.67 79.67 0 00-36.29.19 77.64 77.64 0 00-16.89 6.17 79 79 0 10112.56 87.71c.52-2.36.94-4.77 1.24-7.21s.51-5.08.57-7.67V176a78.84 78.84 0 00-26.67-59.23zm3.71 61.17a56.46 56.46 0 11-28.13-50.33 59.72 59.72 0 015.66 3.76 56.82 56.82 0 018.49 7.91 56.24 56.24 0 0113.1 27.1 57.08 57.08 0 01.9 10.11c-.02.51-.02.97-.02 1.45z"
          />
          <Path
            className="cls-1"
            fill={props?.color ?? "#666"}
            d="M103.41 189.84c0 9.66-6.73 17.91-19.22 20V221H73.44v-10.31c-7.27-.33-14.33-2.39-18.44-4.69l3.26-12.71a38.32 38.32 0 0018 4.78c6.3 0 10.53-2.5 10.53-6.73s-3.47-6.73-11.62-9.45C63.56 178 55.64 172.57 55.64 162c0-9.66 6.73-17.16 18.35-19.33V132.4h10.64v9.6a39.28 39.28 0 0115.85 3.59l-3.26 12.27A33.65 33.65 0 0081.59 154c-7.06 0-9.34 3.14-9.34 6.19 0 3.47 3.8 5.86 13.14 9.22 12.92 4.59 18.02 10.59 18.02 20.43z"
          />
          <Path
            className="cls-1"
            fill={props?.color ?? "#666"}
            d="M85.39 169.42c-9.34-3.36-13.14-5.75-13.14-9.22 0-3 2.28-6.19 9.34-6.19a33.65 33.65 0 0115.63 3.8l3.26-12.27A39.28 39.28 0 0084.63 142v-9.6H74v10.31c-11.62 2.17-18.35 9.67-18.35 19.33 0 10.53 7.92 16 19.54 19.87 8.15 2.72 11.62 5.32 11.62 9.45s-4.23 6.73-10.53 6.73a38.32 38.32 0 01-18-4.78L55 206c4.13 2.28 11.19 4.34 18.46 4.67V221h10.73v-11.18c12.49-2.07 19.22-10.32 19.22-20 0-9.82-5.1-15.82-18.02-20.4z"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
