const wreath = require("assets/wreath.png");
const logoHeaderLight = require("assets/logo-header-light.png");
const logoHeaderDark = require("assets/logo-header-dark.png");
const larry = require("assets/larry-new.png");
const logoFull = require("assets/propSwapLogoFull.png");
const logoFullDark = require("assets/propSwapLogoFullDark.png");

// const promoLight = require("assets/qOwhyN5y.png");
// const promoDark = require("assets/qOwhyN5y.png");

// const promoLight = require("assets/promoLight.jpg");
// const promoDark = require("assets/promoDark.jpg");

const promoLight = require("assets/banner-2-white.png");
const promoDark = require("assets/banner-2-white.png");

const promoRightLight = require("assets/promo-right-light.png");
const promoRightDark = require("assets/promo-right-dark.png");

const luke = require("assets/luke.jpg");
const ian = require("assets/ian.jpg");

export default [
  wreath,
  logoFull,
  logoFullDark,
  logoHeaderLight,
  larry,
  promoLight,
  promoDark,
  luke,
  ian,
  logoHeaderDark,
  promoRightLight,
  promoRightDark,
];

export {
  wreath,
  logoFull,
  logoHeaderLight,
  logoFullDark,
  larry,
  promoLight,
  promoDark,
  luke,
  ian,
  logoHeaderDark,
  promoRightLight,
  promoRightDark,
};
