// const gcd = (x, y) => {
//   x = Math.abs(x);
//   y = Math.abs(y);
//   while(y) {
//     var t = y;
//     y = x % y;
//     x = t;
//   }
//   return x;
// }

const formatOdds = (collectAmount, betAmount) => {
  const collect = parseFloat(collectAmount);
  const bet = parseFloat(betAmount);
  const odds = (collect - bet) / bet;

  if (odds < 1.0) {
    return isFinite(parseFloat((1.0 / odds) * -100))
      ? parseFloat((1.0 / odds) * -100).toFixed(2)
      : "";
  } else if (isNaN(odds) || !isFinite(odds)) {
    return "";
  }

  return `${odds.toFixed(2)}/1`;
};

export default formatOdds;
