import { useQuery } from "@apollo/client";
import { useNavigation } from "@react-navigation/core";
import { CurrentUserContext } from "Contexts/CurrentUser";
import useStyles from "Hooks/useStyles";
import { useContext, useCallback, useMemo } from "react";
import Activity from "shared/api/Activity";
import Listing from "shared/api/Listing";
import Ticket from "shared/api/Ticket";

import TicketItemStyles from "./TicketItemStyles";
import TicketItemView from "./TicketItemView";

export default ({
  ticketObj,
  ticketId,
  listingObj,
  listingId,
  activityObj,
  activityId,
  isFeatured,
  view,
}) => {
  const currentUser = useContext(CurrentUserContext);
  const { theme, styles } = useStyles({ Styles: TicketItemStyles });
  const navigation = useNavigation();

  const { data: { getListing } = {} } = useQuery(Listing.queries.get, {
    skip: !listingId,
    pollInterval: 6000,
    variables: {
      id: listingId,
    },
  });

  const { data: { getTicket } = {} } = useQuery(Ticket.queries.get, {
    skip: !ticketId,
    variables: {
      id: ticketId,
    },
  });

  const { data: { getActivity } = {} } = useQuery(Activity.queries.get, {
    skip: !activityId,
    variables: {
      id: activityId,
    },
  });

  const memoizedTicket = useMemo(
    () => getTicket ?? ticketObj,
    [getTicket, ticketObj]
  );
  const memoizedListing = useMemo(
    () => getListing ?? listingObj,
    [getListing, listingObj]
  );
  const memoizedActivity = useMemo(
    () => getActivity ?? activityObj,
    [getActivity, activityObj]
  );

  const handleTicketPress = useCallback(
    ({ listingId }) =>
      () =>
        navigation.push("ListingModalStack", {
          screen: "ListingDetail",
          params: { id: listingId },
        }),
    [navigation]
  );

  return !memoizedTicket ? null : (
    <TicketItemView
      ticket={memoizedTicket}
      listing={memoizedListing}
      activity={memoizedActivity}
      isFeatured={isFeatured}
      view={view}
      currentUser={currentUser}
      theme={theme}
      onPress={handleTicketPress}
      styles={styles}
    />
  );
};
