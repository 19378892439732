import Screen from "Components/Screen";
import { Platform, View } from "react-native";
import TicketFeaturedList from "Components/Ticket/FeaturedList";
import TicketPersonalizedList from "Components/Ticket/PersonalizedList";
import { Headline, Paragraph, useTheme } from "react-native-paper";
import { useState, useCallback, useContext } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import LinkList from "Components/Link/List";
import LoginAward from "Components/LoginAward";
import AdList from "Components/Ad/List";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation }) => {
  const theme = useTheme();
  const [showPeronsalizedTickets, setShowPersonalizedTickets] = useState(false);
  const [linkCount, setLinkCount] = useState(null);
  const [adCount, setAdCount] = useState(null);
  const handleFetchPersonalizedList = useCallback(
    items => setShowPersonalizedTickets(!!items?.length),
    []
  );
  const currentUser = useContext(CurrentUserContext);
  const handleFetchLinks = useCallback(
    items => setLinkCount(items?.length ?? null),
    []
  );
  const handleFetchAds = useCallback(
    items => setAdCount(items?.length ?? null),
    []
  );

  return (
    <Screen fullWidth fullHeight hasScroll title="Home">
      {!!currentUser?.shouldCreateActivity &&
        !currentUser?.hideActivePointsPopup &&
        false && ( //DISABLED FOR NOW
          <LoginAward />
        )}
      <View
        style={{
          marginVertical: theme.spacing.xl,
          ...Platform.select({
            web: {
              marginHorizontal: theme.spacing.xl,
            },
            default: {},
          }),
        }}
      >
        <Headline
          style={{
            ...Platform.select({
              default: {
                marginHorizontal: theme.spacing.xl,
              },
              web: {},
            }),
          }}
        >
          Featured Tickets
        </Headline>
        <TicketFeaturedList />
      </View>
      {!!currentUser &&
        !!currentUser?.id &&
        !!currentUser?.groups.includes("Admins") &&
        !!showPeronsalizedTickets && (
          <View
            style={{
              marginVertical: theme.spacing.xl,
              ...Platform.select({
                web: {
                  marginHorizontal: theme.spacing.xl,
                },
                default: {},
              }),
            }}
          >
            <Headline
              style={{
                ...Platform.select({
                  default: {
                    marginHorizontal: theme.spacing.xl,
                  },
                  web: {},
                }),
              }}
            >
              Picks for U
            </Headline>
            <TicketPersonalizedList
              userId={currentUser?.id}
              onFetchItems={handleFetchPersonalizedList}
              horizontal
            />
          </View>
        )}

      <View
        style={{
          marginVertical: theme.spacing.xl,
          ...Platform.select({
            web: {
              marginHorizontal: theme.spacing.xl,
            },
            default: {},
          }),
        }}
      >
        <Headline
          style={{
            ...Platform.select({
              default: {
                marginHorizontal: theme.spacing.xl,
              },
              web: {},
            }),
          }}
        >
          Promo
        </Headline>
        <AdList adCount={adCount} onFetchItems={handleFetchAds} />
      </View>

      {(linkCount > 0 || linkCount === null) && (
        <View
          style={{
            marginVertical: theme.spacing.xl,
            ...Platform.select({
              web: {
                marginHorizontal: theme.spacing.xl,
              },
              default: {},
            }),
          }}
        >
          <Headline
            style={{
              ...Platform.select({
                default: {
                  marginHorizontal: theme.spacing.xl,
                },
                web: {},
              }),
            }}
          >
            In The News
          </Headline>
          <LinkList linkCount={linkCount} onFetchItems={handleFetchLinks} />
        </View>
      )}

      <View style={{ margin: theme.spacing.xl }}>
        <Paragraph>
          Must be 21 years of age or older. Please bet responsibly. Gaming
          problem? Call 1-800-GAMBLER.
        </Paragraph>
      </View>
    </Screen>
  );
};
