import { MaterialCommunityIcons } from "@expo/vector-icons";
import { CardStyleInterpolators } from "@react-navigation/stack";
import BackButton from "Components/BackButton";
import NavHeader from "Components/NavHeader";
import { Platform, View } from "react-native";

const modalOptions = {
  headerShown: false,
  cardOverlayEnabled: true,
  animationEnabled: true,
  cardStyleInterpolator: CardStyleInterpolators.forModalPresentationIOS,
  cardStyle: {
    backgroundColor: "transparent",
  },
};

const headerNavOptions = {
  header: NavHeader,
  headerMode: "screen",
};

const getStandardScreenOptions = ({ screenOptions = {}, navigation }) => ({
  ...screenOptions,
  headerBackTitleVisible: false,
  headerTitleAlign: "center",
  headerLeft:
    Platform.OS === "web" && navigation.canGoBack() ? BackButton : undefined,
});

const getPartialModalOptions = ({ offset = 0 } = {}) => ({
  // presentation: 'transparentModal',
  headerShown: true,
  cardOverlayEnabled: true,
  animationEnabled: true,
  cardStyleInterpolator: CardStyleInterpolators.forModalPresentationIOS,
  cardStyle: {
    backgroundColor: "transparent",
    // marginTop: 80 + offset,
  },
});

const getPlainOptions = ({ navigation, theme }) => ({
  header: undefined,
  headerLeft: () => null,
  headerStyle: {
    height: 40,
    borderTopLeftRadius: theme.roundness,
    borderTopRightRadius: theme.roundness,
    backgroundColor: theme.colors.secondary,
    borderBottomWidth: 0,
  },
  headerTitleStyle: {
    ...theme.fonts.h4,
    color: theme.colors.textReverse,
    textTransform: "uppercase",
  },
  headerTitleAlign: "center",
  headerShown: true,
  cardOverlayEnabled: true,
  animationEnabled: true,
  cardStyleInterpolator: CardStyleInterpolators.forModalPresentationIOS,
  // cardShadowEnabled: false,
  cardStyle: {
    marginTop: 40,
    // backgroundColor: "transparent",
    borderTopLeftRadius: theme.roundness,
    borderTopRightRadius: theme.roundness,
    // overflow: "hidden"
  },
  headerRight: props => (
    <View style={{ marginRight: theme.spacing.xl }}>
      <MaterialCommunityIcons
        name="close"
        size={18}
        color={theme.colors.textReverse}
        onPress={() => navigation.goBack()}
      />
    </View>
  ),
});

export {
  getStandardScreenOptions,
  getPartialModalOptions,
  modalOptions,
  headerNavOptions,
  getPlainOptions,
};
