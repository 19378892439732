import React from "react";
import { useContext } from "react";

import { TicketContext } from "Contexts/Ticket";
import { useQuery } from "@apollo/client";
import Locale from "shared/api/Locale";
import useStyles from "Hooks/useStyles";
import LocaleSelectStyles from "./LocaleSelectStyles";
import LocaleSelectView from "./LocaleSelectView";

export default ({ onSubmit }) => {
  const { theme, styles } = useStyles({ Styles: LocaleSelectStyles });
  const { ticketValues } = useContext(TicketContext);

  const { data: { listLocales: { items = [] } = {} } = {} } = useQuery(
    Locale.queries.list,
    {
      variables: {
        limit: 100,
      },
    }
  );

  return (
    <LocaleSelectView
      theme={theme}
      styles={styles}
      onSubmit={onSubmit}
      items={items}
      ticketValues={ticketValues}
    />
  );
};
