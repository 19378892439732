import { MaterialCommunityIcons } from "@expo/vector-icons";
import BidHighBidLabel from "Components/Bid/HighBidLabel";
import Sports from "Components/Icons/Sports";
import ListingValueMeter from "Components/Listing/ValueMeter";
import WatchingIcon from "Components/Watching/Icon";
import moment from "moment";
import { View } from "react-native";
import { Caption, Card, Subheading, Text } from "react-native-paper";
import { activityLabelMap } from "shared/Config";
import { formatPennies } from "shared/Util/money";
import formatOdds from "Util/formatOdds";

//TODO merge commonalites with TicketDetails

const activityLabel = activity =>
  (activityLabelMap[activity.type] ?? activity.type).replace(
    / \- (Up|Down)/i,
    ""
  );

const expiresInLessThan2Hours = expiresAt =>
  !!expiresAt && moment(expiresAt).diff(moment(), "hours", true) < 2;

const TopRow = ({
  ticket,
  listing,
  activity,
  theme,
  isFeatured,
  currentUser,
  styles,
}) => (
  <View
    style={[
      {
        marginTop: !!isFeatured ? theme.spacing.lg * -1 : theme.spacing.md * -1,
        backgroundColor: !!isFeatured ? theme.colors.secondary : "transparent",
        marginHorizontal: !!isFeatured ? theme.spacing.lg * -1 : 0,
      },
      styles.topRow,
    ]}
  >
    {(listing?.status === "PENDING" || listing?.status === "SOLD") && (
      <View style={styles.soldWrapper}>
        <Text style={styles.soldText}>Sold</Text>
      </View>
    )}
    {!!activity && (
      <View style={styles.activityTypeWrapper}>
        <Text style={[{ textTransform: "capitalize" }, styles.textReverse]}>
          {activityLabel(activity)}
        </Text>
      </View>
    )}
    {!activity && (
      <View
        style={[
          styles.sportWrapper,
          {
            paddingLeft: !!isFeatured ? theme.spacing.lg : 0,
            paddingBottom: !!isFeatured ? theme.spacing.sm : 0,
          },
        ]}
      >
        <View style={styles.iconWrapper}>
          <Sports
            sportAbbrev={ticket.sport.abbrev}
            color={
              !!isFeatured ? theme.colors.textReverse : theme.colors.secondary
            }
          />
        </View>
        <Text
          style={{
            color: !!isFeatured
              ? theme.colors.textReverse
              : theme.colors.secondary,
            fontSize: theme.fonts.h4.fontSize,
            fontWeight: "bold",
          }}
        >
          {ticket.sport.abbrev}
        </Text>
      </View>
    )}
    {listing?.status === "PENDING" &&
      currentUser?.id === listing?.listingSellerId &&
      !activity && (
        <View style={styles.actionWrapper}>
          <Text style={styles.textPrimary}>Action Required</Text>
        </View>
      )}
    {listing?.status !== "PENDING" && (
      <View style={styles.ticketActions}>
        <View style={styles.ticketActionsWrapper}>
          {!!listing?.expiresAt && (
            <>
              <Caption
                style={{
                  color: !!expiresInLessThan2Hours(listing?.expiresAt)
                    ? theme.colors.error
                    : !!isFeatured
                    ? theme.colors.textReverse
                    : theme.colors.text,
                }}
              >
                {listing.expiresAt >= new Date().toISOString()
                  ? `${moment(listing.expiresAt).fromNow(true)} left`
                  : "Expired"}{" "}
              </Caption>
              <Caption
                style={{
                  color: !!isFeatured
                    ? theme.colors.textReverse
                    : theme.colors.text,
                }}
              >
                {" "}
                |{" "}
              </Caption>
            </>
          )}
          <Caption
            style={{
              color: !!isFeatured
                ? theme.colors.textReverse
                : theme.colors.text,
            }}
          >
            {ticket.views ?? 0} views
          </Caption>
        </View>
        <View
          style={{
            marginTop: !!isFeatured
              ? theme.spacing.xs * -1
              : theme.spacing.sm * -1,
            marginLeft: theme.spacing.md,
            marginRight: !!isFeatured ? theme.spacing.md : 0,
          }}
        >
          {!["SOLD", "PENDING"].includes(listing?.status) && (
            <WatchingIcon
              theme={theme}
              isFeatured={isFeatured}
              listing={listing}
            />
          )}
        </View>
      </View>
    )}
  </View>
);

const TicketInfo = ({ ticket, theme, styles }) => (
  <View style={styles.ticketInfo}>
    <Subheading style={styles.textTruncate}>
      {ticket.cachedBets?.length
        ? ticket.cachedBets
            .slice(0)
            .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
            .map(cb => cb.subject)
            .join(", ")
        : ticket.subject}
    </Subheading>
    <Text style={{ overflow: "hidden" }}>
      {!!ticket?.cachedBets?.length
        ? ticket.cachedBets
            .slice(0)
            .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
            .map(bet => bet.eventDescription)
            .join(", ")
        : ticket.eventDescription}
    </Text>
  </View>
);

const ListingInfo = ({ ticket, listing, activity, theme, styles }) => (
  <>
    {!["PENDING", "SOLD"].includes(listing?.status) && (
      <View style={styles.listingInfo}>
        <View style={styles.listingInfoBody}>
          <Text>
            {!!listing.askingPrice
              ? formatOdds(
                  ticket.collectAmount,
                  listing.salesPrice ?? listing.askingPrice
                )
              : ticket.odds}
          </Text>
          <Text
            style={{
              color:
                activity?.type === "LISTING_PRICE_DROP"
                  ? theme.colors.error
                  : activity?.type === "LISTING_PRICE_INCREASE"
                  ? theme.colors.primary
                  : theme.colors.text,
            }}
          >
            $
            {formatPennies(
              !!listing.askingPrice
                ? listing.salesPrice ?? listing.askingPrice
                : ticket.betAmount
            )}
          </Text>
          {activity?.type === "LISTING_PRICE_DROP" && (
            <MaterialCommunityIcons
              name="arrow-down"
              size={20}
              color={theme.colors.error}
            />
          )}
          {activity?.type === "LISTING_PRICE_INCREASE" && (
            <MaterialCommunityIcons
              name="arrow-up"
              size={20}
              color={theme.colors.primary}
            />
          )}
        </View>
        <View style={styles.listingMeterWrapper}>
          <ListingValueMeter
            disableValues={!!ticket?.disableValues}
            valueRating={listing?.valueRating}
          />
        </View>
      </View>
    )}

    {["PENDING", "SOLD"].includes(listing?.status) && (
      <View style={[styles.listingInfo, styles.row]}>
        <View style={styles.f1}>
          <Text style={styles.textBold}>
            Sold:{" "}
            <Text style={{ fontWeight: "normal" }}>
              ${formatPennies(listing.salesPrice ?? listing.askingPrice)}
            </Text>
          </Text>
          <Text style={styles.textBold}>
            Date:{" "}
            <Text style={{ fontWeight: "normal" }}>
              {moment(listing?.soldAt ?? listing?.updatedAt).format("MM/DD/YY")}
            </Text>
          </Text>
        </View>
      </View>
    )}
  </>
);

export default ({
  ticket,
  listing,
  activity,
  isFeatured,
  theme,
  onPress,
  currentUser,
  styles,
}) => (
  <Card onPress={onPress({ listingId: listing?.id })}>
    <Card.Content
      style={
        listing?.status === "PENDING" || listing?.status === "SOLD"
          ? {
              borderColor: theme.colors.primary,
              borderWidth: 2,
              borderStyle: "dashed",
            }
          : {}
      }
    >
      <TopRow
        ticket={ticket}
        listing={listing}
        activity={activity}
        theme={theme}
        isFeatured={isFeatured}
        currentUser={currentUser}
        styles={styles}
      />
      <View style={styles.row}>
        <TicketInfo
          listing={listing}
          ticket={ticket}
          theme={theme}
          currentUser={currentUser}
          styles={styles}
        />
        {!!listing && (
          <ListingInfo
            ticket={ticket}
            listing={listing}
            activity={activity}
            theme={theme}
            styles={styles}
          />
        )}
      </View>
      {currentUser?.id === listing?.listingSellerId &&
        listing?.status === "ACTIVE" && <BidHighBidLabel listing={listing} />}
      {listing?.status === "INACTIVE" &&
        currentUser?.id !== listing?.listingSellerId && (
          <Text style={styles.unlistedNotice}>
            This ticket has been unlisted by the seller.
          </Text>
        )}
    </Card.Content>
  </Card>
);
