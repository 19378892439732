import TicketItem from "Components/Ticket/Item";
import Listing from "shared/api/Listing";
import EmptySign from "Components/EmptySign";

import { useMemo, useCallback, useState, useEffect, useRef } from "react";

import DataList from "Components/DataList";
import { Platform, View, useWindowDimensions } from "react-native";
import { Button, useTheme } from "react-native-paper";

export default ({ onFetchItems, userId, horizontal }) => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const windowWidth = useWindowDimensions().width;
  const theme = useTheme();
  const maxWidth =
    Platform.OS === "web" ? Math.min(601, windowWidth) - 2 : windowWidth;
  const dataList = useRef();
  const [itemCount, setItemCount] = useState(0);

  const dataListQuery = useMemo(
    () => ({
      dataKey: "listRecommendedListingsByUser",
      query: Listing.queries.listRecommendedByUser,
      variables: {
        userId,
        limit: 4,
      },
    }),
    [userId]
  );

  const dataListRenderItem = useCallback(
    ({ item, index }) => (
      <View
        style={
          !horizontal
            ? {
                marginBottom: theme.spacing.md,
              }
            : {
                width: maxWidth, // - theme.spacing.xl * 2,
              }
        }
      >
        <View
          style={
            !horizontal
              ? {}
              : {
                  ...Platform.select({
                    web: {
                      marginRight: theme.spacing.xl * 2,
                    },
                    default: {
                      marginRight: theme.spacing.xl,
                      marginLeft: theme.spacing.xl,
                    },
                  }),
                }
          }
        >
          <TicketItem
            ticketObj={item.cachedTicket}
            listingObj={item}
            isFeatured={!!horizontal}
          />
        </View>
      </View>
    ),
    [maxWidth, horizontal, theme]
  );

  const handleFetchItems = useCallback(
    items => [
      !!onFetchItems && onFetchItems(items),
      setItemCount(items?.length ?? 0),
    ],
    [onFetchItems]
  );

  useEffect(() => {
    !!dataList.current &&
      currentIndex !== null &&
      dataList.current.scrollToIndex({ animated: true, index: currentIndex });
  }, [currentIndex, dataList]);

  return (
    <>
      {itemCount > 1 && !!horizontal && Platform.OS === "web" && (
        <View
          style={{
            marginTop: -1 * theme.spacing.lg * 2,
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={currentIndex <= 0}
            compact
            onPress={() => setCurrentIndex(currentIndex => currentIndex - 1)}
          >
            Prev
          </Button>
          <Button
            disabled={currentIndex >= itemCount - 1}
            compact
            onPress={() => setCurrentIndex(currentIndex => currentIndex + 1)}
          >
            Next
          </Button>
        </View>
      )}
      <DataList
        ref={dataList}
        onFetchItems={handleFetchItems}
        query={dataListQuery}
        renderItem={dataListRenderItem}
        horizontal={horizontal}
        pagingEnabled={!!horizontal && Platform.OS !== "web"}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={
          !!horizontal
            ? {}
            : {
                paddingTop: theme.spacing.xl,
                marginHorizontal: theme.spacing.xl,
              }
        }
        ListEmptyComponent={
          !horizontal && (
            <EmptySign
              title="Check back soon!"
              subheading="We have no recommendations for you right now."
            />
          )
        }
      />
    </>
  );
};
