export const formatValue = value =>
  /\.\d\d/.test(value)
    ? parseFloat(value).toFixed(2)
    : /\.\d/.test(value)
    ? parseFloat(value).toFixed(1)
    : value;

export const addCommas = subject =>
  (subject ?? "").toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

export const convertMoneyTextToPennies = text =>
  parseInt(
    parseFloat(
      parseFloat((text || "0").toString().replace(/[^\d\.]/g, "")).toFixed(2)
    ) * 100.0
  );

export const formatPennies = (amount, prefix = "") =>
  `${prefix}${formatMoney(parseFloat(amount || 0) / 100.0)}`.replace(
    /\$-/,
    "-$"
  );

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount || 0) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const scrubInput = amount =>
  (((amount ?? "").toString().match(/\./g) ?? []).length > 1
    ? (amount ?? "").toString().replace(/\./, "")
    : (amount ?? "").toString()
  )
    .replace(/[^\d\.]/g, "")
    .replace(/\.(\d\d)\d/g, ".$1");
