import { useTheme } from "react-native-paper";
import Screen from "Components/Screen";
import Faq from "Components/Faq";

export default () => {
  const theme = useTheme();

  return (
    <Screen
      title="FAQ"
      fullHeight
      hasScroll
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Faq />
    </Screen>
  );
};
