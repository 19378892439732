import { useContext, useState, useCallback, useEffect } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { gql, useMutation } from "@apollo/client";
import User from "shared/api/User";
import { convertMoneyTextToPennies } from "shared/Util/money";
import {
  PAPER_WITHDRAW_FEE,
  ACH_WITHDRAW_FEE,
  w9Threshold,
} from "shared/Config";
import InterchecksWithdrawSummaryView from "./InterchecksWithdrawSummaryView";
import Interchecks from "shared/api/Interchecks";
import useStyles from "Hooks/useStyles";
import InterchecksWithdrawSummaryStyles from "./InterchecksWithdrawSummaryStyles";
import useTotalWithdrawn from "Hooks/useTotalWithdrawn";
import { useIsFocused } from "@react-navigation/core";

const getErrorMessage = error => {
  let errorMessage = "";
  try {
    errorMessage =
      JSON.parse(error.message).error_message ||
      JSON.parse(error.message).recipient.payout_transaction.error_message;
  } catch (error) {
    errorMessage = `${error.code ?? ""} - ${error.message}`;
  }

  return /Invaild recipient data/i.test(errorMessage)
    ? "Oops! Looks like there was an error processing your request. Please try again."
    : errorMessage;
};

export default ({
  navigation,
  amount: amountFromParams,
  accountType: accountTypeFromParams,
  accountNumber: accountNumberFromParams,
  routingNumber: routingNumberFromParams,
  mailingAddress1: mailingAddress1FromParams,
  mailingAddress2: mailingAddress2FromParams,
  mailingCity: mailingCityFromParams,
  mailingState: mailingStateFromParams,
  mailingZip: mailingZipFromParams,
  type, //ACH_STANDARD || CHECK
}) => {
  const [recipientSsn, setRecipientSsn] = useState();
  const { theme, styles } = useStyles({
    Styles: InterchecksWithdrawSummaryStyles,
  });
  const currentUser = useContext(CurrentUserContext);
  const [amount, setAmount] = useState(amountFromParams);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const isFocused = useIsFocused();

  const [accountType, setAccountType] = useState(accountTypeFromParams);
  const [accountNumber, setAccountNumber] = useState(accountNumberFromParams);
  const [routingNumber, setRoutingNumber] = useState(routingNumberFromParams);
  const [mailingAddress1, setMailingAddress1] = useState(
    mailingAddress1FromParams
  );
  const [mailingAddress2, setMailingAddress2] = useState(
    mailingAddress2FromParams
  );
  const [mailingCity, setMailingCity] = useState(mailingCityFromParams);
  const [mailingState, setMailingState] = useState(mailingStateFromParams);
  const [mailingZip, setMailingZip] = useState(mailingZipFromParams);
  const [sendEmail] = useMutation(
    gql`
      mutation SendInterchecksEmail($input: SendEmailInput!) {
        sendEmail(input: $input)
      }
    `,
    {
      variables: {
        input: {
          email: currentUser.email,
          subject: `Withdrawl Request`,
          message: JSON.stringify({
            amount: amountFromParams,
            accountType: accountTypeFromParams,
            accountNumber: accountNumberFromParams,
            routingNumber: routingNumberFromParams,
            mailingAddress1: mailingAddress1FromParams,
            mailingAddress2: mailingAddress2FromParams,
            mailingCity: mailingCityFromParams,
            mailingState: mailingStateFromParams,
            mailingZip: mailingZipFromParams,
            type,
          }),
        },
      },
    }
  );

  const navigateTo = useCallback(
    (screen, params) => () =>
      [setSubmitted(false), navigation.navigate(screen, params)],
    [navigation]
  );

  const [createTransaction] = useMutation(Interchecks.mutations.createPayment, {
    variables: {
      input: {
        userId: currentUser?.id,
        recipient: {
          recipient_email: currentUser?.email,
          recipient_first_name: currentUser?.attributes?.given_name,
          recipient_last_name: currentUser?.attributes?.family_name,
          recipient_address: {
            address_1: currentUser?.attributes?.address,
            address_2:
              currentUser?.attributes?.["custom:address2"] ?? undefined,
            city: `${currentUser?.attributes?.["custom:city"]}`,
            state:
              `${currentUser?.attributes?.["custom:state"]}`?.toUpperCase?.(),
            zip: `${currentUser?.attributes?.["custom:zip"]}`,
          },
          recipient_tin: recipientSsn,
          payments: [
            {
              payment_amount: convertMoneyTextToPennies(amount) / 100.0,
            },
          ],
          payout: {
            method: type,
            email: currentUser?.email,
            ...(type === "ACH_STANDARD"
              ? {
                  account_type: accountType,
                  account_number: accountNumber,
                  routing_number: routingNumber,
                }
              : {
                  address: `${mailingAddress1} ${mailingAddress2 ?? ""}`.trim(),
                  city: mailingCity,
                  state: (mailingState ?? "").toUpperCase(),
                  zip: mailingZip,
                }),
          },
        },
      },
    },
    refetchQueries: [
      {
        query: User.queries.get,
        variables: {
          id: currentUser?.id,
          withPrivate: true,
        },
      },
    ],
  });

  const handleSubmitError = useCallback(
    error =>
      console.error("error", error) ||
      setErrorMessages([getErrorMessage(error)]),
    []
  );

  const clearErrors = useCallback(() => setErrorMessages([]), []);
  const isUsBased = currentUser?.attributes?.["custom:country"] !== "CA";

  const doSubmit = useCallback(
    () =>
      Promise.resolve(setIsSubmitting(true))
        .then(() => (isUsBased ? createTransaction() : sendEmail()))
        .then(clearErrors)
        .then(() => setSubmitted(true))
        .catch(handleSubmitError)
        .finally(() => setIsSubmitting(false)),
    [clearErrors, createTransaction, handleSubmitError, isUsBased, sendEmail]
  );

  const fee = type === "ACH_STANDARD" ? ACH_WITHDRAW_FEE : PAPER_WITHDRAW_FEE;

  const totalWithdrawn = useTotalWithdrawn(currentUser);

  const needsW9 =
    !!isFocused &&
    !currentUser?.interchecksRecipientW9Verified &&
    !recipientSsn &&
    totalWithdrawn + parseFloat(amount) * 100 >= w9Threshold;

  // const needsW9 = !recipientSsn;

  // const needsW9 = false;

  const balance = currentUser?.balance;

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    convertMoneyTextToPennies(amount) > balance
      ? setErrorMessages(errorMessages =>
          !errorMessages.filter
            ? [errorMessages]
            : [
                ...errorMessages.filter(
                  message =>
                    message !==
                    "The Withdrawal Amount exceeds your Available Balance"
                ),
                "The Withdrawal Amount exceeds your Available Balance",
              ]
        )
      : setErrorMessages(errorMessages =>
          errorMessages.filter(
            message =>
              message !== "The Withdrawal Amount exceeds your Available Balance"
          )
        );
  }, [amount, balance]);

  useEffect(() => {
    setAmount(amountFromParams);
  }, [amountFromParams]);

  useEffect(() => {
    setAccountType(accountTypeFromParams);
  }, [accountTypeFromParams]);

  useEffect(() => {
    setAccountNumber(accountNumberFromParams);
  }, [accountNumberFromParams]);

  useEffect(() => {
    setRoutingNumber(routingNumberFromParams);
  }, [routingNumberFromParams]);

  useEffect(() => {
    setMailingAddress1(mailingAddress1FromParams);
  }, [mailingAddress1FromParams]);

  useEffect(() => {
    setMailingAddress2(mailingAddress2FromParams);
  }, [mailingAddress2FromParams]);

  useEffect(() => {
    setMailingCity(mailingCityFromParams);
  }, [mailingCityFromParams]);

  useEffect(() => {
    setMailingState(mailingStateFromParams);
  }, [mailingStateFromParams]);

  useEffect(() => {
    setMailingZip(mailingZipFromParams);
  }, [mailingZipFromParams]);

  // useEffect(() => {
  //   setErrorMessages([
  //     convertMoneyTextToPennies(amount) >= minimumDepositInPennies ? null : "$5 minimum",
  //     !!selectedPaymentMethod ? null : "You must select or add a payment method"
  //   ].filter(i => !!i))
  // }, [amount, selectedPaymentMethod])

  return (
    <InterchecksWithdrawSummaryView
      theme={theme}
      type={type}
      navigateTo={navigateTo}
      currentUser={currentUser}
      errorMessages={errorMessages}
      isSubmitting={isSubmitting}
      amount={amount}
      submitted={submitted}
      doSubmit={doSubmit}
      fee={fee}
      accountType={accountType}
      accountNumber={accountNumber}
      routingNumber={routingNumber}
      mailingAddress1={mailingAddress1}
      mailingAddress2={mailingAddress2}
      mailingCity={mailingCity}
      mailingState={mailingState}
      mailingZip={mailingZip}
      styles={styles}
      needsW9={needsW9}
      onSsnSubmit={setRecipientSsn}
    />
  );
};
