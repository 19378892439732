import gql from "graphql-tag";
import environment from "environment";
import User from "./User";
import Ticket from "./Ticket";

const TicketView = {};

TicketView.fragments = {
  public: gql`
    fragment TicketViewPublic on TicketView {
      id
      createdAt
      updatedAt
      ticketViewUserId
      ticketId
      user {
        ...UserPublic
      }
      ticket {
        ...TicketPublic
      }
    }
    ${User.fragments.public}
    ${Ticket.fragments.public}
  `,
};

TicketView.mutations = {
  update: gql`
    mutation UpdateTicketView($input: UpdateTicketViewInput!) {
      updateTicketView(input: $input) {
        ...TicketViewPublic
      }
    }
    ${TicketView.fragments.public}
  `,
  create: gql`
    mutation CreateTicketView($input: CreateTicketViewInput!) {
      createTicketView(input: $input) {
        ...TicketViewPublic
      }
    }
    ${TicketView.fragments.public}
  `,
  delete: gql`
    mutation DeleteTicketView($input: DeleteTicketViewInput!) {
      deleteTicketView(input: $input) {
        ...TicketViewPublic
      }
    }
    ${TicketView.fragments.public}
  `,
};

TicketView.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateTicketView {
      onUpdateTicketView {
        ...TicketViewPublic
      }
    }
    ${TicketView.fragments.public}
  `,
  onCreate: gql`
    subscription OnCreateTicketView {
      onCreateTicketView {
        ...TicketViewPublic
      }
    }
    ${TicketView.fragments.public}
  `,
};

TicketView.queries = {
  get: gql`
    query GetTicketView($id: ID!) {
      getTicketView(id: $id) {
        ...TicketViewPublic
      }
    }
    ${TicketView.fragments.public}
  `,
  list: gql`
    query ListTicketViews(
      $filter: ModelTicketViewFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTicketViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...TicketViewPublic
        }
        nextToken
      }
    }
    ${TicketView.fragments.public}
  `,
  listByTicketId: gql`
    query ListTicketViewsByTicketId(
      $ticketId: ID${environment.v2_bang}
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTicketViewFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTicketViewsByTicketId(
        ticketId: $ticketId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TicketViewPublic
        }
        nextToken
      }
    }
    ${TicketView.fragments.public}
  `,
  listByUserIdAndTicketId: gql`
    query ListTicketViewsByUserIdAndTicketId(
      $ticketViewUserId: ID${environment.v2_bang}
      $ticketIdCreatedAt: ModelTicketViewByUserIdAndTicketIdCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTicketViewFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTicketViewsByUserIdAndTicketId(
        ticketViewUserId: $ticketViewUserId
        ticketIdCreatedAt: $ticketIdCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TicketViewPublic
        }
        nextToken
      }
    }
    ${TicketView.fragments.public}
  `,
  listByUserId: gql`
    query ListTicketViewsByUserId(
      $userId: ID${environment.v2_bang}
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTicketViewFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTicketViewsByUserId(
        userId: $userId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TicketViewPublic
        }
        nextToken
      }
    }
    ${TicketView.fragments.public}
  `,
};

export default TicketView;
