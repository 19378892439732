import * as StoreReview from "expo-store-review";
import AsyncStorage from "@react-native-async-storage/async-storage";

const version = 9;

const requestReview = () =>
  StoreReview.isAvailableAsync()
    // .then(isAvailableAsync => showInfo(`isAvailableAsync ${isAvailableAsync}`, isAvailableAsync))
    .then(
      isAvailableAsync =>
        !!isAvailableAsync &&
        AsyncStorage.getItem(`@PropSwap:hasReviewed${version}`)
    )
    // .then(hasReviewed => showInfo(`hasReviewed ${hasReviewed}`, hasReviewed))
    .then(hasReviewed => !hasReviewed && StoreReview.hasAction())
    // .then(hasAction => showInfo(`hasAction ${hasAction}`, hasAction))
    .then(hasAction => !!hasAction && StoreReview.requestReview())
    .then(() => AsyncStorage.setItem(`@PropSwap:hasReviewed${version}`, "true"))
    .catch(console.error);

export default requestReview;
