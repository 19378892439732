import Auth from "@aws-amplify/auth";
import React from "react";
import { Button, useTheme } from "react-native-paper";
import Screen from "Components/Screen";

import EmptySign from "Components/EmptySign";
import ScreenBottom from "Components/Screen/Bottom";
import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { View } from "react-native";

export default ({ navigation }) => {
  const theme = useTheme();
  const client = useApolloClient();
  const handleSignOut = useCallback(
    () =>
      client
        .clearStore()
        .then(() => Auth.signOut())
        .catch(console.error),
    // client.resetStore()
    [client]
  );

  return (
    <Screen title="Log Out" fullHeight gatedTo="member">
      <View style={{ flex: 1 }}>
        <EmptySign
          title="Come Back Soon!"
          subheading="Yup! I want to log out of my account"
        />
      </View>

      <ScreenBottom adjustPadding>
        <Button
          mode="contained"
          onPress={handleSignOut}
          style={{ width: "100%" }}
        >
          Log Out
        </Button>
      </ScreenBottom>
    </Screen>
  );
};
