import { Card, Title, Paragraph } from "react-native-paper";

export default ({ link, theme, onPress, linkCount, styles }) => (
  <Card
    style={[
      { marginRight: linkCount > 1 ? 0 : -1 * theme.spacing.xl },
      styles.pointer,
    ]}
    onPress={onPress(link)}
  >
    <Card.Cover source={{ uri: link.photoUrl }} />
    <Card.Content>
      <Title>{link.title}</Title>
      {!!link.description && <Paragraph>{link.description}</Paragraph>}
    </Card.Content>
  </Card>
);
