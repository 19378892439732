import React from "react";
import { useState } from "react";

const ScrollDirectionContext = React.createContext();

const ScrollDirectionProvider = ({ children }) => {
  const [scrollDirection, setScrollDirection] = useState(true);
  return (
    <ScrollDirectionContext.Provider
      value={{ scrollDirection, setScrollDirection }}
    >
      {children}
    </ScrollDirectionContext.Provider>
  );
};

export { ScrollDirectionContext, ScrollDirectionProvider };
