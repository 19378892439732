import React from "react";
import { useContext, useCallback } from "react";
import { TextInput, useTheme } from "react-native-paper";

import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import { formatValue, addCommas } from "shared/Util/money";
import { useState } from "react";
import { Platform, View } from "react-native";
import useHeaderFormSaveButton from "Hooks/useHeaderFormSaveButton";

const field = "winAmount";

export default ({ navigation }) => {
  const theme = useTheme();
  const { setTicketValues, ticketValues } = useContext(TicketContext);
  const [value, setValue] = useState(ticketValues[field]);

  const onChange = useCallback(text => setValue(text), []);

  const onSubmit = useCallback(
    () => [
      setTicketValues(ticketValues => ({
        ...ticketValues,
        [field]: value,
      })),
      navigation.navigate("TicketNewStep3"),
    ],
    [value, navigation, setTicketValues]
  );

  useHeaderFormSaveButton({ onSubmit, isValid: !!value });

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={2}
        title={"Enter Win Amount"}
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />

      <View style={{ marginHorizontal: theme.spacing.xl }}>
        <TextInput
          autoFocus
          mode="outlined"
          label="Win Amount"
          left={<TextInput.Icon name="currency-usd" />}
          right={
            !!value && (
              <TextInput.Icon
                color={theme.colors.primary}
                onPress={onSubmit}
                name="check-circle-outline"
              />
            )
          }
          selectTextOnFocus
          autoCorrect={false}
          autoCapitalize={"none"}
          autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
          keyboardType="decimal-pad"
          textContentType="none"
          returnKeyLabel={"Done"}
          returnKeyType={"done"}
          onSubmitEditing={onSubmit}
          onChangeText={value =>
            onChange(formatValue((value ?? "").replace(/[^0-9.]/gi, "") || 0))
          }
          value={`${
            value.toString() === "0" ? "" : addCommas(value.toString())
          }`}
        />
      </View>
    </Screen>
  );
};
