import * as React from "react";
import {
  Keyboard,
  TouchableWithoutFeedback,
  Platform,
  View,
} from "react-native";

export default ({ children }) =>
  Platform.OS === "web" ? (
    children
  ) : (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <View style={{ flex: 1 }}>{children}</View>
    </TouchableWithoutFeedback>
  );
