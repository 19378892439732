import gql from "graphql-tag";
import environment from "environment";
import User from "./User";

const Search = {};

Search.fragments = {
  public: gql`
    fragment SearchPublic on Search {
      id
      userId
      text
      user {
        ...UserPublic
      }
    }
    ${User.fragments.public}
  `,
};

Search.mutations = {
  update: gql`
    mutation UpdateSearch($input: UpdateSearchInput!) {
      updateSearch(input: $input) {
        ...SearchPublic
      }
    }
    ${Search.fragments.public}
  `,
  create: gql`
    mutation CreateSearch($input: CreateSearchInput!) {
      createSearch(input: $input) {
        ...SearchPublic
      }
    }
    ${Search.fragments.public}
  `,
  delete: gql`
    mutation DeleteSearch($input: DeleteSearchInput!) {
      deleteSearch(input: $input) {
        ...SearchPublic
      }
    }
    ${Search.fragments.public}
  `,
};

Search.queries = {
  listByUserId: gql`
    query ListSearchesByUserId(
      $userId: ID${environment.v2_bang}
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelSearchFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSearchesByUserId(
        userId: $userId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...SearchPublic
        }
        nextToken
      }
    }
    ${Search.fragments.public}
  `,
  get: gql`
    query GetSearch($id: ID!) {
      getSearch(id: $id) {
        ...SearchPublic
      }
    }
    ${Search.fragments.public}
  `,
  list: gql`
    query ListSearchs(
      $filter: ModelSearchFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSearchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...SearchPublic
        }
        nextToken
      }
    }
    ${Search.fragments.public}
  `,
};

export default Search;
