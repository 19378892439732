import gql from "graphql-tag";
import Listing from "./Listing";

const Sportsbook = {};

Sportsbook.fragments = {
  public: gql`
    fragment SportsbookPublic on Sportsbook {
      id
      createdAt
      updatedAt
      name
      active
    }
  `,
};

Sportsbook.mutations = {
  update: gql`
    mutation UpdateSportsbook($input: UpdateSportsbookInput!) {
      updateSportsbook(input: $input) {
        ...SportsbookPublic
      }
    }
    ${Sportsbook.fragments.public}
  `,
  create: gql`
    mutation CreateSportsbook($input: CreateSportsbookInput!) {
      createSportsbook(input: $input) {
        ...SportsbookPublic
      }
    }
    ${Sportsbook.fragments.public}
  `,
  delete: gql`
    mutation DeleteSportsbook($input: DeleteSportsbookInput!) {
      deleteSportsbook(input: $input) {
        ...SportsbookPublic
      }
    }
    ${Sportsbook.fragments.public}
  `,
};

Sportsbook.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateSportsbook {
      onUpdateSportsbook {
        ...SportsbookPublic
      }
    }
    ${Sportsbook.fragments.public}
  `,
  onCreate: gql`
    subscription OnCreateSportsbook {
      onCreateSportsbook {
        ...SportsbookPublic
      }
    }
    ${Sportsbook.fragments.public}
  `,
};

Sportsbook.queries = {
  get: gql`
    query GetSportsbook($id: ID!) {
      getSportsbook(id: $id) {
        ...SportsbookPublic
      }
    }
    ${Sportsbook.fragments.public}
  `,
  list: gql`
    query ListSportsbooks(
      $filter: ModelSportsbookFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSportsbooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...SportsbookPublic
        }
        nextToken
      }
    }
    ${Sportsbook.fragments.public}
  `,
};

export default Sportsbook;
