import { gql, useMutation } from "@apollo/client";
import { gidxRefresSessionVar } from "./useGidxSession";
import { useCallback } from "react";
import useGidxPrepareSession from "./useGidxPrepareSession";

const gidxSavePaymentMethod = gql`
  mutation GidxSavePaymentMethod($input: GidxSavePaymentMethodInput!) {
    gidxSavePaymentMethod(input: $input) {
      MerchantID
      MerchantSessionID
      ResponseCode
      ResponseMessage
      PaymentMethod {
        Token
        DisplayName
        Type
        NameOnAccount
        PhoneNumber
        CVV
        CardNumber
        ExpirationDate
        Network
        AVSResult
        CVVResult
        AccountNumber
        RoutingNumber
      }
      ApiVersion
    }
  }
`;

const useGidxAddPaymentMethod = ({
  type,
  cvv,
  fullName,
  postalCode,
  accountNumber,
  routingNumber,
  cardNumber,
  expirationDate,
}) => {
  const [createSession] = useGidxPrepareSession();

  const [saveCreditCard] = useMutation(gidxSavePaymentMethod);

  const handleSaveCreditCard = useCallback(
    () =>
      createSession().then(({ data }) =>
        saveCreditCard({
          variables: {
            input: {
              MerchantSessionID: data?.gidxCreateSession?.MerchantSessionID,
              PaymentMethod: {
                Type: type,
                BillingAddress: {
                  PostalCode: postalCode,
                },
                NameOnAccount: fullName,
                ...(type === "CC"
                  ? {
                      CVV: cvv,
                      CardNumber: cardNumber,
                      ExpirationDate: expirationDate,
                    }
                  : {
                      AccountNumber: accountNumber,
                      RoutingNumber: routingNumber,
                    }),
              },
            },
          },
        })
          .then(({ data }) => {
            return data?.gidxSavePaymentMethod;
          })
          .finally(() => gidxRefresSessionVar(true))
      ),
    [
      accountNumber,
      cardNumber,
      createSession,
      cvv,
      expirationDate,
      fullName,
      postalCode,
      routingNumber,
      saveCreditCard,
      type,
    ]
  );

  return handleSaveCreditCard;
};

export default useGidxAddPaymentMethod;
