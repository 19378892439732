import React from "react";
import { useState } from "react";

const TabBarContext = React.createContext();

const TabBarProvider = ({ children }) => {
  const [visible, setVisible] = useState(true);
  return (
    <TabBarContext.Provider value={{ visible, setVisible }}>
      {children}
    </TabBarContext.Provider>
  );
};

export { TabBarContext, TabBarProvider };
