import React from "react";

import { createStackNavigator } from "@react-navigation/stack";
import TicketNewStep1 from "Screens/TicketNewStep1";
import TicketNewStep2 from "Screens/TicketNewStep2";
import TicketNewBetSync from "Screens/TicketNewBetSync";
import TicketNewStep3 from "Screens/TicketNewStep3";
import TicketNewStep4 from "Screens/TicketNewStep4";
import TicketNewPrice from "Screens/TicketNewPrice";
import TicketNewSuccess from "Screens/TicketNewSuccess";
import TicketNewLocation from "Screens/TicketNewLocation";
import TicketNewSportsbook from "Screens/TicketNewSportsbook";
import TicketNewSport from "Screens/TicketNewSport";
import TicketNewBetType from "Screens/TicketNewBetType";
import TicketNewBetAmount from "Screens/TicketNewBetAmount";
import TicketNewWinAmount from "Screens/TicketNewWinAmount";
import TicketNewEvent from "Screens/TicketNewEvent";
import TicketNewOption from "Screens/TicketNewOption";
import TicketNewAdditionalInfo from "Screens/TicketNewAdditionalInfo";
import TicketNewMinimumBidPrice from "Screens/TicketNewMinimumBidPrice";
import TicketNewExpiresAt from "Screens/TicketNewExpiresAt";
import TicketNewAskingPrice from "Screens/TicketNewAskingPrice";
import { CurrentUserContext } from "Contexts/CurrentUser";
import TicketNewVerification from "Screens/TicketNewVerification";
import CreditCardAddAccount from "Screens/CreditCardAddAccount";
import AchAddAccount from "Screens/AchAddAccount";
import Verification from "Screens/Verification";
import { getStandardScreenOptions } from "./index";
import MyEmail from "Screens/MyEmail";
import useGidxSession from "Hooks/useGidxSession";
import TicketNewBetSyncAccount from "Screens/TicketNewBetSyncAccount";
import TicketNewMobileConfirmStep1 from "Screens/TicketNewMobileConfirmStep1";
import TicketNewMobileConfirmStep2 from "Screens/TicketNewMobileConfirmStep2";
import TicketNewMobileConfirmStep3 from "Screens/TicketNewMobileConfirmStep3";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  const currentUser = React.useContext(CurrentUserContext);
  const gidxSession = useGidxSession();
  const hasPaymentMethod = (gidxSession?.PaymentMethods ?? []).length > 0;
  // useLayoutEffect(() => {
  //   navigation.setOptions({
  //     headerShown: false,
  //   });
  // }, [navigation]);

  return (
    <Stack.Navigator
      initialRouteName={
        hasPaymentMethod && !!currentUser?.verifiedAt
          ? "TicketNewStep1"
          : "TicketNewVerification"
      }
      screenOptions={getStandardScreenOptions({ screenOptions, navigation })}
    >
      {(!hasPaymentMethod || !currentUser?.verifiedAt) && (
        <Stack.Screen
          name="TicketNewVerification"
          component={TicketNewVerification}
        />
      )}
      <Stack.Screen name="TicketNewStep1" component={TicketNewStep1} />
      <Stack.Screen
        name="TicketNewMobileConfirmStep1"
        component={TicketNewMobileConfirmStep1}
      />
      <Stack.Screen
        name="TicketNewMobileConfirmStep2"
        component={TicketNewMobileConfirmStep2}
      />
      <Stack.Screen
        name="TicketNewMobileConfirmStep3"
        component={TicketNewMobileConfirmStep3}
      />
      <Stack.Screen name="TicketNewStep2" component={TicketNewStep2} />
      <Stack.Screen name="TicketNewBetSync" component={TicketNewBetSync} />
      <Stack.Screen
        name="TicketNewBetSyncAccount"
        component={TicketNewBetSyncAccount}
      />
      <Stack.Screen name="TicketNewStep3" component={TicketNewStep3} />
      <Stack.Screen name="TicketNewLocation" component={TicketNewLocation} />
      <Stack.Screen
        name="TicketNewSportsbook"
        component={TicketNewSportsbook}
      />
      <Stack.Screen name="TicketNewSport" component={TicketNewSport} />
      <Stack.Screen name="TicketNewEvent" component={TicketNewEvent} />
      <Stack.Screen name="TicketNewOption" component={TicketNewOption} />
      <Stack.Screen name="TicketNewBetType" component={TicketNewBetType} />
      <Stack.Screen
        name="TicketNewAdditionalInfo"
        component={TicketNewAdditionalInfo}
      />
      <Stack.Screen name="TicketNewBetAmount" component={TicketNewBetAmount} />
      <Stack.Screen name="TicketNewWinAmount" component={TicketNewWinAmount} />
      <Stack.Screen name="TicketNewStep4" component={TicketNewStep4} />
      <Stack.Screen
        name="TicketNewAskingPrice"
        component={TicketNewAskingPrice}
      />
      <Stack.Screen
        name="TicketNewMinimumBidPrice"
        component={TicketNewMinimumBidPrice}
      />
      <Stack.Screen name="TicketNewExpiresAt" component={TicketNewExpiresAt} />
      <Stack.Screen name="TicketNewPrice" component={TicketNewPrice} />
      <Stack.Screen name="TicketNewSuccess" component={TicketNewSuccess} />
      <Stack.Screen
        name="CreditCardAddAccount"
        component={CreditCardAddAccount}
      />
      <Stack.Screen name="AchAddAccount" component={AchAddAccount} />
      <Stack.Screen name="Verification" component={Verification} />
      <Stack.Screen name="MyEmail" component={MyEmail} />
    </Stack.Navigator>
  );
};
