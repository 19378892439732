import Screen from "Components/Screen";
import { View } from "react-native";
import {
  Title,
  useTheme,
  Text,
  Subheading,
  Paragraph,
  Headline,
  Caption,
  Button,
} from "react-native-paper";

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen hasScroll>
      <Title>Colors</Title>
      <View>
        {Object.entries(theme.colors).map(([key, value]) => (
          <View
            key={key}
            style={{
              alignItems: "center",
              flexDirection: "row",
              marginVertical: theme.spacing.lg,
            }}
          >
            <View
              style={{
                width: 50,
                height: 50,
                backgroundColor: value,
                marginRight: theme.spacing.lg,
              }}
            />
            <Text>
              {key} - {value}
            </Text>
          </View>
        ))}
      </View>

      <Title>Fonts</Title>
      <View>
        <View style={{ marginVertical: theme.spacing.lg }}>
          <Text>Text - 14px</Text>
          <Title>Title - 20px</Title>
          <Subheading>Subheading - 16px</Subheading>
          <Paragraph>Paragraph - 14px</Paragraph>
          <Headline>Headline - 24px</Headline>
          <Caption>Caption - 12px</Caption>
        </View>
      </View>

      <Title>Spacing</Title>
      <View>
        {Object.entries(theme.spacing).map(([key, value]) => (
          <View
            key={key}
            style={{
              alignItems: "center",
              flexDirection: "row",
              marginVertical: theme.spacing.lg,
            }}
          >
            <View
              style={{
                width: value,
                height: 25,
                backgroundColor: theme.colors.onSurface,
                marginRight: theme.spacing.lg,
              }}
            />
            <Text>
              {key} - {value}
            </Text>
          </View>
        ))}
      </View>

      <Title>Buttons</Title>
      <View>
        {["text", "outlined", "contained"].map(mode => (
          <View
            key={mode}
            style={{
              alignItems: "center",
              flexDirection: "row",
              marginVertical: theme.spacing.lg,
            }}
          >
            <Button mode={mode}>Button</Button>
            <Text>&nbsp;&nbsp;{mode}</Text>
          </View>
        ))}
        {["text", "outlined", "contained"].map(mode => (
          <View key={mode}>
            <Button style={{ width: "100%" }} mode={mode}>
              Button
            </Button>
            <Text>&nbsp;&nbsp;{mode} - full width</Text>
          </View>
        ))}
        {["text", "outlined", "contained"].map(mode => (
          <View
            key={mode}
            style={{
              alignItems: "center",
              flexDirection: "row",
              marginVertical: theme.spacing.lg,
            }}
          >
            <Button compact mode={mode}>
              Button
            </Button>
            <Text>&nbsp;&nbsp;{mode} - compact</Text>
          </View>
        ))}
      </View>
    </Screen>
  );
};
