import React, { useCallback, useContext, useState } from "react";
import { List, TextInput, useTheme, Divider, Text } from "react-native-paper";
import useHeaderFormSaveButton from "Hooks/useHeaderFormSaveButton";

import Screen from "Components/Screen";
import {
  formatValue,
  addCommas,
  convertMoneyTextToPennies,
  formatPennies,
} from "shared/Util/money";
import { Platform } from "react-native";
import { CurrentUserContext } from "Contexts/CurrentUser";

// const options = ["Select", "Enter"];

export default ({ navigation, route }) => {
  const {
    backTo,
    amount,
    accountType,
    accountNumber,
    routingNumber,
    mailingAddress1,
    mailingAddress2,
    mailingCity,
    mailingState,
    mailingZip,
  } = route?.params ?? {};

  const currentUser = useContext(CurrentUserContext);
  const theme = useTheme();
  const [value, setValue] = useState(
    amount === "0.00" ? formatPennies(currentUser.balance) : amount
  );
  // const [mode, setMode] = useState("Select");
  const mode = "Enter";

  const onChange = useCallback(text => setValue(text), []);

  const onSubmit = useCallback(
    ({ amount } = {}) =>
      navigation.navigate(backTo, {
        amount: amount ?? value, //(amount ?? value).toString().replace(/\D/g, ""),
        accountType,
        accountNumber,
        routingNumber,
        mailingAddress1,
        mailingAddress2,
        mailingCity,
        mailingState,
        mailingZip,
      }),
    [
      navigation,
      backTo,
      value,
      accountType,
      accountNumber,
      routingNumber,
      mailingAddress1,
      mailingAddress2,
      mailingCity,
      mailingState,
      mailingZip,
    ]
  );

  useHeaderFormSaveButton({ onSubmit, isValid: !!value });

  return (
    <Screen
      gatedTo="member"
      title="Withdraw Amount"
      backgroundColor={theme.colors.surface}
    >
      {/* <View style={{ marginVertical: theme.spacing.lg }}>
        <SegmentedControl
          appearance={theme.dark ? "dark" : "light"}
          values={options}
          selectedIndex={options.indexOf(mode)}
          onChange={event =>
            setMode(options[event.nativeEvent.selectedSegmentIndex])
          }
        />
      </View> */}

      {mode === "Select" &&
        ["25.00", "50.00", "100.00", "250.00", "500.00", "1,000.00"].map(
          option => (
            <React.Fragment key={option}>
              <List.Item
                title={`$${option}`}
                right={props =>
                  convertMoneyTextToPennies(option) ===
                  convertMoneyTextToPennies(amount) ? (
                    <List.Icon {...props} icon="check-circle-outline" />
                  ) : null
                }
                onPress={() => onSubmit({ amount: option })}
              />
              <Divider />
            </React.Fragment>
          )
        )}

      {mode === "Enter" && (
        <>
          <Text style={{ marginBottom: 20 }}>
            Withdrawable Balance: ${formatPennies(currentUser.balance)}
          </Text>
          <TextInput
            mode="outlined"
            label="Amount"
            left={<TextInput.Icon name="currency-usd" />}
            // right={!!value && <TextInput.Icon color={theme.colors.primary} onPress={onSubmit} name="check-circle-outline" />}
            selectTextOnFocus
            autoCorrect={false}
            autoCapitalize="none"
            autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
            keyboardType="decimal-pad"
            textContentType="none"
            returnKeyLabel="Done"
            returnKeyType="done"
            onSubmitEditing={onSubmit}
            onChangeText={value =>
              onChange(formatValue((value ?? "").replace(/[^0-9.]/gi, "") || 0))
            }
            value={`${
              value.toString() === "0" ? "" : addCommas(value.toString())
            }`}
          />
        </>
      )}
    </Screen>
  );
};
