import { createStackNavigator } from "@react-navigation/stack";
import ForSale from "Screens/ForSale";
import ListingLearn from "Screens/ListingLearn";
import { useLayoutEffect } from "react";
import { useTheme } from "react-native-paper";

import ListingModalStack from "./ListingModalStack";
import { getPlainOptions, headerNavOptions, modalOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  const theme = useTheme();
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator screenOptions={headerNavOptions}>
      <Stack.Screen
        name="ForSale"
        component={ForSale}
        options={{
          title: "My Tickets For Sale",
        }}
      />
      <Stack.Screen
        name="ListingLearn"
        component={ListingLearn}
        options={({ navigation }) => getPlainOptions({ navigation, theme })}
      />
      <Stack.Screen
        name="ListingModalStack"
        component={ListingModalStack}
        options={modalOptions}
      />
    </Stack.Navigator>
  );
};
