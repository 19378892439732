import { View } from "react-native";
import { TouchableRipple } from "react-native-paper";

const CircleIndicatorItemView = ({ theme, selected, onPress }) => (
  <TouchableRipple onPress={onPress}>
    <View
      style={{
        marginLeft: theme.spacing.sm,
        height: 14,
        width: 14,
        borderRadius: 7,
        backgroundColor: !!selected ? theme.colors.primary : "white",
        borderColor: theme.colors.primary,
        borderWidth: 1,
        borderStyle: "solid",
      }}
    />
  </TouchableRipple>
);

export default CircleIndicatorItemView;
