import gql from "graphql-tag";
import Listing from "./Listing";

const SportsbookLocale = {};

SportsbookLocale.fragments = {
  public: gql`
    fragment SportsbookLocalePublic on SportsbookLocale {
      id
      createdAt
      updatedAt

      sportsbookId
      localeId

      sportsbook {
        id
        name
        active
      }
      locale {
        id
        state
        name
      }
    }
  `,
};
SportsbookLocale.mutations = {
  update: gql`
    mutation UpdateSportsbookLocale($input: UpdateSportsbookLocaleInput!) {
      updateSportsbookLocale(input: $input) {
        ...SportsbookLocalePublic
      }
    }
    ${SportsbookLocale.fragments.public}
  `,
  create: gql`
    mutation CreateSportsbookLocale($input: CreateSportsbookLocaleInput!) {
      createSportsbookLocale(input: $input) {
        ...SportsbookLocalePublic
      }
    }
    ${SportsbookLocale.fragments.public}
  `,
  delete: gql`
    mutation DeleteSportsbookLocale($input: DeleteSportsbookLocaleInput!) {
      deleteSportsbookLocale(input: $input) {
        ...SportsbookLocalePublic
      }
    }
    ${SportsbookLocale.fragments.public}
  `,
};

SportsbookLocale.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateSportsbookLocale {
      onUpdateSportsbookLocale {
        ...SportsbookLocalePublic
      }
    }
    ${SportsbookLocale.fragments.public}
  `,
  onCreate: gql`
    subscription OnCreateSportsbookLocale {
      onCreateSportsbookLocale {
        ...SportsbookLocalePublic
      }
    }
    ${SportsbookLocale.fragments.public}
  `,
};

SportsbookLocale.queries = {
  get: gql`
    query GetSportsbookLocale($id: ID!) {
      getSportsbookLocale(id: $id) {
        ...SportsbookLocalePublic
      }
    }
    ${SportsbookLocale.fragments.public}
  `,
  listBySportsbookId: gql`
    query ListSportsbookLocalesBySportsbookId(
      $sportbookId: ID!
      $createdAt: ModelStringKeyConditionInput
      $filter: ModelSportsbookLocaleFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listSportsbookLocalesBySportsbookId(
        sportbookId: $sportbookId
        createdAt: $createdAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...SportsbookLocalePublic
        }
        nextToken
      }
    }
    ${SportsbookLocale.fragments.public}
  `,
  listByLocaleId: gql`
    query ListSportsbookLocalesByLocaleId(
      $localeId: ID!
      $createdAt: ModelStringKeyConditionInput
      $filter: ModelSportsbookLocaleFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listSportsbookLocalesByLocaleId(
        localeId: $localeId
        createdAt: $createdAt
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          ...SportsbookLocalePublic
        }
        nextToken
      }
    }
    ${SportsbookLocale.fragments.public}
  `,
  list: gql`
    query ListSportsbookLocales(
      $filter: ModelSportsbookLocaleFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSportsbookLocales(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...SportsbookLocalePublic
        }
        nextToken
      }
    }
    ${SportsbookLocale.fragments.public}
  `,
};

export default SportsbookLocale;
