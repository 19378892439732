import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import ResponsiveImage from "Components/ResponsiveImage";
import { logoHeaderLight, logoHeaderDark } from "assets";

const GuestHeaderView = ({
  theme,
  styles,
  onLoginPress,
  onRegisterPress,
  skipIcons,
}) => (
  <>
    <ResponsiveImage
      source={
        !!skipIcons
          ? !!theme.dark
            ? logoHeaderLight
            : logoHeaderDark
          : logoHeaderLight
      }
      width={70}
    />
    <View style={styles.row}>
      <Text style={!!skipIcons ? styles.text : styles.textReverse}>
        <Text
          onPress={onLoginPress}
          style={[
            !!skipIcons ? styles.text : styles.textReverse,
            styles.pointer,
          ]}
        >
          Login
        </Text>
        <Text style={!!skipIcons ? styles.text : styles.textReverse}> | </Text>
        <Text
          onPress={onRegisterPress}
          style={[
            !!skipIcons ? styles.text : styles.textReverse,
            styles.pointer,
          ]}
        >
          Join
        </Text>
      </Text>
    </View>
  </>
);

export default GuestHeaderView;
