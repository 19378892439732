import * as React from "react";

import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import PaperSummary from "Screens/PaperSummary";
import WithdrawPaperInfo from "Screens/WithdrawPaperInfo";
import WithdrawAmount from "Screens/WithdrawAmount";
import { getStandardScreenOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={getStandardScreenOptions({ screenOptions, navigation })}
    >
      <Stack.Screen name="PaperSummary" component={PaperSummary} />
      <Stack.Screen name="WithdrawAmount" component={WithdrawAmount} />
      <Stack.Screen name="WithdrawPaperInfo" component={WithdrawPaperInfo} />
    </Stack.Navigator>
  );
};
