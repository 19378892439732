import { useQuery } from "@apollo/client";
import environment from "environment";
import DataList from "Components/DataList";
import EmptySign from "Components/EmptySign";
import MyTicketsNav from "Components/MyTicketsNav";
import Screen from "Components/Screen";
import ScreenNav from "Components/Screen/Nav";
import Spacer from "Components/Spacer";
import TicketItem from "Components/Ticket/Item";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { ScrollDirectionContext } from "Contexts/ScrollDirection";
import { handleScroll } from "Util/";
import {
  useRef,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { Platform, View } from "react-native";
import { Text, TouchableRipple, useTheme } from "react-native-paper";
// import listingSearchQuery from "shared/api/schema/listingSearchQuery.graphql";
// import { useFocusEffect } from "@react-navigation/native";
// import { useEffect } from "react";
import Listing from "shared/api/Listing";
import Sport from "shared/api/Sport";

let init = 0;

const filterItems = [
  { label: "Most Recent", value: "updatedAt-desc" },
  { label: "Expires Soon", value: "expiresAt-asc" },
  { label: "Price High to Low", value: "askingPrice-desc" },
  { label: "Price Low to High", value: "askingPrice-asc" },
  // {label: "Alphabetical A-Z", value: "subject-asc"},
  // {label: "Alphabetical Z-A", value: "subject-desc"},
];

export default ({ navigation, route }) => {
  const theme = useTheme();
  const reload = !!route?.params?.reload || !!route?.reload;
  const [subNavValue, setSubNavValue] = useState("All");
  const [filterValue, setFilterValue] = useState("updatedAt-desc");
  const [sportValue, setSportValue] = useState(null);
  const { setScrollDirection } = useContext(ScrollDirectionContext);
  const sortDirection = filterValue.split("-")[1];
  const sortField = filterValue.split("-")[0];
  const [hasItems, setHasItems] = useState(null);
  const dataListRef = useRef();

  const clearSportRef = useRef();

  const { data: { listSports: { items: sports = [] } = {} } = {} } = useQuery(
    Sport.queries.list,
    {
      variables: {
        limit: 50,
      },
    }
  );

  const handleItems = useCallback(items => setHasItems(!!items?.length), []);

  const handleSubNavChange = useCallback(value => setSubNavValue(value), []);

  const handleFilterChange = useCallback(value => setFilterValue(value), []);

  const handleSportChange = useCallback(value => setSportValue(value), []);

  const handleSportClear = useCallback(
    () => [clearSportRef.current.clear(), setSportValue(null)],
    []
  );

  const currentUser = useContext(CurrentUserContext);

  const dataListQuery = useMemo(
    () => ({
      dataKey: `searchListingsRaw${environment.use_v2 ? "2" : ""}`,
      query: Listing.queries.searchRaw,
      variables: {
        limit: 20,
        sort: {
          direction: sortDirection,
          field: sortField,
        },
        filter: JSON.stringify({
          and: [
            {
              status: {
                eq: "ACTIVE",
              },
            },
            {
              [subNavValue === "All" ? "id" : "pendingBidsCount"]: {
                [subNavValue === "All"
                  ? "exists"
                  : subNavValue === "No Bids"
                  ? "eq"
                  : "gt"]: subNavValue === "All" ? "true" : 0,
              },
            },
            {
              listingSellerId: {
                eq: currentUser?.id ?? "null",
              },
            },
            !!sportValue
              ? {
                  "cachedTicket.ticketSportId": {
                    eq: sportValue,
                  },
                }
              : null,
          ].filter(item => !!item),
        }),
      },
    }),
    [sortDirection, sortField, currentUser, subNavValue, sportValue]
  );

  const dataListRenderItem = useCallback(
    ({ item }) => (
      <View
        style={{
          marginBottom: theme.spacing.md,
        }}
      >
        <TicketItem ticketObj={item.cachedTicket} listingObj={item} />
      </View>
    ),
    [theme]
  );

  useEffect(() => {
    reload && !!dataListRef?.current?.refetch && dataListRef.current.refetch();
  }, [dataListRef, reload]);

  // useFocusEffect(
  //   React.useCallback(() => {
  //     !!dataListRef?.current?.refetch && dataListRef.current.refetch();
  //   }, [])
  // );

  // useEffect(() => {
  //   !!dataListRef?.current?.refetch && dataListRef.current.refetch();
  // }, [dataListQuery]);

  return (
    <Screen fullHeight fullWidth gatedTo="member">
      <MyTicketsNav
        clearSportRef={clearSportRef}
        navigation={navigation}
        onChange={handleFilterChange}
        onSportChange={handleSportChange}
        selected="For Sale"
        filterItems={filterItems}
      />

      <ScreenNav
        itemLeft={<Spacer />}
        itemRight={<Spacer />}
        theme={theme}
        selectedValue={subNavValue}
        onValueChange={handleSubNavChange}
        buttons={[
          { label: "All" },
          { label: "Bids Received" },
          { label: "No Bids" },
        ]}
      />
      {!!sportValue && (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: theme.spacing.xl,
            marginTop: theme.spacing.md,
          }}
        >
          <Text>
            {sports.find(sport => sport.id === sportValue).name} Tickets{" "}
          </Text>
          <TouchableRipple onPress={handleSportClear}>
            <Text style={{ color: theme.colors.primary }}>Clear</Text>
          </TouchableRipple>
        </View>
      )}
      <View style={{ flex: 1 }}>
        <DataList
          dataListRef={dataListRef}
          onScroll={
            Platform.OS !== "web"
              ? null
              : handleScroll(
                  () => setScrollDirection("up"),
                  () => setScrollDirection("down")
                )
          }
          onScrollBeginDrag={
            Platform.OS === "web"
              ? null
              : event => {
                  init = event.nativeEvent.contentOffset.y;
                }
          }
          onScrollEndDrag={
            Platform.OS === "web"
              ? null
              : event =>
                  init > event.nativeEvent.contentOffset.y
                    ? setScrollDirection("up")
                    : setScrollDirection("down")
          }
          // fetchPolicy="no-cache" //TODO fix this. has something to do with the apollo client
          // counter={counter}
          query={dataListQuery}
          onFetchItems={handleItems}
          renderItem={dataListRenderItem}
          contentContainerStyle={{
            flex: hasItems ? undefined : 1,
            marginHorizontal: theme.spacing.xl,
            paddingTop: theme.spacing.xl,
          }}
          ListEmptyComponent={
            !sportValue ? (
              <EmptySign
                title={
                  subNavValue === "Bids Received" ? null : "Learn How To Sell"
                }
                subheading={
                  subNavValue === "Bids Received"
                    ? "You Do Not Have Any Bids At This Time"
                    : "You Don't Have Any Tickets For Sale."
                }
                navLabel={subNavValue === "Bids Received" ? null : "Learn Now"}
                onNavPress={() => navigation.push("ListingLearn")}
              />
            ) : (
              <EmptySign
                title="No Tickets"
                subheading="No Tickets Match Your Criteria"
              />
            )
          }
        />
      </View>
    </Screen>
  );
};
