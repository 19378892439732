import * as React from "react";
import { Platform, ScrollView, View } from "react-native";
import {
  Button,
  Text,
  useTheme,
  TextInput,
  HelperText,
} from "react-native-paper";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState, useCallback, useEffect, useContext } from "react";
import * as SecureStore from "expo-secure-store";

import RegisterHeader from "Components/Register/Header";
import Screen from "Components/Screen";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  useIsFocused,
  useLinkTo,
  CommonActions,
} from "@react-navigation/native";
import Auth from "@aws-amplify/auth";
import useLocalAuthentication from "Hooks/useLocalAuthentication";
import LocalAuthToggleContainer from "Components/LocalAuth/Toggle";
import * as LocalAuthentication from "expo-local-authentication";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { RegistrationContext } from "Contexts/Registration";
import AuthWrapper from "Components/AuthWrapper";

// const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE";
const schema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid email address")
    .required("Can't be blank"),
  password: yup.string().required("Can't be blank"),
});

const Form = ({ style, theme, navigation }) => {
  const { resetRegistration } = useContext(RegistrationContext);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setError,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const currentUser = useContext(CurrentUserContext);
  const inputs = React.useRef({});
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const linkTo = useLinkTo();

  const localAuth = useLocalAuthentication();
  const useFaceId = localAuth?.usesLocalAuth;
  const login = localAuth?.login;
  const password = localAuth?.password;
  const isEnrolled = localAuth?.isEnrolled;
  const loggedIn = !!currentUser;
  const isFocused = useIsFocused();

  const handleSubmitError = useCallback(
    error =>
      setError("password", {
        ...error,
        message:
          error.code === "UserNotFoundException"
            ? "Email or Password is incorrect"
            : `${error.code} - ${error.message}`,
      }),
    [setError]
  );

  const onSubmit = useCallback(
    ({ email, password }) =>
      Auth.signIn(email, password)
        .then(user =>
          user.challengeName === "SMS_MFA" ||
          user.challengeName === "SOFTWARE_TOKEN_MFA"
            ? null
            : user.challengeName === "NEW_PASSWORD_REQUIRED"
            ? // eslint-disable-next-line prefer-promise-reject-errors
              Promise.reject({
                name: "NEW_PASSWORD_REQUIRED",
                message: "You must reset your password.",
                code: "NEW_PASSWORD_REQUIRED",
                data: { email },
              })
            : user.challengeName === "MFA_SETUP"
            ? null
            : user.challengeName === "CUSTOM_CHALLENGE"
            ? null
            : user
        )
        // .then(user => client.clearStore().then(() => user))
        .then(user => (!user ? null : Auth.verifiedContact(user)))
        .then(data =>
          !data
            ? null
            : !!Object.keys(data.verified).length // IF THE USER HAS AT LEAST ONE METHOD OF CONTACT VERIFIED
            ? (!useFaceId
                ? Promise.resolve(null)
                : Promise.all([
                    Platform.OS !== "web" &&
                      SecureStore.setItemAsync("login", email),
                    Platform.OS !== "web" &&
                      SecureStore.setItemAsync("password", password),
                  ])
              )
                .then(() => resetRegistration())
                .then(() => AsyncStorage.getItem("returnTo"))
                .then(returnTo =>
                  !!returnTo && Platform.OS === "web"
                    ? AsyncStorage.removeItem("returnTo").then(
                        () =>
                          new Promise(resolve =>
                            setTimeout(
                              () => [resolve(), linkTo(returnTo)],
                              1000
                            )
                          )
                      )
                    : !!returnTo
                    ? AsyncStorage?.getItem("returnTo")
                        .then(JSON.parse)
                        .then(
                          state =>
                            new Promise(resolve =>
                              setTimeout(() => resolve(state), 1000)
                            )
                        )
                        .then(state =>
                          navigation.dispatch(CommonActions.reset(state))
                        )
                    : navigation.replace("Main")
                )
            : (!useFaceId
                ? Promise.resolve(null)
                : Promise.all([
                    Platform.OS !== "web" &&
                      SecureStore.setItemAsync("login", email),
                    Platform.OS !== "web" &&
                      SecureStore.setItemAsync("password", password),
                  ])
              ).then(() => navigation.navigate("VerifyContact", { email }))
        )
        .catch(handleSubmitError),
    [handleSubmitError, useFaceId, resetRegistration, navigation, linkTo]
  );

  const doSubmit = handleSubmit(onSubmit);

  useEffect(() => {
    !!isFocused &&
      !loggedIn &&
      !!useFaceId &&
      !!login &&
      !!password &&
      !!isEnrolled &&
      LocalAuthentication.authenticateAsync()
        .then(() => onSubmit({ email: login, password }))
        .catch(console.log);
  }, [useFaceId, login, password, onSubmit, loggedIn, isFocused, isEnrolled]);

  return (
    <>
      <ScrollView
        style={[
          {
            flex: 1,
          },
          style ?? {},
        ]}
        keyboardShouldPersistTaps="handled"
      >
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                // autoFocus
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["email"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "email"
                }
                keyboardType="email-address"
                textContentType="emailAddress"
                label="Email"
                error={!!errors.email}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.password.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value.toLowerCase())}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.email}>
                {errors.email?.message}
              </HelperText>
            </>
          )}
          name="email"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                secureTextEntry={secureTextEntry}
                autoCorrect={false}
                ref={e => (inputs.current["password"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "password"
                }
                textContentType="password"
                label="Password"
                autoCapitalize="none"
                returnKeyLabel="done"
                returnKeyType="done"
                onSubmitEditing={doSubmit}
                error={!!errors.password}
                right={
                  <TextInput.Icon
                    name={!!secureTextEntry ? "eye-off" : "eye"} // or can be a node where <Icon /> is any component from vector-icons or anything else
                    onPress={() =>
                      setSecureTextEntry(secureTextEntry => !secureTextEntry)
                    }
                  />
                }
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.password}>
                {errors.password?.message?.replace(
                  "NotAuthorizedException - ",
                  ""
                )}
              </HelperText>
            </>
          )}
          name="password"
          defaultValue=""
        />

        <View>
          <Text
            onPress={() => navigation.navigate("PasswordResetStack")}
            style={{ ...theme.fonts.p, color: theme.colors.secondary }}
          >
            Forgot Password?
          </Text>
        </View>

        {!!localAuth?.isEnrolled && (
          <LocalAuthToggleContainer localAuth={localAuth} />
        )}
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%", marginTop: theme.spacing.xl }}
        >
          {!!isSubmitting ? "Please Wait..." : "Log In"}
        </Button>
        <Text style={{ ...theme.fonts.p, lineHeight: 40, textAlign: "center" }}>
          Don't have an account?{" "}
          <Text
            style={{ color: theme.colors.secondary }}
            onPress={() => navigation.navigate("RegistrationInitial")}
          >
            Sign Up Now!
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.p, textAlign: "center" }}>
          Have a code?{" "}
          <Text
            style={{ color: theme.colors.secondary }}
            onPress={() =>
              navigation.navigate("RegistrationStack", {
                screen: "RegistrationConfirmation",
              })
            }
          >
            Click Here
          </Text>
        </Text>
        <Text style={{ ...theme.fonts.p, lineHeight: 40, textAlign: "center" }}>
          <Text
            style={{ textAlign: "center", color: theme.colors.secondary }}
            onPress={() => navigation.replace("Main")}
          >
            Take me to PropSwap
          </Text>
        </Text>
      </ScrollView>
    </>
  );
};

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen
      gatedTo="guest"
      title="Login"
      // hasKeyboard={true}
      backgroundColor={theme.colors.surface}
      keyboardAdjust={1}
      fullHeight
      fullWidth
    >
      {Platform.OS === "web" ? (
        <AuthWrapper>
          <>
            <RegisterHeader action="Log In" theme={theme} />
            <Form
              style={{
                marginTop: theme.spacing.xl,
                marginHorizontal: theme.spacing.xl,
              }}
              theme={theme}
              navigation={navigation}
            />
          </>
        </AuthWrapper>
      ) : (
        <>
          <RegisterHeader action="Log In" theme={theme} />
          <Form
            style={{
              marginTop: theme.spacing.xl,
              marginHorizontal: theme.spacing.xl,
            }}
            theme={theme}
            navigation={navigation}
          />
        </>
      )}
    </Screen>
  );
};
