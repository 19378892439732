import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import { useTheme } from "react-native-paper";
import ListingDetail from "Screens/ListingDetail";
import ListingEdit from "Screens/ListingEdit";
// import { HeaderBackButton } from '@react-navigation/elements';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Platform, View } from "react-native";
import BidDecide from "Screens/BidDecide";
import UserDetail from "Screens/UserDetail";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  const theme = useTheme();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerLeft: () => null,
        presentation: "transparentModal",
        headerStyle: {
          height: 40,
          borderTopLeftRadius: theme.roundness,
          borderTopRightRadius: theme.roundness,
          backgroundColor: theme.colors.secondary,
          borderBottomWidth: 0,
        },
        headerTitleStyle: {
          ...theme.fonts.h4,
          color: theme.colors.textReverse,
          textTransform: "uppercase",
        },
        headerStatusBarHeight: 0,
        headerTitleAlign: "center",
        headerShown: true,
        cardOverlayEnabled: true,
        animationEnabled: true,
        cardStyleInterpolator: CardStyleInterpolators.forModalPresentationIOS,
        // cardShadowEnabled: false,
        cardStyle: {
          borderTopLeftRadius: theme.roundness,
          borderTopRightRadius: theme.roundness,
        },
      }}
    >
      <Stack.Screen
        name="ListingDetail"
        component={ListingDetail}
        options={({ navigation }) => ({
          title: "Ticket Details",
          headerRight: props => (
            <View style={{ marginRight: theme.spacing.xl }}>
              <MaterialCommunityIcons
                name="close"
                size={18}
                color={theme.colors.textReverse}
                onPress={() => navigation.goBack()}
              />
            </View>
          ),
        })}
      />
      <Stack.Screen
        name="ListingEdit"
        component={ListingEdit}
        options={({ navigation, route }) => ({
          // presentation: "transparentModal",
          cardStyle: Platform.select({
            web: {
              // marginTop: 40,
            },
            default: {
              marginTop: 0,
            },
          }),
          title: "Ticket Edit",
          headerRight: props => (
            <View style={{ marginRight: theme.spacing.xl }}>
              <MaterialCommunityIcons
                name="close"
                size={18}
                color={theme.colors.textReverse}
                onPress={() =>
                  navigation.navigate("ListingDetail", { id: route.params.id })
                }
              />
            </View>
          ),
        })}
      />
      <Stack.Screen
        name="UserDetail"
        component={UserDetail}
        options={({ navigation }) => ({
          title: "Seller Profile",
          // presentation: "transparentModal",
          headerRight: props => (
            <View style={{ marginRight: theme.spacing.xl }}>
              <MaterialCommunityIcons
                name="close"
                size={18}
                color={theme.colors.textReverse}
                onPress={() => navigation.goBack()}
              />
            </View>
          ),
          cardStyle: Platform.select({
            web: {
              marginTop: 80,
            },
            default: {
              marginTop: 40,
            },
          }),
        })}
      />
      <Stack.Screen
        name="BidDecide"
        component={BidDecide}
        options={({ navigation }) => ({
          presentation: "transparentModal",
        })}
      />
    </Stack.Navigator>
  );
};
