import { Paragraph, Title, TouchableRipple } from "react-native-paper";
import { View } from "react-native";
import { FontAwesome } from "@expo/vector-icons";

export default ({ styles, theme, title, items, expanded, onPress }) => (
  <>
    <TouchableRipple onPress={onPress}>
      <View style={[styles.row, styles.titleWrapper]}>
        <FontAwesome
          style={styles.icon}
          name={!!expanded ? "minus" : "plus"}
          size={20}
          color={theme.colors.text}
        />
        <Title style={[styles.forceWrap, { paddingRight: theme.spacing.md }]}>
          {title}
        </Title>
      </View>
    </TouchableRipple>
    {!!expanded &&
      items.map((line, i) => <Paragraph key={i}>{line}</Paragraph>)}
  </>
);
