import * as React from "react";

import { Text } from "react-native-paper";
import { FontAwesome } from "@expo/vector-icons";
import { View } from "react-native";

export default ({ doesPass, text, theme }) => (
  <View style={{ alignItems: "center", flexDirection: "row" }}>
    {!!doesPass && (
      <FontAwesome
        name="check"
        size={12}
        color={theme.colors.primary}
        style={{ marginRight: theme.spacing.md }}
      />
    )}
    {!doesPass && (
      <FontAwesome
        name="remove"
        size={12}
        color={theme.colors.error}
        style={{ marginRight: theme.spacing.md }}
      />
    )}

    <Text
      style={{
        lineHeight: 20,
        color: doesPass ? theme.colors.primary : theme.colors.error,
      }}
    >
      {text}
    </Text>
  </View>
);
