import React, { useEffect, useMemo, useState, useCallback } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

const RegistrationContext = React.createContext();

const initialState = {
  receivePromotions: false,
  email: "",
  displayName: "",
  password: "",
  firstName: "",
  lastName: "",
  birthDate: "",
  agree: false,
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  mobileNumber: "",
  country: "",
};

const RegistrationProvider = ({ children }) => {
  const [registrationValues, setRegistrationValues] = useState(initialState);
  const [loaded, setLoaded] = useState(false);
  const resetRegistration = useCallback(
    () =>
      AsyncStorage.setItem(
        "@registrationValues",
        JSON.stringify(initialState)
      ).then(() => setRegistrationValues(initialState)),
    []
  );

  const registrationValuesString = JSON.stringify(registrationValues);

  const memoizedValues = useMemo(
    () => JSON.parse(registrationValuesString),
    [registrationValuesString]
  );

  useEffect(() => {
    !!memoizedValues?.email &&
      AsyncStorage.setItem(
        "@registrationValues",
        JSON.stringify(memoizedValues)
      );
  }, [memoizedValues]);

  useEffect(() => {
    AsyncStorage.getItem("@registrationValues")
      .then(value => (!!value ? JSON.parse(value) : initialState))
      .then(setRegistrationValues)
      .then(() => setLoaded(true));
  }, []);

  return !loaded ? null : (
    <RegistrationContext.Provider
      value={{
        registrationValues: memoizedValues,
        setRegistrationValues,
        resetRegistration,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export { RegistrationContext, RegistrationProvider };
