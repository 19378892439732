import React, { useCallback } from "react";
import { List, Divider, useTheme, TouchableRipple } from "react-native-paper";

import Screen from "Components/Screen";
import { MaterialCommunityIcons } from "@expo/vector-icons";
// import BottomSheet from 'Components/BottomSheet';
import {
  HeaderButtons,
  // HeaderButton,
  Item,
  // HiddenItem,
  // OverflowMenu,
} from "react-navigation-header-buttons";
import { Alert, Platform } from "react-native";
import useGidxDeletePaymentMethod from "Hooks/useGidxDeletePaymentMethod";
import useGidxSession from "Hooks/useGidxSession";

export default ({ navigation, route }) => {
  const theme = useTheme();
  const amount = route?.params?.amount;
  const promotionId = route?.params?.promotionId;

  const doDelete = useGidxDeletePaymentMethod();
  const gidxSession = useGidxSession("ach-select");

  const navigateTo = useCallback(
    (screen, params) => () => [navigation.push(screen, params)],
    [navigation]
  );

  const navigateBackTo = useCallback(
    (screen, params) => () => [navigation.navigate(screen, params)],
    [navigation]
  );

  const handleDelete = useCallback(
    ({ token, type }) =>
      () =>
        Platform.OS === "web"
          ? confirm("Remove this account?") && doDelete({ token, type })
          : Alert.alert(
              "Remove this account?",
              "This will remove the account from your wallet.",
              [
                {
                  text: "Cancel",
                  onPress: () => null,
                  style: "cancel",
                },
                {
                  text: "Delete",
                  onPress: doDelete({ token, type }),
                  style: "destructive",
                },
              ]
            ),
    [doDelete]
  );

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons size={48}>
          <Item
            title="Add Account"
            IconComponent={MaterialCommunityIcons}
            iconSize={24}
            iconName="plus"
            onPress={navigateTo("AchAddAccount")}
          />
        </HeaderButtons>
      ),
    });
  }, [navigateTo, navigation]);

  return (
    <Screen
      gatedTo="member"
      title="Select Account"
      fullHeight
      backgroundColor={theme.colors.surface}
    >
      {(gidxSession?.PaymentMethods ?? [])
        .filter(pm => pm.Type !== "CC")
        .map((info, i) => (
          <React.Fragment key={i}>
            <List.Item
              title="Bank Account"
              titleStyle={{ textTransform: "capitalize" }}
              description={`Ending in ${info.DisplayName}`}
              right={props => (
                <TouchableRipple
                  onPress={handleDelete({ token: info.Token, type: info.Type })}
                >
                  <List.Icon {...props} icon="trash-can-outline" />
                </TouchableRipple>
              )}
              onPress={navigateBackTo("AchSummary", {
                lastFour: info.Token,
                promotionId,
                amount,
              })}
            />
            <Divider />
          </React.Fragment>
        ))}
    </Screen>
  );
};
