//CORE

import { useCallback, useContext, useEffect } from "react";
import {
  useIsFocused,
  useNavigation,
  useNavigationState,
} from "@react-navigation/core";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { getPathFromState } from "@react-navigation/native";
// import { useRoute } from '@react-navigation/native';
import AsyncStorage from "@react-native-async-storage/async-storage";
// import { linkingConfig } from "Screens/";
import { Platform } from "react-native";
// import { useCallback } from "react";

export default ({ gateType, to }) => {
  const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE";
  const navigationState = useNavigationState(state => state);
  // const navigationRef = useNavigationContainerRef();
  const navigation = useNavigation();
  const currentUser = useContext(CurrentUserContext);
  const isLoggedIn = !!currentUser;
  const isFocused = useIsFocused();
  const isAdmin = false;
  // const route = useRoute();

  const isAllowed = !gateType
    ? true
    : gateType === "guest" && !isLoggedIn
    ? true
    : gateType === "member" && !!isLoggedIn
    ? true
    : !!(gateType === "admin" && !!isAdmin);
  // const path = route?.name;
  // const path = JSON.stringify(navigationState);
  // const routeName = route?.name;

  const getPath = useCallback(
    () =>
      Platform.OS === "web"
        ? window.location.pathname
        : !!navigationState
        ? getPathFromState(navigationState)
        : "",
    [navigationState]
  );

  useEffect(() => {
    setTimeout(
      () =>
        !isAllowed &&
        !!isFocused &&
        (!["/account/logout", "/home", "/", "/login", "/logout"].includes(
          getPath().toLowerCase()
        )
          ? Platform.OS === "web"
            ? AsyncStorage.setItem("returnTo", getPath())
            : AsyncStorage?.getItem(NAVIGATION_PERSISTENCE_KEY)
                .then(JSON.parse)
                .then(state =>
                  AsyncStorage.setItem("returnTo", JSON.stringify(state))
                )
          : Promise.resolve(null)
        ).then(end =>
          !!to || gateType === "guest"
            ? navigation.push(to ?? "Main")
            : navigation.replace("AuthStack", { screen: "Login" })
        ),
      1000
    );
  }, [
    isAllowed,
    isFocused,
    navigation,
    to,
    navigationState,
    getPath,
    gateType,
  ]);

  return currentUser;
};
