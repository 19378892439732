export default theme => ({
  outerWrapper: {
    // flex: 1, //DONT REMOVE
    // position: "absolute",
    // bottom: 24,
    // width: "100%",
    flexDirection: "row",
    // alignItems: "flex-end",
    // justifyContent: "flex-end",
    marginBottom: theme.spacing.xl,
    // borderWidth: 2,
    // borderStyle: "dashed",
    // borderColor: "red"
    // marginTop: -100,
  },
  wrapper: {
    flex: 1,
    alignItems: "center",
  },
});
