import * as React from "react";
import { useContext, useCallback } from "react";
import { Button, useTheme } from "react-native-paper";

import BottomSheet from "Components/BottomSheet";
import Confirmation from "Components/Confirmation";
import { TextInput, HelperText } from "react-native-paper";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { View, Platform } from "react-native";
import ScreenBottom from "Components/Screen/Bottom";
import Screen from "Components/Screen";
import { addCommas, formatValue } from "shared/Util/money";
import { useMemo } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import User from "shared/api/User";
import { useMutation } from "@apollo/client";
import { useState } from "react";

// const scrollEnabled = Platform.select({ web: true, default: false });

const Form = ({ style, theme, navigation, onSuccess }) => {
  const currentUser = useContext(CurrentUserContext);
  const availablePoints = currentUser?.availablePoints ?? 0;

  const schema = useMemo(
    () =>
      yup.object().shape({
        amount: yup
          .number()
          .transform(value => (isNaN(value) ? undefined : parseFloat(value)))
          .moreThan(0, `Must be more than $0`)
          .lessThan(availablePoints / 250.0 + 1, "You don't have enough points")
          .required("Can't be blank"),
      }),
    [availablePoints]
  );

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
      isSubmitting,
      touchedFields,
      dirtyFields,
      ...rest
    },
    setError,
    setFocus,
    watch,
    ...others
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const amount = watch("amount", "");

  const [convertPointsToCash] = useMutation(
    User.mutations.convertPointsToCash,
    {
      variables: {
        input: {
          amount,
        },
      },
      refetchQueries: [
        {
          query: User.queries.get,
          variables: {
            id: currentUser?.id,
            withPrivate: true,
          },
        },
      ],
    }
  );

  const handleSubmitError = useCallback(
    error =>
      setError("amount", {
        ...error,
        message: `${error.code} - ${error.message}`,
      }),
    [setError]
  );

  const onSubmit = useCallback(
    ({ amount }) => {
      return convertPointsToCash()
        .then(data => onSuccess(amount))
        .catch(handleSubmitError);
    },
    [navigation, convertPointsToCash, handleSubmitError]
  );

  const doSubmit = handleSubmit(onSubmit);

  return (
    <View
      style={[
        {
          flex: 1,
        },
        style ?? {},
      ]}
    >
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              autoCorrect={false}
              autoCapitalize={"none"}
              autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
              keyboardType="decimal-pad"
              left={<TextInput.Icon name="currency-usd" />}
              textContentType="none"
              label="Amount"
              error={!!errors.amount}
              returnKeyLabel={"Done"}
              returnKeyType={"done"}
              onSubmitEditing={doSubmit}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value =>
                onChange(
                  formatValue((value ?? "").replace(/[^0-9.]/gi, "") || 0)
                )
              }
              value={`${
                value.toString() === "0" ? "" : addCommas(value.toString())
              }`}
              style={{
                backgroundColor: "transparent",
              }}
            />
            {!!errors.amount && (
              <HelperText type="error" visible={!!errors.amount}>
                {errors.amount?.message}
              </HelperText>
            )}
            {!errors.amount && (
              <HelperText type="info" visible={!errors.amount}>
                You have {addCommas(currentUser?.availablePoints ?? 0)}{" "}
                available points, which can be converted into up to $
                {addCommas(
                  Math.floor((currentUser?.availablePoints ?? 0) / 250)
                )}{" "}
                in bonus cash.
              </HelperText>
            )}
          </>
        )}
        name="amount"
        defaultValue={""}
      />

      <ScreenBottom theme={theme}>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          {!!isSubmitting ? "Please Wait..." : "Redeem"}
        </Button>
      </ScreenBottom>
    </View>
  );
};

export default ({ navigation }) => {
  const theme = useTheme();
  const [amount, setAmount] = useState(false);
  const handleSuccess = useCallback(amount => [setAmount(amount)], []);

  const handleDismiss = useCallback(
    () => [setAmount(false), navigation.push("Main")],
    [navigation]
  );

  return (
    <Screen
      gatedTo="member"
      title="Redeem Points"
      hasKeyboard={true}
      backgroundColor={theme.colors.surface}
    >
      <Form
        style={{
          marginTop: theme.spacing.xl,
        }}
        theme={theme}
        navigation={navigation}
        onSuccess={handleSuccess}
      />

      <BottomSheet visible={!!amount} onDismiss={handleDismiss} fullScreen>
        {!!amount && (
          <Confirmation
            title={"Bonus Converted!"}
            type={"success"}
            primaryButtonLabel={"Close"}
            onPrimaryButtonPress={handleDismiss}
            subheading={`You redeemed $${amount} in Bonus Cash.`}
          />
        )}
      </BottomSheet>
    </Screen>
  );
};
