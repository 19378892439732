import { useQuery } from "@apollo/client";
import Bid from "shared/api/Bid";

export default ({ listing }) => {
  const { data: { bidsByListingId: { items = [] } = {} } = {} } = useQuery(
    Bid.queries.listByListingId,
    {
      skip:
        !listing?.id || !!listing?.cachedBid || listing?.pendingBidsCount < 1,
      variables: {
        bidListingId: listing?.id,
        sortDirection: "DESC",
        limit: 100,
        filter: {
          status: {
            eq: "PENDING",
          },
        },
      },
    }
  );

  const bid = listing?.cachedBid ?? items[0];

  return bid;
};
