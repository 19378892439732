export default theme => ({
  contentBody: {
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  avatarContainer: {
    marginRight: theme.spacing.md,
  },
});
