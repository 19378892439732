import { Text } from "react-native-paper";
import { formatPennies } from "shared/Util/money";
import suggestedPriceInPennies from "Util/suggestedPriceInPennies";

export default ({ caluclatedOdds, wagerInPennies }) => (
  <Text>
    {formatPennies(
      suggestedPriceInPennies({
        wagerInPennies,
        currentCalculatedOdds: caluclatedOdds,
      }),
      "$"
    )}
  </Text>
);
