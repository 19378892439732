import { useNavigation } from "@react-navigation/core";
import DismissKeyboard from "Components/DismissKeyboard";
import useGate from "Hooks/useGate";
import React, { useEffect } from "react";
import { useWindowDimensions } from "react-native";
import ScreenView from "./ScreenView";
import { useHeaderHeight } from "@react-navigation/elements";
import useStyles from "Hooks/useStyles";
import ScreenStyles from "./ScreenStyles";
import { useKeyboard } from "@react-native-community/hooks";

// import { useHeaderHeight } from '@react-navigation/stack';

export default ({
  children,
  gatedTo,
  gatedDestination,
  title,
  hasKeyboard,
  fullWidth,
  fullHeight,
  backgroundColor,
  hasScroll,
  keyboardAdjust,
}) => {
  const navigation = useNavigation();
  const screenHeight = useWindowDimensions().height;
  const { theme, styles } = useStyles({ Styles: ScreenStyles });
  const keyboard = useKeyboard();

  let headerHeight = 0;
  try {
    headerHeight = useHeaderHeight();
  } catch {}

  useGate({ gateType: gatedTo, to: gatedDestination });

  useEffect(() => {
    !!title && navigation.setOptions({ title });
  }, [navigation, title]);

  return (
    <ScreenView
      backgroundColor={backgroundColor}
      children={children}
      theme={theme}
      styles={styles}
      keyboardHeight={
        !!keyboard.keyboardShown
          ? keyboard.keyboardHeight - (keyboardAdjust ?? 90)
          : 0
      }
      hasKeyboard={hasKeyboard}
      Wrapper={!!hasKeyboard ? DismissKeyboard : React.Fragment}
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      hasScroll={hasScroll}
      screenHeight={screenHeight}
      headerHeight={headerHeight}
    />
  );
};
