export default theme => ({
  unlistedNotice: {
    fontWeight: "bold",
    color: theme.colors.secondary,
  },
  listingInfo: {
    flex: 2,
    marginLeft: theme.spacing.md,
  },
  listingInfoBody: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listingMeterWrapper: {
    margin: theme.spacing.sm,
  },
  ticketInfo: {
    flex: 3,
    marginRight: theme.spacing.md,
  },
  ticketActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    flex: 2,
  },
  ticketActionsWrapper: {
    flexDirection: "row",
    justifyContent: "flex-end",
    flex: 1,
    marginTop: theme.spacing.xs,
  },
  actionWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 3,
  },
  iconWrapper: {
    marginRight: theme.spacing.sm,
  },
  sportWrapper: {
    flexDirection: "row",
    flex: 1,
    marginTop: theme.spacing.sm,
  },
  activityTypeWrapper: {
    backgroundColor: theme.colors.labelBackground,
    marginTop: theme.spacing.lg,
    paddingHorizontal: theme.spacing.sm,
    paddingVertical: theme.spacing.xs,
  },
  soldWrapper: {
    position: "absolute",
    top: 16,
    right: 16,
    zIndex: 3,
  },
  soldText: {
    fontSize: 60,
    color: theme.colors.disabled,
    textTransform: "uppercase",
    transform: [{ rotate: "-8deg" }],
    // ,transform: "rotate(-8deg)"
  },
  topRow: {
    flexDirection: "row",
    marginBottom: theme.spacing.xs,
    borderTopLeftRadius: theme.roundness,
    borderTopRightRadius: theme.roundness,
  },
});
