import {
  Button,
  Divider,
  HelperText,
  List,
  Paragraph,
  Subheading,
  Text,
  TextInput,
} from "react-native-paper";
import Screen from "Components/Screen";
import {
  addCommas,
  convertMoneyTextToPennies,
  formatPennies,
} from "shared/Util/money";
import ScreenBottom from "Components/Screen/Bottom";
import { View, ScrollView, Platform } from "react-native";
import Confirmation from "Components/Confirmation";
import BottomSheet from "Components/BottomSheet";
import PromoCode from "Components/PromoCode";
import { ALLOWED_STATES } from "shared/Config";

export default ({
  locationString,
  isGeographicallyAllowedToDeposit,
  theme,
  type,
  navigateTo,
  selectedPaymentMethod,
  currentUser,
  errorMessages,
  isSubmitting,
  amount,
  submitted,
  doSubmit,
  fee,
  bonus,
  onPromotionApply,
  promotionId,
  onVerificationPress,
  styles,
  cvv,
  onCvvChange,
  error,
  isValid,
}) => (
  <Screen
    gatedTo="member"
    title="Deposit Funds"
    fullHeight
    backgroundColor={theme.colors.surface}
  >
    <ScrollView style={[styles.fullScreen]}>
      {!!isGeographicallyAllowedToDeposit && (
        <>
          <List.Item
            title="Amount"
            description={(!!amount && `$${addCommas(amount)}`) || " "}
            right={props => <List.Icon {...props} icon="chevron-right" />}
            onPress={navigateTo("DepositAmount", {
              backTo: type === "cc" ? "CreditCardSummary" : "AchSummary",
              amount,
              promotionId,
            })}
          />
          <Divider />
          {type === "cc" && (
            <>
              <List.Item
                title="Card"
                description={
                  (!!selectedPaymentMethod &&
                    `${selectedPaymentMethod.Network} - ${selectedPaymentMethod.CardNumber}`) ||
                  " "
                }
                right={props => <List.Icon {...props} icon="chevron-right" />}
                onPress={navigateTo("CreditCardSelect", {
                  lastFour: selectedPaymentMethod?.Token ?? "",
                  amount,
                  promotionId,
                })}
              />
            </>
          )}
          {type !== "cc" && (
            <List.Item
              title="Account"
              description={
                (!!selectedPaymentMethod &&
                  `Acct # ending in ${selectedPaymentMethod.DisplayName}`) ||
                " "
              }
              right={props => <List.Icon {...props} icon="chevron-right" />}
              onPress={navigateTo("AchSelect", {
                lastFour: selectedPaymentMethod?.Token ?? "",
                amount,
                promotionId,
              })}
            />
          )}
          {type === "cc" && (
            <>
              <Divider />
              <TextInput
                error={!!error}
                maxLength={4}
                autoCorrect={false}
                autoCapitalize="none"
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "cc-csc"
                }
                keyboardType="number-pad"
                textContentType="none"
                label="CVV"
                returnKeyLabel="Done"
                returnKeyType="done"
                mode="flat"
                onChangeText={onCvvChange}
                value={cvv ?? ""}
                style={{
                  backgroundColor: "transparent",
                }}
              />
            </>
          )}
          <HelperText type="error" visible={!!error}>
            {error?.message?.includes?.("2")
              ? "There was an error processing your deposit. Please review your Personal Information on the My Account page. If your issue persists, please contact info@propswap.com"
              : "Payment not accepted. Please try again."}
          </HelperText>
          <Divider />
        </>
      )}
      <View style={styles.wrapper}>
        {!!isGeographicallyAllowedToDeposit && (
          <PromoCode onSuccess={onPromotionApply} promotionId={promotionId} />
        )}

        <View style={{ marginTop: theme.spacing.lg }}>
          <Paragraph style={styles.textBold}>
            You MUST be in one of the following states to place a deposit.
          </Paragraph>
          <Paragraph>
            {ALLOWED_STATES.filter(state => state !== "Massachusetts")
              .map(state => state)
              .join(", ")}
          </Paragraph>
          {!isGeographicallyAllowedToDeposit && !!locationString && (
            <Paragraph style={styles.textBold}>
              Your Current Location is: {locationString}
            </Paragraph>
          )}
        </View>

        {!!isGeographicallyAllowedToDeposit && (
          <>
            <View style={styles.summaryWrapper}>
              <View style={styles.headingWrapper}>
                <Subheading>Deposit Summary</Subheading>
              </View>
              {/* <View style={{flex: 1, flexDirection: "row", marginBottom: theme.spacing.md, justifyContent: "space-between"}}>
                  <Text>Available Balance:</Text>
                  <Text style={{textAlign: "right"}}>{formatPennies(currentUser?.balance, "$")}</Text>
                </View> */}
              <View style={styles.headingWrapper}>
                <Text>Amount:</Text>
                <Text style={styles.textRight}>${amount}</Text>
              </View>
              {!!bonus && (
                <View style={styles.headingWrapper}>
                  <Text>Bonus:</Text>
                  <Text style={styles.textRight}>${bonus}</Text>
                </View>
              )}
              <View style={styles.headingWrapper}>
                <Text>Service Fee:</Text>
                {!!fee && (
                  <Text style={styles.textRight}>
                    {formatPennies(
                      fee * convertMoneyTextToPennies(amount),
                      "$"
                    )}
                  </Text>
                )}
                {!fee && (
                  <Text
                    style={[
                      styles.textRight,
                      styles.textUpper,
                      styles.textPrimary,
                    ]}
                  >
                    Free
                  </Text>
                )}
              </View>
              {/* <View style={{flex: 1, flexDirection: "row", marginBottom: theme.spacing.md, justifyContent: "space-between"}}>
                  <Text>Amount to be {type === 'cc' ? "Charged" : "Transferred"}:</Text>
                  <Text style={{textAlign: "right"}}>{formatPennies((fee * convertMoneyTextToPennies(amount) + convertMoneyTextToPennies(amount)), "$")}</Text>
                </View> */}
              <View style={{ marginBottom: theme.spacing.md }}>
                <Divider />
              </View>
              <View style={styles.headingWrapper}>
                <Text>Total Deposit into Account:</Text>
                <Text style={styles.textRight}>
                  {formatPennies(
                    convertMoneyTextToPennies(bonus) +
                      convertMoneyTextToPennies(amount),
                    "$"
                  )}
                </Text>
              </View>
            </View>
          </>
        )}
      </View>
    </ScrollView>

    {!!isGeographicallyAllowedToDeposit && (
      <ScreenBottom adjustPadding>
        {!currentUser?.verifiedAt && (
          <Button
            uppercase={false}
            compact
            color={theme.colors.secondary}
            onPress={onVerificationPress}
            style={[styles.fullWidth, { marginBottom: theme.spacing.xl }]}
            mode="outlined"
          >
            Click this link to Verify your account.
          </Button>
        )}
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={styles.fullWidth}
        >
          {!!isSubmitting ? "Please Wait..." : "Deposit"}
        </Button>

        {type !== "cc" && false && (
          <Text style={styles.textNotification}>
            Please allow up to 5 business days for funds to be available.
            Deposits via Credit Card are available immediately.
          </Text>
        )}

        {errorMessages?.map((errorMessage, i) => (
          <Text
            key={i}
            style={([{ marginTop: theme.spacing.md }], styles.textError)}
          >
            {errorMessage}
          </Text>
        ))}
      </ScreenBottom>
    )}

    <BottomSheet
      visible={!!submitted}
      onDismiss={navigateTo("Main", { screen: "Buy" }, "replace")}
      fullScreen
    >
      {!!submitted && (
        <Confirmation
          title={`Deposit ${type === "cc" ? "Successful" : "Pending"}`}
          subtitle={
            type === "cc"
              ? "The funds are immediately available"
              : "You will be notified when the funds are available"
          }
          type="success"
          primaryButtonLabel="Buy Tickets"
          onPrimaryButtonPress={navigateTo(
            "Main",
            { screen: "Buy" },
            "replace"
          )}
          secondaryButtonLabel="View Transaction History"
          onSecondaryButtonPress={navigateTo("Account", {
            screen: "TransactionStack",
          })}
        />
      )}
    </BottomSheet>
  </Screen>
);
