import Constants from "expo-constants";

// sentry_url will be the same for every env.
// You have to add the project to the gunner tech account to get the sentry_url
// (https://sentry.io/organizations/gunner-technology/projects/new/)
// const sentry_url = "<sentry-url>";

const ENV = {
  cody: {
    use_v2: true,
    v2_bang: "!",
    cdn: "d3bs6ok0h9777e.cloudfront.net",
    base_url: "https://cody.d33wk3w9ow2x6k.amplifyapp.com",
    sentry_url:
      "https://f25218a744054a05959feeb63f04add8@o203714.ingest.sentry.io/1776300",
    sentry_token:
      "affb46815519416b80ebd1f2a4b72c4092ce27d1fe4e44638d7c69ad513c735b",
    ga: "UA-71312402-4",
    bluesnap_encryption_key:
      "10001$a97a668256cb27f7ae48ff06851b0fbd617191dd7242072f1f45d549bb86570144142a7cfd95a2e1b57c824ba8033083778f22133d5a4dfbe84dcd72fa973580fb770d7908488eedb0722d0239286861dd3d19ecf07c2b1204a65d13de62e5792c2ae613b1410c0b984a836e6ace80dd4f3b9d8380ef75fe051780227fb5937e0e6a8daa0253c1a3f97262d43e10b370081cb20137264933641f6c35848067424700f14c22b42eebc381ca5c74ead31f23be7e89008fdcb368d4ed7f9ee12a12c108c2582be3515b68fc28fc8e85c577a7f70245a592751effe9449f734943976bce36768b4293ab242dd44d80fa8fdf28a6b9da63b7db65dc77e6cf414a0715",
    stage: "development",
    oauth_url:
      "https://propswapcc73e73d-cc73e73d-cody.auth.us-east-1.amazoncognito.com/login?client_id=7aif5a8d1a00heqv0jitgda9uq&response_type=code&redirect_uri=http://localhost:19006/oauth/",
  },

  staging: {
    use_v2: true,
    v2_bang: "!",
    cdn: "dr7rwb9vnk0kt.cloudfront.net",
    base_url: "https://propswapstaging.com",
    sentry_url:
      "https://f25218a744054a05959feeb63f04add8@o203714.ingest.sentry.io/1776300",
    sentry_token:
      "affb46815519416b80ebd1f2a4b72c4092ce27d1fe4e44638d7c69ad513c735b",
    ga: "UA-71312402-4",
    bluesnap_encryption_key:
      "10001$a97a668256cb27f7ae48ff06851b0fbd617191dd7242072f1f45d549bb86570144142a7cfd95a2e1b57c824ba8033083778f22133d5a4dfbe84dcd72fa973580fb770d7908488eedb0722d0239286861dd3d19ecf07c2b1204a65d13de62e5792c2ae613b1410c0b984a836e6ace80dd4f3b9d8380ef75fe051780227fb5937e0e6a8daa0253c1a3f97262d43e10b370081cb20137264933641f6c35848067424700f14c22b42eebc381ca5c74ead31f23be7e89008fdcb368d4ed7f9ee12a12c108c2582be3515b68fc28fc8e85c577a7f70245a592751effe9449f734943976bce36768b4293ab242dd44d80fa8fdf28a6b9da63b7db65dc77e6cf414a0715",
    stage: "staging",
    oauth_url:
      "https://propswapcc73e73d-cc73e73d-staging.auth.us-east-1.amazoncognito.com/login?client_id=22ce68jhhljs1hbe0o1qeod2s&response_type=code&redirect_uri=https://propswapstaging.com/oauth/",
  },
  prod: {
    use_v2: true,
    v2_bang: "!",
    cdn: "d1pjjt0i9ewcn.cloudfront.net",
    base_url: "https://propswap.com",
    sentry_url:
      "https://f25218a744054a05959feeb63f04add8@o203714.ingest.sentry.io/1776300",
    sentry_token:
      "affb46815519416b80ebd1f2a4b72c4092ce27d1fe4e44638d7c69ad513c735b",
    ga: "UA-71312402-3",
    bluesnap_encryption_key:
      "10001$918c95adbdb61af42f0876b934e35e85abf92767b6c5ccd9a7a956dd9a56968fca8ab18a5f24149c53ebffacf83ce332427ae959f9f1f4e37cd25be95d3c5c32e9d9e121484a9a198c4a394e1fd41173bd9290fcd8f858e97ba7bac0aa0244e5e624214443c3ea3d769c7bd881d0a2d174354b8102798291e0393b00beae091e0ee4ef40a17a149e2860a6ad4ba7eef185c13c0927edcd9598b54383749fab3d60a9f5614c6886c034c1578de5ab22ff3703881b64be18d86ef3bbd8044fb0f784eda8b191f6662040b2b14644c7345e908831b330b45936b85cb1ee212828a126fb49794425c67b27268f55714736c03bd46d5b5994473d3ad2b6ef2e9f7825",
    stage: "production",
  },

  dary: {
    cdn: "d318wd2uqf9jsy.cloudfront.net",
    base_url: "https://dary.d35g4cufl7b8q9.amplifyapp.com",
    sentry_url: "<sentry-url>",
  },
  //<new-environment>
};

function getEnvVars(env = "") {
  return ENV[env];
}

export default getEnvVars(
  Constants.expoConfig?.extra?.ENV ??
    Constants.manifest?.releaseChannel ??
    Constants.manifest?.extra?.ENV ??
    "development"
);
