import { useQuery } from "@apollo/client";
import SegmentedControl from "@react-native-segmented-control/segmented-control";
import Screen from "Components/Screen";
import ScreenBottom from "Components/Screen/Bottom";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import formatOdds from "Util/formatOdds";
import React, { useContext, useCallback } from "react";
import { ScrollView, View } from "react-native";
import { Divider, List, useTheme, Button } from "react-native-paper";
import { addCommas } from "shared/Util/money";
import Locale from "shared/api/Locale";
import Sport from "shared/api/Sport";
import Sportsbook from "shared/api/Sportsbook";

const listOptions = ["List Now", "List Later"];

const SportItem = ({ id, ...rest }) => {
  const { data: { getSport } = {} } = useQuery(Sport.queries.get, {
    skip: !id,
    variables: {
      id,
    },
  });

  return <List.Item description={getSport?.abbrev || " "} {...rest} />;
};

export default ({ navigation }) => {
  const theme = useTheme();
  console.log("theme", theme);
  const { setTicketValues, ticketValues } = useContext(TicketContext);

  const { data: { getLocale } = {} } = useQuery(Locale.queries.get, {
    skip: !ticketValues?.ticketLocaleId,
    variables: {
      id: ticketValues?.ticketLocaleId,
    },
  });

  const { data: { getSportsbook } = {} } = useQuery(Sportsbook.queries.get, {
    skip: !ticketValues?.ticketSportsbookId,
    variables: {
      id: ticketValues?.ticketSportsbookId,
    },
  });

  const { data: { getSport } = {} } = useQuery(Sport.queries.get, {
    skip: !ticketValues?.ticketSportId,
    variables: {
      id: ticketValues?.ticketSportId,
    },
  });

  const toggleListNow = useCallback(
    () =>
      setTicketValues(ticketValues => ({
        ...ticketValues,
        listNow: !ticketValues.listNow,
      })),
    [setTicketValues]
  );

  const navigateTo = useCallback(
    (screen, params) => () => navigation.push(screen, params),
    [navigation]
  );

  const isValid =
    !!ticketValues.ticketLocaleId &&
    !!ticketValues.ticketSportsbookId &&
    !!ticketValues.ticketSportId &&
    !!ticketValues.betType &&
    !!ticketValues.betAmount &&
    !!ticketValues.winAmount &&
    !!ticketValues.eventDescription &&
    !!ticketValues.subject &&
    (ticketValues.betType !== "PARLAY" ||
      [...Array(parseInt(ticketValues.parlayLegs || 2)).keys()].every(
        i =>
          !!(ticketValues.parlay ?? {})[i]?.eventDescription &&
          !!(ticketValues.parlay ?? {})[i]?.subject
      ));

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={2}
        title="Ticket Info"
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />
      <ScrollView style={{ flex: 1 }} contentContainerStyle={{}}>
        <List.Item
          title="Ticket Location"
          description={
            !!getLocale
              ? getLocale.state === getLocale.name
                ? getLocale.state
                : `${getLocale.state} / ${getLocale.name}`
              : " "
          }
          right={
            ticketValues.isSharpSports
              ? undefined
              : props => <List.Icon {...props} icon="chevron-right" />
          }
          disabled={ticketValues.isSharpSports}
          onPress={
            ticketValues.isSharpSports
              ? undefined
              : navigateTo("TicketNewLocation")
          }
        />
        <Divider />
        <List.Item
          title="Sportsbook"
          description={!!getSportsbook ? `${getSportsbook.name}` : " "}
          right={
            ticketValues.isSharpSports
              ? undefined
              : props => <List.Icon {...props} icon="chevron-right" />
          }
          disabled={ticketValues.isSharpSports}
          onPress={
            ticketValues.isSharpSports
              ? undefined
              : navigateTo("TicketNewSportsbook")
          }
        />
        <Divider />
        <List.Item
          title="Bet Type"
          description={
            ticketValues.betType === "PARLAY"
              ? `PARLAY (${ticketValues.parlayLegs ?? 0} Legs)`
              : ticketValues.betType || " "
          }
          right={
            ticketValues.isSharpSports
              ? undefined
              : props => <List.Icon {...props} icon="chevron-right" />
          }
          disabled={ticketValues.isSharpSports}
          onPress={
            ticketValues.isSharpSports
              ? undefined
              : navigateTo("TicketNewBetType")
          }
        />
        {ticketValues.betType === "PARLAY" &&
          [...Array(parseInt(ticketValues.parlayLegs || 2)).keys()].map(i => (
            <React.Fragment key={i}>
              <Divider />
              <SportItem
                id={
                  ticketValues.parlay?.[i]?.ticketSportId ??
                  ticketValues.parlay?.[i]?.sportId
                }
                title={`Sport #${i + 1}`}
                right={
                  ticketValues.isSharpSports
                    ? undefined
                    : props => <List.Icon {...props} icon="chevron-right" />
                }
                disabled={ticketValues.isSharpSports}
                onPress={
                  ticketValues.isSharpSports
                    ? undefined
                    : navigateTo("TicketNewSport", { leg: i })
                }
              />
              <Divider />
              {(!!ticketValues.parlay?.[i]?.ticketSportId ||
                !!ticketValues.parlay?.[i]?.sportId) && (
                <>
                  <List.Item
                    title={`Event #${i + 1}`}
                    description={
                      (ticketValues.betType === "PARLAY"
                        ? ticketValues.parlay?.[i]?.eventDescription
                        : ticketValues.eventDescription) || " "
                    }
                    right={props => (
                      <List.Icon {...props} icon="chevron-right" />
                    )}
                    disabled={ticketValues.isSharpSports}
                    onPress={
                      ticketValues.isSharpSports
                        ? undefined
                        : navigateTo("TicketNewEvent", { leg: i })
                    }
                  />
                  <Divider />
                </>
              )}
              {!!ticketValues.parlay?.[i]?.eventDescription && (
                <>
                  <List.Item
                    title={`Team / Player #${i + 1}`}
                    description={ticketValues.parlay?.[i]?.subject || " "}
                    right={
                      isValid && ticketValues.isSharpSports
                        ? undefined
                        : props => <List.Icon {...props} icon="chevron-right" />
                    }
                    disabled={isValid && ticketValues.isSharpSports}
                    onPress={
                      isValid && ticketValues.isSharpSports
                        ? undefined
                        : navigateTo("TicketNewOption", { leg: i })
                    }
                  />
                  <Divider />
                </>
              )}
            </React.Fragment>
          ))}
        {ticketValues.betType !== "PARLAY" && (
          <>
            <Divider />
            <List.Item
              title="Sport"
              description={getSport?.abbrev || " "}
              right={
                ticketValues.isSharpSports
                  ? undefined
                  : props => <List.Icon {...props} icon="chevron-right" />
              }
              disabled={ticketValues.isSharpSports}
              onPress={
                ticketValues.isSharpSports
                  ? undefined
                  : navigateTo("TicketNewSport")
              }
            />
            <Divider />
            {!!ticketValues.ticketSportId && (
              <>
                <List.Item
                  title="Event"
                  description={ticketValues.eventDescription || " "}
                  right={
                    ticketValues.isSharpSports
                      ? undefined
                      : props => <List.Icon {...props} icon="chevron-right" />
                  }
                  disabled={ticketValues.isSharpSports}
                  onPress={
                    ticketValues.isSharpSports
                      ? undefined
                      : navigateTo("TicketNewEvent")
                  }
                />
                <Divider />
              </>
            )}
            {!!ticketValues.eventDescription && (
              <>
                <List.Item
                  title="Team / Player"
                  description={ticketValues.subject || " "}
                  right={
                    isValid && ticketValues.isSharpSports
                      ? undefined
                      : props => <List.Icon {...props} icon="chevron-right" />
                  }
                  disabled={isValid && ticketValues.isSharpSports}
                  onPress={
                    isValid && ticketValues.isSharpSports
                      ? undefined
                      : navigateTo("TicketNewOption")
                  }
                />
                <Divider />
              </>
            )}
          </>
        )}
        <List.Item
          title="Bet Amount"
          description={
            !ticketValues.betAmount
              ? " "
              : `$${addCommas(ticketValues.betAmount)}`
          }
          right={
            ticketValues.isSharpSports
              ? undefined
              : props => <List.Icon {...props} icon="chevron-right" />
          }
          disabled={ticketValues.isSharpSports}
          onPress={
            ticketValues.isSharpSports
              ? undefined
              : navigateTo("TicketNewBetAmount")
          }
        />
        <Divider />
        <List.Item
          title="Win Amount"
          description={
            !ticketValues.winAmount
              ? " "
              : `$${addCommas(ticketValues.winAmount)} ${
                  !!ticketValues.betAmount
                    ? `\
  (Odds: ${formatOdds(
    parseFloat(ticketValues.betAmount) + parseFloat(ticketValues.winAmount),
    parseFloat(ticketValues.betAmount)
  )}, Collect: $${addCommas(
                        parseFloat(ticketValues.betAmount) +
                          parseFloat(ticketValues.winAmount)
                      )})\
  `
                    : ""
                }\
          `
          }
          right={
            ticketValues.isSharpSports
              ? undefined
              : props => <List.Icon {...props} icon="chevron-right" />
          }
          disabled={ticketValues.isSharpSports}
          onPress={
            ticketValues.isSharpSports
              ? undefined
              : navigateTo("TicketNewWinAmount")
          }
        />
        <Divider />
        <View style={{ margin: theme.spacing.xl }}>
          <SegmentedControl
            appearance={theme.dark ? "dark" : "light"}
            values={listOptions}
            selectedIndex={!!ticketValues.listNow ? 0 : 1}
            onChange={toggleListNow}
          />
        </View>
      </ScrollView>

      <ScreenBottom style={{ marginHorizontal: theme.spacing.xl }}>
        <Button
          disabled={!isValid}
          mode="contained"
          onPress={navigateTo("TicketNewStep4")}
          style={{ width: "100%" }}
        >
          {!!isValid ? "Continue" : "Fill Out Form"}
        </Button>
      </ScreenBottom>
    </Screen>
  );
};
