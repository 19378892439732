import { View } from "react-native";
import CircleIndicatorItem from "./CircleIndicatorItem";

const CircleIndicatorView = ({
  itemCount,
  theme,
  scrolledIndex,
  onItemPress,
}) => (
  <View
    style={{
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      marginLeft: -1 * theme.spacing.sm,
      marginTop: theme.spacing.md * -2,
      marginRight: theme.spacing.xl,
      paddingBottom: theme.spacing.sm,
    }}
  >
    {[...Array(itemCount).keys()].map(i => (
      <CircleIndicatorItem
        theme={theme}
        selected={scrolledIndex === i}
        key={i}
        onPress={onItemPress}
        index={i}
      />
    ))}
  </View>
);

export default CircleIndicatorView;
