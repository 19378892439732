import React, { useContext, useCallback } from "react";
import { Divider, List, useTheme } from "react-native-paper";

import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import { useQuery } from "@apollo/client";
import Sport from "shared/api/Sport";
import { ScrollView } from "react-native";

export default ({ navigation, route }) => {
  const theme = useTheme();
  const { setTicketValues, ticketValues } = useContext(TicketContext);
  const leg = route?.params?.leg;
  const hasLeg = leg !== undefined && leg !== null;

  const { data: { listSports: { items = [] } = {} } = {} } = useQuery(
    Sport.queries.list,
    {
      variables: {
        limit: 200,
      },
    }
  );

  const onSubmit = useCallback(
    ({ ticketSportId }) =>
      () =>
        [
          hasLeg &&
            setTicketValues(ticketValues => ({
              ...ticketValues,
              parlay: {
                ...(ticketValues.parlay ?? {}),
                [leg]: {
                  ...(ticketValues.parlay?.[leg] ?? {}),
                  ticketSportId,
                },
              },
            })),
          !hasLeg &&
            setTicketValues(ticketValues => ({
              ...ticketValues,
              ticketSportId,
              ticketEventId: null,
              eventDescription: "",
            })),
          navigation.navigate("TicketNewStep3"),
        ],
    [hasLeg, setTicketValues, navigation, leg]
  );

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={2}
        title="Choose Sport"
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />

      <ScrollView>
        {items
          .slice()
          .sort((a, b) => (a.position > b.position ? 1 : -1))
          .map(sport => (
            <React.Fragment key={sport.id}>
              <List.Item
                title={`${sport.abbrev}`}
                right={props =>
                  ticketValues.ticketSportId === sport.id ? (
                    <List.Icon {...props} icon="check-circle-outline" />
                  ) : null
                }
                onPress={onSubmit({ ticketSportId: sport.id })}
              />
              <Divider />
            </React.Fragment>
          ))}
      </ScrollView>
    </Screen>
  );
};
