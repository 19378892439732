export default theme => ({
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    // marginHorizontal: theme?.sizes?.spacing?.xl
  },
  iconHeadlineWrapper: {
    marginRight: theme.spacing.md,
  },
  headlineWrapper: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  iconWrapper: {
    width: 60,
  },
});
