import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect, useContext } from "react";
import ForSaleModalStack from "./ForSaleModalStack";
import MyBidsModalStack from "./MyBidsModalStack";
import NotForSaleModalStack from "./NotForSaleModalStack";

import { useSafeAreaInsets } from "react-native-safe-area-context";
import Activity from "Screens/Activity";
import Notifications from "Screens/Notifications";
import WatchList from "Screens/WatchList";
import DepositStack from "./DepositStack";
import { getPartialModalOptions, headerNavOptions } from "./index";
import ListingModalStack from "./ListingModalStack";
import PointsStack from "./PointsStack";
import ProfileStack from "./ProfileStack";
import { useQuery } from "@apollo/client";
import Bid from "shared/api/Bid";
import { CurrentUserContext } from "Contexts/CurrentUser";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  const insets = useSafeAreaInsets();
  const currentUser = useContext(CurrentUserContext);
  const { data } = useQuery(Bid.queries.listByBidderId, {
    pollInterval: 5000,
    skip: !currentUser?.id,
    limit: 100,
    variables: {
      bidBidderId: currentUser?.id,
      statusUpdatedAt: {
        eq: {
          status: "PENDING",
        },
      },
    },
  });

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={{
        ...screenOptions,
        ...headerNavOptions,
        animationEnabled: false,
      }}
    >
      <Stack.Screen name="ForSaleModalStack" component={ForSaleModalStack} />
      <Stack.Screen
        name="NotForSaleModalStack"
        component={NotForSaleModalStack}
      />
      <Stack.Screen
        name="MyBidsModalStack"
        component={MyBidsModalStack}
        options={{
          title: `My Bids (${data?.bidsByBidderId?.items?.length ?? 0})`,
        }}
      />
      <Stack.Screen name="Activity" component={Activity} />
      <Stack.Screen
        name="WatchList"
        component={WatchList}
        // options={modalOptions}
      />
      <Stack.Screen name="Notifications" component={Notifications} />
      <Stack.Screen
        name="ListingModalStack"
        component={ListingModalStack}
        options={getPartialModalOptions({ offset: insets.top })}
      />
      <Stack.Screen name="ProfileStack" component={ProfileStack} />
      <Stack.Screen name="PointsStack" component={PointsStack} />
      <Stack.Screen name="DepositStack" component={DepositStack} />
    </Stack.Navigator>
  );
};
