import Screen from "Components/Screen";
import { TabBarContext } from "Contexts/TabBar";
import { useContext, useLayoutEffect, useCallback } from "react";
// import { useState } from 'react';
// import BottomSheet from 'Components/BottomSheet';
import { useTheme } from "react-native-paper";
import ListingEdit from "Components/Listing/Edit";
// import Confirmation from 'Components/Confirmation';
// import { View } from 'react-native';
// import { formatPennies } from 'shared/Util/money';

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation, route, ...rest }) => {
  const theme = useTheme();
  const tabBar = useContext(TabBarContext);
  // const [listings, setListings] = useState(null);

  const handleSubmit = useCallback(
    (mutationResults, originalListing) => [
      // setListings([originalListing, (mutationResults.data.pauseListing??mutationResults.data.reactivateListing)]),
      navigation.navigate("ListingDetail", { id: originalListing.id }),
    ],
    [navigation]
  );

  // const handleBottomSheetDismiss = useCallback(() =>
  //   setListings(null)
  // , [])

  // const handleNavigate = useCallback(() => [
  //   setListings(null),
  //   navigation.navigate("MyTickets")
  // ], [navigation])

  // const handleViewPoints = useCallback(() => [
  //   setListings(null),
  //   navigation.push("Main", {screen: "Account", params: {screen: "PointsStack"}})
  // ], [navigation])

  // const action = listings?.[0]?.status === "NEW" && listings?.[1]?.status === "ACTIVE" ? "list" : listings?.[1]?.status === "ACTIVE" && listings?.[0]?.status !== "ACTIVE" ? "relist" : listings?.[0]?.status === "ACTIVE" && listings?.[1]?.status !== "ACTIVE" ? "unlisted" : "edit"

  useLayoutEffect(() => {
    tabBar.setVisible(false);

    return () => tabBar.setVisible(true);
  }, [tabBar]);

  return (
    <Screen fullHeight backgroundColor={theme.colors.surface}>
      <ListingEdit listingId={route.params?.id} onSubmit={handleSubmit} />
    </Screen>
  );
};
