import React from "react";

import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import PasswordResetRequest from "Screens/PasswordResetRequest";
import PasswordResetConfirmation from "Screens/PasswordResetConfirmation";
import PasswordResetNew from "Screens/PasswordResetNew";
import PasswordResetSuccess from "Screens/PasswordResetSuccess";
import { getStandardScreenOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={getStandardScreenOptions({ screenOptions, navigation })}
    >
      <Stack.Screen
        name="PasswordResetRequest"
        component={PasswordResetRequest}
      />
      <Stack.Screen
        name="PasswordResetConfirmation"
        component={PasswordResetConfirmation}
      />
      <Stack.Screen name="PasswordResetNew" component={PasswordResetNew} />
      <Stack.Screen
        name="PasswordResetSuccess"
        component={PasswordResetSuccess}
        options={{
          headerLeft: () => null,
        }}
      />
    </Stack.Navigator>
  );
};
