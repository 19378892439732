import { Divider, Paragraph, Title } from "react-native-paper";
import { View } from "react-native";
import ResponsiveImage from "Components/ResponsiveImage";
import { ian, luke } from "assets";

export default ({ styles, theme }) => (
  <>
    <View style={[styles.section, { marginTop: 0 }]}>
      <Title style={styles.title}>What is PropSwap?</Title>
      <Paragraph>
        PropSwap is the largest secondary market for sports bets in America.
        Sellers are able to lock in a profit on a bet they've previously made,
        while Buyers are able to secure the best odds in the world, by buying
        directly from other gamblers. 
      </Paragraph>
      <Paragraph>
        When you make the bet with the intention to sell it, the whole approach
        to betting changes. With PropSwap, your bet doesn’t need to win in order
        to make money, it just needs to improve.
      </Paragraph>
      <Paragraph>
        We accept sports betting tickets from any licensed gaming operator in
        the country. Once sold, tickets are sent in to PropSwap to be verified
        with the issuing sportsbook, and stored securely until they win or
        lose. 
      </Paragraph>
      <Paragraph>
        Once they win, the Buyer will be sent the ticket in the mail, which can
        be cashed in-person at the casino or redeemed via Mail Pay. All losing
        tickets are discarded. 
      </Paragraph>
      <Paragraph>
        Welcome to PropSwap, where you can find the best odds in the world! 
      </Paragraph>
    </View>
    <Divider />
    <View style={[styles.row, styles.section]}>
      <View>
        <ResponsiveImage style={styles.image} source={ian} width={90} />
      </View>
      <View style={{ flexShrink: 1 }}>
        <Title style={styles.title}>Ian Epstein, Co-Founder</Title>
        <Paragraph>
          Voted “Most Likely to be a Billionaire by Age 25” by his high school
          senior class, Ian was only a few billion short of achieving that goal
          by the quarter-century mark. Selling glow sticks at Chicago street
          festivals at the age of 13 and ticket scalping by the age of 15,
          entrepreneurship is nothing new to Ian.
        </Paragraph>
        <Paragraph>
          During a college spring break trip to Vegas in 2010 Ian walked into
          the MGM Grand Sportsbook, soaked in the sights & sounds, and made the
          declaration to himself that this was the industry he was going to make
          his mark in.
        </Paragraph>
        <Paragraph>
          In 2012 Ian moved to Las Vegas to accept a position at the sports
          betting firm Cantor Gaming. He left the company in 2015 to pursue
          PropSwap full time.
        </Paragraph>
      </View>
    </View>
    <View style={[styles.row, styles.section]}>
      <View>
        <ResponsiveImage style={styles.image} source={luke} width={90} />
      </View>
      <View style={{ flexShrink: 1 }}>
        <Title style={styles.title}>Luke Pergande, Co-Founder</Title>
        <Paragraph>
          Born in Chicago, Luke entered the world talking financial markets. His
          first transaction came at the age of two in 1990, when he bought
          10,000 shares of Apple with a loan from his grandfather. It’s been
          rated by the Wall Street Journal as one of the best trades executed by
          a toddler in recent memory.
        </Paragraph>
        <Paragraph>
          Joking aside, the real journey began 6 years ago, spending a wide-eyed
          summer grabbing coffee, running trades to and from the pit, at the
          Chicago Options Exchange.
        </Paragraph>
        <Paragraph>
          His most recent 3.5 years were spent working at Bloomberg, talking
          with the hedge fund managers by day, trading stocks by night.
          Parlaying this background into the world of sports wagering, and
          starting the first marketplace for betting slips, was a logical next
          step.
        </Paragraph>
      </View>
    </View>
  </>
);
