import { Platform } from "react-native";

export default theme => ({
  wrapper: {
    justifyContent: "center",
    alignItems: "center",
  },
  iconButton: {
    marginBottom: theme.spacing.xl,
    backgroundColor: theme.colors.primary,
  },
  callToAction: {
    marginBottom: theme.spacing.xl * 2,
  },
  optionsWrapper: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  button: {
    width: "100%",
    marginVertical: theme.spacing.md,
  },
  camera: {
    backgroundColor: "#000000",
    zIndex: 10000,
    position: Platform.OS === "web" ? "fixed" : "absolute",
    ...(Platform.OS === "web"
      ? {
          left: 0,
          right: 0,
          display: "grid",
          marginLeft: "auto",
          marginRight: "auto",
        }
      : {
          left: -24,
          top: 0,
          // right: 0,
        }),
  },
  cameraIconButtonWrapper: {
    ...Platform.select({
      web: {
        flex: 1,
      },
      default: {
        height: "100%",
      },
    }),
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
