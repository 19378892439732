import MainStack from "Navigators/MainStack";
import { createURL } from "expo-linking";
import { Text } from "react-native-paper";

const Fallback = <Text>Loading…</Text>;

const titleFormatter = (options, route) =>
  `${options?.title ?? route?.name ?? "Tutorial"} - PropSwap`;

const profileStack = {
  ProfileStack: {
    path: "profile",
    initialRouteName: "ProfileNav",
    screens: {
      ProfileNav: "",
      MyEmail: "email",
      MyPhoto: "photo",
      MyRating: "rating",
    },
  },
};

const pointsStack = {
  PointsStack: {
    path: "points",
    initialRouteName: "PointsNav",
    screens: {
      PointsNav: "",
      PointsRedeem: "redeem",
      PointsEarn: "earn",
      PointsWorth: "worth",
      PointsRedeemCash: "redeem-cash",
      PointsRedeemFee: "redeem-fee",
      PointsRedeemShirt: "redeem-shirt",
      PointsRedeemHat: "redeem-hat",
    },
  },
};

const listingModalStack = {
  ListingModalStack: {
    initialRouteName: "ListingDetail",
    screens: {
      ListingDetail: "listings/:id",
      ListingEdit: "listings/:id/edit",
      UserDetail: "users/:id/edit",
      BidDecide: "bids/:id/:action",
    },
  },
};

const depositStack = {
  DepositStack: {
    path: "deposit",
    initialRouteName: "DepositNav",
    screens: {
      DepositNav: "",
      AchDepositStack: {
        path: "ach",
        initialRouteName: "AchSummary",
        screens: {
          AchSummary: "summary",
          DepositAmount: "amount",
          AchAddAccount: "add",
          AchSelect: "select",
          Verification: "verify",
          MyEmail: "account",
        },
      },
      CreditCardDepositStack: {
        path: "card",
        initialRouteName: "CreditCardSummary",
        screens: {
          CreditCardSummary: "summary",
          DepositAmount: "amount",
          CreditCardAddAccount: "add",
          CreditCardSelect: "select",
          Verification: "verify",
          MyEmail: "account",
        },
      },
    },
  },
};

const globalScreens = {
  Activity: "activity",
  WatchList: "watchlist",
  Notifications: "notifications",
  ...listingModalStack,
  ...profileStack,
  ...pointsStack,
  ...depositStack,
};

const newLinkingConfig = {
  // To test deep linking on, run the following in the Terminal:
  // Android: adb shell am start -a android.intent.action.VIEW -d "exp://127.0.0.1:19000/--/simple-stack"
  // iOS: xcrun simctl openurl booted exp://127.0.0.1:19000/--/simple-stack
  // Android (bare): adb shell am start -a android.intent.action.VIEW -d "rne://127.0.0.1:19000/--/simple-stack"
  // iOS (bare): xcrun simctl openurl booted rne://127.0.0.1:19000/--/simple-stack
  // The first segment of the link is the the scheme + host (returned by `Linking.makeUrl`)
  prefixes: [createURL("/")],
  config: {
    initialRouteName: "AuthStack",
    screens: {
      DesignPatternLibrary: "design",
      AuthStack: {
        initialRouteName: "Login",
        screens: {
          Login: "login",
          RegistrationInitial: "register",
          RegistrationStack: {
            path: "signup",
            initialRouteName: "RegistrationStep1",
            screens: {
              RegistrationStep1: "step-1",
              RegistrationStep2: "step-2",
              RegistrationStep3: "step-3",
              RegistrationStep4: "step-4",
              // RegistrationConfirmation: "confirmation",
              RegistrationConfirmation: ":code/confirmation",
              RegistrationPhoto: "photo",
              RegistrationSuccess: "success",
            },
          },
          PasswordResetStack: {
            path: "password-reset",
            initialRouteName: "PasswordResetRequest",
            screens: {
              PasswordResetRequest: "request",
              PasswordResetConfirmation: "confirmation",
              PasswordResetNew: "new",
              PasswordResetSuccess: "success",
            },
          },
        },
      },
      Main: {
        initialRouteName: "HomeStack",
        screens: {
          HomeStack: {
            path: "",
            initialRouteName: "Home",
            screens: {
              Home: "",
              ...globalScreens,
            },
          },

          MyTickets: {
            path: "my/tickets",
            initialRouteName: "ForSaleModalStack",
            screens: {
              ForSaleModalStack: {
                path: "for-sale",
                initialRouteName: "ForSale",
                screens: {
                  ForSale: "",
                  ListingLearn: "listings/learn",
                  ...listingModalStack,
                },
              },
              NotForSaleModalStack: {
                path: "not-for-sale",
                initialRouteName: "NotForSale",
                screens: {
                  NotForSale: "",
                  ListingLearn: "listings/learn",
                  ...listingModalStack,
                },
              },
              MyBidsModalStack: {
                path: "my/bids",
                exact: true,
                initialRouteName: "MyBids",
                screens: {
                  MyBids: {
                    path: "",
                  },
                  BidLearn: "bids/learn",
                  ...listingModalStack,
                },
              },
              ...globalScreens,
            },
          },

          Buy: {
            path: "buy/tickets",
            initialRouteName: "Listings",
            screens: {
              Listings: "",
              Search: "search",
              Sports: "sports",
              ...globalScreens,
            },
          },

          Sell: {
            path: "sell/tickets",
            initialRouteName: "TicketsAwaiting",
            screens: {
              TicketsAwaiting: "awaiting",
              ...globalScreens,
              ListingLearn: "learn",
              TicketsWarning: "warning",
              TicketNewStack: {
                initialRouteName: "TicketNewStep1",
                screens: {
                  CreditCardAddAccount: "add-credit-card",
                  TicketNewIdVerification: "verify-id",
                  TicketNewVerification: "verification",
                  TicketNewStep1: "step-1",
                  TicketNewMobileConfirmStep1: "mobile-confirm",
                  TicketNewMobileConfirmStep2: "mobile-confirm-2",
                  TicketNewMobileConfirmStep3: "mobile-confirm-3",
                  TicketNewStep2: "step-2",
                  TicketNewBetSync: "bet-sync",
                  TicketNewBetSyncAccount: "bet-sync/:accountId",
                  TicketNewStep3: "step-3",
                  TicketNewLocation: "locations",
                  TicketNewSportsbook: "sportsbook",
                  TicketNewSport: "sports",
                  TicketNewBetType: "bet-type",
                  TicketNewBetAmount: "bet-amount",
                  TicketNewWinAmount: "win-amount",
                  TicketNewEvent: "event",
                  TicketNewOption: "option",
                  TicketNewAdditionalInfo: "additional-info",
                  TicketNewStep4: "step-4",
                  TicketNewAskingPrice: "asking-price",
                  TicketNewMinimumBidPrice: "minimum-bid",
                  TicketNewExpiresAt: "expires",
                  TicketNewPrice: "price",
                  TicketNewSuccess: ":id/success",
                },
              },
            },
          },
          Account: {
            path: "account",
            initialRouteName: "AccountNav",
            screens: {
              AccountNav: {
                path: "",
              },
              ...globalScreens,
              WalletStack: {
                path: "wallet",
                initialRouteName: "WalletNav",
                screens: {
                  WalletNav: "",
                  ...depositStack,
                  WithdrawStack: {
                    path: "withdraw",
                    initialRouteName: "WithdrawNav",
                    screens: {
                      WithdrawNav: "",
                      DirectDepositWithdrawStack: {
                        path: "ach",
                        initialRouteName: "DirectDepositSummary",
                        screens: {
                          DirectDepositSummary: "summary",
                          DirectDepositAmount: "amount",
                          AchWithdrawSelect: "select",
                          WithdrawAchInfo: "info",
                        },
                      },
                      PaperWithdrawStack: {
                        path: "paper",
                        initialRouteName: "PaperSummary",
                        screens: {
                          PaperSummary: "summary",
                          WithdrawAmount: "amount",
                          WithdrawPaperInfo: "info",
                        },
                      },
                    },
                  },
                },
              },
              TransactionStack: {
                initialRouteName: "Transactions",
                path: "transactions",
                screens: {
                  Transactions: "list",
                  TransactionDetail: "transactions/:id",
                  ...listingModalStack,
                },
              },
              ...pointsStack,
              NotificationSettings: "notification-settings",
              ThemeSettings: "theme-settings",
              Faq: "faq",
              Settings: "settings",
              TutorialReset: "tutorial-reset",
              Contact: "contact",
              SdkAccessRequest: "sdk-access-request",
              About: "about",
              Logout: "logout",
            },
          },
        },
      },
      OauthRequest: "oauth/request",
      NotFound: "*",
    },
  },
};

export {
  newLinkingConfig as linkingConfig,
  Fallback,
  titleFormatter,
  MainStack as HomeStack,
};
