import { useMemo } from "react";
import ListingSuggestedPriceView from "./ListingSuggestedPriceView";
import Bovado from "shared/api/Bovado";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const fanDuelQuery = gql`
  query FanDuelListMarketPrices($marketIds: [String!]!) {
    fanDuelListMarketPrices(marketIds: $marketIds) {
      marketDetails {
        marketId
        eventId
        marketName
        marketType
        marketStartTime
        inplay
        livePriceAvailable
        guaranteedPriceAvailable
        runnerDetails {
          selectionId
          selectionName
          runnerOrder
          handicap
          runnerStatus
          winRunnerOdds {
            decimal
            numerator
            denominator
          }
          runnerResult {
            runnerResultType
          }
        }
      }
    }
  }
`;

export default ({
  groupId,
  eventId,
  fanDuelMarketId,
  fanDuelSelectionId,
  participantId,
  participantName,
  wagerInPennies,
  format,
  event,
}) => {
  const { data: { fanDuelListMarketPrices } = {} } = useQuery(fanDuelQuery, {
    skip: !fanDuelMarketId,
    variables: {
      marketIds: [fanDuelMarketId],
    },
  });

  const runnerDetails = useMemo(
    () =>
      (fanDuelListMarketPrices?.marketDetails?.[0]?.runnerDetails ?? []).find(
        runnerDetails =>
          runnerDetails.selectionId.toString() ===
          fanDuelSelectionId?.toString?.()
      ),
    [fanDuelListMarketPrices, fanDuelSelectionId]
  );

  const { data: { getOddsDataForEventAndOption: odds } = {} } = useQuery(
    Bovado.queries.getOddsDataForEventAndOption,
    {
      variables: {
        bovadoGroupId: groupId,
        bovadoEventId: eventId,
        bovadoMarketId: event?.bovadoMarketId ?? undefined,
        bovadoParticipantId: participantId,
        bovadoParticipantName: participantName,
      },
    }
  );

  const caluclatedOdds = !!runnerDetails
    ? (runnerDetails.winRunnerOdds.numerator * 1.0) /
      (runnerDetails.winRunnerOdds.denominator * 1.0)
    : !!odds
    ? (odds.currentpriceup * 1.0) / (odds.currentpricedown * 1.0)
    : null;

  return !caluclatedOdds || !wagerInPennies ? null : (
    <ListingSuggestedPriceView
      wagerInPennies={wagerInPennies}
      format={format}
      odds={odds}
      caluclatedOdds={caluclatedOdds}
    />
  );
};
