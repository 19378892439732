// import RegisterStepper from 'Components/Register/Stepper';
import * as React from "react";
import { useContext, useCallback, useEffect } from "react";
import { Button, useTheme, TextInput, HelperText } from "react-native-paper";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RegistrationContext } from "Contexts/Registration";
import { View, Platform } from "react-native";
import ScreenBottom from "Components/Screen/Bottom";
import Screen from "Components/Screen";
import Stepper from "Components/Stepper";

// const scrollEnabled = Platform.select({ web: true, default: false });

const schema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.’'-]+$/i, "Must be a valid first name")
    .required("Can't be blank"),
  lastName: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.’'-]+$/i, "Must be a valid last name")
    .required("Can't be blank"),
});

const Form = ({ style, theme, navigation }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const inputs = React.useRef({});
  const { setRegistrationValues, registrationValues } =
    useContext(RegistrationContext);

  const onSubmit = useCallback(
    ({ firstName, lastName }) => [
      setRegistrationValues(registrationValues => ({
        ...registrationValues,
        firstName,
        lastName,
      })),
      navigation.push("RegistrationStep2"),
    ],
    [navigation, setRegistrationValues]
  );

  const doSubmit = handleSubmit(onSubmit);

  const handleInputAutofocus = useCallback(() => {
    const timeout = setTimeout(() => {
      inputs.current.firstName.focus();
    }, 10);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    handleInputAutofocus();
  }, [handleInputAutofocus]);

  return (
    <>
      <View
        style={[
          {
            flex: 1,
          },
          style ?? {},
        ]}
      >
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize="words"
                ref={e => (inputs.current["firstName"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "name"
                }
                keyboardType="default"
                textContentType="givenName"
                label="First Name"
                error={!!errors.firstName}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.lastName.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.firstName}>
                {errors.firstName?.message}
              </HelperText>
            </>
          )}
          name="firstName"
          defaultValue={registrationValues.firstName}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="words"
                ref={e => (inputs.current["lastName"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "name"
                }
                keyboardType="default"
                textContentType="familyName"
                label="Last Name"
                error={!!errors.lastName}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={doSubmit}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.lastName}>
                {errors.lastName?.message}
              </HelperText>
            </>
          )}
          name="lastName"
          defaultValue={registrationValues.lastName}
        />
      </View>

      <ScreenBottom adjustPadding>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          Continue
        </Button>
      </ScreenBottom>
    </>
  );
};

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen
      gatedTo="guest"
      title="Register"
      hasKeyboard
      backgroundColor={theme.colors.surface}
    >
      <Stepper
        theme={theme}
        currentPosition={0}
        title="Enter Your Full Name"
        labels={["", "", "", ""]}
      />
      <Form
        style={{ marginTop: theme.spacing.xl }}
        theme={theme}
        navigation={navigation}
      />
    </Screen>
  );
};
