import React, { useContext, useCallback } from "react";
import {
  Button,
  Divider,
  HelperText,
  List,
  useTheme,
  TextInput,
} from "react-native-paper";
import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import { betTypes } from "shared/Config";
import { Platform, View } from "react-native";

export default ({ navigation }) => {
  const theme = useTheme();
  const { setTicketValues, ticketValues } = useContext(TicketContext);

  const onSubmit = useCallback(
    ({ betType, parlayLegs }) =>
      () =>
        [
          setTicketValues(ticketValues => ({
            ...ticketValues,
            betType: betType ?? ticketValues.betType,
            parlayLegs: parlayLegs ?? ticketValues.parlayLegs,
            ticketEventId:
              betType === "PARLAY" ? null : ticketValues.ticketEventId,
            ticketOptionId:
              betType === "PARLAY" ? null : ticketValues.ticketOptionId,
          })),
          (betType || ticketValues.betType) !== "PARLAY" &&
            navigation.navigate("TicketNewStep3"),
        ],
    [navigation, setTicketValues, ticketValues.betType]
  );

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      backgroundColor={theme.colors.surface}
      hasScroll
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={2}
        title="Choose Bet Type"
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />
      {Object.entries(betTypes)
        .slice()
        .sort((a, b) => (a[0] > b[0] ? 1 : -1))
        .map(([value, label]) => (
          <React.Fragment key={value}>
            <List.Item
              title={label}
              right={props =>
                ticketValues.betType === value ? (
                  <List.Icon {...props} icon="check-circle-outline" />
                ) : null
              }
              onPress={onSubmit({ betType: value })}
            />
            <Divider />
          </React.Fragment>
        ))}
      {ticketValues?.betType === "PARLAY" && (
        <View style={{ margin: theme.spacing.xl }}>
          <TextInput
            mode="outlined"
            label="Number of Legs"
            autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
            keyboardType="number-pad"
            textContentType="none"
            returnKeyLabel="Done"
            returnKeyType="done"
            onSubmitEditing={() => navigation.navigate("TicketNewStep3")}
            onChangeText={value => onSubmit({ parlayLegs: value })()}
            value={ticketValues.parlayLegs}
          />
          <HelperText
            type={
              ticketValues.parlayLegs > 12 || ticketValues.parlayLegs < 2
                ? "error"
                : "info"
            }
            visible
          >
            Must be between 2 and 12
          </HelperText>
          <Button
            style={{ marginTop: theme.spacing.xl }}
            disabled={
              ticketValues.betType === "PARLAY" &&
              (ticketValues.parlayLegs > 12 || ticketValues.parlayLegs < 2)
            }
            mode="contained"
            onPress={() => navigation.navigate("TicketNewStep3")}
          >
            Confirm
          </Button>
        </View>
      )}
    </Screen>
  );
};
