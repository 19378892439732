import React from "react";
import { View, Image, ScrollView } from "react-native";
import { Button, Headline, Subheading, Text, Title } from "react-native-paper";
import { FontAwesome5 } from "@expo/vector-icons";
import { wreath } from "assets";

export default ({
  title,
  subtitle,
  type,
  theme,
  styles,

  primaryButtonLabel,
  onPrimaryButtonPress,

  secondaryButtonLabel,
  onSecondaryButtonPress,

  tertiaryButtonLabel,
  onTertiaryButtonPress,

  headline,
  subheading,
  More,
  Footer,
}) => (
  <ScrollView
    keyboardShouldPersistTaps="handled"
    contentContainerStyle={styles.f1}
  >
    {(!!title || !!subtitle) && (
      <View style={styles.titleWrapper}>
        <Title style={[styles.textCenter, styles.textBold, styles.textPrimary]}>
          {title}
        </Title>
        <Text style={[styles.textCenter]}>{subtitle}</Text>
      </View>
    )}

    <View style={styles.bodyWrapper}>
      {type === "success" && (
        <View style={styles.successWrapper}>
          <FontAwesome5
            name="check-circle"
            size={172}
            color={theme.colors.primary}
          />
        </View>
      )}

      {type === "award" && (
        <View style={styles.awardWrapper}>
          <Image style={styles.award} source={wreath} />
        </View>
      )}

      <View style={styles.headingsWrapper}>
        {!!headline && (
          <Headline style={[styles.textUpper, styles.textCenter]}>
            {headline}
          </Headline>
        )}
        {!!subheading && (
          <Subheading style={styles.textCenter}>{subheading}</Subheading>
        )}
        {More && More}
      </View>
    </View>

    {!!Footer && <View style={styles.footerWrapper}>{Footer}</View>}

    {
      //(!!secondaryButtonLabel || !!primaryButtonLabel) &&
      <View style={styles.buttonsWrapper}>
        {secondaryButtonLabel && (
          <Button
            mode="outlined"
            onPress={onSecondaryButtonPress}
            style={[styles.fullWidth, styles.button]}
          >
            {secondaryButtonLabel}
          </Button>
        )}
        {!!primaryButtonLabel && (
          <Button
            mode="contained"
            onPress={onPrimaryButtonPress}
            style={[styles.fullWidth, styles.button]}
          >
            {primaryButtonLabel}
          </Button>
        )}
        {!!tertiaryButtonLabel && (
          <Button
            mode="contained"
            onPress={onTertiaryButtonPress}
            style={[styles.fullWidth, styles.button]}
          >
            {tertiaryButtonLabel}
          </Button>
        )}
      </View>
    }
  </ScrollView>
);
