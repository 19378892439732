import { MaterialCommunityIcons } from "@expo/vector-icons";
import { TouchableWithoutFeedback, View } from "react-native";
import { Text } from "react-native-paper";
import { activityHeaderTitles } from "shared/Config";

export default ({ theme, styles, onIconPress, activity }) => (
  <TouchableWithoutFeedback onPress={onIconPress}>
    <View style={styles.wrapper}>
      <Text numberOfLines={1} style={styles.text}>
        {activityHeaderTitles[activity.type]?.(activity)}
      </Text>
      <MaterialCommunityIcons
        name="lightning-bolt-circle"
        size={theme.fonts.subtitle.fontSize}
        color="white"
        style={styles.icon}
        onPress={onIconPress}
      />
    </View>
  </TouchableWithoutFeedback>
);
