import React from "react";

import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import PointsNav from "Screens/PointsNav";
import PointsRedeem from "Screens/PointsRedeem";
import PointsWorth from "Screens/PointsWorth";
import PointsEarn from "Screens/PointsEarn";
import PointsRedeemCash from "Screens/PointsRedeemCash";
import PointsRedeemHat from "Screens/PointsRedeemHat";
import PointsRedeemShirt from "Screens/PointsRedeemShirt";
import PointsRedeemFee from "Screens/PointsRedeemFee";
import { getStandardScreenOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={getStandardScreenOptions({ screenOptions, navigation })}
    >
      <Stack.Screen name="PointsNav" component={PointsNav} />
      <Stack.Screen name="PointsRedeem" component={PointsRedeem} />
      <Stack.Screen name="PointsWorth" component={PointsWorth} />
      <Stack.Screen name="PointsEarn" component={PointsEarn} />
      <Stack.Screen name="PointsRedeemCash" component={PointsRedeemCash} />
      <Stack.Screen name="PointsRedeemHat" component={PointsRedeemHat} />
      <Stack.Screen name="PointsRedeemShirt" component={PointsRedeemShirt} />
      <Stack.Screen name="PointsRedeemFee" component={PointsRedeemFee} />
    </Stack.Navigator>
  );
};
