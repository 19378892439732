import { View } from "react-native";
import { Button } from "react-native-paper";

export default ({
  theme,
  selectedValue,
  onValueChange,
  buttons,
  itemLeft,
  itemRight,
  styles,
}) => (
  <View style={styles.outerWrapper}>
    <View style={styles.wrapper}>
      <View style={styles.innerWrapper}>
        {itemLeft}
        {buttons.map((button, i) => (
          <Button
            key={i}
            mode={
              selectedValue === button.label ||
              (selectedValue === "My Bids" && /My Bids/.test(button.label))
                ? "contained"
                : "outlined"
            }
            compact
            labelStyle={styles.label}
            onPress={() => [
              !!onValueChange && onValueChange(button.label),
              !!button.onPress && button.onPress(),
            ]}
          >
            {button.label}
          </Button>
        ))}
        {itemRight}
      </View>
    </View>
  </View>
);
