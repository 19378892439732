import React, { useEffect, useState, useContext } from "react";
import Storage from "@aws-amplify/storage";
import ENV from "../environment";
import { CurrentUserContext } from "./CurrentUser";
import { useMutation } from "@apollo/client";
import User from "shared/api/User";

const AvatarContext = React.createContext();

const AvatarProvider = ({ children }) => {
  const [localUri, setLocalUri] = useState(null);
  const currentUser = useContext(CurrentUserContext);
  const [saveProfilePicUri] = useMutation(User.mutations.update, {
    variables: {
      input: {
        id: currentUser?.id,
      },
    },
  });

  useEffect(() => {
    !!localUri &&
      fetch(localUri)
        .then(imageData => imageData.blob())
        .then(blobData => [
          blobData,
          localUri.match(/\.gif$/)
            ? "gif"
            : localUri.match(/\.gif$/)
            ? "png"
            : "jpeg",
        ])
        .then(([blobData, fileType]) =>
          Storage.put(
            `${new Date().getTime().toString()}.${fileType}`,
            blobData,
            {
              contentType: `image/${fileType}`,
            }
          )
            .then(({ key }) => Storage.get(key))
            .then(
              url =>
                url
                  .replace(/^.+\.s3.*\.amazonaws\.com/, `https://${ENV?.cdn}`)
                  .split("?")[0]
            )
            .then(url => ({
              variables: { input: { id: currentUser?.id, profilePicUri: url } },
            }))
            .then(saveProfilePicUri)
            .then(() => setLocalUri(null))
        )
        .catch(console.log);
  }, [currentUser?.id, localUri, saveProfilePicUri]);

  return (
    <AvatarContext.Provider value={{ localUri, setLocalUri }}>
      {children}
    </AvatarContext.Provider>
  );
};

export { AvatarContext, AvatarProvider };
