import { Platform } from "react-native";

export default theme => ({
  stateNotAllowedWrapper: {
    flex: 1,
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  stateNotAllowedText: {
    color: "black",
    textAlign: "center",
  },
  webClip: {
    ...Platform.select({
      web: {
        overflowX: "hidden",
      },
      default: {},
    }),
    flex: 1,
    width: "100%",
    maxWidth: 601,
    marginHorizontal: "auto",
  },
  webWrapper: {
    flex: 1,
    // height: "100%",
    width: "100%",
  },
});
