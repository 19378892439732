import { useEffect, useState } from "react";

const ipRegex = /ip=(\d{1,3}(?:\.\d{1,3}){3})/;

const useIpAddressFromCloudFlare = ({ skip }) => {
  const [ip, setIp] = useState(null);

  useEffect(() => {
    !skip &&
      fetch("https://www.cloudflare.com/cdn-cgi/trace")
        .then(res => res.text())
        .then(text => text.match(ipRegex)?.[0]?.replace?.("ip=", ""))
        .then(setIp);
  }, [skip]);

  return ip;
};

export default useIpAddressFromCloudFlare;
