import { useContext, useCallback } from "react";
import { Divider, List, useTheme } from "react-native-paper";

import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { Platform } from "react-native";

export default ({ navigation }) => {
  const currentUser = useContext(CurrentUserContext);
  const theme = useTheme();
  const { setTicketValues } = useContext(TicketContext);

  const onSubmit = useCallback(
    ({ isMobile, isBetSync }) =>
      () => {
        if (isBetSync) {
          navigation.push("TicketNewBetSync");
        } else {
          setTicketValues(ticketValues => ({
            ...ticketValues,
            isMobile,
            started: true,
          }));
          navigation.push("TicketNewStep2");
        }
      },
    [navigation, setTicketValues]
  );

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      hasKeyboard
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={0}
        title="Choose Ticket Type"
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />

      <List.Item
        title="Paper"
        onPress={onSubmit({ isMobile: false })}
        right={props => <List.Icon {...props} icon="chevron-right" />}
        description={" "}
      />
      <Divider />
      <List.Item
        title="Mobile"
        right={props => <List.Icon {...props} icon="chevron-right" />}
        onPress={onSubmit({ isMobile: true })}
      />
      <Divider />
      {currentUser?.groups?.includes?.("Admins") && (
        <>
          <List.Item
            title="BetSync"
            right={props => <List.Icon {...props} icon="chevron-right" />}
            onPress={currentUser?.rating >= 3 && onSubmit({ isBetSync: true })}
            style={{
              ...Platform.select({
                web: {
                  cursor: currentUser?.rating >= 3 ? "pointer" : "default",
                },
                default: {},
              }),
            }}
          />
          <Divider />
        </>
      )}
    </Screen>
  );
};
