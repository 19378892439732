import { gql, useQuery } from "@apollo/client";
import environment from "environment";
import Constants from "expo-constants";
import { Platform } from "react-native";

const query = gql`
  query GetAppStoreData {
    getAppStoreData
  }
`;

function versionCompare(v1, v2, options) {
  const lexicographical = (options && options.lexicographical) || false,
    zeroExtend = (options && options.zeroExtend) || true;

  let v1parts = (v1 || "0").split("."),
    v2parts = (v2 || "0").split(".");

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-zαß]*$/ : /^\d+[A-Za-zαß]?$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push("0");
    while (v2parts.length < v1parts.length) v2parts.push("0");
  }

  if (!lexicographical) {
    v1parts = v1parts.map(function (x) {
      const match = /[A-Za-zαß]/.exec(x);
      return Number(
        match ? x.replace(match[0], "." + x.charCodeAt(match.index)) : x
      );
    });
    v2parts = v2parts.map(function (x) {
      const match = /[A-Za-zαß]/.exec(x);
      return Number(
        match ? x.replace(match[0], "." + x.charCodeAt(match.index)) : x
      );
    });
  }

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
}

export default () => {
  const isIos = Platform.OS === "ios";
  const { data: { getAppStoreData } = {} } = useQuery(query, { skip: !isIos });
  const json = !!getAppStoreData ? JSON.parse(getAppStoreData) : null;
  const remoteVersionNumber = json?.results?.[0]?.version;
  const isProduction = environment?.stage === "production";
  const localVersionNumber =
    Constants.expoConfig?.version ?? Constants.manifest?.version;
  const hasLatestVersion =
    !isProduction ||
    !isIos ||
    !remoteVersionNumber ||
    versionCompare(localVersionNumber, remoteVersionNumber) >= 0;

  return {
    hasLatestVersion,
    remoteVersionNumber,
    localVersionNumber,
  };
};
