import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={20}
      height={20}
      viewBox="0 0 30 30"
      xmlSpace="preserve"
      enableBackground="new 0 0 30 30"
      {...props}
    >
      <Path
        fill={props?.color ?? "#666"}
        d="M30 11.924c-.09 1.312-1.49 1.252-1.49 1.252-.773-.119-1.15-1.328-1.488-1.816-.828-1.2-1.375-.496-1.549-.149-.951 1.888-2.184 2.565-2.652 3.517-.211.43-.061 1.431-.09 1.905-.086 1.519 1.312 2.296 1.312 2.296 2.232 1.28 2.562 5.394 2.562 5.394.535 1.549-.209 1.549-.209 1.549l-1.043-.924c-.478-.716.357-.863.357-.863a2.169 2.169 0 01-.27-.39c-.625-3.99-2.301-3.78-3.127-4.021-1.133-.326-1.281.09-1.281.09-.268 2.025-1.608 3.158-1.608 3.158-1.431 2.055-2.354 1.967-2.354 1.967-.387.029-.805.207-.805.207-1.787.896-1.189-.178-1.189-.178l.596-.834c.688-.566.834.059.834.059 1.162-.146 2.294-1.696 2.294-1.696 1.312-2.087.447-3.872.447-3.872-4.945.98-7.15-.656-7.15-.656-1.311-.834-1.847 1.49-1.847 1.49-.687 1.369-.507 2.113-.507 2.113l2.592 2.057c1.312.924.953 2.205.953 2.205l-1.251-.479c-.625-.209-.089-.894-.089-.894-.389-.208-2.771-1.938-2.771-1.938-1.758-.687-.924-1.877-.924-1.877.864-1.906.029-1.759.029-1.759-.446.507-1.604 1.007-2.204 1.189-2.132.66-2.115 3.012-2.115 3.012-.328 2.055-1.489 2.441-1.489 2.441-.895 1.045-1.014.151-1.014.151l.238-1.222c.148-.807.805-.417.805-.417.715-.716.936-2.83 1.014-3.812.075-.953.5-1.049.834-1.193.502-.215 1.906-1.07 1.906-1.07-1.607-2.8-.298-4.887-.298-4.887-1.312 0-1.549 1.669-2.086 2.205C1.727 17.379 0 18.15 0 18.15c1.014-6.91 6.971-5.957 6.971-5.957.953-.834 2.61-.938 3.516-.895.781.036 1.507.038 2.193.02-1.729-1.268.209-3.9.209-3.9 1.913-2.32 5.095-1.969 5.095-1.969 1.078-1.565 1.882-1.559 2.562-1.321 1.282.444 1.104 2.211 1.104 2.211l.353.458-.754-.135c0 .188-.647.674-.647.674l-.056.324-.538.107c-.511.513-1.214-.164-1.214-.164.138.567-.458 1.269-.458 1.269-.202.722.055 1.168.48 1.444 1.375-.469 2.037-.924 2.037-.924 3.158-3.812 5.84-2.92 5.84-2.92l.863-1.19-.09 1.639.717-1.399-.148 1.607c.773.149.773.717.773.717-.597-.358-.447.178-.447.178.535.475 1.639 3.9 1.639 3.9z"
      />
    </Svg>
  );
}

export default SvgComponent;
