import { useTheme } from "react-native-paper";
import CircleIndicatorView from "./CircleIndicatorView";

const CircleIndicatorContainer = ({
  itemCount,
  scrolledIndex,
  onItemPress,
}) => {
  const theme = useTheme();

  return (
    <CircleIndicatorView
      theme={theme}
      itemCount={itemCount}
      scrolledIndex={scrolledIndex}
      onItemPress={onItemPress}
    />
  );
};

export default CircleIndicatorContainer;
