import { View } from "react-native";
import { Subheading, Button } from "react-native-paper";
import ScreenBottom from "Components/Screen/Bottom";

export default ({
  transaction,
  canRequestRefund,
  step,
  onStart,
  onCancel,
  onSubmit,
  onClose,
  theme,
  styles,
}) => (
  <View style={{ flex: 1, marginTop: theme.spacing.xl }}>
    {transaction.type === "MANUAL" ? (
      <>
        <Subheading style={styles.textCenter}>{transaction.note}</Subheading>
        <ScreenBottom adjustPadding>
          <Button style={styles.fullWidth} onPress={onClose} mode="contained">
            Close
          </Button>
        </ScreenBottom>
      </>
    ) : !canRequestRefund ? (
      <>
        <Subheading style={styles.textCenter}>
          This deposit is not eligible for a refund.
        </Subheading>
        <ScreenBottom adjustPadding>
          <Button style={styles.fullWidth} onPress={onClose} mode="contained">
            Close
          </Button>
        </ScreenBottom>
      </>
    ) : !!canRequestRefund && step === 1 ? (
      <>
        <Subheading style={styles.textCenter}>
          This deposit is eligible for a refund.
        </Subheading>
        <Subheading style={styles.textCenter}>
          Please allow 3-5 business days for the refund to take effect.
        </Subheading>
        <ScreenBottom adjustPadding>
          <Button style={styles.fullWidth} onPress={onStart} mode="contained">
            Initiate Refund
          </Button>
        </ScreenBottom>
      </>
    ) : !!canRequestRefund && step === 2 ? (
      <>
        <Subheading style={styles.textCenter}>
          Are you sure you want to request a refund?
        </Subheading>
        <ScreenBottom adjustPadding>
          <Button
            style={styles.continueButton}
            onPress={onSubmit}
            mode="contained"
          >
            Continue
          </Button>
          <Button style={styles.fullWidth} onPress={onClose} mode="outlined">
            Cancel
          </Button>
        </ScreenBottom>
      </>
    ) : !!canRequestRefund && step === 3 ? (
      <>
        <Subheading style={styles.textCenter}>
          Your deposit has been refunded!
        </Subheading>
        <ScreenBottom adjustPadding>
          <Button style={styles.fullWidth} onPress={onClose} mode="contained">
            Close
          </Button>
        </ScreenBottom>
      </>
    ) : null}
  </View>
);
