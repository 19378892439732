import useStyles from "Hooks/useStyles";
import { useState, useCallback } from "react";
import ScreenBottomStyles from "./ScreenBottomStyles";
import ScreenBottomView from "./ScreenBottomView";
import { useKeyboard } from "@react-native-community/hooks";

export default ({
  children,
  style,
  adjustPadding,
  additionalPadding = 0,
  inset,
}) => {
  const { theme, styles } = useStyles({ Styles: ScreenBottomStyles });
  const [height, setHeight] = useState();
  const keyboard = useKeyboard();
  const handleLayout = useCallback(
    event => setHeight(event.nativeEvent.layout.height + additionalPadding),
    [additionalPadding]
  );

  return (
    <ScreenBottomView
      additionalPadding={additionalPadding}
      adjustPadding={adjustPadding}
      height={height}
      theme={theme}
      children={children}
      style={style}
      onLayout={handleLayout}
      styles={styles}
      inset={inset}
      keyboardHeight={!!keyboard.keyboardShown ? keyboard.keyboardHeight : 0}
    />
  );
};
