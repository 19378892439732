import { Headline } from "react-native-paper";

import { View } from "react-native";
// import { HeaderBackButton } from '@react-navigation/elements';
import BackButton from "Components/BackButton";

export default ({ title, icon, rightButton, styles, navigation }) => (
  <View style={styles.wrapper}>
    <View style={styles.iconWrapper}>
      {
        // !!navigation.canGoBack() &&
        //<HeaderBackButton tintColor={theme.colors.text} labelVisible={false} style={{padding: 0, marginLeft: 0, marginRight: 0}} onPress={navigation.goBack} />
      }
      {!!navigation.canGoBack() && <BackButton />}
    </View>
    <View style={styles.headlineWrapper}>
      <View style={styles.iconHeadlineWrapper}>{icon}</View>
      <Headline style={styles.textUpper}>{title}</Headline>
    </View>
    <View style={styles.iconWrapper}>{!!rightButton && rightButton}</View>
  </View>
);
