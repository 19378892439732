import React from "react";
import { TouchableWithoutFeedback, View } from "react-native";
import { Badge } from "react-native-paper";

const IconWithBadgeCount = ({
  IconSet,
  onPress,
  count,
  wrapperStyles,
  theme,
  styles,
  ...rest
}) => (
  <TouchableWithoutFeedback onPress={onPress}>
    <View
      style={[{ marginRight: theme.spacing.lg }, styles.pointer, wrapperStyles]}
    >
      <Badge
        size={parseInt(rest.size * 0.83)}
        style={styles.badge}
        visible={!!count}
      >
        {count}
      </Badge>
      <IconSet {...rest} />
    </View>
  </TouchableWithoutFeedback>
);

export default IconWithBadgeCount;
