import { useMemo, useCallback, useRef, useState, useEffect } from "react";

import DataList from "Components/DataList";
import TicketItem from "Components/Ticket/Item";
import Listing from "shared/api/Listing";
import { Platform, View, useWindowDimensions } from "react-native";
import { Button, useTheme } from "react-native-paper";
import CircleIndicator from "Components/CircleIndicator";

export default ({}) => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const [scrolledIndex, setScrolledIndex] = useState(0);
  const windowWidth = useWindowDimensions().width;
  const theme = useTheme();
  const maxWidth =
    Platform.OS === "web" ? Math.min(601, windowWidth) - 2 : windowWidth;
  const dataList = useRef();
  const [itemCount, setItemCount] = useState(0);

  const dataListQuery = useMemo(
    () => ({
      dataKey: "searchListings",
      query: Listing.queries.search,
      variables: {
        sort: {
          direction: "desc",
          field: "featuredAt",
        },
        filter: {
          and: [
            {
              status: {
                eq: "ACTIVE",
              },
            },
            {
              featuredAt: {
                exists: true,
              },
            },
          ],
        },
        limit: 4,
      },
    }),
    []
  );

  const dataListCreateSubscription = useMemo(
    () => ({
      dataKey: "onCreateListing",
      subscription: Listing.subscriptions.onCreate,
    }),
    []
  );

  const handleViewableItemsChanged = useCallback(
    ({ viewableItems, changed }) =>
      setScrolledIndex(viewableItems?.[0]?.index ?? 0),
    []
  );

  const dataListRenderItem = useCallback(
    ({ item, index }) => (
      <View
        style={{
          width: maxWidth, // - theme.spacing.xl * 2,
        }}
      >
        <View
          style={{
            ...Platform.select({
              web: {
                marginRight: theme.spacing.xl * 2,
              },
              default: {
                marginRight: theme.spacing.xl,
                marginLeft: theme.spacing.xl,
              },
            }),
          }}
        >
          <TicketItem
            ticketObj={item.cachedTicket}
            listingObj={item}
            isFeatured
          />
        </View>
      </View>
    ),
    [maxWidth, theme]
  );

  useEffect(() => {
    !!dataList.current &&
      currentIndex !== null &&
      dataList.current.scrollToIndex({ animated: true, index: currentIndex });
  }, [currentIndex, dataList]);

  return (
    <>
      {itemCount > 1 && Platform.OS === "web" && (
        <View
          style={{
            marginTop: -1 * theme.spacing.lg * 2,
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={currentIndex <= 0}
            compact
            onPress={() => setCurrentIndex(currentIndex => currentIndex - 1)}
          >
            Prev
          </Button>
          <Button
            disabled={currentIndex >= itemCount - 1}
            compact
            onPress={() => setCurrentIndex(currentIndex => currentIndex + 1)}
          >
            Next
          </Button>
        </View>
      )}
      {itemCount > 1 && Platform.OS !== "web" && (
        <CircleIndicator
          itemCount={itemCount}
          scrolledIndex={scrolledIndex}
          onItemPress={setCurrentIndex}
        />
      )}
      <DataList
        ref={dataList}
        query={dataListQuery}
        createSubscription={dataListCreateSubscription}
        renderItem={dataListRenderItem}
        horizontal
        pagingEnabled={Platform.OS !== "web"}
        showsHorizontalScrollIndicator={false}
        onEndReached={() => null}
        onFetchItems={items => setItemCount(items?.length ?? 0)}
        onViewableItemsChanged={handleViewableItemsChanged}
      />
    </>
  );
};
