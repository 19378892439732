import { useEffect, useMemo, useState } from "react";
import { makeVar, useReactiveVar } from "@apollo/client";
import useIpAddressFromCloudFlare from "./useIpAddressFromCloudFlare";
import useLocation from "./useLocation";

const gidxGidxGeoInfo = makeVar(null);

const useGidxGeoInfo = () => {
  const [info, setInfo] = useState(null);
  const [useBackup, setUseBackUp] = useState(false);
  const geoInfo = useReactiveVar(gidxGidxGeoInfo);
  const shouldRequestIp = !geoInfo?.ip && !useBackup;
  const ip = useIpAddressFromCloudFlare({ skip: !shouldRequestIp });
  const location = useLocation({ skip: !useBackup });
  const dataString = JSON.stringify({
    ip,
    latitude: location?.coords?.latitude,
    longitude: location?.coords?.longitude,
  });

  const data = useMemo(() => JSON.parse(dataString), [dataString]);

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then(response => response.json())
      .then(json =>
        json.error
          ? setUseBackUp(true)
          : setInfo({
              ...json,
            })
      );
  }, [setInfo]);

  return info ?? data;
};

export default useGidxGeoInfo;
