import React from "react";
import { useState } from "react";

const StatusBarContext = React.createContext();

const StatusBarProvider = ({ children }) => {
  const [style, setStyle] = useState(null);
  return (
    <StatusBarContext.Provider value={[style, setStyle]}>
      {children}
    </StatusBarContext.Provider>
  );
};

export { StatusBarContext, StatusBarProvider };
