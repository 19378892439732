import gql from "graphql-tag";
import environment from "environment";

const Promotion = {};

Promotion.fragments = {
  public: gql`
    fragment PromotionPublic on Promotion {
      id
      createdAt
      updatedAt
      code
      type
      promotionUserId
      expiresAt
      isExpired
      promotionCampaignId
    }
  `,
};

Promotion.mutations = {
  update: gql`
    mutation UpdatePromotion($input: UpdatePromotionInput!) {
      updatePromotion(input: $input) {
        ...PromotionPublic
      }
    }
    ${Promotion.fragments.public}
  `,
  create: gql`
    mutation CreatePromotion($input: CreatePromotionInput!) {
      createPromotion(input: $input) {
        ...PromotionPublic
      }
    }
    ${Promotion.fragments.public}
  `,
};

Promotion.queries = {
  getByCode: gql`
    query GetPromotionByCode(
      $code: String!
      $sortDirection: ModelSortDirection
      $filter: ModelPromotionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      getPromotionByCode(
        code: $code
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...PromotionPublic
        }
        nextToken
      }
    }
    ${Promotion.fragments.public}
  `,
  listByCampaignId: gql`
    query ListPromotionsByCampaignId(
      $promotionCampaignId: ID${environment.v2_bang}
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelPromotionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listPromotionsByCampaignId(
        promotionCampaignId: $promotionCampaignId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...PromotionPublic
        }
      }
    }
    ${Promotion.fragments.public}
  `,
  list: gql`
    query ListPromotions(
      $filter: ModelPromotionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listPromotions(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...PromotionPublic
        }
      }
    }
    ${Promotion.fragments.public}
  `,
  get: gql`
    query GetPromotion($id: ID!) {
      getPromotion(id: $id) {
        ...PromotionPublic
      }
    }
    ${Promotion.fragments.public}
  `,
};

export default Promotion;
