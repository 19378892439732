import React, { useMemo, useCallback, useContext } from "react";
import {
  Button,
  Divider,
  Subheading,
  Text,
  TouchableRipple,
  useTheme,
} from "react-native-paper";
import Screen from "Components/Screen";
import { CurrentUserContext } from "Contexts/CurrentUser";

import DataList from "Components/DataList";
import { ScrollView, View } from "react-native";
import Transaction from "shared/api/Transaction";
import moment from "moment";
import { formatPennies } from "shared/Util/money";

const TransactionItem = ({ item, theme }) => (
  <View
    style={{
      margin: theme.spacing.md,
    }}
  >
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: theme.spacing.md,
      }}
    >
      <Text>{moment(item.createdAt).format("MM/DD/YYYY")}</Text>
    </View>
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: theme.spacing.md,
      }}
    >
      <Text>
        {item.type
          .replace(/(cc|ecp)/i, "Deposit")
          .replace(/(check|ach_standard)/i, "Withdraw")}
      </Text>
      <Text style={{ textAlign: "right" }}>
        {formatPennies(item.amount, "$")}
      </Text>
    </View>
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: theme.spacing.md,
      }}
    >
      <Text>{item.status}</Text>
      <Text style={{ textAlign: "right" }}>
        {item.type.replace(/(cc|ecp)/i, "Deposit") === "Deposit" &&
        !item.balanceSnapshot
          ? formatPennies(
              item.status === "PENDING"
                ? item.pendingBalanceSnapshot
                : item.promotionalBalanceSnapshot,
              "$"
            )
          : formatPennies(
              item.status === "PENDING"
                ? item.pendingBalanceSnapshot
                : item.type === "PROMO"
                ? item.promotionalBalanceSnapshot
                : item.balanceSnapshot,
              "$"
            )}
      </Text>
    </View>
    <Divider />
  </View>
);

const buttons = [
  { label: "All", value: null },
  { label: "Deposits", value: "DEPOSIT" },
  { label: "Withdrawals", value: "WITHDRAWL" },
  { label: "Purchases", value: "BUY" },
  { label: "Sales", value: "SALE" },
  { label: "Manual", value: "MANUAL" },
  { label: "Promo", value: "PROMO" },
];

const ButtonRow = ({ theme, selected, onPress }) => (
  <View
    style={{
      // width: "100%",
      // borderColor: "pink",
      // borderStyle: "dashed",
      // borderWidth: 2,
      // marginHorizontal: theme.spacing.xl * -1
      backgroundColor: theme.colors.surface,
    }}
  >
    <ScrollView
      horizontal
      contentContainerStyle={{
        paddingVertical: theme.spacing.lg,
      }}
      style={
        {
          // flexDirection: "row",
          // flex: 1,
          // paddingVertical: theme.spacing.lg,
        }
      }
    >
      {buttons
        .filter(({ label, value }) => value === selected || true)
        .map(({ label, value }, i) => (
          <Button
            key={i}
            onPress={() => onPress(value)}
            compact
            mode={selected === value ? "outlined" : "contained"}
            labelStyle={{
              ...theme.fonts.caption,
              marginVertical: theme.spacing.xs,
            }}
            style={{
              marginLeft: theme.spacing.sm,
            }}
          >
            {label}
          </Button>
        ))}
      {/* {
        buttons.filter(({label, value}) => value !== selected).map(({label, value}, i) => 
          <Button 
            key={i}
            onPress={() => onPress(value)}
            compact
            mode={selected === value ? "outlined" : 'contained'}
            labelStyle={{
              ...theme.fonts.caption,
              marginVertical: theme.spacing.xs,
            }}
            style={{
              marginLeft: theme.spacing.sm,
            }}
          >{label}</Button> 
        )
      } */}
    </ScrollView>
    <Divider />
  </View>
);

export default ({ navigation }) => {
  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);
  const [selectedType, setSelectedType] = React.useState(null);

  const filterItems = useCallback(
    item =>
      (selectedType === "DEPOSIT" &&
        item.type !== "CHECK" &&
        item.type !== "BUY") ||
      (selectedType === "WITHDRAWL" &&
        item.type !== "CC" &&
        item.type !== "BUY") ||
      !["DEPOSIT", "WITHDRAWL"].includes(selectedType),
    [selectedType]
  );

  const dataListQuery = useMemo(
    () => ({
      dataKey: !selectedType
        ? "transactionsByUserId"
        : "transactionsByUserIdAndType",
      query: !selectedType
        ? Transaction.queries.listByUserId
        : Transaction.queries.listByUserIdAndType,
      variables: !selectedType
        ? {
            transactionUserId: currentUser?.id ?? "<>",
            sortDirection: "DESC",
          }
        : selectedType === "DEPOSIT"
        ? {
            sortDirection: "DESC",
            transactionUserId: currentUser?.id ?? "<>",
            type: {
              between: ["C", "F"],
            },
            // filter: {
            //   type: {
            //     ne: 'CHECK'
            //   }
            // }
          }
        : selectedType === "WITHDRAWL"
        ? {
            sortDirection: "DESC",
            transactionUserId: currentUser?.id ?? "<>",
            type: {
              between: ["A", "D"],
            },
            // filter: {
            //   type: {
            //     ne: 'CC'
            //   }
            // }
          }
        : {
            sortDirection: "DESC",
            transactionUserId: currentUser?.id ?? "<>",
            type: {
              eq: selectedType.replace(/SALE/, "SELL"),
            },
          },
    }),
    [currentUser, selectedType]
  );

  const dataListRenderItem = useCallback(
    ({ item, index }) =>
      !item?.id ? (
        <ButtonRow
          theme={theme}
          selected={selectedType}
          onPress={setSelectedType}
        />
      ) : ["BUY", "SELL"].includes(item.type) ? (
        <TouchableRipple
          onPress={() =>
            navigation.push("ListingModalStack", {
              screen: "ListingDetail",
              params: { id: item.transactionId },
            })
          }
        >
          <TransactionItem item={item} theme={theme} />
        </TouchableRipple>
      ) : ["CC", "CHECK", "MANUAL"].includes(item.type) ? (
        <TouchableRipple
          onPress={() => navigation.push("TransactionDetail", { id: item.id })}
        >
          <TransactionItem item={item} theme={theme} />
        </TouchableRipple>
      ) : (
        <TransactionItem item={item} theme={theme} />
      ),
    [theme, selectedType, navigation]
  );

  return (
    <Screen
      title="Transactions"
      fullHeight
      fullWidth
      gatedTo="member"
      hasScroll={false}
      backgroundColor={theme.colors.surface}
    >
      <DataList
        cellsToAppend={1}
        filterItems={filterItems}
        query={dataListQuery}
        renderItem={dataListRenderItem}
        stickyHeaderIndices={[1]}
        ListHeaderComponent={
          <View
            style={{
              paddingVertical: theme.spacing.sm,
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: theme.colors.background,
              borderBottomWidth: 1,
              borderColor: theme.colors.border,
              borderStyle: "solid",
            }}
          >
            <View style={{ marginHorizontal: theme.spacing.md }}>
              <Subheading style={{ textAlign: "center" }}>Balance</Subheading>
              <Text style={{ textAlign: "center" }}>
                {formatPennies(
                  currentUser?.balance + currentUser?.promotionalBalance,
                  "$"
                )}
              </Text>
            </View>
            <View style={{ marginHorizontal: theme.spacing.md }}>
              <Subheading style={{ textAlign: "center" }}>Pending</Subheading>
              <Text style={{ textAlign: "center" }}>
                {formatPennies(currentUser?.pendingBalance, "$")}
              </Text>
            </View>
            <View style={{ marginHorizontal: theme.spacing.md }}>
              <Subheading style={{ textAlign: "center" }}>Promo</Subheading>
              <Text style={{ textAlign: "center" }}>
                {formatPennies(currentUser?.promotionalBalance, "$")}
              </Text>
            </View>
          </View>
        }
      />
    </Screen>
  );
};
