import Screen from "Components/Screen";
import TransactionDetail from "Components/Transaction/Detail";
import { TabBarContext } from "Contexts/TabBar";
import { useContext, useCallback, useLayoutEffect } from "react";
import { useTheme } from "react-native-paper";
import { HeaderButtons, Item } from "react-navigation-header-buttons";
import { MaterialCommunityIcons } from "@expo/vector-icons";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation, route, ...rest }) => {
  const theme = useTheme();
  const tabBar = useContext(TabBarContext);

  const handleClose = useCallback(() => navigation.goBack(), [navigation]);

  useLayoutEffect(() => {
    tabBar.setVisible(false);

    return () => tabBar.setVisible(true);
  }, [tabBar]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons size={48}>
          <Item
            color={theme.colors.textReverse}
            title="Close"
            IconComponent={MaterialCommunityIcons}
            iconSize={18}
            iconName="close"
            onPress={handleClose}
          />
        </HeaderButtons>
      ),
    });
  }, [navigation, handleClose, theme.colors.textReverse]);

  return (
    <Screen
      fullHeight
      backgroundColor={theme.colors.surface}
      title="Transaction Details"
    >
      <TransactionDetail
        onClose={handleClose}
        transactionId={route.params?.id}
      />
    </Screen>
  );
};
