import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import { useTheme } from "react-native-paper";

import { useSafeAreaInsets } from "react-native-safe-area-context";

import ListingLearn from "Screens/ListingLearn";
import TicketsAwaiting from "Screens/TicketsAwaiting";
import TicketsWarning from "Screens/TicketsWarning";
import ListingModalStack from "./ListingModalStack";
import TicketNewStack from "./TicketNewStack";

import Activity from "Screens/Activity";
import Notifications from "Screens/Notifications";
import WatchList from "Screens/WatchList";
import DepositStack from "./DepositStack";
import {
  getPartialModalOptions,
  getPlainOptions,
  headerNavOptions,
} from "./index";
import PointsStack from "./PointsStack";
import ProfileStack from "./ProfileStack";
// import BackButton from 'Components/BackButton';

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={{
        ...screenOptions,
        ...headerNavOptions,
      }}
    >
      <Stack.Screen
        name="TicketsAwaiting"
        component={TicketsAwaiting}
        options={{
          title: "Sell",
        }}
      />
      <Stack.Screen
        name="ListingModalStack"
        component={ListingModalStack}
        options={getPartialModalOptions({ offset: insets.top })}
      />
      <Stack.Screen name="TicketsWarning" component={TicketsWarning} />
      <Stack.Screen
        name="ListingLearn"
        component={ListingLearn}
        options={({ navigation }) => getPlainOptions({ navigation, theme })}
      />
      <Stack.Screen name="TicketNewStack" component={TicketNewStack} />
      <Stack.Screen name="Activity" component={Activity} />
      <Stack.Screen name="WatchList" component={WatchList} />
      <Stack.Screen name="Notifications" component={Notifications} />
      <Stack.Screen name="ProfileStack" component={ProfileStack} />
      <Stack.Screen name="PointsStack" component={PointsStack} />
      <Stack.Screen name="DepositStack" component={DepositStack} />
    </Stack.Navigator>
  );
};
