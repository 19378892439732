import {
  createStackNavigator,
  HeaderStyleInterpolators,
} from "@react-navigation/stack";
// import { useLayoutEffect } from 'react';
import MainTabs from "./MainTabs";
import NotFoundScreen from "Screens/NotFound";
import DesignPatternLibrary from "Screens/DesignPatternLibrary";
import AuthStack from "./AuthStack";
import OauthRequest from "Screens/OauthRequest";
import useHasLatestVersion from "Hooks/useHasLatestVersion";
import { useEffect } from "react";
import { Alert } from "react-native";
import * as Linking from "expo-linking";

const Stack = createStackNavigator();

export default () => {
  const { hasLatestVersion, remoteVersionNumber, localVersionNumber } =
    useHasLatestVersion();

  useEffect(() => {
    !hasLatestVersion &&
      Alert.alert(
        "New Version Available",
        `You are using version ${localVersionNumber}. Please update the app to version ${remoteVersionNumber} by visiting the Apple Store.`,
        [
          {
            text: "OK",
            onPress: () =>
              Linking.openURL(
                "https://apps.apple.com/us/app/propswap/id1492962264?uo=4"
              ),
          },
        ]
      );
  }, [hasLatestVersion, localVersionNumber, remoteVersionNumber]);

  return (
    <Stack.Navigator
      screenOptions={{
        headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
        headerBackTitleVisible: false,
      }}
    >
      <Stack.Screen name="AuthStack" component={AuthStack} />
      <Stack.Screen name="Main" component={MainTabs} />
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: "Oops!" }}
      />
      <Stack.Screen
        name="DesignPatternLibrary"
        component={DesignPatternLibrary}
        options={{ title: "Design" }}
      />
      <Stack.Screen name="OauthRequest" component={OauthRequest} />
    </Stack.Navigator>
  );
};
