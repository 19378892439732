import Screen from "Components/Screen";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { TutorialSwipperContext } from "Contexts/TutorialSwipper";
import Constants from "expo-constants";
import React, { useCallback, useContext } from "react";
import { Divider, List, useTheme } from "react-native-paper";

export default ({ navigation }) => {
  const paperTheme = useTheme();
  const currentUser = useContext(CurrentUserContext);
  const { restartTutorial } = useContext(TutorialSwipperContext);

  const navigateTo = useCallback(
    screen => () =>
      screen === "TutorialReset" ? restartTutorial() : navigation.push(screen),
    [navigation, restartTutorial]
  );

  return (
    <Screen
      title="My Account"
      fullHeight
      fullWidth
      hasScroll
      backgroundColor={paperTheme.colors.surface}
    >
      {(!!currentUser
        ? [
            ["My Profile", "ProfileStack"],
            ["My Wallet", "WalletStack"],
            ["Transaction History", "TransactionStack"],
            ["My Points", "PointsStack"],
            ["Notifications", "NotificationSettings"],
            ["FAQ", "Faq"],
            ["Tutorial", "TutorialReset"],
            ["About", "About"],
            ["Contact", "Contact"],
            !!currentUser?.groups?.includes?.("Admins") && [
              "Cache",
              "Settings",
            ],
            ["Theme", "ThemeSettings"],
            ["Logout", "Logout"],
          ]
        : [
            ["FAQ", "Faq"],
            ["Tutorial", "TutorialReset"],
            ["About", "About"],
            ["Contact", "Contact"],
            !!currentUser?.groups?.includes?.("Admins") && [
              "Cache",
              "Settings",
            ],
          ]
      )
        .filter(item => !!item)
        .map(([label, screen]) => (
          <React.Fragment key={label}>
            <List.Item
              title={label}
              right={props => <List.Icon {...props} icon="chevron-right" />}
              onPress={navigateTo(screen)}
            />
            <Divider />
          </React.Fragment>
        ))}
      <List.Item
        title={`Version ${
          Constants.expoConfig?.version ?? Constants.manifest?.version
        }`}
      />
    </Screen>
  );
};
