import useStyles from "Hooks/useStyles";
import { useCallback, useState } from "react";
import ExpandableStyles from "./ExpandableStyles";
import ExpandableView from "./ExpandableView";

export default ({ title, items }) => {
  const { theme, styles } = useStyles({ Styles: ExpandableStyles });
  const [expanded, setExpanded] = useState(false);

  const handlePress = useCallback(() => setExpanded(expanded => !expanded), []);

  return (
    <ExpandableView
      title={title}
      items={items}
      theme={theme}
      styles={styles}
      expanded={expanded}
      onPress={handlePress}
    />
  );
};
