import InterchecksWithdrawSummary from "Components/Interchecks/WithdrawSummary";
import { useContext } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";

export default ({ navigation, route }) => {
  const currentUser = useContext(CurrentUserContext);
  return (
    <InterchecksWithdrawSummary
      navigation={navigation}
      type="CHECK"
      amount={route?.params?.amount ?? "0.00"}
      mailingAddress1={
        route?.params?.mailingAddress1 ?? currentUser?.attributes?.address
      }
      mailingAddress2={
        route?.params?.mailingAddress2 ??
        currentUser?.attributes?.["custom:address2"]
      }
      mailingCity={
        route?.params?.mailingCity ?? currentUser?.attributes?.["custom:city"]
      }
      mailingState={
        route?.params?.mailingState ?? currentUser?.attributes?.["custom:state"]
      }
      mailingZip={
        route?.params?.mailingZip ?? currentUser?.attributes?.["custom:zip"]
      }
    />
  );
};
