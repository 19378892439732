export default theme => ({
  avatarWrapper: {
    marginRight: theme.spacing.sm,
  },
  wrapper: {
    marginTop: theme.spacing.md,
    paddingHorizontal: theme.spacing.xl,
  },
  bottomWrapper: {
    paddingHorizontal: theme.spacing.xl * 2,
    borderWidth: 1,
    borderColor: "pink",
    borderStyle: "dashed",
  },
});
