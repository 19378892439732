import { Text } from "react-native-paper";

import ResponsiveImage from "Components/ResponsiveImage";
import { logoFull, logoFullDark } from "assets";

export default ({ theme, action }) => (
  <>
    <ResponsiveImage
      style={{
        alignSelf: "center",
        marginBottom: theme.spacing.xl,
        marginTop: theme.spacing.xl * 4,
      }}
      source={theme.dark ? logoFullDark : logoFull}
      height={72}
    />
    <Text style={{ ...theme.fonts.h2, textAlign: "center" }}>
      {action} to Buy or Sell tickets on PropSwap.
    </Text>
  </>
);
