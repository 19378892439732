import * as React from "react";

import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import WithdrawNav from "Screens/WithdrawNav";
import DirectDepositWithdrawStack from "./DirectDepositWithdrawStack";
import PaperWithdrawStack from "./PaperWithdrawStack";
import { Platform } from "react-native";
import BackButton from "Components/BackButton";
import { getStandardScreenOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={getStandardScreenOptions({ screenOptions, navigation })}
    >
      <Stack.Screen name="WithdrawNav" component={WithdrawNav} />
      <Stack.Screen
        name="DirectDepositWithdrawStack"
        component={DirectDepositWithdrawStack}
      />
      <Stack.Screen name="PaperWithdrawStack" component={PaperWithdrawStack} />
    </Stack.Navigator>
  );
};
