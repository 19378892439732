import { useCallback, useContext, useState, useEffect, useMemo } from "react";
import Redemption from "shared/api/Redemption";
import Promotion from "shared/api/Promotion";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { useQuery } from "@apollo/client";
import PromoCodeView from "./PromoCodeView";
import useStyles from "Hooks/useStyles";
import PromoCodeStyles from "./PromoCodeStyles";

export default ({ onSuccess, promotionId }) => {
  const { theme, styles } = useStyles({ Styles: PromoCodeStyles });
  const currentUser = useContext(CurrentUserContext);
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const getOptions = useMemo(
    () => ({
      skip: !promotionId || !!code,
      variables: {
        id: promotionId,
      },
    }),
    [code, promotionId]
  );

  const { data: { getPromotion } = {} } = useQuery(
    Promotion.queries.get,
    getOptions
  );

  const promotionsOptions = useMemo(
    () => ({
      skip: !code,
      variables: {
        code,
      },
    }),
    [code]
  );

  const {
    error,
    data: { getPromotionByCode: { items: promotions } = {} } = {},
  } = useQuery(Promotion.queries.getByCode, promotionsOptions);

  const redemptionsOptions = useMemo(
    () => ({
      variables: {
        redemptionUserId: currentUser?.id,
      },
      pollInterval: 6000,
    }),
    [currentUser?.id]
  );

  const {
    error: redemptionError,
    data: {
      getRedemptionByUserIdAndPromotionId: { items: redemptions } = {},
    } = {},
  } = useQuery(
    Redemption.queries.getByUserIdAndPromotionId,
    redemptionsOptions
  );

  const selectedPromotion = !code
    ? getPromotion ?? promotions?.[0]
    : promotions?.[0];
  const isExpired =
    selectedPromotion?.isExpired || selectedPromotion?.campaign?.isExpired;
  const usedRedemption = !!(redemptions ?? []).find(
    redemption =>
      redemption.promotion.code === code ||
      redemption.redemptionCampaignId === selectedPromotion?.promotionCampaignId
  );

  const validateCode = useCallback(
    () =>
      !selectedPromotion
        ? setErrorMessage("Invalid code.")
        : !!usedRedemption
        ? setErrorMessage("This code has already been used.")
        : !!isExpired
        ? setErrorMessage("That code has expired")
        : onSuccess(selectedPromotion) ||
          setErrorMessage("") ||
          setSuccessMessage(
            "Code Applied! Funds will be added to your Bonus Balance."
          ),
    [selectedPromotion, usedRedemption, isExpired, onSuccess]
  );

  !!error && console.error(error);
  !!redemptionError && console.error(redemptionError);

  useEffect(() => {
    !!selectedPromotion &&
      setTimeout(
        () => [validateCode(), setCode(selectedPromotion?.code)],
        1000
      );
  }, [selectedPromotion, validateCode]);

  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
  }, [code]);

  return (
    <PromoCodeView
      theme={theme}
      styles={styles}
      code={code}
      setCode={setCode}
      errorMessage={errorMessage}
      successMessage={successMessage}
      validateCode={validateCode}
    />
  );
};
