import * as React from "react";
import { useContext, useCallback, useEffect } from "react";
import {
  Button,
  Text,
  Title,
  useTheme,
  TextInput,
  HelperText,
} from "react-native-paper";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RegistrationContext } from "Contexts/Registration";
import { View, Platform } from "react-native";
import Auth from "@aws-amplify/auth";
import ScreenBottom from "Components/Screen/Bottom";
import Screen from "Components/Screen";
import AuthWrapper from "Components/AuthWrapper";

const schema = yup.object().shape({
  code: yup
    .string()
    .matches(/^\d\d\d\d\d\d$/i, "Must be a valid 6-digit code")
    .required("Can't be blank"),
});

const emailSchema = yup.object().shape({
  code: yup
    .string()
    .matches(/^\d\d\d\d\d\d$/i, "Must be a valid 6-digit code")
    .required("Can't be blank"),
  email: yup
    .string()
    .email("Must be a valid email address")
    .required("Can't be blank"),
});

const Form = ({ style, theme, navigation, code }) => {
  const { setRegistrationValues, registrationValues } =
    useContext(RegistrationContext);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setError,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(!!registrationValues.email ? schema : emailSchema),
  });

  // const isValid = !Object.keys(errors).length;

  const inputs = React.useRef({});

  const handleSubmitError = useCallback(
    error =>
      setError(!!registrationValues.email ? "email" : "code", {
        ...error,
        message:
          error?.code === "UserNotFoundException"
            ? "We don't have an account that matches that email"
            : `${error.code} - ${error.message}`,
      }),
    [setError, registrationValues]
  );

  const onSubmit = useCallback(
    ({ code, email }) =>
      Auth.confirmSignUp(email ?? registrationValues.email, code)
        .then(() =>
          setRegistrationValues({
            ...registrationValues,
            email: email ?? registrationValues.email,
          })
        )
        .then(data => navigation.push("RegistrationPhoto"))
        .catch(handleSubmitError),
    [registrationValues, handleSubmitError, setRegistrationValues, navigation]
  );

  const doSubmit = handleSubmit(onSubmit);
  const email = registrationValues.email;
  const submitter = React.useRef({
    submit: doSubmit,
  });

  useEffect(() => {
    !!email && !!code && !!submitter?.current && submitter.current.submit();
  }, [email, code, submitter]);

  return (
    <>
      <View
        style={[
          {
            flex: 1,
          },
          style ?? {},
        ]}
      >
        {!registrationValues.email && (
          <>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value, ...mre },
                ...rest
              }) => (
                <>
                  <TextInput
                    autoFocus
                    autoCorrect={false}
                    autoCapitalize="none"
                    ref={e => (inputs.current["email"] = e)}
                    autoCompleteType={
                      Platform.OS === "web" ? "new-password" : "email"
                    }
                    keyboardType="email-address"
                    textContentType="emailAddress"
                    label="Email"
                    error={!!errors.email}
                    returnKeyLabel="Next"
                    returnKeyType="next"
                    onSubmitEditing={() => inputs.current.code.focus()}
                    mode="flat"
                    onBlur={onBlur}
                    onChangeText={value => onChange(value.toLowerCase())}
                    value={value}
                    style={{
                      backgroundColor: "transparent",
                    }}
                  />
                  <HelperText type="error" visible={!!errors.email}>
                    {errors.email?.message}
                  </HelperText>
                </>
              )}
              name="email"
              defaultValue=""
            />
            {!!errors.email && (
              <HelperText type="error" visible={!!errors.email}>
                {errors.email?.message}
              </HelperText>
            )}
            {!errors.email && (
              <HelperText type="info" visible>
                Enter the email address you used to register
              </HelperText>
            )}
          </>
        )}
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoFocus={!registrationValues.email}
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["code"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="number-pad"
                textContentType="oneTimeCode"
                label="Enter Code"
                error={!!errors.code}
                returnKeyLabel="Done"
                returnKeyType="done"
                onSubmitEditing={doSubmit}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              {!!errors.code && (
                <HelperText type="error" visible={!!errors.code}>
                  {errors.code?.message}
                </HelperText>
              )}
              {!errors.code && (
                <HelperText type="info" visible>
                  Enter the six digit code we sent to{" "}
                  {!!registrationValues.email
                    ? registrationValues.email
                    : "your email address"}
                </HelperText>
              )}
            </>
          )}
          name="code"
          defaultValue={code ?? ""}
        />
      </View>
      <ScreenBottom adjustPadding>
        <View style={{ paddingHorizontal: theme.spacing.xl, width: "100%" }}>
          <Button
            disabled={!isValid || !!isSubmitting}
            loading={!!isSubmitting}
            mode="contained"
            onPress={doSubmit}
            style={{ width: "100%" }}
          >
            Confirm Account
          </Button>
        </View>
        <View style={{ flexDirection: "row", marginTop: theme.spacing.lg }}>
          <Text
            onPress={() =>
              Promise.resolve(
                registrationValues.email ||
                  prompt("Please enter the email you used to register")
              )
                .then(
                  email =>
                    !!email &&
                    Auth.resendSignUp(email).then(() =>
                      alert("Code Sent! Please check your email")
                    )
                )
                .catch(handleSubmitError)
            }
            style={{ color: theme.colors.secondary }}
          >
            Resend Code
          </Text>
          <Text style={{ color: theme.colors.secondary }}> | </Text>
          <Text
            onPress={() => navigation.navigate("Main")}
            style={{ color: theme.colors.secondary }}
          >
            Cancel
          </Text>
        </View>
      </ScreenBottom>
    </>
  );
};

export default ({ navigation, route }) => {
  const theme = useTheme();

  return (
    <Screen
      gatedTo="guest"
      title="Confirm Account"
      hasKeyboard
      backgroundColor={theme.colors.surface}
      fullHeight
      fullWidth
    >
      {Platform.OS === "web" ? (
        <AuthWrapper>
          <>
            <Title style={{ textAlign: "center", color: theme.colors.primary }}>
              Enter Code
            </Title>
            <Form
              style={{
                marginTop: theme.spacing.xl,
                marginHorizontal: theme.spacing.xl,
              }}
              theme={theme}
              navigation={navigation}
              code={route?.params?.code}
            />
          </>
        </AuthWrapper>
      ) : (
        <>
          <Title style={{ textAlign: "center", color: theme.colors.primary }}>
            Enter Code
          </Title>
          <Form
            style={{
              marginTop: theme.spacing.xl,
              marginHorizontal: theme.spacing.xl,
            }}
            theme={theme}
            navigation={navigation}
            code={route?.params?.code}
          />
        </>
      )}
    </Screen>
  );
};
