import { useContext, useCallback, useState } from "react";
import { Button, HelperText, useTheme } from "react-native-paper";

import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import { View } from "react-native";
import DateTimePicker from "Components/DateTimePicker";
import moment from "moment";
import useHeaderFormSaveButton from "Hooks/useHeaderFormSaveButton";

const field = "expiresAt";

export default ({ navigation }) => {
  const theme = useTheme();
  const { setTicketValues, ticketValues } = useContext(TicketContext);
  const [value, setValue] = useState(ticketValues[field]);

  const format = true ? "YYYY-MM-DDTHH:mm:ss.SSS" : "MM/DD/YYYY hh:mm a";

  const onChange = useCallback(
    text =>
      !!text?.target?.value
        ? setValue(moment(text.target.value).format(format))
        : !!text?.getDay
        ? setValue(moment(text).format(format))
        : !!text?.length
        ? setValue(moment(text).format(format))
        : setValue(null),
    [format]
  );

  const onSubmit = useCallback(
    () => [
      setTicketValues(ticketValues => ({
        ...ticketValues,
        [field]: value,
      })),
      navigation.navigate("TicketNewStep4"),
    ],
    [value, navigation, setTicketValues]
  );

  const isValid =
    !value ||
    (moment(value, format, true).isValid() &&
      moment(value, format, true).subtract("2", "minutes").toDate() >=
        moment().toDate());

  useHeaderFormSaveButton({ onSubmit, isValid });

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={3}
        title="Enter Expiration Date"
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />

      <View style={{ marginHorizontal: theme.spacing.xl }}>
        <DateTimePicker
          onChange={onChange}
          value={!value ? "" : moment(value).format("YYYY-MM-DDTHH:mm")}
        />

        <Button disabled={!isValid} mode="contained" onPress={onSubmit}>
          Save
        </Button>
        {!isValid && (
          <HelperText type="error">Must be at least 2 minutes ahead</HelperText>
        )}
      </View>
    </Screen>
  );
};
