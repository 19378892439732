import React from "react";

import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import RegistrationStep1 from "Screens/RegistrationStep1";
import RegistrationStep2 from "Screens/RegistrationStep2";
import RegistrationStep3 from "Screens/RegistrationStep3";
import RegistrationStep4 from "Screens/RegistrationStep4";
import RegistrationConfirmation from "Screens/RegistrationConfirmation";
import RegistrationPhoto from "Screens/RegistrationPhoto";
import RegistrationSuccess from "Screens/RegistrationSuccess";
import { getStandardScreenOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={getStandardScreenOptions({ screenOptions, navigation })}
    >
      <Stack.Screen name="RegistrationStep1" component={RegistrationStep1} />
      <Stack.Screen name="RegistrationStep2" component={RegistrationStep2} />
      <Stack.Screen name="RegistrationStep3" component={RegistrationStep3} />
      <Stack.Screen name="RegistrationStep4" component={RegistrationStep4} />
      <Stack.Screen
        name="RegistrationConfirmation"
        component={RegistrationConfirmation}
      />
      <Stack.Screen
        name="RegistrationPhoto"
        component={RegistrationPhoto}
        options={{
          headerLeft: () => null,
        }}
      />
      <Stack.Screen
        name="RegistrationSuccess"
        component={RegistrationSuccess}
        options={{
          headerLeft: () => null,
        }}
      />
    </Stack.Navigator>
  );
};
