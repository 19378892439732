import { useEffect } from "react";
import { Button, Subheading, Title, useTheme } from "react-native-paper";

import { View } from "react-native";
import DismissKeyboard from "Components/DismissKeyboard";
import { FontAwesome5 } from "@expo/vector-icons";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation }) => {
  const theme = useTheme();
  const handleDefaultNav = () => navigation.replace("Main");

  useEffect(() => {
    navigation.setOptions({ title: "Confirm Account" });
  }, [navigation]);

  return (
    <DismissKeyboard>
      <View
        style={{
          marginHorizontal: theme.spacing.xl,
          marginTop: theme.spacing.xl * 2,
          flex: 1,
        }}
      >
        <Title
          style={{
            textAlign: "center",
            color: theme.colors.primary,
            fontWeight: "bold",
          }}
        >
          Registration Successful!
        </Title>
        <View
          style={{
            width: "100%",
            alignItems: "center",
            marginTop: theme.spacing.xl * 4,
            marginBottom: theme.spacing.xl * 2,
          }}
        >
          <FontAwesome5
            name="check-circle"
            size={172}
            color={theme.colors.primary}
          />
        </View>
        <Subheading style={{ textAlign: "center" }}>
          Earn a FREE Deposit Bonus up to $100! Use Promo Code "FREE100" when
          you add money to your PropSwap accont.
        </Subheading>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "flex-end",
            marginBottom: theme.spacing.xl,
          }}
        >
          <View style={{ flex: 1 }}>
            <View style={{ flex: 1, alignItems: "center" }}>
              <Button
                mode="contained"
                onPress={() =>
                  navigation.replace("Main", {
                    screen: "Account",
                    initial: false,
                    // params: {
                    //   screen: "WalletStack",
                    //   initial: false,
                    //   // params: {
                    //   //   screen: "DepositStack",
                    //   //   initial: false,
                    //   //   // params: {
                    //   //   //   screen: "CreditCardDepositStack",
                    //   //   //   initial: false,
                    //   //   // },
                    //   // },
                    // },
                  })
                }
                style={{ width: "100%" }}
              >
                Add Money To My Account
              </Button>
            </View>

            <View style={{ flex: 1, alignItems: "center" }}>
              <Button
                mode="text"
                onPress={handleDefaultNav}
                style={{ width: "100%" }}
              >
                No Thanks! Start using PropSwap
              </Button>
            </View>
          </View>
        </View>
      </View>
    </DismissKeyboard>
  );
};
