import Screen from "Components/Screen";
import BidForm from "Components/Bid/Form";
import { TabBarContext } from "Contexts/TabBar";
import { useContext, useCallback, useLayoutEffect } from "react";
import { useTheme } from "react-native-paper";
import { HeaderButtons, Item } from "react-navigation-header-buttons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useMutation, useQuery } from "@apollo/client";
import Bid from "shared/api/Bid";
import { convertMoneyTextToPennies } from "shared/Util/money";
import { CurrentUserContext } from "Contexts/CurrentUser";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation, route, ...rest }) => {
  const theme = useTheme();
  const tabBar = useContext(TabBarContext);
  const id = route.params?.id;
  const action = route.params?.action;
  const currentUser = useContext(CurrentUserContext);

  const { data: { getBid } = {} } = useQuery(Bid.queries.get, {
    variables: {
      id,
    },
  });

  const listingId = getBid?.bidListingId;

  const [updateBidStatus] = useMutation(Bid.mutations.updateBidStatus, {
    variables: {
      id,
      status: action,
    },
  });

  const handleSubmit = useCallback(
    ({ counter }) =>
      updateBidStatus({
        variables: {
          input: {
            id,
            status: action === "ACCEPTED" ? "ACCEPTED" : "REJECTED",
            counter: !!counter ? convertMoneyTextToPennies(counter) : undefined,
            // boughtFrom: Platform.OS.toUpperCase()
          },
        },
      }).then(() => navigation.navigate("ListingDetail", { id: listingId })),
    [updateBidStatus, navigation, id, action, listingId]
  );

  const handleClose = useCallback(
    () => navigation.navigate("ListingDetail", { id: listingId }),
    [navigation, listingId]
  );

  useLayoutEffect(() => {
    tabBar.setVisible(false);

    return () => tabBar.setVisible(true);
  }, [tabBar]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons size={48}>
          <Item
            color={theme.colors.textReverse}
            title="Close"
            IconComponent={MaterialCommunityIcons}
            iconSize={18}
            iconName="close"
            onPress={handleClose}
          />
        </HeaderButtons>
      ),
    });
  }, [navigation, handleClose, theme.colors.textReverse]);

  return (
    <Screen
      fullHeight
      backgroundColor={theme.colors.surface}
      title={
        action === "REJECTED"
          ? "Reject Bid"
          : action === "COUNTERED"
          ? "Counter Bid"
          : "Accept Bid"
      }
    >
      <BidForm
        onSubmit={handleSubmit}
        bidId={route.params?.id}
        action={action}
        currentUser={currentUser}
      />
    </Screen>
  );
};
