import gql from "graphql-tag";

const Locale = {};

Locale.fragments = {
  public: gql`
    fragment LocalePublic on Locale {
      id
      name
      state
      mailingAddress
      createdAt
      updatedAt
      additionalEmails
    }
  `,
};

Locale.queries = {
  list: gql`
    query ListLocales(
      $filter: ModelLocaleFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listLocales(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...LocalePublic
        }
      }
    }
    ${Locale.fragments.public}
  `,
  get: gql`
    query GetLocale($id: ID!) {
      getLocale(id: $id) {
        ...LocalePublic
      }
    }
    ${Locale.fragments.public}
  `,
};

Locale.mutations = {
  create: gql`
    mutation CreateLocale($input: CreateLocaleInput!) {
      createLocale(input: $input) {
        ...LocalePublic
      }
    }
    ${Locale.fragments.public}
  `,
  update: gql`
    mutation UpdateLocale($input: UpdateLocaleInput!) {
      updateLocale(input: $input) {
        ...LocalePublic
      }
    }
    ${Locale.fragments.public}
  `,
  delete: gql`
    mutation DeleteLocale($input: DeleteLocaleInput!) {
      deleteLocale(input: $input) {
        ...LocalePublic
      }
    }
    ${Locale.fragments.public}
  `,
};

export default Locale;
