import { Title, useTheme } from "react-native-paper";
import Screen from "Components/Screen";
import { useContext, useEffect } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import environment from "environment";
import Provider from "shared/api/Provider";
import { useQuery } from "@apollo/client";

const redirectToOauthLogin = state => {
  window.location.href = environment.oauth_url + "&state=" + state;
};

export default ({ navigation }) => {
  const theme = useTheme();

  const currentUser = useContext(CurrentUserContext);
  const providerId = (
    (currentUser?.groups).find(group => /Manager/.test(group)) ?? ""
  ).split("Manager-")[1];
  const { data: { getProvider } = {} } = useQuery(Provider.queries.get, {
    skip: !providerId,
    variables: {
      id: providerId,
    },
  });
  const url = !!getProvider ? getProvider.oauthUrl : null;

  useEffect(() => {
    !!url && redirectToOauthLogin(url);
  }, [url]);

  return (
    <Screen
      fullHeight
      fullWidth
      title="oAuth Code Request"
      hasKeyboard
      backgroundColor={theme.colors.surface}
      gatedTo="member"
    >
      <Title style={{ textAlign: "center", color: theme.colors.primary }}>
        You are being redirected...
      </Title>
    </Screen>
  );
};
