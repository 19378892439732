import { useEffect, useState } from "react";
import { Image, Platform, useWindowDimensions } from "react-native";

export default ({
  height,
  width,
  source,
  style,
  maxWidth: passedMaxWidth,
  maxHeight: passedMaxHeight,
}) => {
  const uri = source?.uri ?? source;
  const [imageDimensions, setImageDimensions] = useState([1, 1]);
  const [imageWidth, imageHeight] = imageDimensions;
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const maxHeight = passedMaxHeight ?? windowHeight;
  const maxWidth = Math.min(
    passedMaxWidth ?? windowWidth,
    Platform.OS === "web" ? 601 : 50000
  );

  // const usableWidth = !!width ? width : imageHeight < height ? (imageHeight / height) * imageWidth : (height / imageHeight) * imageWidth;
  // const usableHeight = !!height ? height : imageWidth < width ? (imageWidth / width) * imageHeight : (width / imageWidth) * imageHeight;

  const usableWidth = width ?? (height / imageHeight) * imageWidth;
  const usableHeight = height ?? (width / imageWidth) * imageHeight;

  const finalHeight =
    usableWidth > maxWidth
      ? (maxWidth / usableWidth) *
        (usableHeight > maxHeight ? maxHeight : usableHeight)
      : usableHeight > maxHeight
      ? maxHeight
      : usableHeight;
  const finalWidth =
    usableHeight > maxHeight
      ? (maxHeight / usableHeight) *
        (usableWidth > maxWidth ? maxWidth : usableWidth)
      : usableWidth > maxWidth
      ? maxWidth
      : usableWidth;

  useEffect(() => {
    !!uri &&
      !Number.isInteger(uri) &&
      Image.getSize(uri, (width, height) =>
        setImageDimensions([width, height])
      );

    !!uri &&
      !!Number.isInteger(uri) &&
      Promise.resolve(Image.resolveAssetSource(uri)).then(resp =>
        setImageDimensions([resp.width, resp.height])
      );
  }, [uri]);

  return (
    <Image
      source={source}
      style={[
        style ?? {},
        {
          width: finalWidth,
          height: finalHeight,
        },
      ]}
    />
  );
};
