import * as React from "react";
import { Button, useTheme } from "react-native-paper";

import Screen from "Components/Screen";
import PhotoUpload from "Components/PhotoUpload";
import { useContext, useEffect } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { Auth } from "aws-amplify";
import { RegistrationContext } from "Contexts/Registration";
import { AvatarContext } from "Contexts/Avatar";
import ScreenBottom from "Components/Screen/Bottom";
import { useMutation } from "@apollo/client";
import User from "shared/api/User";
import { View } from "react-native";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation }) => {
  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);
  const { registrationValues, resetRegistration } =
    useContext(RegistrationContext);
  const { localUri } = useContext(AvatarContext);
  const [updateDisplayName] = useMutation(User.mutations.update);
  const [signedIn, setSignedIn] = React.useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !!registrationValues.email && !!registrationValues.password
      ? Auth.signIn(registrationValues.email, registrationValues.password).then(
          cognitoUser => [
            setSignedIn(true),
            updateDisplayName({
              variables: {
                input: {
                  id: cognitoUser.username,
                  displayName: registrationValues.displayName.toLowerCase(),
                },
              },
            }).then(resetRegistration),
          ]
        )
      : !signedIn
      ? navigation.push("AuthStack", { screen: "Login" })
      : null;
  }, [
    registrationValues,
    resetRegistration,
    navigation,
    signedIn,
    updateDisplayName,
  ]);

  return (
    <Screen title="Upload Profile Photo" backgroundColor={theme.colors.surface}>
      {!!currentUser && (
        <>
          <View style={{ flex: 1 }}>
            <PhotoUpload />
          </View>
          <ScreenBottom theme={theme} adjustPadding>
            <Button
              color={theme.colors.primary}
              onPress={() => navigation.push("RegistrationSuccess")}
              style={{ width: "100%", marginBottom: theme.spacing.xl }}
              mode="outlined"
            >
              {!!currentUser?.profilePicUri || !!localUri
                ? "Continue"
                : "Skip for Later"}
            </Button>
          </ScreenBottom>
        </>
      )}
    </Screen>
  );
};
