import React from "react";

import BottomSheet from "Components/BottomSheet";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { ScrollView, View } from "react-native";
import { RadioButton, Subheading, Divider, Button } from "react-native-paper";
import Spacer from "Components/Spacer";
import ScreenBottom from "Components/Screen/Bottom";

const groupBy = (xs, key) =>
  xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, []);

export default ({
  visible,
  onDismiss,
  theme,
  title,
  selectedValue,
  onValueChange,
  items,
  multiple,
  height = "80%",
  limitHeight,
  styles,
  fullScreen,
}) => (
  <BottomSheet
    visible={visible}
    onDismiss={onDismiss}
    fullWidth
    height={height}
    containerStyle={styles.bottomSheet}
    fullScreen={fullScreen}
  >
    <View style={styles.wrapper}>
      <Spacer />
      <View style={styles.f1}>
        <Subheading
          style={[styles.textCenter, styles.textUpper, styles.textReverse]}
        >
          {title}
        </Subheading>
      </View>
      <View style={{ width: 20 }}>
        <MaterialCommunityIcons
          name="close"
          size={18}
          color={theme.colors.textReverse}
          onPress={onDismiss}
        />
      </View>
    </View>
    <View
      style={{
        backgroundColor: theme.colors.background,
      }}
    >
      {!!multiple && !!items && (
        <>
          {Object.entries(groupBy(items, "group")).map(([_label, items], i) => (
            <RadioButton.Group
              key={i}
              onValueChange={newValue => {
                const oldValue = items.find(
                  item => !!selectedValue.find(value => item.value === value)
                )?.value;

                oldValue === newValue
                  ? onValueChange([
                      ...selectedValue.filter(value => value !== oldValue),
                    ])
                  : onValueChange([
                      newValue,
                      ...selectedValue.filter(value => value !== oldValue),
                    ]);
              }}
              value={
                items.find(
                  item => !!selectedValue.find(value => item.value === value)
                )?.value
              }
            >
              {items.map(item => (
                <React.Fragment key={item.value}>
                  <RadioButton.Item
                    color={theme.colors.primary}
                    label={item.label}
                    value={item.value}
                  />
                  <Divider />
                </React.Fragment>
              ))}
            </RadioButton.Group>
          ))}
          <ScreenBottom style={{ margin: theme.spacing.xl }}>
            <Button
              mode="contained"
              style={styles.fullWidth}
              onPress={onDismiss}
            >
              Apply
            </Button>
            <Button
              mode="text"
              style={styles.fullWidth}
              onPress={() => onValueChange([])}
            >
              Reset
            </Button>
          </ScreenBottom>
        </>
      )}
      {!multiple && !!items && (
        <Wrapper height={limitHeight}>
          <RadioButton.Group
            onValueChange={onValueChange}
            value={selectedValue}
          >
            {items.map(item => (
              <React.Fragment key={item.value}>
                <RadioButton.Item
                  color={theme.colors.primary}
                  label={item.label}
                  value={item.value}
                />
                <Divider />
              </React.Fragment>
            ))}
          </RadioButton.Group>
        </Wrapper>
      )}
    </View>
  </BottomSheet>
);

const Wrapper = ({ height, children }) =>
  !!height ? (
    <ScrollView
      contentContainerStyle={{ paddingBottom: 180 }}
      style={{ height }}
    >
      {children}
    </ScrollView>
  ) : (
    children
  );
