import { useEffect, useContext } from "react";
import TicketView from "shared/api/TicketView";
import { useMutation } from "@apollo/client";
import { CurrentUserContext } from "Contexts/CurrentUser";

export default ({ ticketId, skip }) => {
  const currentUser = useContext(CurrentUserContext);
  const [
    createTicketView,
    { error, data: { createTicketView: newTicketView } = {} },
  ] = useMutation(TicketView.mutations.create, {
    variables: {
      input: {
        ticketId,
        ticketViewUserId: currentUser?.id ?? undefined,
      },
    },
  });

  !!error && console.error(error);

  useEffect(() => {
    !!ticketId && !skip && createTicketView();
  }, [ticketId, createTicketView, skip]);

  return newTicketView;
};
