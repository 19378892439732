import Confirmation from "Components/Confirmation";
import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { formatPennies } from "shared/Util/money";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ listing, ticket, onClosePress, theme, styles }) => (
  <Confirmation
    title={"Purchase Successful!"}
    type="award"
    primaryButtonLabel={"Close"}
    onPrimaryButtonPress={onClosePress}
    headline={ticket.subject}
    subheading={ticket.eventDescription}
    More={
      <View style={styles.rowCenter}>
        <View style={[styles.row, styles.textWrapper]}>
          <Text style={styles.textBold}>Odds: </Text>
          <Text>{listing.newOdds}</Text>
        </View>
        <View style={[styles.row, styles.textWrapper]}>
          <Text style={styles.textBold}>Price: </Text>
          <Text>${formatPennies(listing.salesPrice)}</Text>
        </View>
      </View>
    }
    Footer
  />
);

// (
//     <View style={{flex: 1}}>
//       <View style={{flex: 1, justifyContent: "flex-end", marginBottom: theme.spacing.xl}}>
//         <Title style={{textAlign: "center", color: theme.colors.primary, fontWeight: "bold"}}>Purchase Successful!</Title>
//       </View>

//       <View style={{justifyContent: "center", flex: 3}}>
//         <View style={{position: "absolute", zIndex: 1, width: "100%", height: "100%"}}>
//           <Image style={{maxWidth: "100%", height: "100%", resizeMode: "contain"}} source={wreath} />
//         </View>
//         <View style={{position: "relative", alignItems: "center", zIndex: 2, marginBottom: theme.spacing.xl * 2}}>
//           <Headline style={{textAlign: "center", textTransform: "uppercase"}}>{ticket.subject}</Headline>
//           <Subheading style={{"textAlign": "center"}}>{ticket.eventDescription}</Subheading>
//           <View style={{flexDirection: "row", justifyContent: "center"}}>
//             <View style={{flexDirection: "row", marginRight: theme.spacing.sm}}>
//               <Text style={{fontWeight: "bold"}}>Odds: </Text>
//               <Text>{listing.newOdds}</Text>
//             </View>
//             <View style={{flexDirection: "row", marginLeft: theme.spacing.sm}}>
//               <Text style={{fontWeight: "bold"}}>Price: </Text>
//               <Text>${formatPennies(listing.salesPrice)}</Text>
//             </View>
//           </View>
//         </View>
//       </View>

//       <View style={{flex: 1, justifyContent: "flex-end", marginBottom: theme.spacing.xl}}>
//         <Button
//           mode="contained"
//           onPress={onClosePress}
//           style={{width: "100%"}}
//         >
//           Close
//         </Button>
//       </View>
//     </View>
//   )
