import ScreenStyles from "Components/Screen/ScreenStyles";
import useStyles from "Hooks/useStyles";
import { View } from "react-native";

const AuthWrapperView = ({ children }) => {
  const { theme } = useStyles({ Styles: ScreenStyles });
  return (
    <View style={{ flex: 1, alignItems: "center" }} nativeID="big-wrapper">
      <View
        style={{
          width: "100%",
          maxWidth: 601,
          flex: 1,
          backgroundColor: theme.colors.surface,
        }}
      >
        {children}
      </View>
    </View>
  );
};

export default AuthWrapperView;
