import { IconButton, Text } from "react-native-paper";
import { View } from "react-native";
import UserAvatar from "Components/User/Avatar";
import Camera from "./Camera";
import Picker from "./Picker";

export default ({
  wantsUpload,
  setWantsUpload,
  theme,
  onImageChange,
  currentUser,
  styles,
}) => (
  <View style={styles.wrapper}>
    {!wantsUpload && (
      <>
        <IconButton
          onPress={() => setWantsUpload(true)}
          size={124}
          icon="camera"
          style={styles.iconButton}
          color={theme.colors.textReverse}
        />
        <Text style={styles.callToAction}>
          {!!currentUser?.profilePicUri
            ? "Change Profile Photo"
            : "Add Profile Photo"}
        </Text>
        <UserAvatar size={100} user={currentUser} theme={theme} />
      </>
    )}
    {!!wantsUpload && (
      <View style={styles.optionsWrapper}>
        <Camera styles={styles} theme={theme} onImageChange={onImageChange} />
        <Picker styles={styles} theme={theme} onImageChange={onImageChange} />
      </View>
    )}
  </View>
);
