import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import Option from "shared/api/Option";

const OptionsContext = React.createContext();

const OptionsProvider = ({ children }) => {
  const {
    fetchMore,
    error,
    data: { listOptions: { nextToken, items } = {} } = {},
  } = useQuery(Option.queries.list, {
    variables: {
      limit: 100,
      filter: {
        active: {
          eq: true,
        },
      },
    },
  });

  useEffect(() => {
    !!nextToken &&
      !!fetchMore &&
      fetchMore({ variables: { nextToken } }).catch(() => null);
  }, [nextToken, fetchMore]);

  !!error && console.error(error);

  return (
    <OptionsContext.Provider value={items}>{children}</OptionsContext.Provider>
  );
};

export { OptionsContext, OptionsProvider };
