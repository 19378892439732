import * as React from "react";
import { StatusBar } from "expo-status-bar";
import { useIsFocused } from "@react-navigation/native";
import { useTheme } from "react-native-paper";

export default ({ style, ...rest }) => {
  const isFocused = useIsFocused();
  const theme = useTheme();

  // return <StatusBar style="light" />
  return !!isFocused ? (
    <StatusBar style={style ?? (!!theme.dark ? "light" : "dark")} {...rest} />
  ) : null;
};
