import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import MyBids from "Screens/MyBids";
import BidLearn from "Screens/BidLearn";
import ListingModalStack from "./ListingModalStack";
import { useTheme } from "react-native-paper";
import { getPlainOptions, headerNavOptions, modalOptions } from "./index";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  const theme = useTheme();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator screenOptions={headerNavOptions}>
      <Stack.Screen name="MyBids" component={MyBids} />
      <Stack.Screen
        name="BidLearn"
        component={BidLearn}
        options={({ navigation }) => getPlainOptions({ navigation, theme })}
      />
      <Stack.Screen
        name="ListingModalStack"
        component={ListingModalStack}
        options={{
          ...modalOptions,
          cardStyle: {
            backgroundColor: "transparent",
            // marginTop: 40,
          },
        }}
      />
    </Stack.Navigator>
  );
};
