import { useContext, useCallback, useState, useEffect } from "react";
import { Button } from "react-native-paper";

import ScreenBottom from "Components/Screen/Bottom";
import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import ResponsiveImage from "Components/ResponsiveImage";
import {
  View,
  useWindowDimensions,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import Camera from "Components/PhotoUpload/Camera";
import Picker from "Components/PhotoUpload/Picker";
import { ScrollView } from "react-native-gesture-handler";
import useStyles from "Hooks/useStyles";
import PhotoUploadStyles from "Components/PhotoUpload/PhotoUploadStyles";
import { useFocusEffect } from "@react-navigation/core";
import { CurrentUserContext } from "Contexts/CurrentUser";

export default ({ navigation }) => {
  const { styles, theme } = useStyles({ Styles: PhotoUploadStyles });
  const { setTicketValues, ticketValues } = useContext(TicketContext);
  const [image, setImage] = useState(ticketValues.localPhotoUri);
  const { height, width } = useWindowDimensions();
  const maxWidth = Math.min(width, Platform.OS === "web" ? 601 : 50000);

  const clearImage = useCallback(
    () => [
      setImage(null),
      // setImageData(null)
    ],
    []
  );

  const handleImageChange = useCallback(
    image => [
      setImage(image.uri),
      // setImageData(image.base64)
    ],
    []
  );

  const onSubmit = useCallback(
    ({ localPhotoUri }) =>
      () =>
        [
          setTicketValues(ticketValues => ({
            ...ticketValues,
            localPhotoUri,
          })),
          navigation.push("TicketNewStep3"),
        ],
    [navigation, setTicketValues]
  );

  const currentUser = useContext(CurrentUserContext);

  const canAccess = currentUser?.rating >= 3 || !ticketValues.isMobile;

  useFocusEffect(() => {
    if (!canAccess) {
      navigation.replace("TicketNewMobileConfirmStep1");
    }
  }, [canAccess]);

  useEffect(() => {
    // if (!!image && image !== ticketValues.localPhotoUri) {
    setImage(ticketValues.localPhotoUri);
    // }
  }, [ticketValues.localPhotoUri]);

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      hasKeyboard={false}
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={1}
        title="Ticket Upload"
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />

      {!!image && (
        <ScrollView>
          <TouchableWithoutFeedback onPress={clearImage}>
            <View
              style={{
                alignItems: "center",
                height: height - 350 + theme.spacing.sm * 2,
              }}
            >
              <View
                style={{
                  position: "absolute",
                  borderWidth: 1,
                  borderColor: theme.colors.primary,
                  borderStyle: "solid",
                  padding: theme.spacing.sm,
                }}
              >
                <ResponsiveImage
                  source={{ uri: image }}
                  height={height - 350}
                  maxWidth={
                    maxWidth - theme.spacing.xl * 2 - 2 - theme.spacing.sm * 2
                  }
                />
              </View>
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
      )}

      {!!image && (
        <ScreenBottom
          style={{
            marginTop: theme.spacing.xl,
            marginHorizontal: theme.spacing.xl,
            flex: null,
          }}
          theme={theme}
        >
          {!!image && (
            <Button
              mode="outlined"
              onPress={clearImage}
              style={{ width: "100%", marginBottom: theme.spacing.xl }}
            >
              Change Photo
            </Button>
          )}
          <Button
            mode="contained"
            onPress={onSubmit({
              localPhotoUri: image,
            })}
            style={{ width: "100%" }}
          >
            Continue
          </Button>
        </ScreenBottom>
      )}

      {!image && (
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
            marginHorizontal: theme.spacing.xl,
          }}
        >
          <Camera
            theme={theme}
            styles={styles}
            onImageChange={handleImageChange}
          />
          <Picker
            theme={theme}
            styles={styles}
            onImageChange={handleImageChange}
          />
        </View>
      )}
    </Screen>
  );
};
