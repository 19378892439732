import React, { useCallback } from "react";
import { Divider, List, useTheme } from "react-native-paper";
import Screen from "Components/Screen";

export default ({ navigation }) => {
  const navigateTo = useCallback(
    screen => () => navigation.push(screen),
    [navigation]
  );

  const theme = useTheme();

  // useLayoutEffect(() => {
  //   navigation.setOptions({
  //     headerLeft: () => <Text>HI</Text>
  //   })
  // }, [navigation]);

  return (
    <Screen
      gatedTo="member"
      title="My Wallet"
      fullHeight
      fullWidth
      backgroundColor={theme.colors.surface}
    >
      {[
        ["Deposit Funds", "DepositStack"],
        ["Withdraw Funds", "WithdrawStack"],
      ].map(([label, screen]) => (
        <React.Fragment key={label}>
          <List.Item
            title={label}
            right={props => <List.Icon {...props} icon="chevron-right" />}
            onPress={navigateTo(screen)}
          />
          <Divider />
        </React.Fragment>
      ))}
    </Screen>
  );
};
