import { Platform } from "react-native";

export default theme => ({
  bottomSheet: {
    paddingVertical: 0,
    borderTopLeftRadius: theme.roundness,
    borderTopRightRadius: theme.roundness,
    height: Platform.OS === "web" ? "auto" : "height",
    justifyContent: "flex-start",
    backgroundColor: theme.colors.background,
  },
  wrapper: {
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
    borderTopRightRadius: theme.roundness,
    borderTopLeftRadius: theme.roundness,
    backgroundColor: theme.colors.secondary,
    paddingHorizontal: theme.spacing.md,
    paddingVertical: theme.spacing.sm,
  },
});
