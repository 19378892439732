import React from "react";
import { List, Switch } from "react-native-paper";

export default ({ type, isEnabled, theme, onChange }) => (
  <List.Item
    style={{ marginLeft: -1 * theme.spacing.lg }}
    title={`Enable ${type} ID`}
    right={() => (
      <Switch
        value={!!isEnabled}
        color={theme.colors.primary}
        onValueChange={onChange}
      />
    )}
  />
);
