import { useNavigation } from "@react-navigation/core";
import { useCallback, useMemo } from "react";
// import { useTheme } from 'react-native-paper';
import Activity from "shared/api/Activity";
import Styles from "./ActivityLatestStyles";
import View from "./ActivityLatestView";
import { useQuery } from "@apollo/client";
import useStyles from "Hooks/useStyles";

export default ({}) => {
  // const theme = useTheme();
  const { styles, theme } = useStyles({ Styles });
  const navigation = useNavigation();

  const handleIconPress = useCallback(
    () =>
      // navigation.push("Main", {screen: "HomeStack", params: { screen: "Activity" }})
      navigation.push("Activity"),
    [navigation]
  );

  const options = useMemo(
    () => ({
      variables: {
        sortDirection: "DESC",
        globalEligible: "YES",
        limit: 1,
      },
    }),
    []
  );

  const {
    error,
    data: {
      listActivitiesByGlobalEligible: { items: activities = [] } = {},
    } = {},
  } = useQuery(Activity.queries.listByGlobalEligible, options);

  const activity = useMemo(() => activities[0], [activities]);

  !!error && console.error(error);

  return !activity ? null : (
    <View
      theme={theme}
      styles={styles}
      onIconPress={handleIconPress}
      activity={activity}
    />
  );
};
