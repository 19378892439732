import environment from "environment";

const handleScroll = (onScrollUp, onScrollDown) => {
  let init = 0;
  let block = false;

  return event => {
    if (!block && init !== event.nativeEvent.contentOffset.y && init > 50) {
      if (init > event.nativeEvent.contentOffset.y) {
        onScrollUp();
      } else {
        onScrollDown();
      }
      block = true;
      setTimeout(() => {
        block = false;
      }, 1000);
    }
    init = event.nativeEvent.contentOffset.y;
  };
};

const injectThemeStyles = dark => {
  const style = document.createElement("style");
  style.textContent = `::-webkit-calendar-picker-indicator { filter: ${
    !!dark ? "invert(1)" : "none"
  }; }
  #big-wrapper { background: top center repeat-y url("/background-${
    !!dark ? "dark" : "dark"
  }.jpg") #${!!dark ? "000" : "ededed"}; }
  `;
  return document.head.append(style);
};

const calculateValueRating = ({ marketValuePrice, askingPrice }) => {
  const diff = ((askingPrice - marketValuePrice) / marketValuePrice) * 100.0;

  if (diff > 90) {
    return 1;
  } else if (diff > 60) {
    return 2;
  } else if (diff > 25) {
    return 3;
  } else if (diff >= 5) {
    return 4;
  } else {
    return 5;
  }
};

const injectBluesnapJs = ({
  bluesnapVersion,
  clientEncryptionKey,
  fraudSessionId,
}) => {
  // Inject style
  const script = document.createElement("script");
  script.textContent = `
  window.bluesnapinitialize = (function () {
      var queue = [];
      var loaded = false;
      // var bluesnap = null;
      var formId = "bluesnap";
      var BS_CREDIT_CARD_KEY = "encryptedCreditCard";
      var BS_CVV_KEY = "encryptedCvv";

      function createHiddenInput(bluesnapName, value) {
          var $input = document.createElement("input");
          $input.setAttribute("type", "hidden");
          $input.setAttribute("data-bluesnap", bluesnapName);
          $input.value = value;

          return $input;
      }

      function createForm(params) {
          var $form = document.createElement("form");
          var $creditCardNumber = createHiddenInput(BS_CREDIT_CARD_KEY, params.creditCardNumber);
          var $cvvNumber = createHiddenInput(BS_CVV_KEY, params.cvvNumber);

          $form.id = formId;

          $form.appendChild($creditCardNumber);
          $form.appendChild($cvvNumber);

          document.body.appendChild($form);
      }

      function encrypt(action) {
          createForm(action.data);

          window.bluesnap.encrypt(formId);

          var ccLast4Digits = document.querySelector("input[name='ccLast4Digits']").value;
          var encryptedCreditCard = document.querySelector("input[name='" + BS_CREDIT_CARD_KEY + "']").value;
          var encryptedCvv = document.querySelector("input[name='" + BS_CVV_KEY + "']").value;

          var $form = document.querySelector("#" + formId);
          $form.parentNode.removeChild($form);

          return Promise.resolve({
            ccLast4Digits: ccLast4Digits,
            encryptedCreditCard: encryptedCreditCard,
            encryptedCvv: encryptedCvv
          })

          // window.postBluesnapMessage(JSON.stringify({
          //     _id: action._id,
          //     type: "encrypt",
          //     data: {
          //         ccLast4Digits: ccLast4Digits,
          //         encryptedCreditCard: encryptedCreditCard,
          //         encryptedCvv: encryptedCvv
          //     }
          // }));
      }

      window.bluesnapencrypt = encrypt

      function createEmbedFraudSessionUrl(fraudSessionId, type /* htm || gif */) {
          return "https://www.bluesnap.com/servlet/logo." + type + "?s=" + fraudSessionId;
      }

      function setFraudSession(fraudSessionId) {
          var img = document.createElement("img");

          img.src = createEmbedFraudSessionUrl(fraudSessionId, "gif");
          img.style.position = 'absolute';
          img.style.zIndex = 1;
          img.style.bottom = 0;
          img.style.height = 0;
          img.style.width = 0;
          document.body.appendChild(img);
      }

      function handleAction(action) {

          switch (action.type) {
              case "encrypt":
                  encrypt(action);
                  break;
          }
      }

      function onMessage(e) {
          try {
              var action = JSON.parse(e.data);
              if (!action.type) {
                  return;
              }

              if (!loaded) {
                  queue.push(action);
                  return;
              }

              handleAction(action);

          } catch (e) {
          }
      }

      // document.addEventListener("message", onMessage, "*");
      // window.addEventListener("message", onMessage, "*");

      return function (clientEncryptionKey, version, fraudSessionId) {
          window[fraudSessionId]=true;
          var script = document.createElement("script");
          script.src = "https://gateway.bluesnap.com/js/cse/v" + version + "/bluesnap.js"
          script.onload = function () {
              window.bluesnap = new BlueSnap('${environment.bluesnap_encryption_key}');
              var queueLength = queue.length;
              if (queueLength > 0) {
                  for (var i = 0; i < queueLength; i++) {
                      handleAction(queue[i]);
                  }

                  queue.length = 0;
              }
              loaded = true;

              if (fraudSessionId) {
                  setFraudSession(fraudSessionId);
              }
          };
          document.head.appendChild(script);
      }
  })();

  !window.bluesnap && !window["${fraudSessionId}"] && window.bluesnapinitialize("${clientEncryptionKey}", "${bluesnapVersion}", "${fraudSessionId}");
  true;`;

  return document.head.append(script);
};

const injectBluesnapJsForWebView = ({
  bluesnapVersion,
  clientEncryptionKey,
  fraudSessionId,
}) =>
  `
  window.bluesnapinitialize = (function () {
      var queue = [];
      var loaded = false;
      // var bluesnap = null;
      var formId = "bluesnap";
      var BS_CREDIT_CARD_KEY = "encryptedCreditCard";
      var BS_CVV_KEY = "encryptedCvv";

      function createHiddenInput(bluesnapName, value) {
          var $input = document.createElement("input");
          $input.setAttribute("type", "hidden");
          $input.setAttribute("data-bluesnap", bluesnapName);
          $input.value = value;

          return $input;
      }

      function createForm(params) {
          var $form = document.createElement("form");
          var $creditCardNumber = createHiddenInput(BS_CREDIT_CARD_KEY, params.creditCardNumber);
          var $cvvNumber = createHiddenInput(BS_CVV_KEY, params.cvvNumber);

          $form.id = formId;

          $form.appendChild($creditCardNumber);
          $form.appendChild($cvvNumber);

          document.body.appendChild($form);
      }

      function encrypt(action) {
        //   alert("1");
          createForm(action.data);
        //   alert("2");

        //   alert(window.bluesnap)

          try{ window.bluesnap.encrypt(formId); } catch(e) {alert(e)}
        //   alert("3");

          var ccLast4Digits = document.querySelector("input[name='ccLast4Digits']").value;
          var encryptedCreditCard = document.querySelector("input[name='" + BS_CREDIT_CARD_KEY + "']").value;
          var encryptedCvv = document.querySelector("input[name='" + BS_CVV_KEY + "']").value;

        //   alert("4");

          var $form = document.querySelector("#" + formId);
          $form.parentNode.removeChild($form);

        //   alert("5");

          window.ReactNativeWebView.postMessage(JSON.stringify({
            ccLast4Digits: ccLast4Digits,
            encryptedCreditCard: encryptedCreditCard,
            encryptedCvv: encryptedCvv
          }))
      }

      window.bluesnapencrypt = encrypt

      function createEmbedFraudSessionUrl(fraudSessionId, type /* htm || gif */) {
          return "https://www.bluesnap.com/servlet/logo." + type + "?s=" + fraudSessionId;
      }

      function setFraudSession(fraudSessionId) {
          var img = document.createElement("img");

          img.src = createEmbedFraudSessionUrl(fraudSessionId, "gif");
          img.style.position = 'absolute';
          img.style.zIndex = 1;
          img.style.bottom = 0;
          img.style.height = 0;
          img.style.width = 0;
          document.body.appendChild(img);
      }

      function handleAction(action) {

          switch (action.type) {
              case "encrypt":
                  encrypt(action);
                  break;
          }
      }

      

      return function (clientEncryptionKey, version, fraudSessionId) {
          window[fraudSessionId]=true;
          // alert(clientEncryptionKey);
        //   alert("https://gateway.bluesnap.com/js/cse/v" + version + "/bluesnap.js");
          var script = document.createElement("script");
          script.src = "https://gateway.bluesnap.com/js/cse/v" + version + "/bluesnap.js"
          script.onload = function () {
            //   alert("loaded");
              window.bluesnap = new BlueSnap(clientEncryptionKey);
              var queueLength = queue.length;
              if (queueLength > 0) {
                  for (var i = 0; i < queueLength; i++) {
                      handleAction(queue[i]);
                  }

                  queue.length = 0;
              }
              loaded = true;

              if (fraudSessionId) {
                  setFraudSession(fraudSessionId);
              }
          };
          document.head.appendChild(script);
      }
  })();

  !window.bluesnap && !window["${fraudSessionId}"] && window.bluesnapinitialize("${clientEncryptionKey}", "${bluesnapVersion}", "${fraudSessionId}");
  true;`;

const withoutKeys = (
  obj,
  keys //TODO: move to utils
) =>
  Object.keys(obj ?? {}).reduce(
    (object, key) =>
      keys.includes(key)
        ? {
            ...object,
          }
        : {
            ...object,
            [key]: obj[key],
          },
    {}
  );

const removeEmpty = obj =>
  Object.entries(obj)
    .filter(([_, v]) => typeof v !== "undefined" && v !== null && v !== "")
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export {
  withoutKeys,
  injectBluesnapJsForWebView,
  injectBluesnapJs,
  injectThemeStyles,
  calculateValueRating,
  removeEmpty,
  handleScroll,
};
