import BottomSheet from "Components/BottomSheet";
import ListingPurchaseConfirmation from "Components/Listing/PurchaseConfirmation";
import Screen from "Components/Screen";
import TicketDetail from "Components/Ticket/Detail";
import { TabBarContext } from "Contexts/TabBar";
import { useContext, useState, useCallback, useEffect } from "react";
import { ScrollView } from "react-native";
import { useTheme } from "react-native-paper";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation, route, ...rest }) => {
  const theme = useTheme();
  const tabBar = useContext(TabBarContext);
  const [purchasedListingId, setPurchasedListingId] = useState(
    route?.params?.purchasedListingId
  );

  const handleBottomSheetDismiss = useCallback(
    () => setPurchasedListingId(null),
    []
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener("blur", () => {
      tabBar.setVisible(true);
    });

    return unsubscribe;
  }, [navigation, tabBar]);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      tabBar.setVisible(false);
    });

    return unsubscribe;
  }, [navigation, tabBar]);

  return (
    <Screen
      fullHeight
      backgroundColor={theme.colors.surface}
      hasKeyboard={false}
      fullWidth
    >
      <ScrollView
        contentContainerStyle={{ marginHorizontal: theme.spacing.xl }}
        keyboardShouldPersistTaps="handled"
      >
        <TicketDetail listingId={route.params?.id} />
      </ScrollView>
      <BottomSheet
        visible={!!purchasedListingId}
        onDismiss={handleBottomSheetDismiss}
        fullScreen
      >
        {!!purchasedListingId && (
          <ListingPurchaseConfirmation
            listingId={purchasedListingId}
            onClosePress={handleBottomSheetDismiss}
          />
        )}
      </BottomSheet>
    </Screen>
  );
};
