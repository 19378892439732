import { useTheme } from "react-native-paper";
import Screen from "Components/Screen";
import About from "Components/About";

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen
      title="About"
      fullHeight
      hasScroll
      backgroundColor={theme.colors.surface}
    >
      <About />
    </Screen>
  );
};
