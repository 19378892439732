import React from "react";
import { Subheading, TouchableRipple, useTheme } from "react-native-paper";
import Screen from "Components/Screen";
import { View } from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Shirt from "Components/Icons/Shirt";
import Hat from "Components/Icons/Hat";

const ViewButton = ({ text, textColor, image, onPress, theme }) => (
  <View style={{ width: "50%", aspectRatio: "1" }}>
    <TouchableRipple onPress={onPress}>
      <View
        style={{
          paddingVertical: theme.spacing.xl,
          margin: theme.spacing.sm,
          borderColor: theme.colors.primary,
          borderStyle: "solid",
          borderWidth: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {image}
        <Subheading
          style={[
            !!textColor ? { color: textColor } : {},
            { textAlign: "center" },
          ]}
        >
          {text}
        </Subheading>
        {!onPress && (
          <Subheading style={{ textTransform: "uppercase" }}>
            (Coming Soon)
          </Subheading>
        )}
        {!!onPress && (
          <Subheading style={{ textTransform: "uppercase" }}> </Subheading>
        )}
      </View>
    </TouchableRipple>
  </View>
);

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen
      gatedTo="member"
      title="Redeem Points"
      fullHeight={false}
      fullWidth={false}
      backgroundColor={theme.colors.surface}
    >
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ViewButton
          text={"PropSwap Cash"}
          image={
            <FontAwesome name="dollar" size={62} color={theme.colors.primary} />
          }
          theme={theme}
          onPress={() => navigation.push("PointsRedeemCash")}
        />
        <ViewButton
          text={"No Fee"}
          image={
            <MaterialCommunityIcons
              name="cancel"
              size={62}
              color={theme.colors.primary}
            />
          }
          theme={theme}
          onPress={() => navigation.push("PointsRedeemFee")}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ViewButton
          text={"T-Shirt"}
          image={<Shirt stroke={theme.colors.text} />}
          theme={theme}
          onPress={() => navigation.push("PointsRedeemShirt")}
        />
        <ViewButton
          text={"Hat"}
          image={<Hat stroke={theme.colors.text} />}
          theme={theme}
          onPress={() => navigation.push("PointsRedeemHat")}
        />
      </View>
    </Screen>
  );
};
