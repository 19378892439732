import { createStackNavigator } from "@react-navigation/stack";
import { useLayoutEffect } from "react";
import Login from "Screens/Login";
import RegistrationInitial from "Screens/RegistrationInitial";
import RegistrationStack from "./RegistrationStack";
import PasswordResetStack from "./PasswordResetStack";

const Stack = createStackNavigator();

export default ({ navigation, screenOptions }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      screenOptions={{
        headerMode: "none",
      }}
    >
      <Stack.Screen name="Login" component={Login} />
      <Stack.Screen
        name="RegistrationInitial"
        component={RegistrationInitial}
      />
      <Stack.Screen name="RegistrationStack" component={RegistrationStack} />
      <Stack.Screen name="PasswordResetStack" component={PasswordResetStack} />
    </Stack.Navigator>
  );
};
