import { View } from "react-native";
// import Golf from "Components/Icons/Golf"
import { Card, Subheading, Text } from "react-native-paper";
import { activityLabelMap, userStatusLabels } from "shared/Config";
import UserAvatarContainer from "../Avatar";

const TopRow = ({ activity, theme }) => (
  <View
    style={{
      flexDirection: "row",
      // flex: 1,
      marginTop: theme.spacing.md * -1,
      marginBottom: theme.spacing.xs,
      backgroundColor: "transparent",
      marginHorizontal: 0,
      borderTopLeftRadius: theme.roundness,
      borderTopRightRadius: theme.roundness,
    }}
  >
    <View
      style={{
        backgroundColor: theme.colors.labelBackground,
        marginTop: theme.spacing.lg,
        paddingHorizontal: theme.spacing.sm,
        paddingVertical: theme.spacing.xs,
      }}
    >
      <Text
        style={{ textTransform: "capitalize", color: theme.colors.textReverse }}
      >
        {activityLabelMap[activity.type] ?? activity.type}
      </Text>
    </View>
  </View>
);

export default ({ user, activity, theme, styles }) => (
  <Card>
    <Card.Content>
      <TopRow activity={activity} theme={theme} styles={styles} />
      <View style={styles.contentBody}>
        <View style={styles.avatarContainer}>
          <UserAvatarContainer user={user} theme={theme} />
        </View>
        <View style={styles.forceWrap}>
          <Subheading>{user.displayName}</Subheading>

          {activity.type === "USER_SIGN_UP" && (
            <Subheading>Just joined PropSwap!</Subheading>
          )}

          {activity.type === "USER_PROMOTION" && user.status > 1 && (
            <Subheading style={styles.textWrap}>
              Just moved up from{" "}
              {userStatusLabels[(user.status ?? 2) - 2].label} to{" "}
              {userStatusLabels[(user.status ?? 1) - 1].label} status!
            </Subheading>
          )}

          {activity.type === "USER_PROMOTION" && user.status <= 1 && (
            <Subheading style={styles.textWrap}>
              Just reached {userStatusLabels[(user.status ?? 1) - 1].label}{" "}
              status!
            </Subheading>
          )}
        </View>
      </View>
    </Card.Content>
  </Card>
);
