//CORE

import { useMemo } from "react";

const defaultUseFindUser = ({ cognitoUser }) => ({
  user: cognitoUser,
  loading: false,
  error: undefined,
});

const defaultUseNotificationPermissions = currentUser => ({});

export default ({
  cognitoUser,
  useFindUser = defaultUseFindUser,
  useNotificationPermissions = defaultUseNotificationPermissions,
  onUser,
}) => {
  const { loading, error, user } = useFindUser({ cognitoUser });

  !!error && console.error("ERROR", error);

  const currentUser = useMemo(
    () =>
      !cognitoUser
        ? null
        : !!loading && !user
        ? undefined
        : !loading && !user
        ? null
        : !!user && !!cognitoUser
        ? {
            ...user,
            ...cognitoUser,
            groups:
              cognitoUser.signInUserSession?.accessToken?.payload?.[
                "cognito:groups"
              ] || [],
          }
        : null,
    [user, cognitoUser, loading]
  );

  !!currentUser && onUser(currentUser);

  useNotificationPermissions(currentUser);

  return currentUser;
};
