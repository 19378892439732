import { View } from "react-native";
import { TouchableRipple, Title } from "react-native-paper";
import Sell from "Components/Icons/Sell";
import Buy from "Components/Icons/Buy";
import Tickets from "Components/Icons/Tickets";
import {
  FontAwesome5,
  MaterialCommunityIcons,
  AntDesign,
} from "@expo/vector-icons";
import GuestHeaderView from "Components/NavHeader/GuestHeaderView";
import MemberHeaderView from "Components/NavHeader/MemberHeaderView";
import IconWithBadgeCount from "Components/NavHeader/IconWithBadgeCount";

export default ({
  navigation,
  theme,
  currentUser,
  onLoginPress,
  onRegisterPress,
  styles,
  onBalancePress,
  onUserPress,
  onPointsPress,
}) => (
  <View
    nativeID="sidebar-left"
    style={{
      backgroundColor: !!theme.dark
        ? theme.colors.surface
        : theme.colors.background,
      // zIndex: 2000,
      // position: "fixed",
      // // marginLeft: -251,
      // top: 0,
      width: 251,
      paddingHorizontal: 16,
      paddingVertical: 32,
      // height: "100vh",
      borderRightColor: theme.colors.border,
      borderRightStyle: "solid",
      borderRightWidth: 1,
      // minHeight: 1200,
    }}
  >
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: theme.spacing.xl,
        marginBottom: theme.spacing.xl * 2,
      }}
    >
      {!currentUser && (
        <GuestHeaderView
          theme={theme}
          styles={styles}
          onLoginPress={onLoginPress}
          onRegisterPress={onRegisterPress}
          skipIcons
        />
      )}
      {!!currentUser && (
        <MemberHeaderView
          currentUser={currentUser}
          theme={theme}
          styles={styles}
          onBalancePress={onBalancePress}
          onPointsPress={onPointsPress}
          onUserPress={onUserPress}
          skipIcons
        />
      )}
    </View>
    <TouchableRipple onPress={() => navigation.navigate("HomeStack")}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: 16,
        }}
      >
        <View style={{ marginRight: theme.spacing.md }}>
          <AntDesign name="home" color={theme.colors.text} size={32} />
        </View>
        <Title style={{ color: theme.colors.text }}>Home</Title>
      </View>
    </TouchableRipple>
    <TouchableRipple onPress={() => navigation.navigate("MyTickets")}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: 16,
        }}
      >
        <View style={{ marginRight: theme.spacing.md }}>
          <Tickets color={theme.colors.text} size={32} />
        </View>
        <Title style={{ color: theme.colors.text }}>My Tickets</Title>
      </View>
    </TouchableRipple>
    <TouchableRipple onPress={() => navigation.navigate("Buy")}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: 16,
        }}
      >
        <View style={{ marginRight: theme.spacing.md }}>
          <Buy color={theme.colors.text} size={32} />
        </View>
        <Title style={{ color: theme.colors.text }}>Buy</Title>
      </View>
    </TouchableRipple>
    <TouchableRipple onPress={() => navigation.navigate("Sell")}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: 16,
        }}
      >
        <View style={{ marginRight: theme.spacing.md }}>
          <Sell color={theme.colors.text} size={32} />
        </View>
        <Title style={{ color: theme.colors.text }}>Sell</Title>
      </View>
    </TouchableRipple>
    {!!currentUser && (
      <TouchableRipple
        onPress={() =>
          navigation.push("Main", {
            screen: "HomeStack",
            params: { screen: "Notifications" },
          })
        }
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingVertical: 16,
          }}
        >
          <View style={{ marginRight: theme.spacing.md }}>
            <IconWithBadgeCount
              IconSet={FontAwesome5}
              count={
                !!currentUser?.hideNotificationsCount
                  ? 0
                  : currentUser?.notificationsCount ?? 0
              }
              name="bell"
              size={24}
              color={theme.colors.text}
              wrapperStyles={{ marginRight: theme.spacing.md }}
              theme={theme}
              styles={styles}
            />
          </View>
          <Title style={{ color: theme.colors.text }}>Notifications</Title>
        </View>
      </TouchableRipple>
    )}
    {!!currentUser && (
      <TouchableRipple
        onPress={() =>
          navigation.push("Main", {
            screen: "HomeStack",
            params: { screen: "WatchList" },
          })
        }
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingVertical: 16,
          }}
        >
          <View style={{ marginRight: theme.spacing.md }}>
            <IconWithBadgeCount
              IconSet={FontAwesome5}
              count={currentUser?.watchingsCount ?? 0}
              name="bookmark"
              size={24}
              color={theme.colors.text}
              wrapperStyles={{ marginRight: theme.spacing.md }}
              theme={theme}
              styles={styles}
            />
          </View>
          <Title style={{ color: theme.colors.text }}>Watch List</Title>
        </View>
      </TouchableRipple>
    )}
    <TouchableRipple onPress={() => navigation.navigate("Account")}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: 16,
        }}
      >
        <View style={{ marginRight: theme.spacing.md }}>
          <MaterialCommunityIcons
            name="account-outline"
            color={theme.colors.text}
            size={32}
          />
        </View>
        <Title style={{ color: theme.colors.text }}>Account</Title>
      </View>
    </TouchableRipple>
  </View>
);
