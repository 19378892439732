import { Platform, View } from "react-native";

import {
  Button,
  Checkbox,
  Text,
  TextInput,
  HelperText,
} from "react-native-paper";
import { Controller } from "react-hook-form";
import { formatPennies, formatValue, addCommas } from "shared/Util/money";
import environment from "environment";
import openUrl from "Util/openUrl";
import formatOdds from "Util/formatOdds";
import moment from "moment";
import DateTimePicker from "Components/DateTimePicker";

export default ({
  listing,
  ticket,
  defaultAmount,
  onCancelPress,
  hasPendingBid,
  theme,
  styles,
  control,
  doSubmit,
  isValid,
  isSubmitting,
  amountInPennies,
  errors,
  mode,
}) => (
  <View>
    <View style={styles.row}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.f1}>
            <TextInput
              autoFocus
              disabled={!!hasPendingBid || mode === "buy"}
              autoCorrect={false}
              autoCapitalize="none"
              autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
              keyboardType="decimal-pad"
              textContentType="none"
              label=""
              error={!!errors.amount}
              returnKeyLabel="Done"
              returnKeyType="done"
              // onSubmitEditing={doSubmit}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value =>
                onChange(
                  formatValue((value ?? "").replace(/[^0-9.]/gi, "") || 0)
                )
              }
              value={`$${
                value.toString() === "0" ? "" : addCommas(value.toString())
              }`}
              style={{
                backgroundColor: "transparent",
              }}
            />
            {!!errors.amount && (
              <HelperText type="error" visible={!!errors.amount}>
                {errors.amount?.message}
              </HelperText>
            )}
            {!errors.amount && (
              <HelperText type="info" visible={!errors.amount}>
                {amountInPennies === listing.askingPrice
                  ? `Clicking the buy button will deduct $${formatPennies(
                      amountInPennies
                    )} from your wallet.`
                  : `If your bid is approved, $${formatPennies(
                      amountInPennies
                    )} will be deducted from your wallet.`}
              </HelperText>
            )}
          </View>
        )}
        name="amount"
        defaultValue={defaultAmount / 100.0}
      />
      {amountInPennies !== listing.askingPrice && (
        <View style={[styles.f1, styles.bidOddsWrapper]}>
          <Text style={styles.textBold}>
            Bid Odds:{" "}
            <Text style={styles.textNormal}>
              {formatOdds(ticket.collectAmount, amountInPennies)}
            </Text>
          </Text>
        </View>
      )}
    </View>
    {amountInPennies !== listing.askingPrice && (
      <View style={[styles.row, { marginVertical: 20 }]}>
        <Controller
          control={control}
          name="expiresAt"
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <View style={{ flex: 1 }}>
              <View style={{ marginBottom: 8 }}>
                <Text style={styles.textBold}>Expiration: </Text>
              </View>
              <View style={{ flex: 1 }}>
                <DateTimePicker
                  onChange={onChange}
                  value={!value ? "" : moment(value).format("YYYY-MM-DDTHH:mm")}
                />
              </View>
              <HelperText type="error" visible={!!errors?.expiresAt}>
                {errors?.expiresAt?.message}
              </HelperText>
            </View>
          )}
        />
      </View>
    )}
    <View style={styles.buttonsWrapper}>
      <Button mode="outlined" style={styles.button} onPress={onCancelPress}>
        Cancel
      </Button>
      <Button
        mode="contained"
        style={styles.button}
        onPress={doSubmit}
        disabled={!isValid || !!isSubmitting}
        loading={!!isSubmitting}
      >
        {amountInPennies === listing.askingPrice ? "Buy Now" : "Submit Bid"}
      </Button>
    </View>

    <View style={styles.inputWrapper}>
      {!ticket.inPropswapsPossession && (
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <View style={styles.controlInner}>
              <Checkbox.Android
                color={theme.colors.primary}
                status={!!value ? "checked" : "unchecked"}
                onPress={() => onChange(!value)}
              />
              {!ticket.isMobile && (
                <Text
                  style={(styles.pointer, styles.textWrap)}
                  onPress={() => onChange(!value)}
                >
                  I understand PropSwap does not possess this ticket, and in the
                  event the Seller fails to send the ticket to PropSwap within 7
                  calendar days, I will receive a full 100% refund.
                </Text>
              )}
              {!!ticket.isMobile && (
                <Text
                  style={(styles.pointer, styles.textWrap)}
                  onPress={() => onChange(!value)}
                >
                  I understand I am purchasing this mobile ticket from another
                  user (the Seller) on PropSwap. In the event this ticket should
                  win, the Seller has 48 hours to send the full Collect Amount
                  to PropSwap, which will then be added to my Balance.
                </Text>
              )}
            </View>
          )}
          name="understand"
          defaultValue={false}
        />
      )}
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <View style={styles.controlInner}>
            <Checkbox.Android
              color={theme.colors.primary}
              status={!!value ? "checked" : "unchecked"}
              onPress={() => onChange(!value)}
            />
            <Text
              style={(styles.pointer, styles.textWrap)}
              onPress={() => onChange(!value)}
            >
              I have read and agree to the{" "}
              <Text
                onPress={() =>
                  openUrl(
                    `${environment.base_url}/pages/terms-and-conditions.html`
                  )
                }
                style={[styles.textPrimary, styles.pointer]}
              >
                Terms & Conditions
              </Text>
              .
            </Text>
          </View>
        )}
        name="terms"
        defaultValue={false}
      />
    </View>
  </View>
);
