import gql from "graphql-tag";
import environment from "environment";
import Sport from "./Sport";

const Option = {};

Option.fragments = {
  public: gql`
    fragment OptionPublic on Option {
      id
      sportId
      userId
      name
      createdAt
      updatedAt
      active
      bovadoParticipantId
      bovadoParticipantName
      fanDuelSelectionId
      sharpSportsContestantId
      aliases
      viewCount
      sport {
        ...SportPublic
      }
    }
    ${Sport.fragments.public}
  `,
};

Option.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateOptionPublic {
      onUpdateOptionPublic {
        ...OptionPublic
      }
    }
    ${Option.fragments.public}
  `,
  onCreate: gql`
    subscription OnCreateOption {
      onCreateOption {
        ...OptionPublic
      }
    }
    ${Option.fragments.public}
  `,
};

Option.mutations = {
  update: gql`
    mutation UpdateOption($input: UpdateOptionInput!) {
      updateOption(input: $input) {
        ...OptionPublic
      }
    }
    ${Option.fragments.public}
  `,
  create: gql`
    mutation CreateOption($input: CreateOptionInput!) {
      createOption(input: $input) {
        ...OptionPublic
      }
    }
    ${Option.fragments.public}
  `,
};

Option.queries = {
  get: gql`
    query GetOption($id: ID!) {
      getOption(id: $id) {
        ...OptionPublic
      }
    }
    ${Option.fragments.public}
  `,
  list: gql`
    query ListOptions(
      $filter: ModelOptionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...OptionPublic
        }
        nextToken
      }
    }
    ${Option.fragments.public}
  `,
  listBySportId: gql`
    query ListOptionsBySportId(
      $sportId: ID${environment.v2_bang}
      $name: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOptionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionsBySportId(
        sportId: $sportId
        name: $name
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionPublic
        }
      }
    }
    ${Option.fragments.public}
  `,
};

export default Option;
