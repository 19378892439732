// import { initializeApp } from 'firebase/app';
// import { getFirestore, setDoc, doc } from 'firebase/firestore';
// import "react-native-get-random-values";
// import "react-native-reanimated";
// import "react-native-url-polyfill/auto";
// import '@expo/match-media'
import { ApolloProvider } from "@apollo/client/react";
// import {
//   Entypo,
//   EvilIcons,
//   FontAwesome,
//   FontAwesome5,
//   Foundation,
//   Ionicons,
//   MaterialCommunityIcons,
//   MaterialIcons,
//   Octicons,
//   // eslint-disable-next-line prettier/prettier
//   SimpleLineIcons
// } from "@expo/vector-icons";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Assets } from "@react-navigation/elements";
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
  // eslint-disable-next-line prettier/prettier
  useNavigationContainerRef
} from "@react-navigation/native";
import Amplify, { Auth, Hub } from "aws-amplify";
import Main from "Components/Main";
import { CurrentUserProvider } from "Contexts/CurrentUser";
import { StatusBarContext, StatusBarProvider } from "Contexts/StatusBar";
import { ThemeContext, ThemeProvider } from "Contexts/Theme";
import { Analytics, Event, PageHit } from "expo-analytics";
import { useAssets } from "expo-asset";
// import * as FirebaseAnalytics from "expo-firebase-analytics";
import * as Linking from "expo-linking";
import { StatusBar } from "expo-status-bar";
import { disableFragmentWarnings } from "graphql-tag";
import useAppsyncClient from "Hooks/useAppsyncClient";
import useCurrentUser from "Hooks/useCurrentUser";
import useFindUser from "Hooks/useFindUser";
import useNotificationPermissions from "Hooks/useNotificationPermissions";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  // eslint-disable-next-line prettier/prettier
  useState
} from "react";
import {
  // Animated,
  // Linking,
  LogBox,
  Platform,
  // eslint-disable-next-line prettier/prettier
  useColorScheme
} from "react-native";
import {
  DarkTheme as PaperDarkTheme,
  DefaultTheme as PaperLightTheme,
  Portal,
  // eslint-disable-next-line prettier/prettier
  Provider as PaperProvider
} from "react-native-paper";
import { enableScreens } from "react-native-screens";
import { Fallback, HomeStack, linkingConfig, titleFormatter } from "Screens/";
// import * as Sentry from "sentry-expo";
import {
  getExtendedTheme,
  NAVIGATION_PERSISTENCE_KEY,
  // eslint-disable-next-line prettier/prettier
  THEME_PERSISTENCE_KEY
} from "shared/Config/index";
import { injectThemeStyles } from "Util/";

import Constants from "expo-constants";
import { SafeAreaProvider } from "react-native-safe-area-context";
import useUpdater from "useUpdater";
import awsmobile from "./aws-exports";
import environment from "./src/environment";
import { TutorialSwipperProvider } from "Contexts/TutorialSwipper";
import AppsFlyerHandler from "Components/AppsFlyer/AppsFlyerHandler";

// const firebaseConfig = {
//   apiKey: "AIzaSyAhhdJTghORomAS1yIzWDTiKS_Xm_iXnfI",
//   authDomain: "propswap-app-84694.firebaseapp.com",
//   projectId: "propswap-app-84694",
//   storageBucket: "propswap-app-84694.appspot.com",
//   messagingSenderId: "623806063598",
//   appId: "1:623806063598:web:898176c68811ba400e64ff",
//   measurementId: "G-9JNDLSLC4S"
// }

// const fonts = [
//   MaterialCommunityIcons.font,
//   FontAwesome.font,
//   FontAwesome5.font,
//   Foundation.font,
//   Ionicons.font,
//   SimpleLineIcons.font,
//   Octicons.font,
//   Entypo.font,
//   EvilIcons.font,
//   MaterialIcons.font,
// ];

const isRunningInExpoGo = Constants.appOwnership === "expo";
const referringDomain = Platform.OS === "web" && document.referrer;
const urlParams =
  Platform.OS === "web" ? new URLSearchParams(window.location.search) : null;
const analytics = new Analytics(
  environment.ga,
  {
    dr: referringDomain ?? undefined,
    cid: urlParams?.get?.("gclid"),
    ck: urlParams?.get?.("utm_keyword"),
    cm: urlParams?.get?.("utm_medium"),
    cs: urlParams?.get?.("utm_source"),
    cn: urlParams?.get?.("utm_campaign"),
    an: Constants.expoConfig?.name,
    aid: Constants.expoConfig?.slug,
    av: Constants.expoConfig?.version,
  },
  { debug: false }
);

const injectWebCss = f => {
  if (Platform.OS !== "web") return;

  const style = document.createElement("style");
  style.textContent = `textarea, select, input, button { outline: none !important; }`;
  return document.head.append(style);
};

// Sentry.init({
//   dsn: environment.sentry_url,
//   enableInExpoDevelopment: false,
//   debug: false,
//   setCommits: true,
//   deployEnv: environment.stage,
// });

disableFragmentWarnings();
// TODO: https://github.com/apollographql/graphql-tag#warnings (move to webpack?)

Platform.OS !== "web" &&
  LogBox.ignoreLogs([
    "axios-http-handler",
    "AWSPinpointProvider",
    "Require cycle:",
    "DatePickerIOS has been merged with DatePickerAndroid and will be removed in a future release.",
    "StatusBarIOS has been merged with StatusBar and will be removed in a future release.",
    "DatePickerAndroid has been merged with DatePickerIOS and will be removed in a future release.",
    "Firebase is not configured",
  ]);

Amplify.configure({
  ...awsmobile,
  // Auth: {
  //   storage: MemoryStorageNew,
  // },
});

// Platform.OS !== "android" &&
//   SplashScreen.preventAutoHideAsync().catch(() => {
//     /* reloading the app might trigger some race conditions, ignore them */
//   });

enableScreens();
injectWebCss();
// initializeApp(firebaseConfig);

// const BranchNav = () => {
//   useEffect(() => {
//     Constants.appOwnership === "standalone" &&
//       import("expo-branch").then(({ default: Branch }) =>
//         Branch.subscribe(
//           bundle =>
//             !bundle?.error &&
//             !!bundle?.params?.params &&
//             setTimeout(
//               () =>
//                 Linking.openURL(
//                   Linking.makeUrl(
//                     `${JSON.parse(bundle?.params?.params).uri}`,
//                     {}
//                   )
//                 ),
//               1000
//             )
//         )
//       );
//   }, []);
//   return null;
// };

// const MySplashScreen = ({ children, image }) => {
//   // const animation = useMemo(() => new Animated.Value(1), []);
//   const [isAppReady, setAppReady] = useState(true);
//   // const [isSplashAnimationComplete, setAnimationComplete] = useState(false);

//   // useEffect(() => {
//   //   isAppReady &&
//   //     Animated.timing(animation, {
//   //       toValue: 0,
//   //       duration: 200,
//   //       useNativeDriver: true,
//   //     }).start(() => setAnimationComplete(true));
//   // }, [animation, isAppReady]);

//   // const onImageLoaded = React.useMemo(
//   //   () => () =>
//   //     Promise.all([
//   //       ...fonts.map(font => Font.loadAsync(font)),
//   //       Asset.loadAsync([...assets, ...Assets]),
//   //     ])
//   //       .then(resp => SplashScreen.hideAsync())
//   //       .catch(console.error)
//   //       .finally(() => setAppReady(true)),
//   //   []
//   // );

//   return (
//     <View style={{ flex: 1 }}>
//       {isAppReady && children}
//       {/* {false && (
//         <Animated.View
//           pointerEvents="none"
//           style={[
//             StyleSheet.absoluteFill,
//             {
//               backgroundColor: Constants.manifest.splash.backgroundColor,
//               opacity: animation,
//             },
//           ]}
//         >
//           <Animated.Image
//             style={{
//               width: "100%",
//               height: "100%",
//               resizeMode: Constants.manifest.splash.resizeMode || "contain",
//               transform: [
//                 {
//                   scale: animation,
//                 },
//               ],
//             }}
//             source={image}
//             onLoadEnd={onImageLoaded}
//             fadeDuration={0}
//           />
//         </Animated.View>
//       )} */}
//     </View>
//   );
// };

// const AppLoader = ({ children, image }) => {
//   // const [isSplashReady, setSplashReady] = React.useState(false);

//   // // alert(isSplashReady)

//   // const startAsync = useCallback(
//   //   () => Asset.fromModule(logoFull).downloadAsync(),
//   //   []
//   // );

//   // const onFinish = useCallback(() => setSplashReady(true), []);

//   return <MySplashScreen image={image}>{children}</MySplashScreen>;

//   // return !isSplashReady ? (
//   //   <AppLoading
//   //     // Instruct SplashScreen not to hide yet, we want to do this manually
//   //     autoHideSplash={false}
//   //     startAsync={startAsync}
//   //     onError={console.error}
//   //     onFinish={onFinish}
//   //   />
//   // ) : (
//   //   <MySplashScreen image={image}>{children}</MySplashScreen>
//   // );
// };

const App = () => (
  // Platform.OS !== "android" ? (
  //   <AppLoader image={{ uri: Constants.manifest.splash.image }}>
  //     <SafeAreaProvider>
  //       <ThemeProvider>
  //         <StatusBarProvider>
  //           <ThemeWrapper />
  //         </StatusBarProvider>
  //       </ThemeProvider>
  //     </SafeAreaProvider>
  //   </AppLoader>
  // ) : (
  <SafeAreaProvider>
    <ThemeProvider>
      <StatusBarProvider>
        <ThemeWrapper />
      </StatusBarProvider>
    </ThemeProvider>
  </SafeAreaProvider>
);
// );

const CognitoWrapper = ({
  cognitoUser,
  children,
  useFindUser,
  useNotificationPermissions,
}) => {
  const handleUser = useCallback(
    user =>
      analytics.addCustomDimension(
        1,
        user?.signInUserSession?.idToken?.payload?.realUserId
      ),
    []
  );

  const currentUser = useCurrentUser({
    cognitoUser,
    useNotificationPermissions,
    useFindUser,
    onUser: handleUser,
  });
  // const gavePermission = useNotificationPermissions(currentUser);

  return (
    <CurrentUserProvider currentUser={currentUser}>
      {typeof currentUser === "undefined" ? null : children}
    </CurrentUserProvider>
  );
};

const ThemeWrapper = () => {
  useUpdater();
  const colorScheme = useColorScheme();
  const [statusBarStyle] = useContext(StatusBarContext);
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef();
  const [cognitoUser, setCognitoUser] = useState(undefined);
  const client = useAppsyncClient({
    isLoggedIn: cognitoUser,
    appSyncConfig: awsmobile,
  });
  const [assets] = useAssets([
    //TODO. Should pass this in
    ...Assets,
    require("assets/logo-header-light.png"),
    require("assets/propSwapLogoFull.png"),
  ]);
  const [isReady, setIsReady] = useState(Platform.OS === "web");
  const [initialState, setInitialState] = useState();
  const { theme, setTheme } = useContext(ThemeContext);

  const paperTheme = useMemo(() => {
    const t = theme.dark ? PaperDarkTheme : PaperLightTheme;

    // console.log("theme", {
    //   ...theme,
    //   ...t,
    //   ...getExtendedTheme(t, theme)
    // })

    return {
      ...theme,
      ...t,
      ...getExtendedTheme(t, theme),
    };
  }, [theme]);

  const loading = !client || !assets || !isReady;

  const onAuthEvent = useCallback(capsule => {
    switch (capsule.payload.event) {
      case "signOut":
        setCognitoUser(null);
        analytics
          .event(new Event("Authentication", "signOut"))
          .catch(console.error);
        break;
      case "signIn_failure":
        analytics
          .event(new Event("Authentication", "signIn_failure"))
          .catch(console.error);
        break;
      case "signUp_failure":
        analytics
          .event(new Event("Authentication", "signUp_failure"))
          .catch(console.error);
        break;
      case "signIn":
        setCognitoUser(capsule.payload.data);
        analytics
          .event(new Event("Authentication", "signIn"))
          .catch(console.error);
        break;
      case "signUp":
        analytics
          .event(new Event("Authentication", "signUp"))
          .catch(console.error);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    Hub.listen("auth", onAuthEvent);

    // return () =>
    //   Hub.remove(('auth', onAuthEvent))
  }, [onAuthEvent]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(setCognitoUser)
      .catch(err => console.error(err) || setCognitoUser(null));
  }, []);

  useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();

        if (Platform.OS !== "web" || initialUrl === null) {
          const savedState = await AsyncStorage?.getItem(
            NAVIGATION_PERSISTENCE_KEY
          );

          const state = savedState ? JSON.parse(savedState) : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        try {
          const themeName = await AsyncStorage?.getItem(THEME_PERSISTENCE_KEY);

          const themeToUseName =
            themeName === "dark"
              ? "dark"
              : themeName === "light"
              ? "light"
              : colorScheme;

          Platform.OS === "web" && injectThemeStyles(themeToUseName === "dark");

          setTheme(themeToUseName === "dark" ? DarkTheme : DefaultTheme);
        } catch (e) {
          console.error(e);
        }

        setIsReady(true);
      }
    };
    restoreState();
  }, [colorScheme, setTheme]);

  useEffect(() => {
    (async () => {
      if (isRunningInExpoGo || Platform.OS !== "ios") return;
      const { requestTrackingPermissionsAsync } = await import(
        "expo-tracking-transparency"
      );
      const { status } = await requestTrackingPermissionsAsync();
      if (status === "granted") {
        console.log("Yay! I have user permission to track data");
      }
    })();
  }, []);

  console.log("APP ThemeWrapper");

  return loading ? null : (
    <ApolloProvider client={client}>
      <PaperProvider theme={paperTheme}>
        <BottomSheetModalProvider>
          <CognitoWrapper
            cognitoUser={cognitoUser}
            useFindUser={useFindUser}
            useNotificationPermissions={useNotificationPermissions}
          >
            <StatusBar
              style={statusBarStyle ?? (theme.dark ? "light" : "dark")}
            />
            <Main>
              <Portal.Host>
                <NavigationContainer
                  ref={navigationRef}
                  initialState={initialState}
                  onStateChange={async state => {
                    await AsyncStorage?.setItem(
                      NAVIGATION_PERSISTENCE_KEY,
                      JSON.stringify(state)
                    );

                    const previousRouteName = routeNameRef.current;
                    const currentRouteName =
                      navigationRef.getCurrentRoute()?.name;

                    if (previousRouteName !== currentRouteName) {
                      // FirebaseAnalytics.logEvent(
                      //   "screen_view",
                      //   currentRouteName
                      //   // eslint-disable-next-line node/handle-callback-err
                      // ).catch(() => null);

                      await analytics
                        .hit(new PageHit(currentRouteName))
                        .catch(console.error);
                    }

                    // Save the current route name for later comparison
                    routeNameRef.current = currentRouteName;
                  }}
                  theme={paperTheme}
                  linking={linkingConfig}
                  fallback={Fallback}
                  documentTitle={{
                    formatter: titleFormatter,
                  }}
                  onReady={async () => {
                    routeNameRef.current =
                      navigationRef.getCurrentRoute()?.name;
                    await analytics
                      .hit(new PageHit(routeNameRef.current))
                      .catch(console.error);
                  }}
                >
                  {/* <BranchNav /> */}
                  <TutorialSwipperProvider>
                    {Platform.OS !== "web" && !isRunningInExpoGo && (
                      <AppsFlyerHandler />
                    )}

                    <HomeStack theme={theme} setTheme={setTheme} />
                  </TutorialSwipperProvider>
                </NavigationContainer>
              </Portal.Host>
            </Main>
          </CognitoWrapper>
        </BottomSheetModalProvider>
      </PaperProvider>
    </ApolloProvider>
  );
};

export default App;
