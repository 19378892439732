import { useNavigation } from "@react-navigation/core";
import NavHeaderStyles from "Components/NavHeader/NavHeaderStyles";
import { CurrentUserContext } from "Contexts/CurrentUser";
import useStyles from "Hooks/useStyles";
import { useCallback, useContext } from "react";

import VerticalNavBarView from "./VerticalNavBarView";

export default () => {
  const navigation = useNavigation();
  const { styles, theme } = useStyles({ Styles: NavHeaderStyles });
  const currentUser = useContext(CurrentUserContext);

  const handleOnLoginPress = useCallback(
    () => navigation.push("AuthStack", { screen: "Login" }),
    [navigation]
  );

  const handleOnRegisterPress = useCallback(
    () => navigation.push("AuthStack", { screen: "RegistrationInitial" }),
    [navigation]
  );

  const handleBalancePress = useCallback(
    () => navigation.navigate("DepositStack"),
    [navigation]
  );

  const handlePointsPress = useCallback(
    () => navigation.navigate("PointsStack"),
    [navigation]
  );

  const handleUserPress = useCallback(
    () => navigation.navigate("ProfileStack"),
    [navigation]
  );

  return (
    <VerticalNavBarView
      navigation={navigation}
      theme={theme}
      currentUser={currentUser}
      styles={styles}
      onRegisterPress={handleOnRegisterPress}
      onLoginPress={handleOnLoginPress}
      onBalancePress={handleBalancePress}
      onPointsPress={handlePointsPress}
      onUserPress={handleUserPress}
    />
  );
};
