import Bovado from "shared/api/Bovado";
import { useQuery } from "@apollo/client";
import Option from "shared/api/Option";
import Event from "shared/api/Event";
import suggestedPriceInPennies from "Util/suggestedPriceInPennies";
import gql from "graphql-tag";
import { useMemo } from "react";

const fanDuelQuery = gql`
  query FanDuelListMarketPrices($marketIds: [String!]!) {
    fanDuelListMarketPrices(marketIds: $marketIds) {
      marketDetails {
        marketId
        eventId
        marketName
        marketType
        marketStartTime
        inplay
        livePriceAvailable
        guaranteedPriceAvailable
        runnerDetails {
          selectionId
          selectionName
          runnerOrder
          handicap
          runnerStatus
          winRunnerOdds {
            decimal
            numerator
            denominator
          }
          runnerResult {
            runnerResultType
          }
        }
      }
    }
  }
`;

export default ({ eventId, optionId, collectAmountInPennies }) => {
  const { data: { getEvent } = {} } = useQuery(Event.queries.get, {
    skip: !eventId,
    variables: {
      id: eventId,
    },
  });

  const { data: { getOption } = {} } = useQuery(Option.queries.get, {
    skip: !optionId,
    variables: {
      id: optionId,
    },
  });

  const { data: { fanDuelListMarketPrices } = {} } = useQuery(fanDuelQuery, {
    skip: !getEvent?.fanDuelMarketId,
    variables: {
      marketIds: [getEvent?.fanDuelMarketId],
    },
  });

  const runnerDetails = useMemo(
    () =>
      (fanDuelListMarketPrices?.marketDetails?.[0]?.runnerDetails ?? []).find(
        runnerDetails =>
          runnerDetails.selectionId.toString() ===
          getOption?.fanDuelSelectionId?.toString?.()
      ),
    [fanDuelListMarketPrices, getOption]
  );

  const { data: { getOddsDataForEventAndOption: odds } = {}, error } = useQuery(
    Bovado.queries.getOddsDataForEventAndOption,
    {
      skip: !getEvent || !getOption,
      variables: {
        bovadoGroupId: getEvent?.bovadoGroupId,
        bovadoEventId: getEvent?.bovadoEventId,
        bovadoMarketId: getEvent?.bovadoMarketId ?? undefined,
        bovadoParticipantId: getOption?.bovadoParticipantId,
        bovadoParticipantName: getOption?.bovadoParticipantName,
      },
    }
  );

  // const currentOddsCalculated = !askingPriceInPennies || !collectAmountInPennies ? null : ((parseFloat(collectAmountInPennies)) / parseFloat(askingPriceInPennies));

  const marketValuePrice = !!runnerDetails?.winRunnerOdds
    ? suggestedPriceInPennies({
        wagerInPennies: collectAmountInPennies,
        currentCalculatedOdds:
          (runnerDetails.winRunnerOdds.numerator * 1.0) /
          (runnerDetails.winRunnerOdds.denominator * 1.0),
      })
    : !!odds?.currentpriceup || !!odds?.currentpricedown
    ? suggestedPriceInPennies({
        wagerInPennies: collectAmountInPennies,
        currentCalculatedOdds:
          (odds.currentpriceup * 1.0) / (odds.currentpricedown * 1.0),
      })
    : null;

  return !marketValuePrice
    ? null
    : [
        marketValuePrice,
        marketValuePrice * 1.901,
        marketValuePrice * 1.601,
        marketValuePrice * 1.251,
        marketValuePrice * 1.051,
        marketValuePrice,
      ];

  // return [
  //   collectAmountInPennies,
  //   collectAmountInPennies * 1.901,
  //   collectAmountInPennies * 1.601,
  //   collectAmountInPennies * 1.251,
  //   collectAmountInPennies * 1.051,
  //   collectAmountInPennies
  // ]
};
