import React from "react";
import { Button, Caption } from "react-native-paper";
import { TextInput, View } from "react-native";

export default ({
  theme,
  code,
  setCode,
  successMessage,
  errorMessage,
  validateCode,
  styles,
}) => (
  <View style={styles.wrapper}>
    <View style={[styles.row, { justifyContent: "space-between" }]}>
      <View style={styles.codeWrapper}>
        <Caption>Have a code?</Caption>
      </View>
      <View style={styles.inputWrapper}>
        <TextInput
          onChangeText={text => setCode(text.toUpperCase())}
          value={code ?? ""}
          style={[styles.textSecondary, styles.input]}
        />
      </View>
      <View style={styles.inputWrapper}>
        <Button
          disabled={!code || !!errorMessage}
          onPress={validateCode}
          mode="contained"
          compact
        >
          Apply
        </Button>
      </View>
    </View>
    {!!errorMessage && (
      <Caption style={styles.textError}>{errorMessage}</Caption>
    )}
    {!!successMessage && (
      <Caption style={styles.textPrimary}>{successMessage}</Caption>
    )}
  </View>
);
