import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState, useCallback } from "react";
import { useTheme } from "react-native-paper";
import LocalAuthToggleView from "./LocalAuthToggleView";

export default ({ localAuth }) => {
  const theme = useTheme();
  const [isEnabled, setIsEnabled] = useState(localAuth.usesLocalAuth);
  const handleChange = useCallback(
    () =>
      AsyncStorage.setItem(
        "@PropSwap:usesLocalAuth",
        !isEnabled ? "true" : "false"
      ).then(() => setIsEnabled(isEnabled => !isEnabled)),
    [isEnabled]
  );

  return (
    <LocalAuthToggleView
      theme={theme}
      type={localAuth.hasFace ? "Face" : "Fingerprint"}
      isEnabled={isEnabled}
      onChange={handleChange}
    />
  );
};
