import { useNavigation } from "@react-navigation/core";
import { TicketContext } from "Contexts/Ticket";
import { useCallback, useContext } from "react";

import TicketSellSimilarButtonView from "./TicketSellSimilarButtonView";

const TicketSellSimilarButtonContainer = ({ listing, bets }) => {
  const navigation = useNavigation();
  const { setTicketValues } = useContext(TicketContext);

  const handlePress = useCallback(() => {
    setTicketValues({
      expiresAt: null,
      askingPrice: 0,
      minimumBidPrice: 0,
      notes: "",
      listingTicketId: listing?.listingTicketId,
      commissionWaiverApplied: false,

      listNow: true,
      localPhotoUri: "",
      started: false,

      /*TicketData*/
      ...(listing?.cachedTicket ?? {}),
      winAmount: (listing?.cachedTicket?.winAmount ?? 0) / 100.0,
      betAmount: (listing?.cachedTicket?.betAmount ?? 0) / 100.0,
      photoUrl: null,
      parlay:
        listing?.cachedTicket?.betType !== "PARLAY"
          ? null
          : bets
              .map(bet => ({ ...bet, ticketSportId: bet.sportId }))
              .reduce(
                (acc, value, i) => ({
                  ...acc,
                  [i]: value,
                }),
                {}
              ),
      parlayLegs:
        listing?.cachedTicket?.betType !== "PARLAY" ? 0 : bets?.length,
    });

    navigation.push("Main", {
      screen: "Sell",
      initial: false,
      params: {
        screen: "TicketNewStack",
        initial: false,
        params: {
          screen: "TicketNewStep2",
          initial: false,
        },
      },
    });
  }, [bets, listing, navigation, setTicketValues]);

  return <TicketSellSimilarButtonView onPress={handlePress} />;
};

export default TicketSellSimilarButtonContainer;
