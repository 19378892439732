import { useState, useEffect } from "react";
import * as LocalAuthentication from "expo-local-authentication";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";

export default () => {
  const [localAuth, setLocalAuth] = useState(null);

  useEffect(() => {
    Promise.all([
      LocalAuthentication.isEnrolledAsync(),
      LocalAuthentication.hasHardwareAsync(),
      LocalAuthentication.supportedAuthenticationTypesAsync(),
      AsyncStorage.getItem("@PropSwap:usesLocalAuth").then(
        answer => answer === "true"
      ),
      Platform.OS !== "web" && SecureStore.getItemAsync("login"),
      Platform.OS !== "web" && SecureStore.getItemAsync("password"),
    ]) //LocalAuthentication.AuthenticationType.{FINGERPRINT, FACIAL_RECOGNITION, IRIS}
      .then(
        ([
          isEnrolled,
          hasHardware,
          supportedTypes,
          usesLocalAuth,
          login,
          password,
        ]) =>
          setLocalAuth({
            isEnrolled,
            hasHardware,
            usesLocalAuth,
            login,
            password,
            hasFingerPrint: supportedTypes.includes(
              LocalAuthentication.AuthenticationType.FINGERPRINT
            ),
            hasFace: supportedTypes.includes(
              LocalAuthentication.AuthenticationType.FACIAL_RECOGNITION
            ),
            hasIris: supportedTypes.includes(
              LocalAuthentication.AuthenticationType.IRIS
            ),
          })
      );
  }, []);

  return localAuth;
};
