import gql from "graphql-tag";
import environment from "environment";
import Sport from "./Sport";

const Group = {};

Group.fragments = {
  public: gql`
    fragment GroupPublic on Group {
      id
      sportId
      userId
      name
      createdAt
      updatedAt
      active

      sport {
        ...SportPublic
      }
    }
    ${Sport.fragments.public}
  `,
};

Group.mutations = {
  update: gql`
    mutation UpdateGroup($input: UpdateGroupInput!) {
      updateGroup(input: $input) {
        ...GroupPublic
      }
    }
    ${Group.fragments.public}
  `,
  create: gql`
    mutation CreateGroup($input: CreateGroupInput!) {
      createGroup(input: $input) {
        ...GroupPublic
      }
    }
    ${Group.fragments.public}
  `,
};

Group.queries = {
  get: gql`
    query GetGroup($id: ID!) {
      getGroup(id: $id) {
        ...GroupPublic
      }
    }
    ${Group.fragments.public}
  `,
  list: gql`
    query ListGroups(
      $filter: ModelGroupFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...GroupPublic
        }
        nextToken
      }
    }
    ${Group.fragments.public}
  `,
  listBySportId: gql`
    query ListGroupsBySportId(
      $sportId: ID${environment.v2_bang}
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelGroupFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listGroupsBySportId(
        sportId: $sportId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...GroupPublic
        }
      }
    }
    ${Group.fragments.public}
  `,
};

export default Group;
