import React from "react";
import { useTheme, Button } from "react-native-paper";

import { Platform } from "react-native";

const Input = ({ value, onChange, theme, style }) =>
  React.createElement("input", {
    type: "datetime-local",
    value,
    onInput: event => onChange(event.target.value),
    onChange: event => onChange(event.target.value),
    style: {
      ...(!!style
        ? style
        : {
            backgroundColor: theme.colors.surface,
            color: theme.colors.text,
            borderStyle: "solid",
            borderColor: theme.colors.border,
            borderRadius: theme.roundness,
            padding: theme.spacing.lg,
          }),
      fontFamily:
        Platform.OS === "web"
          ? "Helvetica"
          : Platform.OS === "android"
          ? "Roboto"
          : "System",
    },
  });

export default ({ value, onChange, style, buttonCompact }) => {
  const theme = useTheme();

  return (
    <>
      <Input style={style} onChange={onChange} value={value} theme={theme} />
      {!!value && (
        <Button
          compact={!!buttonCompact}
          style={{ marginVertical: theme.spacing.xl }}
          onPress={() => [onChange(null)]}
          mode="outlined"
        >
          Clear
        </Button>
      )}
    </>
  );
};
