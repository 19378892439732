import { Button, Divider, List, Subheading, Text } from "react-native-paper";
import Screen from "Components/Screen";
import {
  addCommas,
  convertMoneyTextToPennies,
  formatPennies,
} from "shared/Util/money";
import ScreenBottom from "Components/Screen/Bottom";
import { ScrollView, View } from "react-native";
import Confirmation from "Components/Confirmation";
import BottomSheet from "Components/BottomSheet";
import UserW9Form from "Components/User/W9Form";

export default ({
  theme,
  type,
  navigateTo,
  currentUser,
  errorMessages,
  isSubmitting,
  amount,
  submitted,
  doSubmit,
  fee,
  accountType,
  accountNumber,
  routingNumber,
  mailingAddress1,
  mailingAddress2,
  mailingCity,
  mailingState,
  mailingZip,
  styles,
  needsW9,
  onSsnSubmit,
}) => (
  <Screen
    gatedTo="member"
    title="Withdraw Funds"
    fullHeight
    backgroundColor={theme.colors.surface}
    fullWidth
  >
    <ScrollView keyboardShouldPersistTaps="handled">
      <List.Item
        title="Amount"
        description={(!!amount && `$${addCommas(amount)}`) || " "}
        right={props => <List.Icon {...props} icon="chevron-right" />}
        onPress={navigateTo("WithdrawAmount", {
          backTo:
            type === "ACH_STANDARD" ? "DirectDepositSummary" : "PaperSummary",
          accountType,
          accountNumber,
          routingNumber,
          mailingAddress1,
          mailingAddress2,
          mailingCity,
          mailingState,
          mailingZip,
          amount,
        })}
      />
      <Divider />
      {type === "ACH_STANDARD" && (
        <List.Item
          title="Account"
          description={
            (!!accountNumber && `${accountNumber} / ${routingNumber}`) || " "
          }
          right={props => <List.Icon {...props} icon="chevron-right" />}
          onPress={navigateTo("AchWithdrawSelect", {
            accountType,
            accountNumber,
            routingNumber,
            amount,
          })}
        />
      )}
      {type !== "ACH_STANDARD" && (
        <List.Item
          title="Mail To"
          description={`${mailingAddress1} ${
            mailingAddress2 ?? ""
          } ${mailingCity} ${mailingState} ${mailingZip}`}
          right={props => <List.Icon {...props} icon="chevron-right" />}
          onPress={navigateTo("WithdrawPaperInfo", {
            mailingAddress1,
            mailingAddress2,
            mailingCity,
            mailingState,
            mailingZip,
            amount,
          })}
        />
      )}
      <Divider />

      <View style={styles.summaryWrapper}>
        <View style={styles.headingWrapper}>
          <Subheading>Withdraw Summary</Subheading>
        </View>
        <View style={styles.headingWrapper}>
          <Text>Available Balance:</Text>
          <Text style={styles.textRight}>
            {formatPennies(currentUser?.balance, "$")}
          </Text>
        </View>
        <View style={styles.headingWrapper}>
          <Text>Withdrawal Amount:</Text>
          <Text style={styles.textRight}>${addCommas(amount)}</Text>
        </View>
        <View style={styles.headingWrapper}>
          <Text>Service Fee:</Text>
          {!!fee && (
            <Text style={styles.textRight}>
              {formatPennies(fee * convertMoneyTextToPennies(amount), "$")}
            </Text>
          )}
          {!fee && (
            <Text
              style={[styles.textRight, styles.textUpper, styles.textPrimary]}
            >
              Free
            </Text>
          )}
        </View>
        <View style={styles.headingWrapper}>
          <Text>Amount to be Withdrawn:</Text>
          <Text style={styles.textRight}>
            {formatPennies(
              fee * convertMoneyTextToPennies(amount) +
                convertMoneyTextToPennies(amount),
              "$"
            )}
          </Text>
        </View>
        <View style={{ marginBottom: theme.spacing.md }}>
          <Divider />
        </View>
        <View style={styles.headingWrapper}>
          <Text>New Balance:</Text>
          <Text style={styles.textRight}>
            {formatPennies(
              currentUser?.balance - convertMoneyTextToPennies(amount),
              "$"
            )}
          </Text>
        </View>
      </View>
    </ScrollView>

    <ScreenBottom adjustPadding inset>
      <Button
        disabled={!!errorMessages.length || !!isSubmitting}
        loading={!!isSubmitting}
        mode="contained"
        onPress={doSubmit}
        style={styles.fullWidth}
      >
        {!!isSubmitting ? "Please Wait..." : "Withdraw"}
      </Button>

      {errorMessages?.map((errorMessage, i) => (
        <Text key={i} style={styles.textError}>
          {(errorMessage ?? "").replace(
            /Invaild recipient data/,
            "Oops! Looks like there was an error processing your request. Please try again."
          )}
        </Text>
      ))}
    </ScreenBottom>

    <BottomSheet visible={!!submitted} fullScreen>
      {!!submitted && (
        <Confirmation
          title="Withdrawal Successful"
          subtitle={
            type === "ACH_STANDARD"
              ? "Please allow 3-5 business days for your payment to arrive."
              : "Please allow 7-10 business days for your payment to arrive."
          }
          type="success"
          primaryButtonLabel="Buy Tickets"
          onPrimaryButtonPress={navigateTo("Main", { screen: "Buy" })}
          secondaryButtonLabel="View Transaction History"
          onSecondaryButtonPress={navigateTo("Account", {
            screen: "TransactionStack",
          })}
        />
      )}
    </BottomSheet>

    <BottomSheet visible={!!needsW9} onDismiss={console.log} fullScreen>
      <UserW9Form onSubmit={onSsnSubmit} />
    </BottomSheet>
  </Screen>
);
