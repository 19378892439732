import Screen from "Components/Screen";
import useStyles from "Hooks/useStyles";
import PhotoUploadStyles from "Components/PhotoUpload/PhotoUploadStyles";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { FlatList, View } from "react-native";
import { Text, Button, Divider, Subheading } from "react-native-paper";
import moment from "moment";
import { formatPennies } from "shared/Util/money";
import { useCallback, useContext } from "react";
import { TicketContext } from "Contexts/Ticket";
import { listSharpsportsBettorAccounts } from "./TicketNewBetSync";

function gcd(a, b) {
  return b === 0 ? a : gcd(b, a % b);
}

function convertAmericanToFractional(americanOddsString) {
  const americanOdds = parseInt(americanOddsString, 10);
  const isPositive = americanOdds > 0;
  const numerator = isPositive ? americanOdds : 100;
  const denominator = isPositive ? 100 : Math.abs(americanOdds);

  // Calculate GCD for simplification
  const divisor = gcd(numerator, denominator);

  // Create simplified fraction without mutating any variable
  const simplifiedNumerator = numerator / divisor;
  const simplifiedDenominator = denominator / divisor;

  return !isPositive
    ? americanOddsString
    : `${simplifiedNumerator}/${simplifiedDenominator}`;
}

const listSharpsportsBetSlipsByBetterAccountId = gql`
  query ListSharpsportsBetSlipsByBetterAccountId($bettorAccountId: String!) {
    listSharpsportsBetSlipsByBetterAccountId(
      bettorAccountId: $bettorAccountId
      status: "pending"
    ) {
      id
      timePlaced
      toWin
      atRisk
      oddsAmerican
      bets {
        id
        oddsAmerican
        position
        bookDescription
        event {
          id
          name
        }
      }
    }
  }
`;

const translateBetSlipToPropSwapTicket = gql`
  mutation TranslateBetSlipToPropSwapTicket(
    $input: TranslateBetSlipToPropSwapTicketInput!
  ) {
    translateBetSlipToPropSwapTicket(input: $input) {
      ticketSportId
      isMobile
      betType
      betAmount
      winAmount
      collectAmount
      sharpSportsBetSlipId
      eventDescription
      subject
      photoUrl
      odds
      oddsCalculated
      ticketLocaleId
      ticketSportsbookId
      cachedBets {
        optionId
        eventId
        sportId
        sharpSportsBetId
        sharpSportsEventId
        odds
        oddsCalculated
        eventDescription
        subject
        spread
      }
    }
  }
`;

const BetSyncBetSlip = ({ item, bookRegionId, navigation }) => {
  const { theme } = useStyles({ Styles: PhotoUploadStyles });
  const { setTicketValues } = useContext(TicketContext);
  const [translateBetSlip, { loading, error }] = useMutation(
    translateBetSlipToPropSwapTicket,
    {
      variables: { input: { betSlipId: item.id, bookRegionId } },
    }
  );

  // resetTicket();

  const handleButtonPress = useCallback(
    () =>
      translateBetSlip()
        .then(({ data }) =>
          setTicketValues(ticketValues => ({
            ...ticketValues,
            ...data?.translateBetSlipToPropSwapTicket,
            parlayLegs:
              data?.translateBetSlipToPropSwapTicket?.cachedBets.length > 1
                ? data?.translateBetSlipToPropSwapTicket?.cachedBets.length
                : 0,
            parlay: [
              ...(data?.translateBetSlipToPropSwapTicket?.cachedBets ?? []),
            ],

            isSharpSports: true,
            listNow: true,
          }))
        )
        .then(() => navigation.push("TicketNewStep3"))
        .catch(err => {
          console.error(err);
          alert(err?.message ?? "An error occurred");
        }),
    [navigation, setTicketValues, translateBetSlip]
  );

  !!error && console.log(error);

  return (
    <View
      style={{
        margin: theme.spacing.xl,
      }}
    >
      <View
        style={{
          borderColor: theme.colors.border,
          borderWidth: 1,
          borderStyle: "solid",
          marginBottom: theme.spacing.md,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: theme.spacing.md,
          }}
        >
          <View>
            {item.bets.map(bet => (
              <View key={bet.id}>
                {bet.bookDescription.split(" - ").map((line, i) => (
                  <Text style={{ fontWeight: "bold" }} key={i}>
                    {line}
                  </Text>
                ))}
              </View>
            ))}
          </View>
          <View>
            <Text>{convertAmericanToFractional(item.oddsAmerican)}</Text>
          </View>
        </View>
        <Divider />
        <View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: theme.spacing.md,
            }}
          >
            <View>
              <Text style={{ fontWeight: "bold", textAlign: "center" }}>
                ${formatPennies(item.atRisk)}
              </Text>
              <Text style={{ textAlign: "center" }}>Bet Amount</Text>
            </View>
            <View>
              <Text style={{ fontWeight: "bold", textAlign: "center" }}>
                ${formatPennies(item.toWin)}
              </Text>
              <Text style={{ textAlign: "center" }}>Win Amount</Text>
            </View>
          </View>
        </View>
        <Divider />
        <View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: theme.spacing.md,
            }}
          >
            <View>
              <Text>ID: {item.id.replace("SLIP_", "")}</Text>
            </View>
            <View>
              <Text>
                Placed: {moment(item.timePlaced).format("MM/DD/YYYY")}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <Button
        onPress={handleButtonPress}
        mode="contained"
        style={{ width: "100%" }}
        loading={loading}
        disabled={loading}
      >
        List for Sale
      </Button>
    </View>
  );
};

export default ({ navigation, route }) => {
  const accountId = route.params?.accountId;
  const accountName = route.params?.accountName;
  const bookRegionId = route.params?.bookRegionId;
  const { theme } = useStyles({ Styles: PhotoUploadStyles });
  const { data: accountData } = useQuery(listSharpsportsBettorAccounts);
  const selectedAccount = accountData?.listSharpsportsBettorAccounts.find(
    ba => ba.id === accountId
  );

  const { data } = useQuery(listSharpsportsBetSlipsByBetterAccountId, {
    variables: {
      bettorAccountId: accountId,
    },
  });

  return (
    <Screen
      gatedTo="member"
      title={accountName}
      hasKeyboard={false}
      backgroundColor={theme.colors.surface}
      fullWidth
      fullHeight
    >
      <FlatList
        data={data?.listSharpsportsBetSlipsByBetterAccountId ?? []}
        keyExtractor={item => item.id}
        ListHeaderComponent={
          <View style={{ margin: theme.spacing.md }}>
            <Subheading
              style={{ textAlign: "center", color: theme.colors.primary }}
            >
              Select a Bet to list for sale on PropSwap
            </Subheading>
            <Text style={{ textAlign: "center" }}>
              You will be able to review and enter your Sale Price on the next
              page
            </Text>
            {!!selectedAccount?.latestRefreshResponse?.timeCreated && (
              <View style={{ marginTop: theme.spacing.md }}>
                <Text style={{ textAlign: "center", fontWeight: "bold" }}>
                  {selectedAccount?.latestRefreshResponse.status === 200
                    ? `Last Refreshed: ${moment(
                        selectedAccount?.latestRefreshResponse?.timeCreated
                      ).format("MM/DD/YYYY hh:mm A")}`
                    : `An error occurred during the last refresh. Please try again.`}
                </Text>
              </View>
            )}
          </View>
        }
        renderItem={({ item }) => (
          <BetSyncBetSlip
            item={item}
            bookRegionId={bookRegionId}
            navigation={navigation}
          />
        )}
      />
    </Screen>
  );
};
