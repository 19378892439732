import { gql, useMutation } from "@apollo/client";
import { gidxRefresSessionVar } from "./useGidxSession";
import { useCallback } from "react";
import useGidxPrepareSession from "./useGidxPrepareSession";

const gidxCompleteSession = gql`
  mutation GidxCompleteSession($input: GidxCompleteSessionInput!) {
    gidxCompleteSession(input: $input) {
      Action {
        Type
        URL
      }
      AllowRetry
      ApiKey
      ApiVersion
      MerchantID
      MerchantSessionID
      MerchantTransactionID
      PaymentDetails {
        CurrencyCode
        FinancialConfidenceScore
        PaymentAmount
        PaymentAmountCode
        PaymentAmountType
        PaymentApprovalDateTime
        PaymentMethodAccount
        PaymentMethodType
        PaymentProcessDateTime
        PaymentStatusCode
        PaymentStatusDateTime
        PaymentStatusMessage
      }
      ReasonCodes
      ResponseCode
      ResponseMessage
      SessionID
    }
  }
`;

const useGidxCheckout = ({ type, token, cvv, amount, promotionId }) => {
  const [createSession] = useGidxPrepareSession();

  const [completeSession, result] = useMutation(gidxCompleteSession);

  const handleCompleteSession = useCallback(
    () =>
      createSession()
        .then(({ data }) =>
          completeSession({
            variables: {
              input: {
                promotionId,
                PaymentAmount: {
                  PaymentAmount: amount * 1.0,
                },
                PaymentMethod: {
                  Token: token,
                  Type: type,
                  CVV: type === "CC" ? cvv : undefined,
                },
                MerchantSessionID: data.gidxCreateSession.MerchantSessionID,
                MerchantTransactionID: (
                  data.gidxCreateSession.MerchantSessionID ?? ""
                ).slice(0, 16),
              },
            },
          })
        )
        .then(({ data }) => {
          gidxRefresSessionVar(true);
          return data?.gidxCompleteSession;
        }),
    [amount, completeSession, createSession, cvv, promotionId, token, type]
  );

  return [handleCompleteSession, result];
};

export default useGidxCheckout;
