import gql from "graphql-tag";
import environment from "environment";

const User = {};

User.fragments = {
  public: gql`
    fragment UserPublic on User {
      id
      disabled
      displayName
      profilePicUri
      createdAt
      updatedAt
      agreedToTermsAt
      verifiedAt
      agreedToCcTermsAt
      rating
      status
      points
      availablePoints
      buyOrSellTransactionCount
      referralCode
      notificationsCount
      watchingsCount
      totalReferralBonus
      hideNotificationsCount
      allowPushNotifications
      hideActivePointsPopup
      ticketsBoughtCount
      ticketsSoldCount

      providerId
      providerUserId

      sport {
        id
        name
        abbrev
      }
      team {
        id
        name
        location
        abbrev
      }
    }
  `,
  private: gql`
    fragment UserPrivate on User {
      email
      promotionalBalance
      pendingBalance
      balance
      bluesnapVaultedShopperId
      interchecksRecipientId
      lockRating
      lockStatus
      interchecksRecipientW9Required
      interchecksRecipientW9Verified
      interchecksRecipientW9Date
      emailNotifications
      pushNotifications
      allowListingWithoutAuth
      state
    }
  `,
};

User.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateUserPublic {
      onUpdateUserPublic {
        ...UserPublic
      }
    }
    ${User.fragments.public}
  `,
  onCreate: gql`
    subscription OnCreateUser {
      onCreateUser {
        ...UserPublic
      }
    }
    ${User.fragments.public}
  `,
};

User.mutations = {
  convertPointsToMerch: gql`
    mutation ConvertPointsToMerch($input: ConvertPointsToMerchInput!) {
      convertPointsToMerch(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
  convertPointsToCash: gql`
    mutation ConvertPointsToCash($input: ConvertPointsToCashInput!) {
      convertPointsToCash(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
  update: gql`
    mutation UpdateUser($input: UpdateUserInput!) {
      updateUser(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
  verify: gql`
    mutation VerifyUser($input: VerifyUserInput!) {
      verifyUser(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
  verifyCanadian: gql`
    mutation VerifyCanadianUser($input: VerifyUserInput!) {
      verifyCanadianUser(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
  updateAttributes: gql`
    mutation UpdateUserAttributes($input: UpdateUserAttributesInput!) {
      updateUserAttributes(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
  enable: gql`
    mutation EnableUser($input: EnableUserInput!) {
      enableUser(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
  create: gql`
    mutation CreateUser($input: CreateUserInput!) {
      createUser(input: $input) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
};

User.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateUser($id: String) {
      onUpdateUser(id: $id) {
        id
      }
    }
  `,
};

User.queries = {
  get: gql`
    query GetUser($id: ID!, $withPrivate: Boolean = false) {
      getUser(id: $id) {
        ...UserPublic
        ...UserPrivate @include(if: $withPrivate)
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
  list: gql`
    query ListUsers(
      $filter: ModelUserFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...UserPublic
        }
        nextToken
      }
    }
    ${User.fragments.public}
  `,
  listByEmail: gql`
    query UsersByEmail(
      $email: String${environment.v2_bang}
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelUserFilterInput
      $limit: Int
      $nextToken: String
      $withPrivate: Boolean = false
    ) {
      usersByEmail(
        email: $email
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...UserPublic
          ...UserPrivate @include(if: $withPrivate)
        }
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
  listByProviderIdAndProviderUserId: gql`
    query ListUsersByProviderIdAndProviderUserId(
      $providerId: ID${environment.v2_bang}
      $providerUserId: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelUserFilterInput
      $limit: Int
      $nextToken: String
      $withPrivate: Boolean = false
    ) {
      listUsersByProviderIdAndProviderUserId(
        providerId: $providerId
        providerUserId: $providerUserId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...UserPublic
          ...UserPrivate @include(if: $withPrivate)
        }
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
  listByDisplayName: gql`
    query ListUsersByDisplayName(
      $displayName: String${environment.v2_bang}
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelUserFilterInput
      $limit: Int
      $nextToken: String
      $withPrivate: Boolean = false
    ) {
      listUsersByDisplayName(
        displayName: $displayName
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...UserPublic
          ...UserPrivate @include(if: $withPrivate)
        }
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
  getWithPrivate: gql`
    query GetUserWithPrivate($id: ID!) {
      getUser(id: $id) {
        ...UserPublic
        ...UserPrivate
      }
    }
    ${User.fragments.public}
    ${User.fragments.private}
  `,
};

export default User;
