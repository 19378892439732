import React, { useContext, useCallback } from "react";
import { Button, HelperText, useTheme, TextInput } from "react-native-paper";
import { CurrentUserContext } from "Contexts/CurrentUser";

import * as yup from "yup";
import Screen from "Components/Screen";

import valid from "card-validator"; //import statement

import { HeaderButtons, Item } from "react-navigation-header-buttons";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Platform, ScrollView, Text } from "react-native";
import ScreenBottom from "Components/Screen/Bottom";
import useGidxAddPaymentMethod from "Hooks/useGidxAddPaymentMethod";
import useGidxGeoInfo from "Hooks/useGidxGeoInfo";

// const [createTransaction] = useMutation(
//   Bluesnap.mutations.createTransaction,
//   {
//     variables: {
//       input: {
//         amount: "1",
//         currency: "USD",
//         userId: currentUser?.id,
//         authorizedByShopper: true,
//         ecpTransaction: {
//           accountNumber,
//           routingNumber,
//           accountType,
//         },
//         vaultedShopperId: currentUser?.bluesnapVaultedShopperId ?? undefined,
//         payerInfo: {
//           firstName,
//           lastName,
//           zip: postalCode,
//         },
//       },
//     },
//     refetchQueries: [
//       {
//         query: Bluesnap.queries.getVaultedShopper,
//         variables: {
//           vaultedShopperId: currentUser?.bluesnapVaultedShopperId,
//         },
//       },
//       {
//         query: User.queries.get,
//         variables: {
//           id: currentUser?.id,
//           withPrivate: true,
//         },
//       },
//     ],
//   }
// );

const schema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.'-]+$/i, "Must be a valid first name")
    .required("Can't be blank"),
  lastName: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.'-]+$/i, "Must be a valid last name")
    .required("Can't be blank"),
  accountNumber: yup.string().required(),
  routingNumber: yup.string().required(),
  postalCode: yup
    .string()
    .test(
      "test-number", // this is used internally by yup
      "Zipcode is invalid", //validation message
      value => valid.postalCode(value).isValid
    ) // return true false based on validation
    .required(),
});

export default ({ navigation, route }) => {
  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);
  const inputs = React.useRef({});
  // const location = useLocation();

  // useContext(BluesnapEncrypterContext);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setError,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  // const [accountType, setAccountType] = React.useState("CONSUMER_CHECKING");
  const gidxInfo = useGidxGeoInfo();
  const routingNumber = watch("routingNumber", "");
  const postalCode = watch("postalCode", "");
  const firstName = watch("firstName", "");
  const lastName = watch("lastName", "");
  const accountNumber = watch("accountNumber", "");

  const savePaymentMethod = useGidxAddPaymentMethod({
    type: "ACH",
    fullName: `${firstName} ${lastName}`,
    accountNumber,
    routingNumber,
    postalCode,
  });

  const handleSubmitError = useCallback(
    error =>
      setError("accountNumber", {
        ...error,
        message: `${error.code} - ${error.message}`,
      }),
    [setError]
  );

  const onSubmit = useCallback(
    () =>
      savePaymentMethod()
        .then(() => navigation.goBack())
        .catch(handleSubmitError),
    [savePaymentMethod, handleSubmitError, navigation]
  );

  const doSubmit = handleSubmit(onSubmit);
  const hasGeoInfo = gidxInfo?.ip && gidxInfo?.latitude && gidxInfo?.longitude;

  console.log("gidxInfo", gidxInfo);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons size={48}>
          <Item
            title="Save"
            disabled={!isValid || !!isSubmitting}
            onPress={doSubmit}
          />
        </HeaderButtons>
      ),
    });
  }, [navigation, doSubmit, isValid, isSubmitting]);

  return !hasGeoInfo ? (
    <Text>Getting Location Info</Text>
  ) : (
    <Screen
      gatedTo="member"
      title="Add Account"
      fullHeight
      hasKeyboard
      backgroundColor={theme.colors.surface}
    >
      <ScrollView style={{ flex: 1 }}>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize="words"
                ref={e => (inputs.current["firstName"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "name"
                }
                keyboardType="default"
                textContentType="givenName"
                label="First Name on Account"
                error={!!errors.firstName}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.lastName.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value ?? ""}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.firstName}>
                {errors.firstName?.message}
              </HelperText>
            </>
          )}
          name="firstName"
          defaultValue={currentUser?.attributes?.given_name}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="words"
                ref={e => (inputs.current["lastName"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "name"
                }
                keyboardType="default"
                textContentType="familyName"
                label="Last Name on Account"
                error={!!errors.lastName}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.accountNumber.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.lastName}>
                {errors.lastName?.message}
              </HelperText>
            </>
          )}
          name="lastName"
          defaultValue={currentUser?.attributes?.family_name}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["accountNumber"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="number-pad"
                textContentType="none"
                label="Account Number"
                error={!!errors.accountNumber}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.routingNumber.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.accountNumber}>
                {errors.accountNumber?.message}
              </HelperText>
            </>
          )}
          name="accountNumber"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["routingNumber"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="number-pad"
                textContentType="none"
                label="Routing Number"
                error={!!errors.routingNumber}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.postalCode.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.routingNumber}>
                {errors.routingNumber?.message}
              </HelperText>
            </>
          )}
          name="routingNumber"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                maxLength={5}
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["postalCode"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "postal-code"
                }
                keyboardType="number-pad"
                textContentType="postalCode"
                label="Zip Code"
                error={!!errors.postalCode}
                returnKeyLabel="Done"
                returnKeyType="done"
                onSubmitEditing={doSubmit}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.postalCode}>
                {errors.postalCode?.message}
              </HelperText>
            </>
          )}
          name="postalCode"
          defaultValue=""
        />

        {/* <RadioButton.Group onValueChange={setAccountType} value={accountType}>
          <RadioButton.Item
            color={theme.colors.primary}
            label="Consumer Checking"
            value="CONSUMER_CHECKING"
          />
          <RadioButton.Item
            color={theme.colors.primary}
            label="Consumer Savings"
            value="CONSUMER_SAVINGS"
          />
        </RadioButton.Group> */}
      </ScrollView>

      <ScreenBottom adjustPadding>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          {!!isSubmitting ? "Please Wait..." : "Save Account"}
        </Button>
      </ScreenBottom>
    </Screen>
  );
};
