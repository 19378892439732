export default theme => ({
  wrapper: {
    flex: 1,
    justifyContent: "center",
  },
  headingsOuterWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing.xl,
  },
  headingsWrapper: {
    backgroundColor: theme.colors.backgroundBlack,
    width: 258,
    height: 157,
    justifyContent: "center",
    paddingHorizontal: theme.spacing.lg,
  },
  buttonWrapper: {
    justifyContent: "flex-end",
    marginBottom: theme.spacing.xl,
  },
});
