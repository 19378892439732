import React, { useState, useCallback, useContext } from "react";

import RegistrationInitialView from "./RegistrationInitialView";
import RegistrationInitialStyles from "./RegistrationInitialStyles";
import useStyles from "Hooks/useStyles";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { RegistrationContext } from "Contexts/Registration";
import { useApolloClient } from "@apollo/client";
import User from "shared/api/User";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid email address")
    .required("Can't be blank"),
  agree: yup.boolean().oneOf([true]),
  displayName: yup
    .string()
    .min(3, "Too short - should be 3 chars minimum.")
    .max(12, "Too long - should be 12 chars maximum.")
    .required("Can't be blank"),
  password: yup
    .string()
    .required("Can't be blank")
    .min(8, "Too short - should be 8 chars minimum.")
    .max(48, "Too long - should be 48 chars maximum.")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
});

export default ({ navigation }) => {
  const { theme, styles } = useStyles({ Styles: RegistrationInitialStyles });
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, dirtyFields },
    setError,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const inputs = React.useRef({});
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const { setRegistrationValues, registrationValues } =
    useContext(RegistrationContext);
  const displayName = watch("displayName", "");
  const password = watch("password", "");

  const client = useApolloClient();

  const handleSubmitError = useCallback(
    (field, error) =>
      setError(field, {
        ...error,
        message: error.message,
      }),
    [setError]
  );

  const onSubmit = useCallback(
    ({ email, displayName, password, receivePromotions, agree }) =>
      Promise.all([
        client
          .query({
            fetchPolicy: "network-only",
            query: User.queries.listByEmail,
            variables: {
              email,
            },
          })
          .then(
            ({
              data: {
                usersByEmail: { items },
              },
            }) => !!items.length
          ),
        client
          .query({
            fetchPolicy: "network-only",
            query: User.queries.listByDisplayName,
            variables: {
              displayName,
            },
          })
          .then(
            ({
              data: {
                listUsersByDisplayName: { items },
              },
            }) => !!items.length
          ),
      ])
        .then(([emailTaken, displayNameTaken]) => [
          !!emailTaken &&
            handleSubmitError("email", { message: "Email has been taken" }),
          !!displayNameTaken &&
            handleSubmitError("displayName", {
              message: "Display Name has been taken",
            }),
          !displayNameTaken &&
            !emailTaken && [
              setRegistrationValues(registrationValues => ({
                ...registrationValues,
                receivePromotions,
                email,
                displayName,
                password,
                agree,
              })),
              navigation.push("RegistrationStack"),
            ],
        ])
        .catch(e => console.error(JSON.stringify(e, null, 4))),
    [navigation, handleSubmitError, setRegistrationValues, client]
  );

  const doSubmit = handleSubmit(onSubmit);

  return (
    <RegistrationInitialView
      styles={styles}
      theme={theme}
      control={control}
      inputs={inputs}
      errors={errors}
      registrationValues={registrationValues}
      secureTextEntry={secureTextEntry}
      setSecureTextEntry={setSecureTextEntry}
      dirtyFields={dirtyFields}
      password={password}
      doSubmit={doSubmit}
      displayName={displayName}
      navigation={navigation}
      isValid={isValid}
      isSubmitting={isSubmitting}
    />
  );
};
