import gql from "graphql-tag";

const Bovado = {};

Bovado.queries = {
  getOddsDataForEvent: gql`
    query GetOddsDataForEvent(
      $bovadoGroupId: String!
      $bovadoEventId: String!
    ) {
      getOddsDataForEvent(
        bovadoGroupId: $bovadoGroupId
        bovadoEventId: $bovadoEventId
      )
    }
  `,
  getOddsDataForEventAndOption: gql`
    query GetOddsDataForEventAndOption(
      $bovadoGroupId: String!
      $bovadoEventId: String!
      $bovadoParticipantId: String
      $bovadoParticipantName: String
      $bovadoMarketId: String
    ) {
      getOddsDataForEventAndOption(
        bovadoGroupId: $bovadoGroupId
        bovadoEventId: $bovadoEventId
        bovadoParticipantId: $bovadoParticipantId
        bovadoParticipantName: $bovadoParticipantName
        bovadoMarketId: $bovadoMarketId
      ) {
        currentpricedown
        currentpriceup
        currentscore
        estimatepricedown
        estimatepriceup
        idfomarket
        idfoparticipant
        idfoselection
        name
        price
        shortname
        sppricedown
        sppriceup
      }
    }
  `,
};

export default Bovado;
