export default theme => ({
  wrapper: {
    flex: 1,
    marginTop: theme.spacing.md,
    marginHorizontal: theme.spacing.xl * -1,
    paddingHorizontal: theme.spacing.xl,
  },
  controlWrapper: {
    // flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing.sm,
  },
  controlInner: {
    alignItems: "center",
    flexDirection: "row",
  },
  label: {
    fontWeight: "bold",
    width: 60,
  },
  input: {
    color: theme.colors.text,
    flex: 1,
    borderWidth: 1,
    padding: theme.spacing.xs,
    borderStyle: "solid",
    minWidth: 0,
    backgroundColor: "transparent",
  },
  dateTimeInput: {
    fontSize: 14,
    minWidth: 186,
  },
  multilineInput: {
    borderRadius: 0,
    marginTop: theme.spacing.lg,
    height: 100,
  },
  helperWrapper: {
    flexDirection: "row",
    marginBottom: theme.spacing.sm,
  },
  valueWrapper: {
    flex: 1,
    marginLeft: theme.spacing.lg,
  },
  notesWrapper: {
    marginBottom: theme.spacing.xl,
  },
  buttonsWrapper: {
    marginLeft: theme.spacing.md * -1,
    flexDirection: "row",
    width: "100%",
  },
  button: {
    flex: 1,
    marginLeft: theme.spacing.md,
  },
});
