import { View } from "react-native";

export default ({
  theme,
  children,
  style,
  onLayout,
  height,
  adjustPadding,
  additionalPadding,
  styles,
  inset,
  keyboardHeight,
}) => (
  <View
    style={[
      styles.outerWrapper,
      !!inset ? { marginHorizontal: theme.spacing.xl } : {},
      // (!!height && !!adjustPadding ? {paddingTop: height + additionalPadding} : {}),
      !!keyboardHeight
        ? {}
        : !!height && !!adjustPadding
        ? { height: height + additionalPadding }
        : {},
      style ?? {},
    ]}
  >
    <View style={styles.wrapper} onLayout={onLayout}>
      {Array.isArray(children) ? [...children] : children}
    </View>
  </View>
);
