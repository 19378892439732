import React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
// import Golf from "Components/Icons/Golf"
import Sports from "Components/Icons/Sports";
import WatchingIcon from "Components/Watching/Icon";
import ShareIcon from "Components/Share/Icon";
import moment from "moment";

export default ({ listing, ticket, theme, currentUser }) => (
  <View
    style={{
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing.lg,
    }}
  >
    <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
      <View style={{ marginRight: theme.spacing.sm }}>
        <Sports
          sportAbbrev={ticket.sport.abbrev}
          color={theme.colors.secondary}
        />
      </View>
      <Text
        style={{
          color: theme.colors.secondary,
          fontSize: theme.fonts.h4.fontSize,
          fontWeight: "bold",
        }}
      >
        {ticket.sport.abbrev}
      </Text>
    </View>
    {listing?.status === "PENDING" &&
      currentUser?.id === listing?.listingSellerId && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            flex: 3,
          }}
        >
          <Text style={{ color: theme.colors.primary }}>Action Required</Text>
        </View>
      )}
    {!["PENDING", "SOLD"].includes(listing?.status) && (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          flex: 3,
        }}
      >
        {!!listing?.expiresAt && (
          <>
            <Text
              style={{
                color: theme.colors.text,
                fontSize: theme.fonts.caption.fontSize,
              }}
            >
              {listing.expiresAt >= new Date().toISOString()
                ? `${moment(listing.expiresAt).fromNow(true)} left`
                : "Expired"}{" "}
            </Text>
            <Text
              style={{
                color: theme.colors.text,
                fontSize: theme.fonts.caption.fontSize,
              }}
            >
              {" "}
              |{" "}
            </Text>
          </>
        )}
        <Text
          style={{
            color: theme.colors.text,
            fontSize: theme.fonts.caption.fontSize,
          }}
        >
          {ticket.views ?? 0} views
        </Text>
        <View style={{ marginLeft: theme.spacing.sm }}>
          <ShareIcon theme={theme} listing={listing} ticket={ticket} />
        </View>
        <View style={{ marginLeft: theme.spacing.sm }}>
          <WatchingIcon theme={theme} listing={listing} />
        </View>
      </View>
    )}
  </View>
);
