import gql from "graphql-tag";
// import Ticket from "./Ticket"

const Interchecks = {};

Interchecks.queries = {
  listAchAccounts: gql`
    query ListInterchecksAchAccounts($recipientId: String!) {
      listInterchecksAchAccounts(recipientId: $recipientId)
    }
  `,
};

Interchecks.mutations = {
  deleteAchAccount: gql`
    mutation DeleteInterchecksAchAccount(
      $input: DeleteInterchecksAchAccountInput!
    ) {
      deleteInterchecksAchAccount(input: $input)
    }
  `,
  createAchAccount: gql`
    mutation CreateInterchecksAchAccount(
      $input: CreateInterchecksAchAccountInput!
    ) {
      createInterchecksAchAccount(input: $input)
    }
  `,
  createPayment: gql`
    mutation CreateInterchecksPayment($input: CreateInterchecksPaymentInput!) {
      createInterchecksPayment(input: $input) {
        request_id
        request_status
        payments_processed
        payments_created
        payments_failed
        recipient {
          recipient_id
          recipient_email
          recipient_first_name
          recipient_last_name
          payments {
            payment_id
            payment_amount
            payment_reference_id
            payment_currency
            payment_status
            payment_created_date
          }
          payout_transaction {
            payout_id
            payout_amount
            payout_currency
            payout_status
            payout_date
            payout_transaction_id
            payout_method
            payout_destination
          }
        }
      }
    }
  `,
};

export default Interchecks;
