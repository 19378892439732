export default theme => ({
  wrapper: {
    backgroundColor: theme.colors.background,
    borderRadius: 4,
    width: "100%",
    height: 10,
  },
  meter: {
    borderRadius: 4,
    height: 10,
  },
});
