import { MaterialIcons } from "@expo/vector-icons";

export default ({ theme, isFeatured, onPress, isBookedMarked, color }) => (
  <MaterialIcons
    name={
      !!isBookedMarked
        ? "bookmark"
        : !!isFeatured
        ? "bookmark-border"
        : "bookmark"
    }
    size={24}
    color={
      color ??
      (!isBookedMarked
        ? theme.colors.disabled
        : !!isFeatured
        ? theme.colors.textReverse
        : theme.colors.secondary)
    }
    onPress={onPress}
  />
);
