import { AvatarContext } from "Contexts/Avatar";
import { CurrentUserContext } from "Contexts/CurrentUser";
import useStyles from "Hooks/useStyles";
import React from "react";
import { useContext } from "react";
import UserAvatarStyles from "./UserAvatarStyles";
import UserAvatarView from "./UserAvatarView";

export default ({ user, size = 40 }) => {
  const avatar = useContext(AvatarContext);
  const currentUser = useContext(CurrentUserContext);
  const { theme, styles } = useStyles({ Styles: UserAvatarStyles });

  return (
    <UserAvatarView
      user={user}
      theme={theme}
      styles={styles}
      size={size}
      uri={
        currentUser?.id !== user?.id
          ? user?.profilePicUri
          : avatar?.localUri ?? user?.profilePicUri
      }
    />
  );
};
