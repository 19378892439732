import * as React from "react";
import { useEffect, useContext, useCallback } from "react";
import { Button, useTheme, TextInput, HelperText } from "react-native-paper";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RegistrationContext } from "Contexts/Registration";
import { View, Platform } from "react-native";
import { TextInputMask } from "react-native-masked-text";
import useGate from "Hooks/useGate";
import ScreenBottom from "Components/Screen/Bottom";
import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
// const scrollEnabled = Platform.select({ web: true, default: false });

const schema = yup.object().shape({
  mobileNumber: yup
    .string()
    .matches(
      /^\+1 \(\d\d\d\) \d\d\d\-\d\d\d\d$/i,
      "Must be a valid US phone number"
    )
    .required("Can't be blank"),
});

const Form = ({ style, theme, navigation }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const inputRef = React.useRef(null);
  const { setRegistrationValues, registrationValues } =
    useContext(RegistrationContext);

  const onSubmit = useCallback(
    ({ mobileNumber }) => [
      setRegistrationValues(registrationValues => ({
        ...registrationValues,
        mobileNumber,
      })),
      navigation.push("RegistrationStep4"),
    ],
    [navigation, setRegistrationValues]
  );

  const handleInputAutofocus = useCallback(() => {
    const timeout = setTimeout(() => {
      inputRef.current._inputElement.focus();
    }, 10);

    return () => clearTimeout(timeout);
  }, []);

  const doSubmit = handleSubmit(onSubmit);

  useEffect(() => {
    handleInputAutofocus();
  }, [handleInputAutofocus]);

  return (
    <>
      <View
        style={[
          {
            flex: 1,
          },
          style ?? {},
        ]}
      >
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize="none"
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "tel"
                }
                keyboardType="phone-pad"
                textContentType="telephoneNumber"
                label="Mobile Number"
                error={!!errors.mobileNumber}
                returnKeyLabel="Done"
                returnKeyType="done"
                onSubmitEditing={doSubmit}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
                render={props => (
                  <TextInputMask
                    {...props}
                    ref={inputRef}
                    type="custom"
                    options={{
                      mask: "+1 (999) 999-9999",
                    }}
                  />
                )}
              />
              <HelperText type="error" visible={!!errors.mobileNumber}>
                {errors.mobileNumber?.message}
              </HelperText>
            </>
          )}
          name="mobileNumber"
          defaultValue={registrationValues.mobileNumber}
        />
      </View>
      <ScreenBottom adjustPadding>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          Continue
        </Button>
      </ScreenBottom>
    </>
  );
};

export default ({ navigation }) => {
  const theme = useTheme();

  useGate({ gateType: "guest", to: "Main" });

  useEffect(() => {
    navigation.setOptions({ title: "Register" });
  }, [navigation]);

  return (
    <Screen
      gatedTo="guest"
      title="Register"
      hasKeyboard
      backgroundColor={theme.colors.surface}
    >
      <Stepper
        theme={theme}
        currentPosition={2}
        title="Enter Your Mobile Number"
        labels={["", "", "", ""]}
      />
      <Form
        style={{ marginTop: theme.spacing.xl }}
        theme={theme}
        navigation={navigation}
      />
    </Screen>
  );
};
