//CORE

import { useQuery } from "@apollo/client";
import { withoutKeys } from "Util/";
import React, { useCallback, useEffect, useState } from "react";
import { ActivityIndicator, FlatList, Platform, View } from "react-native";

// import { Platform } from 'react-native';
// import Listing from "shared/api/Listing"
// import { useMutation } from '@apollo/client';

// const CreateNewButton = ({}) => {
//   const [createListing] = useMutation(Listing.mutations.create, {
//     variables: {
//       input: {
//         askingPrice: 1000,
//         postedFrom: Platform.OS.toUpperCase(),
//         listingTicketId: "69e0307a-9daf-49bd-ae25-6c1074a82b6d"
//       }
//     }
//   });

//   return (
//     <Button
//       title={"Create a Listing"}
//       onPress={createListing}
//     />
//   )
// }

// const LoadMoreButton = ({ subscribeToNew, hasNewItems, onPress }) => {
//   useEffect(() => {
//     subscribeToNew();
//   }, []);

//   return !hasNewItems ? null : (
//     <Button title={!!hasNewItems ? "New Items" : "Nothing"} onPress={onPress} />
//   );
// };

const defaultKeyExtractor = (item, index) => item.id ?? index.toString();
const defaultFilter = item => true;
const defaultOnFetchItems = items => true;
const defaultSort = undefined;

// createSubscription = {
//   dataKey: "onCreateListing"
// }

export default React.forwardRef(
  (
    {
      onFetchItems = defaultOnFetchItems,
      query,
      createSubscription,
      renderItem,
      keyExtractor = defaultKeyExtractor,
      filterItems = defaultFilter,
      sortItems = defaultSort,
      fetchPolicy, // = "cache-first",
      counter = 0, //increment this in the parent container to indicate you're loading a new query and you won't get the load new items flag
      cellsToAppend = 0,
      noPagination,
      dataListRef,
      ...rest
    },
    ref
  ) => {
    const {
      loading,
      data,
      error,
      // variables,
      fetchMore,
      refetch,
      // subscribeToMore,
    } = useQuery(query.query, {
      fetchPolicy,
      pollInterval: 5000,
      ...withoutKeys(query, ["dataKey", "query"]),
      notifyOnNetworkStatusChange: true,
    });

    const [moreToken, setMoreToken] = useState(false);
    const [firstItemId, setFirstItemId] = useState(null);
    const [lastCounter, setLastCounter] = useState(0);
    const newFirstItemId = data?.[query.dataKey]?.items?.[0]?.id;
    const items = data?.[query.dataKey]?.items;
    const nextToken =
      moreToken === false ? data?.[query.dataKey]?.nextToken : moreToken;

    // const handleLoadNewPress = useCallback(
    //   () => [
    //     refContainer.current.scrollToIndex({ animated: true, index: 0 }),
    //     setHasNewItems(false),
    //   ],
    //   [refContainer]
    // );

    const handleNewItem = useCallback(
      ({ firstItemId, newFirstItemId, counter, lastCounter }) => [
        !!newFirstItemId &&
          newFirstItemId !== firstItemId &&
          setFirstItemId(newFirstItemId),
        !!newFirstItemId &&
          !!firstItemId &&
          newFirstItemId !== firstItemId &&
          counter === lastCounter,
        !!newFirstItemId &&
          newFirstItemId !== firstItemId &&
          setLastCounter(counter),
      ],
      []
    );

    const handleEndReached = useCallback(() => {
      !!nextToken &&
        !loading &&
        !noPagination &&
        fetchMore({ variables: { nextToken } }).then(({ data }) =>
          setMoreToken(data[query.dataKey]?.nextToken)
        );
    }, [nextToken, fetchMore, loading, noPagination, query.dataKey]);

    // const createSubscriptionDataKey = createSubscription?.dataKey;

    // const handleSubscribeToNew = useCallback(
    //   () =>
    //     !!createSubscription &&
    //     subscribeToMore({
    //       document: createSubscription.subscription,
    //       variables: createSubscription.variables,
    //       updateQuery: (prev, { subscriptionData }) => {
    //         if (!subscriptionData.data) return prev;
    //         const newFeedItem =
    //           subscriptionData.data[createSubscription.dataKey];
    //         return {
    //           ...prev,
    //           [query.dataKey]: {
    //             ...prev[query.dataKey],
    //             items: [newFeedItem, ...prev[query.dataKey].items],
    //           },
    //         };
    //       },
    //     }),
    //   [createSubscriptionDataKey, query.dataKey]
    // );

    !!error && console.error(query.dataKey, error);

    if (dataListRef) {
      dataListRef.current = { refetch };
    }

    useEffect(() => {
      handleNewItem({ firstItemId, newFirstItemId, counter, lastCounter });
    }, [newFirstItemId, firstItemId, counter, lastCounter, handleNewItem]);

    useEffect(() => {
      onFetchItems(items);
    }, [items, onFetchItems]);

    return (
      <View
        style={{
          ...Platform.select({
            web: {
              flex: 1,
            },
            default: { flex: rest?.horizontal ? undefined : 1 },
          }),
          // borderColor: "green", borderStyle: "dashed", borderWidth: 2
          // height: 601, //not sure why this is needed. Sticky header doesn't work without in on web
        }}
      >
        {!!data?.[query.dataKey]?.items && (
          <FlatList
            // scrollEnabled={false}
            ref={ref}
            data={[
              ...Array(cellsToAppend).keys(),
              ...(data?.[query.dataKey]?.items ?? [])
                .filter(filterItems)
                .slice()
                .sort(sortItems),
            ]}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            onEndReached={handleEndReached}
            onEndReachedThreshold={0.5}
            // stickyHeaderIndices={[0]}
            contentContainerStyle={{
              // flex: 1,
              ...(rest.contentContainerStyle ? rest.contentContainerStyle : {}),
            }}
            // style={{flex: 1}}
            // ListHeaderComponent={
            //   <View>
            //     <LoadMoreButton
            //       onPress={handleLoadNewPress}
            //       hasNewItems={hasNewItems}
            //       subscribeToNew={handleSubscribeToNew}
            //     />
            //   </View>
            // }
            ListFooterComponent={
              !!loading && (
                <View
                  style={{
                    position: "absolute",
                    zIndex: 90,
                    bottom: 20,
                    alignItems: "center",
                    width: "99%",
                  }}
                >
                  <ActivityIndicator size="large" />
                </View>
              )
            }
            {...rest}
          />
        )}

        {/* <Button onPress={() => fetchMore({variables: {nextToken: data?.searchListings?.nextToken}})} title="Load More" /> */}
        {/* <Button onPress={() => refetch()} title="Refetch" /> */}
        {/* <View style={{height: 100}}></View> */}
        {/* <CreateNewButton /> */}
      </View>
    );
  }
);
