import useStyles from "Hooks/useStyles";
import FaqStyles from "./FaqStyles";
import FaqView from "./FaqView";
import { ALLOWED_STATES } from "shared/Config";

const items = [
  {
    header: "General FAQ",
    items: [
      {
        title: "Is this legal?",
        items: [
          "Yes, it’s legal. Once your bet is made at a licensed sportsbook, it’s your property, and you may do as you please with it, including sell it! But of course, we take gaming regulation very seriously here at PropSwap. All inquiries made by gaming regulators will be addressed with full attention.",
        ],
      },
      {
        title: "Where do all of these tickets come from?",
        items: [
          "These tickets all originated at licensed sportsbooks throughout the country. To ensure the Seller follows through with delivering the ticket, we place a Hold on his/her credit card. Should they not deliver the ticket on-time, we have the full authority to charge the Seller a penalty fee.",
        ],
      },
      {
        title: "Why wouldn’t I just hedge my bet, instead of selling it?",
        items: [
          "1st reason – Everyone knows, hedging involves spending more money on a new bet! We want you to keep your money in your pocket. Let’s do a simple example: You bet on Texas Tech before March Madness starts, at 200:1. You wager $100 collect $20,100. The Championship game comes and Tech is set to square off vs. Virginia! UVA is a -150 Favorite.",
          "With hedging, you’d need to bet $14,000 on UVA Moneyline, to guarantee yourself at least $9,000 in profit (what your Tech ticket would currently sell for.) Do you have $14,000 in your bank account to make that bet? Not many people do!",
          "2nd reason – Deciding when to hedge, is very hard. If you own the Cowboys at 50/1 to win the NFL Championship, and you want to hedge before the Wild Card Round, you’d need to bet their opponent in the Wild Card, and then the Divisional Round, and then the Conference round…… still with me? By the time they make the Championship, you’ve got no profit left!",
          "Moral of the story: Sell your bet on PropSwap, do less math, and spend less money!",
        ],
      },
      {
        title:
          "How secure is the mail service? I'm worried about my tickets getting lost in the mail.",
        items: [
          "PropSwap has sold over 50,000 tickets. Not one ticket has ever been lost in the mail! The USPS, UPS, and FedEx are extremely reliable. We know from experience!",
          "However, before sending any ticket in, please retain a Photo of each ticket for your own records. If for any reason, the physical ticket is not received by PropSwap, we will open a Case with the issuing Sportsbook, to come to a resolution. We will IMMEDIATELY contact the issuing Sportsbook, so that they freeze the betting ticket, and the misplaced ticket will become unredeemable.",
        ],
      },
      {
        title: "Does PropSwap disclose which State/Casino each ticket is from?",
        items: [
          "We do not. For security purposes, this information is kept private. Every ticket listed on PropSwap is able to be cashed via Mail Pay, if you are unable to cash it in person at the sportsbook.",
        ],
      },
    ],
  },
  {
    header: "Selling",
    items: [
      {
        title: "Once my ticket sells, how do I get paid?",
        items: [
          "You will be notified by PropSwap that your ticket has been sold. Those proceeds (net our 10% commission) will be deposited into your PropSwap Balance. Once we receive your ticket, you can request a withdrawal via Check or ACH.",
        ],
      },
      {
        title: "Once my ticket sells, where do I mail my ticket to?",
        items: [
          "When your ticket sells, you will receive an email confirmation letting you know it has been sold. Depending on where your ticket was originated, your email confirmation will have a corresponding address for where to send it. If you did not receive an address on where to send in your ticket, please contact PropSwap support immediately at info@propswap.com.",
        ],
      },
      {
        title: "Are there any bets you won't accept?",
        items: [
          "Yes. We do not accept ANY bets made on an offshore account, or made with your local bookie. Only bets made at a licensed U.S. sportsbook, will be accepted.",
          "PropSwap reserves the right to decline any ticket that is presented to us for Sale. We’ll do our best to accommodate you as the Seller, but try to keep your tickets presentable, and legible!",
          "Other examples of bets we WON’T accept are:",
          "1. Any “field” bet. Say you make a bet at South Point Casino, taking the “Field” to win the College Football Championship Game. The “Field” may differ from South Point vs. the Westgate, and we are not (yet) in the business of figuring out which casino offers which Field!",
          "2. A crumbled up ticket that is illegible.",
          "3. Tickets from Nevada and Illinois sportsbooks are not eligible to be sold.",
        ],
      },
      {
        title: "Am I required to set a Sale Price for ticket I list for sale?",
        items: [
          "Yes, all tickets listed for sale must have a Sale Price associated with it. This Sale Price acts as a “Buy It Now” price for the Buyer. Meaning, if a Buyer decides to pay the listed Sale Price, then the sale is finalized and the ticket belongs to the Buyer. However, Buyers may submit a Bid for an amount less than the Sale Price, in which case the Seller has the option to Accept, Reject or Counter that Bid.",
        ],
      },
      {
        title:
          "Am I able to change my Sale Price or Unlist my ticket once it has been listed for sale?",
        items: [
          "Yes, as long as the ticket has not sold yet, you may change the Sale Price as many times as you would like and/or Unlist your ticket at any time.",
        ],
      },
      {
        title:
          "Can I set a Minimum Bid that Buyers must submit in order to Bid on my ticket?",
        items: [
          "Yes, during the Selling process, or any time after that, you may enter a “Minimum Bid” amount, which means no Buyers can submit a Bid for less than that amount.",
        ],
      },
      {
        title: "Can I set an Expiration Date when I list my ticket for sale?",
        items: [
          "Yes, during the Selling process, or any time after that, you may set a Date & Time for your listing to expire. If your ticket has not sold by this time, then it will automatically be unlisted from the PropSwap marketplace. You are able to change and/or remove this Expiration at any time.",
        ],
      },
      {
        title:
          "Am I able to load a ticket into my PropSwap account without listing it for sale?",
        items: [
          "Yes, during the Sell process, you can choose the “List Later” option. This allows you to create the ticket in your account, but you do not need to set a Sale Price or list it for sale. If/When you choose to list it for sale, you can do so by going to your My Tickets page.",
        ],
      },
    ],
  },
  {
    header: "Buying",
    items: [
      {
        title: "Which states does PropSwap allow Buyers to be located in?",
        items: [
          `Customers must be located in one of these ${
            ALLOWED_STATES.filter(state => state !== "Massachusetts").length
          } states in order to purchase tickets: ${ALLOWED_STATES.filter(
            state => state !== "Massachusetts"
          ).join(", ")}`,
          "Please note you will be delivered the hard ticket to the address you’ve provided. It is your responsibility to redeem the ticket, either in-person at the issuing sportsbook, or by mailing the ticket in. Mail-in directions can be found on the back of the ticket.",
        ],
      },
      {
        title: "Do I need to deposit in order to submit a Bid?",
        items: [
          "Yes, Buyers must have sufficient funds in their account in order to submit a Bid. But not to worry! If you make a deposit and then your Bid is declined, you are able to initiate a refund back to your card, including all fees. Just go to your Transactions page and click the entry associated with the deposit. Please note: refunds are only available for 48 hours after the deposit has been made.",
        ],
      },
      {
        title: "How long does my Bid stay active for?",
        items: [
          "All Bids stay active until either the Seller responds or the Buyer cancels it. You can cancel any active Bid by going to your My Tickets page, click the “My Bids” tab and then click on the ticket you wish to cancel.",
        ],
      },
      {
        title:
          "If I buy a ticket on PropSwap, can I resell it at a later time?",
        items: [
          "Yes, all tickets purchased on PropSwap can be resold at any time. Just go to your My Tickets page, click on the “Not For Sale” tab and then click on the ticket you wish to list for sale. You will then need to input your desired Sale Price. And because you are not the original seller and there is nothing to deliver, when the ticket sells the funds are immediately available in your account!",
        ],
      },
      {
        title:
          "I'm worried about buying a counterfeit ticket. Is PropSwap taking measures to combat counterfeiting?",
        items: [
          "Yes. Any time a Ticket is listed for Sale, we place a Hold on the Seller’s credit card, until the ticket has been delivered, and verified. Once the ticket has been verified for authenticity, the Hold will be removed.",
        ],
      },
      {
        title: "When do I receive the ticket I’ve purchased?",
        items: [
          "Once a ticket wins, it will be sent to the final Buyer. When you purchase a ticket on PropSwap and the Event is still undecided, PropSwap maintains possession of the ticket. Once the Event has been decided and the ticket is a winner, then it will be mailed to you.",
        ],
      },
      {
        title: "What is PropSwap's Refund Policy?",
        items: [
          "All sales are final. The only time a refund is issued to a buyer, is if the seller fails to send the ticket to PropSwap within the mandatory 7 day timeframe. In these cases, the buyer is given a full 100% refund of the purchase price, regardless of the result of the event.",
        ],
      },
    ],
  },
  {
    header: "Fees & Withdrawals",
    items: [
      {
        title: "Is there a fee to withdraw my funds from PropSwap?",
        items: ["Nope! You can withdraw as many times as you’d like!"],
      },
      {
        title: "Why can't PropSwap cash my winning ticket for me?",
        items: [
          "Some casinos prefer that the final Buyer redeem the ticket. So for now, we send all tickets to the winning Buyer for redemption.",
        ],
      },
      {
        title: "How much is PropSwap's fee?",
        items: [
          "PropSwap’s commission fee is 10% of the Sale Price. Say a Seller would like to net $90 for his ticket. He should list the ticket for $100.",
          "The deposit fee is 3% on new money into PropSwap. We encourage Sellers to leave their sale proceeds with us, to avoid paying the 3% fee on new money deposited into PropSwap.",
        ],
      },
    ],
  },
];

export default ({}) => {
  const { theme, styles } = useStyles({ Styles: FaqStyles });

  return <FaqView theme={theme} styles={styles} items={items} />;
};
