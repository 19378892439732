export default theme => ({
  outerWrapper: {
    // marginHorizontal: theme.spacing.xl * -1,
    backgroundColor: theme.colors.surface,
  },
  wrapper: {
    paddingHorizontal: theme.spacing.xl,
    paddingVertical: theme.spacing.sm,
    justifyContent: "center",
  },
  innerWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: 300,
  },
  label: {
    ...theme.fonts.caption,
    marginVertical: theme.spacing.xs,
  },
});
