import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path d="M0 0h20v20H0z" />
        <Path
          fill={props?.color ?? "#666"}
          d="M16.702 14.834l1.38-1.49v.076c0 .48-.496.98-1.38 1.414zM1.96 13.986l3.378-3.646c.193.042.394.081.599.118L2.33 14.352a2.14 2.14 0 01-.37-.366zm-.22-1.623L3.962 9.96c.176.06.359.117.55.172l-2.774 2.995v-.765zm0-2.96v-.634c.1.091.213.181.336.27l-.337.363zm0 1.097l.984-1.063c.153.08.317.16.49.235L1.74 11.265V10.5zm8.17-5.746c4.817 0 8.173 1.39 8.173 2.637 0 1.248-3.356 2.638-8.172 2.638-4.816 0-8.172-1.39-8.172-2.638 0-1.247 3.356-2.637 8.172-2.637zm6.22 5.11c.481-.179.9-.377 1.255-.59l-6.255 6.753c-.24.012-.484.021-.732.026l5.732-6.189zm-2.282.601c.306-.054.598-.115.876-.18l-5.342 5.767a25.128 25.128 0 01-.686-.025l5.152-5.562zm-1.95.243c.267-.02.525-.046.777-.074L7.742 15.96a23.414 23.414 0 01-.643-.071l4.799-5.18zm-1.826.073c.257-.001.509-.006.755-.014l-4.622 4.99a15.805 15.805 0 01-.604-.117l4.471-4.859zm-1.655-.04c.213.012.431.021.653.028l-4.298 4.67a12.273 12.273 0 01-.554-.165l4.199-4.533zm-.95-.073l-4.004 4.324a6.892 6.892 0 01-.492-.234l3.856-4.163c.208.027.421.052.64.073zm10.616-.285L13.02 15.85c-.258.037-.524.069-.797.096l5.86-6.327v.765zm0 1.863l-2.932 3.165c-.288.082-.594.158-.918.227l3.85-4.157v.765zM9.91 4C4.123 4 1 5.747 1 7.391v6.03c0 1.643 3.123 3.39 8.91 3.39 5.79 0 8.912-1.747 8.912-3.39V7.39C18.822 5.747 15.699 4 9.91 4z"
        />
        <Path
          fill={props?.color ?? "#666"}
          d="M16.702 14.834l1.38-1.49v.076c0 .48-.496.98-1.38 1.414zM1.96 13.986l3.378-3.646c.193.042.394.081.599.118L2.33 14.352a2.14 2.14 0 01-.37-.366zm-.22-1.623L3.962 9.96c.176.06.359.117.55.172l-2.774 2.995v-.765zm0-2.96v-.634c.1.091.213.181.336.27l-.337.363zm0 1.097l.984-1.063c.153.08.317.16.49.235L1.74 11.265V10.5zm8.17-5.746c4.817 0 8.173 1.39 8.173 2.637 0 1.248-3.356 2.638-8.172 2.638-4.816 0-8.172-1.39-8.172-2.638 0-1.247 3.356-2.637 8.172-2.637zm6.22 5.11c.481-.179.9-.377 1.255-.59l-6.255 6.753c-.24.012-.484.021-.732.026l5.732-6.189zm-2.282.601c.306-.054.598-.115.876-.18l-5.342 5.767a25.128 25.128 0 01-.686-.025l5.152-5.562zm-1.95.243c.267-.02.525-.046.777-.074L7.742 15.96a23.414 23.414 0 01-.643-.071l4.799-5.18zm-1.826.073c.257-.001.509-.006.755-.014l-4.622 4.99a15.805 15.805 0 01-.604-.117l4.471-4.859zm-1.655-.04c.213.012.431.021.653.028l-4.298 4.67a12.273 12.273 0 01-.554-.165l4.199-4.533zm-.95-.073l-4.004 4.324a6.892 6.892 0 01-.492-.234l3.856-4.163c.208.027.421.052.64.073zm10.616-.285L13.02 15.85c-.258.037-.524.069-.797.096l5.86-6.327v.765zm0 1.863l-2.932 3.165c-.288.082-.594.158-.918.227l3.85-4.157v.765zM9.91 4C4.123 4 1 5.747 1 7.391v6.03c0 1.643 3.123 3.39 8.91 3.39 5.79 0 8.912-1.747 8.912-3.39V7.39C18.822 5.747 15.699 4 9.91 4z"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
