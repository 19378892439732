import React from "react";
import { ScrollView, View } from "react-native";
import { Button, Headline, useTheme } from "react-native-paper";

import Screen from "Components/Screen";

import YoutubePlayer from "react-native-youtube-iframe";
import Section from "Components/Section";
import TextListItem from "Components/TextListItem";

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen fullHeight>
      <ScrollView>
        <View style={{ flex: 1, paddingTop: theme.spacing.lg }}>
          <Headline>Selling Is Easy On PropSwap</Headline>

          <Section skipDivider theme={theme} heading="Step 1">
            <TextListItem theme={theme}>
              Create your account, if you haven’t done so already Create your
              account, if you haven’t done so already
            </TextListItem>
          </Section>

          <Section skipDivider theme={theme} heading="Step 2">
            <TextListItem theme={theme}>
              Create your account, if you haven’t done so already Create your
              account, if you haven’t done so already
            </TextListItem>
            <TextListItem theme={theme}>
              Create your account, if you haven’t done so already Create your
              account, if you haven’t done so already
            </TextListItem>
            <TextListItem theme={theme}>
              Create your account, if you haven’t done so already Create your
              account, if you haven’t done so already
            </TextListItem>
          </Section>

          <Section skipDivider theme={theme} heading="Step 3">
            <TextListItem theme={theme}>
              Create your account, if you haven’t done so already Create your
              account, if you haven’t done so already
            </TextListItem>
            <TextListItem theme={theme}>
              Create your account, if you haven’t done so already Create your
              account, if you haven’t done so already
            </TextListItem>
            <TextListItem theme={theme}>
              Create your account, if you haven’t done so already Create your
              account, if you haven’t done so already
            </TextListItem>
          </Section>

          <View
            style={{
              marginVertical: theme.spacing.xl,
              marginBottom: theme.spacing.xl * 2,
            }}
          >
            <YoutubePlayer
              height={177}
              play={true}
              width={314}
              videoId={"speGSaNAiOo"}
            />
          </View>
        </View>
      </ScrollView>
      <View
        style={{
          flex: 1,
          justifyContent: "flex-end",
          marginBottom: theme.spacing.xl,
        }}
      >
        <Button
          mode="contained"
          onPress={() => navigation.navigate("MyBids")}
          style={{ width: "100%" }}
        >
          Close
        </Button>
      </View>
    </Screen>
  );
};
