import Screen from "Components/Screen";
import useStyles from "Hooks/useStyles";
import PhotoUploadStyles from "Components/PhotoUpload/PhotoUploadStyles";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Divider, List, Button } from "react-native-paper";
import React, { useLayoutEffect } from "react";
import { View } from "react-native";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import { HeaderButtons, Item } from "react-navigation-header-buttons";
import openUrl from "Util/openUrl";
import moment from "moment";

export const listSharpsportsBettorAccounts = gql`
  query ListSharpsportsBettorAccounts {
    listSharpsportsBettorAccounts {
      id
      latestRefreshRequestId
      latestRefreshResponse {
        detail
        id
        requestId
        status
        timeCreated
        type
      }
      bettor
      book {
        id
        name
        abbr
      }
      bookRegion {
        id
        name
        abbr
        status
        country
        sdkRequired
        mobileOnly
      }
    }
  }
`;

const getSharpsportsContext = gql`
  query TicketNewGetSharpsportsContext($redirectUrl: String!) {
    getSharpsportsContext(redirectUrl: $redirectUrl) {
      cid
    }
  }
`;

export default ({ navigation }) => {
  const { theme } = useStyles({ Styles: PhotoUploadStyles });

  const { data } = useQuery(listSharpsportsBettorAccounts);

  const { data: contextData } = useQuery(getSharpsportsContext, {
    variables: {
      redirectUrl: `${
        window
          ? window.location.href
          : "https://propswapstaging.com/account/sharpsports"
      }`,
    },
  });
  const cid = contextData?.getSharpsportsContext?.cid;

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons size={48}>
          <Item
            title="Add Account"
            IconComponent={MaterialCommunityIcons}
            iconSize={24}
            iconName="plus"
            onPress={() => openUrl(`https://ui.sharpsports.io/link/${cid}`)}
          />
        </HeaderButtons>
      ),
    });
  }, [cid, navigation]);

  return (
    <Screen
      gatedTo="member"
      title="Choose Account"
      hasKeyboard={false}
      backgroundColor={theme.colors.surface}
      fullWidth
      fullHeight
    >
      <View style={{ flex: 1 }}>
        {(data?.listSharpsportsBettorAccounts ?? []).map(account => (
          <React.Fragment key={account.id}>
            <List.Item
              onPress={() =>
                navigation.push("TicketNewBetSyncAccount", {
                  accountName: `${account.book.name} (${account.bookRegion.name})`,
                  accountId: account.id,
                  bookRegionId: account.bookRegion.id,
                })
              }
              title={`${account.book.name} (${account.bookRegion.name})`}
              description={
                !account?.latestRefreshResponse?.timeCreated
                  ? " "
                  : account?.latestRefreshResponse?.status === 200
                  ? `Last Refreshed: ${moment(
                      account?.latestRefreshResponse?.timeCreated
                    ).format("MM/DD/YYYY hh:mm A")}`
                  : `An error occurred during the last refresh. Please try again.`
              }
              right={props => <List.Icon {...props} icon="chevron-right" />}
            />
            <Divider />
          </React.Fragment>
        ))}
      </View>
      <View style={{ marginHorizontal: 16, marginBottom: 16 }}>
        <Button
          mode="contained"
          onPress={() => openUrl(`https://ui.sharpsports.io/link/${cid}`)}
          style={{ width: "100%" }}
        >
          Manage Accounts
        </Button>
      </View>
    </Screen>
  );
};
