import { useContext, useCallback, useState } from "react";
import {
  Divider,
  List,
  useTheme,
  Button,
  Text,
  Headline,
  Checkbox,
} from "react-native-paper";
import BottomSheet from "Components/BottomSheet";
import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import { TicketContext } from "Contexts/Ticket";
import { useMutation } from "@apollo/client";
import formatOdds from "Util/formatOdds";
import {
  addCommas,
  convertMoneyTextToPennies,
  formatMoney,
} from "shared/Util/money";
import ScreenBottom from "Components/Screen/Bottom";
import { Platform, ScrollView, View } from "react-native";
import { commissionWaiverRedemption } from "shared/Config";
import Ticket from "shared/api/Ticket";
import Bet from "shared/api/Bet";
import Listing from "shared/api/Listing";
import moment from "moment";
import Confirmation from "Components/Confirmation";
import { CurrentUserContext } from "Contexts/CurrentUser";
import useStyles from "Hooks/useStyles";
import openUrl from "Util/openUrl";
import environment from "environment";
import calculatePayout from "shared/Util/calculatePayout";
import requestReview from "Util/requestReview";

export default ({ navigation }) => {
  const theme = useTheme();
  const { ticketValues, resetTicket, setTicketValues } =
    useContext(TicketContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmittError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const currentUser = useContext(CurrentUserContext);
  const [sellerAgreementChecked, setSellerAgreementChecked] = useState(false);
  const [mailAgreementChecked, setMailAgreementChecked] = useState(false);
  const firstEventId =
    ticketValues.ticketEventId ?? ticketValues?.cachedBets?.[0]?.eventId;
  const firstOptionId =
    ticketValues.ticketOptionId ?? ticketValues?.cachedBets?.[0]?.optionId;

  const [createBet] = useMutation(Bet.mutations.create);

  const listingParams = useCallback(
    ticket => ({
      refetchQueries: [
        {
          query: Listing.queries.searchRaw,
          variables: {
            limit: 20,
            sort: {
              direction: "desc",
              field: "valueRating",
            },
            sort2: {
              direction: "desc",
              field: "cachedTicket.views",
            },
            filter:
              '{"and":[{"or":[{"cachedTicket.providerId":{"exists":false}},{"cachedTicket.localeId":{"eq":"<>"}},{"cachedTicket.allowAcrossLocales":{"eq":true}}]},{"status":{"eq":"ACTIVE"}}]}',
          },
        },
      ],
      variables: {
        input: {
          listingTicketId: ticket?.id,
          expiresAt:
            !!ticketValues.expiresAt &&
            moment(ticketValues.expiresAt, "MM/DD/YYYY hh:mm a", true).isValid()
              ? moment(ticketValues.expiresAt, "MM/DD/YYYY hh:mm a", true)
                  .toDate()
                  .toISOString()
              : moment(ticketValues.expiresAt, true).isValid()
              ? moment(ticketValues.expiresAt, true).toDate().toISOString()
              : undefined,
          askingPrice: convertMoneyTextToPennies(ticketValues.askingPrice),
          minimumBidPrice: convertMoneyTextToPennies(
            ticketValues.minimumBidPrice
          ),
          notes: ticketValues.notes || undefined,
          postedFrom: Platform.OS.toUpperCase(),
          commissionWaiverApplied: ticketValues.commissionWaiverApplied,
        },
      },
    }),
    [ticketValues]
  );

  const [createTicket] = useMutation(Ticket.mutations.create, {
    variables: {
      input: {
        collectAmount: 0,
        ticketOriginalOwnerId: currentUser?.id,
        sharpSportsBetSlipId: ticketValues.sharpSportsBetSlipId ?? undefined,
        odds: formatOdds(
          convertMoneyTextToPennies(ticketValues.betAmount) +
            convertMoneyTextToPennies(ticketValues.winAmount),
          convertMoneyTextToPennies(ticketValues.betAmount)
        ),
        oddsCalculated:
          (convertMoneyTextToPennies(ticketValues.betAmount) +
            convertMoneyTextToPennies(ticketValues.winAmount)) /
          convertMoneyTextToPennies(ticketValues.betAmount),

        photoUrl: ticketValues.photoUrl,
        betType: ticketValues.betType,
        eventDescription: ticketValues.eventDescription.trim(),
        subject: ticketValues.subject?.trim?.(),
        winAmount: convertMoneyTextToPennies(ticketValues.winAmount),
        betAmount: convertMoneyTextToPennies(ticketValues.betAmount),
        ticketSportId: ticketValues.ticketSportId,
        ticketLocaleId: ticketValues.ticketLocaleId,

        additionalInfo: ticketValues.additionalInfo?.trim?.(),
        isMobile: !!ticketValues.isMobile,
        ticketEventId: firstEventId ?? undefined,
        ticketOptionId: firstOptionId ?? undefined,
        ticketSportsbookId: ticketValues.ticketSportsbookId ?? undefined,
      },
    },
  });

  const [createNewListing2] = useMutation(
    Listing.mutations.create2,
    listingParams({})
  );

  const createBets = useCallback(
    listing =>
      !!ticketValues.parlay
        ? Promise.all(
            Object.values(ticketValues.parlay).map(bet =>
              createBet({
                variables: {
                  input: {
                    ticketId: listing.listingTicketId,
                    eventDescription: bet.eventDescription.trim(),
                    subject: bet.subject?.trim?.() ?? ticketValues.subject,
                    eventId:
                      bet.ticketEventId ??
                      bet.eventId ??
                      firstEventId ??
                      undefined,
                    optionId:
                      bet.ticketOptionId ??
                      bet.optionId ??
                      firstOptionId ??
                      undefined,
                    sportId: bet.ticketSportId ?? bet.sportId ?? undefined,
                    sharpSportsBetId: bet.sharpSportsBetId ?? undefined,
                    sharpSportsEventId: bet.sharpSportsEventId ?? null,
                  },
                },
              })
            )
          )
        : createBet({
            variables: {
              input: {
                ticketId: listing.listingTicketId,
                eventDescription: ticketValues.eventDescription,
                subject: ticketValues.subject,
                eventId: firstEventId ?? undefined,
                optionId: firstOptionId ?? undefined,
                sportId: ticketValues.ticketSportId ?? undefined,
              },
            },
          }),

    [
      ticketValues.parlay,
      ticketValues.eventDescription,
      ticketValues.subject,
      ticketValues.ticketSportId,
      createBet,
      firstEventId,
      firstOptionId,
    ]
  );

  // const shouldRequestReview = !!currentUser && !currentUser?.promptedToRate;
  // const [setPromptedToRate] = useMutation(User.mutations.update, {
  //   variables: {
  //     input: {
  //       id: currentUser?.id,
  //       promptedToRate: true
  //     }
  //   }
  // })

  const doSubmit = useCallback(
    () =>
      Promise.resolve(setIsSubmitting(true))
        .then(createTicket)
        .then(({ data: { createTicket } }) => createTicket)
        .then(listingParams)
        .then(createNewListing2)
        .then(({ data: { createNewListing2 } }) => createNewListing2)
        .then(createBets)
        .then(() => requestReview())
        // .then(setPromptedToRate)
        .then(() => setSubmitted(true))
        .catch(setSubmittError)
        .finally(() => setIsSubmitting(false)),
    [createTicket, listingParams, createNewListing2, createBets]
  );

  const handleWaiverToggle = useCallback(
    () =>
      setTicketValues(ticketValues => ({
        ...ticketValues,
        commissionWaiverApplied: !ticketValues.commissionWaiverApplied,
      })),
    [setTicketValues]
  );

  const navigateTo = useCallback(
    screen => () => navigation.push(screen),
    [navigation]
  );

  const errors = [
    !!ticketValues.askingPrice &&
    !!ticketValues.minimumBidPrice &&
    convertMoneyTextToPennies(ticketValues.askingPrice || "0") <=
      convertMoneyTextToPennies(ticketValues.minimumBidPrice || "0")
      ? "The Minimum Bid must be less than the Sale Price"
      : null,
    !!ticketValues.askingPrice &&
    !!ticketValues.winAmount &&
    convertMoneyTextToPennies(ticketValues.askingPrice || "0") >=
      convertMoneyTextToPennies(ticketValues.winAmount || "0") +
        convertMoneyTextToPennies(ticketValues.betAmount || "0")
      ? "Sale Price must be less than Collect Amount"
      : null,
  ].filter(item => !!item);

  const listingIsValid =
    !ticketValues.listNow || (!!ticketValues.askingPrice && !errors.length);

  const isValid =
    !!ticketValues.ticketLocaleId &&
    !!ticketValues.ticketSportId &&
    !!ticketValues.betType &&
    !!ticketValues.betAmount &&
    !!ticketValues.winAmount &&
    !!ticketValues.eventDescription &&
    !!ticketValues.subject;

  const handleGoToMyTickets = useCallback(
    () => [
      resetTicket(),
      setSubmitted(false),
      navigation.push("Main", {
        screen: "MyTickets",
        params: {
          screen: "ForSaleModalStack",
          params: { screen: "ForSale", params: { reload: true } },
        },
      }),
    ],
    [navigation, resetTicket]
  );

  const handleCreateNewTicket = useCallback(
    () => [
      resetTicket(),
      setSubmitted(false),
      navigation.navigate("TicketNewStep1"),
    ],
    [navigation, resetTicket]
  );

  const handleCreateSimilarTicket = useCallback(
    () => [
      resetTicket(true),
      setSubmitted(false),
      navigation.navigate("TicketNewStep2"),
    ],
    [navigation, resetTicket]
  );

  const { styles } = useStyles();

  return (
    <Screen
      gatedTo="member"
      title="Sell Ticket"
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      <Stepper
        theme={theme}
        currentPosition={3}
        title="Pricing Info"
        labels={["Ticket Type", "Ticket Upload", "Ticket Info", "Pricing Info"]}
      />
      <ScrollView keyboardShouldPersistTaps="handled">
        {!!ticketValues.listNow && (
          <>
            <List.Item
              title="Sale Price"
              description={
                !ticketValues.askingPrice
                  ? " "
                  : `$${addCommas(ticketValues.askingPrice)}`
              }
              right={props => <List.Icon {...props} icon="chevron-right" />}
              onPress={navigateTo("TicketNewAskingPrice")}
            />
            <Divider />
            <List.Item
              title="Minimum Bid (Recommended)"
              description={`$${addCommas(ticketValues.minimumBidPrice)}`}
              right={props => <List.Icon {...props} icon="chevron-right" />}
              onPress={navigateTo("TicketNewMinimumBidPrice")}
            />
            <Divider />
            <List.Item
              title="Expiration Date (Recommended)"
              description={ticketValues.expiresAt ?? " "}
              right={props => <List.Icon {...props} icon="chevron-right" />}
              onPress={navigateTo("TicketNewExpiresAt")}
            />
            <Divider />
            <List.Item
              title="Notes (Optional)"
              description={ticketValues.notes || " "}
              right={props => <List.Icon {...props} icon="chevron-right" />}
              onPress={navigateTo("TicketNewAdditionalInfo")}
            />
            <Divider />
          </>
        )}

        <View
          style={{
            marginHorizontal: theme.spacing.xl,
            marginTop: theme.spacing.xl,
          }}
        >
          <Headline>Ticket Summary</Headline>
          <View
            style={{ flexDirection: "row", marginVertical: theme.spacing.lg }}
          >
            <View style={{ flex: 1, marginRight: theme.spacing.md }}>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ fontWeight: "bold" }}>Team: </Text>
                <Text>{ticketValues.subject}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ fontWeight: "bold" }}>Event: </Text>
                <Text style={{ flexShrink: 1 }}>
                  {ticketValues.eventDescription}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ fontWeight: "bold" }}>Bet Amount: </Text>
                <Text>${formatMoney(ticketValues.betAmount)}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ fontWeight: "bold" }}>Win Amount: </Text>
                <Text>${formatMoney(ticketValues.winAmount)}</Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ fontWeight: "bold" }}>Collect: </Text>
                <Text>
                  $
                  {formatMoney(
                    parseFloat(ticketValues.betAmount) +
                      parseFloat(ticketValues.winAmount)
                  )}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={{ fontWeight: "bold" }}>Odds: </Text>
                <Text>
                  {formatOdds(
                    convertMoneyTextToPennies(ticketValues.betAmount) +
                      convertMoneyTextToPennies(ticketValues.winAmount),
                    convertMoneyTextToPennies(ticketValues.betAmount)
                  )}
                </Text>
              </View>
            </View>
            {!!ticketValues.listNow && (
              <View style={{ flex: 1, marginLeft: theme.spacing.md }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "bold" }}>Sale Price: </Text>
                  <Text>${formatMoney(ticketValues.askingPrice)}</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "bold" }}>Min Bid: </Text>
                  <Text>${formatMoney(ticketValues.minimumBidPrice)}</Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "bold" }}>New Odds: </Text>
                  <Text>
                    {formatOdds(
                      convertMoneyTextToPennies(ticketValues.betAmount) +
                        convertMoneyTextToPennies(ticketValues.winAmount),
                      convertMoneyTextToPennies(ticketValues.askingPrice)
                    )}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ fontWeight: "bold" }}>Payout: </Text>
                  <Text>
                    $
                    {formatMoney(
                      calculatePayout(
                        currentUser,
                        ticketValues.commissionWaiverApplied,
                        convertMoneyTextToPennies(ticketValues.askingPrice)
                      )
                    )}
                  </Text>
                </View>
              </View>
            )}
          </View>
          <Divider />

          {currentUser?.availablePoints >= commissionWaiverRedemption && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginVertical: theme.spacing.lg,
              }}
            >
              <Checkbox.Android
                color={theme.colors.primary}
                status={
                  !!ticketValues.commissionWaiverApplied
                    ? "checked"
                    : "unchecked"
                }
                onPress={handleWaiverToggle}
              />
              <Text
                onPress={handleWaiverToggle}
                style={{
                  marginRight: theme.spacing.xl,
                  marginLeft: theme.spacing.md,
                }}
              >
                Waive PropSwap's 10% Commission, up to $100. (Cost: 5,000
                Points)
              </Text>
            </View>
          )}

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: theme.spacing.lg,
            }}
          >
            <Checkbox.Android
              color={theme.colors.primary}
              status={!!mailAgreementChecked ? "checked" : "unchecked"}
              onPress={() =>
                setMailAgreementChecked(
                  mailAgreementChecked => !mailAgreementChecked
                )
              }
            />
            <Text
              onPress={() =>
                setMailAgreementChecked(
                  mailAgreementChecked => !mailAgreementChecked
                )
              }
              style={{
                marginRight: theme.spacing.xl,
                marginLeft: theme.spacing.md,
              }}
            >
              If this ticket is sold, I understand it is my responsibility for
              PropSwap to receive the ticket within seven (7) calendar days of
              the sale. If PropSwap does not receive the ticket within 7 days, I
              will be charged $250 or 50% of the Collect Amount, whichever is
              greater.
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: theme.spacing.lg,
            }}
          >
            <Checkbox.Android
              color={theme.colors.primary}
              status={!!sellerAgreementChecked ? "checked" : "unchecked"}
              onPress={() =>
                setSellerAgreementChecked(
                  sellerAgreementChecked => !sellerAgreementChecked
                )
              }
            />
            <Text
              style={{
                marginRight: theme.spacing.xl,
                marginLeft: theme.spacing.md,
              }}
            >
              I have read and agree to PropSwap's&nbsp;
              <Text
                onPress={() =>
                  openUrl(
                    `${environment.base_url}/pages/terms-and-conditions.html`
                  )
                }
                style={[styles.textPrimary, styles.pointer]}
              >
                Terms & Conditions
              </Text>
              .
            </Text>
          </View>
        </View>
      </ScrollView>

      <ScreenBottom
        adjustPadding
        style={{ marginHorizontal: theme.spacing.xl }}
      >
        <Button
          disabled={
            !isValid ||
            !listingIsValid ||
            !!isSubmitting ||
            !mailAgreementChecked ||
            !sellerAgreementChecked
          }
          mode="contained"
          loading={!!isSubmitting}
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          {!!ticketValues.listNow ? "List Ticket For Sale" : "Create Ticket"}
        </Button>
        {!!ticketValues.listNow &&
          errors.map((errorMessage, i) => (
            <Text key={i} style={{ color: theme.colors.error }}>
              {errorMessage}
            </Text>
          ))}
        {!!submitError && (
          <Text style={{ color: theme.colors.error }}>
            {submitError.code} - {submitError.message}
          </Text>
        )}
      </ScreenBottom>
      <BottomSheet
        visible={!!submitted}
        // onDismiss={handleGoToMyTickets}
        fullScreen
      >
        {!!submitted && (
          <Confirmation
            title="Ticket Created Successfully"
            type="award"
            primaryButtonLabel="Upload a Similar Ticket"
            onPrimaryButtonPress={handleCreateSimilarTicket}
            secondaryButtonLabel="Go To My Tickets"
            onSecondaryButtonPress={handleGoToMyTickets}
            tertiaryButtonLabel="Upload a New Ticket"
            onTertiaryButtonPress={handleCreateNewTicket}
            headline={ticketValues.subject}
            subheading={ticketValues.eventDescription}
            More={
              !!ticketValues.listNow && (
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      marginRight: theme.spacing.sm,
                    }}
                  >
                    <Text style={{ fontWeight: "bold" }}>Odds: </Text>
                    <Text>
                      {formatOdds(
                        convertMoneyTextToPennies(ticketValues.betAmount) +
                          convertMoneyTextToPennies(ticketValues.winAmount),
                        convertMoneyTextToPennies(ticketValues.askingPrice)
                      )}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginLeft: theme.spacing.sm,
                    }}
                  >
                    <Text style={{ fontWeight: "bold" }}>Price: </Text>
                    <Text>${ticketValues.askingPrice}</Text>
                  </View>
                </View>
              )
            }
          />
        )}
      </BottomSheet>
    </Screen>
  );
};
