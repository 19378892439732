import React from "react";
import { Headline } from "react-native-paper";
import Expandable from "Components/Expandable";
import { View } from "react-native";

export default ({ styles, theme, items }) =>
  items.map(({ header, items }, i) => (
    <React.Fragment key={i}>
      <Headline style={styles.headline}>{header}</Headline>
      <View style={styles.wrapper}>
        {items.map(({ title, items }, j) => (
          <Expandable title={title} items={items} key={j} />
        ))}
      </View>
    </React.Fragment>
  ));
