import { Divider, List, useTheme } from "react-native-paper";
import Screen from "Components/Screen";
import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useCallback, useEffect, useState } from "react";

const byteSize = str => new Blob([str]).size;
const formatBytes = (bytes, decimals = 2) => {
  if (!bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const Settings = ({ navigation }) => {
  const theme = useTheme();
  const [cacheKeys, setCacheKeys] = useState([]);
  const [cacheValues, setCacheValues] = useState({});

  const setAllCacheKeys = useCallback(
    () => AsyncStorage.getAllKeys().then(keys => setCacheKeys(keys)),
    []
  );

  const removeCacheItem = useCallback(
    key => AsyncStorage.removeItem(key).then(setAllCacheKeys),
    [setAllCacheKeys]
  );

  useEffect(() => {
    setAllCacheKeys();
  }, [setAllCacheKeys]);

  useEffect(() => {
    Promise.all(
      cacheKeys.map(key =>
        AsyncStorage.getItem(key).then(value =>
          setCacheValues(cacheValues => ({
            ...cacheValues,
            [key]: byteSize(value),
          }))
        )
      )
    );
  }, [cacheKeys]);

  return (
    <Screen
      title="Settings"
      fullHeight
      hasScroll
      backgroundColor={theme.colors.surface}
      fullWidth
    >
      {cacheKeys
        .slice()
        .sort()
        .map(key => (
          <React.Fragment key={key}>
            <List.Item
              title={key}
              description={formatBytes(cacheValues[key])}
              right={props => <List.Icon {...props} icon="trash-can-outline" />}
              onPress={() => removeCacheItem(key)}
            />
            <Divider />
          </React.Fragment>
        ))}
    </Screen>
  );
};

export default Settings;
