export default theme => ({
  wrapper: {
    marginTop: theme.spacing.md,
  },
  infoWrapper: {
    flexDirection: "row",
    marginBottom: theme.spacing.md,
  },
  expiresWrapper: {
    marginTop: theme.spacing.md * -1,
    marginBottom: theme.spacing.lg,
  },
  infoLabelWrapper: {
    flexDirection: "row",
    marginBottom: theme.spacing.sm,
  },
  usersWrapper: {
    flexDirection: "row",
    marginBottom: theme.spacing.xl,
    alignItems: "center",
  },
  unlistedNotice: {
    fontWeight: "bold",
    color: theme.colors.secondary,
  },
  bidButtonsWrapper: {
    flex: 1,
    flexDirection: "row",
    marginVertical: theme.spacing.xl,
  },
  button: {
    flex: 1,
    marginHorizontal: theme.spacing.sm,
  },
  editButtonsWrapper: {
    flexDirection: "row",
    marginTop: theme.spacing.sm,
  },
  editButton: {
    flex: 1,
    marginHorizontal: theme.spacing.sm,
  },
  pendingBidWrapper: {
    marginTop: theme.spacing.lg,
  },
});
