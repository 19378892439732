import ScreenHeaderView from "./ScreenHeaderView";
import useStyles from "Hooks/useStyles";
import ScreenHeaderStyles from "./ScreenHeaderStyles";

export default ({ title, icon, rightButton, navigation }) => {
  const { theme, styles } = useStyles({ Styles: ScreenHeaderStyles });
  return (
    <ScreenHeaderView
      theme={theme}
      styles={styles}
      title={title}
      icon={icon}
      rightButton={rightButton}
      navigation={navigation}
    />
  );
};
