import { useTheme } from "react-native-paper";
import GlobalStyles from "Components/Styles";
import { useMemo } from "react";
import { StyleSheet } from "react-native";

export default ({ Styles } = {}) => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        ...GlobalStyles(theme),
        ...(!!Styles ? Styles(theme) : {}),
      }),
    [theme, Styles]
  );

  return useMemo(
    () => ({
      styles,
      theme,
    }),
    [theme, styles]
  );
};
