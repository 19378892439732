import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import TransactionDetailView from "./TransactionDetailView";
import useCanRequestRefund from "Hooks/useCanRequestRefund";
import Bluesnap from "shared/api/Bluesnap";
import useStyles from "Hooks/useStyles";
import TransactionDetailStyles from "./TransactionDetailStyles";
import { Transaction } from "shared/api";

export default ({ transactionId, onClose }) => {
  const { theme, styles } = useStyles({ Styles: TransactionDetailStyles });
  const canRequestRefund = useCanRequestRefund({ transactionId });
  const [step, setStep] = useState(1);
  const [refundTransaction] = useMutation(
    Bluesnap.mutations.refundTransaction,
    {
      variables: {
        transactionId,
      },
    }
  );

  const { data } = useQuery(Transaction.queries.get, {
    variables: {
      id: transactionId,
    },
  });

  const handleSubmit = useCallback(
    () => refundTransaction().then(() => setStep(3)),
    [refundTransaction]
  );

  const handleStart = useCallback(() => setStep(2), []);

  const handleCancel = useCallback(() => [setStep(1), onClose()], [onClose]);

  return !data?.getTransaction ? null : (
    <TransactionDetailView
      transaction={data?.getTransaction}
      canRequestRefund={canRequestRefund}
      step={step}
      onStart={handleStart}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      onClose={onClose}
      theme={theme}
      styles={styles}
    />
  );
};
