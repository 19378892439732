import { commissionAdjust, commissionWaiverRedemption } from "shared/Config";

const calculatePayout = (
  user,
  commissionWaiverApplied,
  newAskingPrice,
  override
) =>
  (!commissionWaiverApplied ||
  (user?.availablePoints < commissionWaiverRedemption && !override)
    ? parseFloat(newAskingPrice) * commissionAdjust
    : parseFloat(newAskingPrice) > 100000
    ? 100000 + (parseFloat(newAskingPrice) - 100000) * commissionAdjust
    : parseFloat(newAskingPrice)) / 100.0;

export default calculatePayout;
