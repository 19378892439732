import React from "react";
import { useNavigation } from "@react-navigation/native";
import { useLayoutEffect } from "react";
import { HeaderButtons, Item } from "react-navigation-header-buttons";

export default ({ onSubmit, isValid, skip }) => {
  const navigation = useNavigation();

  useLayoutEffect(() => {
    !!skip &&
      navigation.setOptions({
        headerRight: null,
      });

    !skip &&
      navigation.setOptions({
        headerRight: () =>
          !!isValid && (
            <HeaderButtons size={48}>
              <Item title="Save" disabled={!isValid} onPress={onSubmit} />
            </HeaderButtons>
          ),
      });
  }, [navigation, onSubmit, isValid, skip]);

  return null;
};
