import useStyles from "Hooks/useStyles";
import React from "react";
import ListingSearchbarStyles from "./ListingSearchbarStyles";
import ListingSearchbarView from "./ListingSearchbarView";

export default React.forwardRef(({ enabled, onPress, ...rest }, ref) => {
  const { theme, styles } = useStyles({ Styles: ListingSearchbarStyles });

  return (
    <ListingSearchbarView
      ref={ref}
      theme={theme}
      onPress={onPress}
      enabled={enabled}
      styles={styles}
      {...rest}
    />
  );
});
