import { useEffect } from "react";
import ActivityItemView from "./ActivityItemView";
import { useMutation } from "@apollo/client";
import Activity from "shared/api/Activity";
import useStyles from "Hooks/useStyles";
import Styles from "./ActivityItemStyles";

export default ({ activity, onPress }) => {
  const { styles, theme } = useStyles({ Styles });

  const [markViewed] = useMutation(Activity.mutations.update, {
    variables: {
      input: {
        id: activity?.id,
        viewed: true,
      },
    },
  });

  const hasBeenViewed = !!activity?.viewed;

  useEffect(() => {
    !hasBeenViewed && markViewed();
  }, [markViewed, hasBeenViewed]);

  return (
    <ActivityItemView
      activity={activity}
      theme={theme}
      styles={styles}
      onPress={onPress}
    />
  );
};
