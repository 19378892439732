import React from "react";

import Screen from "Components/Screen";
import { useTheme } from "react-native-paper";
import MyTicketsNav from "Components/MyTicketsNav";
import { useCallback, useMemo } from "react";
import TicketItem from "Components/Ticket/Item";
import Listing from "shared/api/Listing";
import DataList from "Components/DataList";
import { Platform, View } from "react-native";
import { useContext, useState } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import EmptySign from "Components/EmptySign";
import { ScrollDirectionContext } from "Contexts/ScrollDirection";
import { useFocusEffect } from "@react-navigation/native";
import { useEffect } from "react";
import { useRef } from "react";
import { handleScroll } from "Util/";

let init = 0;

const filterItems = [
  { label: "Most Recent", value: "updatedAt-desc" },
  { label: "Price High to Low", value: "askingPrice-desc" },
  { label: "Price Low to High", value: "askingPrice-asc" },
  // {label: "Alphabetical A-Z", value: "subject-asc"},
  // {label: "Alphabetical Z-A", value: "subject-desc"},
];

export default ({ navigation }) => {
  const theme = useTheme();
  const [filterValue, setFilterValue] = useState("updatedAt-desc");
  const [counter, setCounter] = useState(0);
  const { setScrollDirection } = useContext(ScrollDirectionContext);
  const sortDirection = filterValue.split("-")[1];
  const sortField = filterValue.split("-")[0];
  const [hasItems, setHasItems] = useState(null);
  const dataListRef = useRef();

  const handleItems = useCallback(items => setHasItems(!!items?.length), []);

  const handleFilterChange = useCallback(value => setFilterValue(value), []);

  const currentUser = useContext(CurrentUserContext);

  const dataListQuery = useMemo(
    () => ({
      dataKey: "searchListings",
      query: Listing.queries.search,
      variables: {
        limit: 20,
        sort: {
          direction: sortDirection,
          field: sortField,
        },
        filter: {
          and: [
            {
              or: [
                {
                  status: {
                    eq: "NEW",
                  },
                },
                {
                  status: {
                    eq: "INACTIVE",
                  },
                },
              ],
            },
            {
              listingSellerId: {
                eq: currentUser?.id,
              },
            },
          ],
        },
      },
    }),
    [sortDirection, sortField, currentUser]
  );

  const dataListRenderItem = useCallback(
    ({ item, index }) => (
      <View
        style={{
          marginBottom: theme.spacing.md,
        }}
      >
        <TicketItem ticketObj={item.cachedTicket} listingObj={item} />
      </View>
    ),
    [theme]
  );

  useEffect(() => {
    !!dataListRef?.current?.refetch && dataListRef.current.refetch();
  }, [dataListRef]);

  useFocusEffect(
    React.useCallback(() => {
      !!dataListRef?.current?.refetch && dataListRef.current.refetch();
    }, [])
  );

  useEffect(() => {
    !!dataListRef?.current?.refetch && dataListRef.current.refetch();
  }, [dataListQuery]);

  return (
    <Screen fullHeight fullWidth gatedTo="member">
      <MyTicketsNav
        navigation={navigation}
        onChange={handleFilterChange}
        selected="Not For Sale"
        filterItems={filterItems}
      />

      <DataList
        dataListRef={dataListRef}
        onScroll={
          Platform.OS !== "web"
            ? null
            : handleScroll(
                () => setScrollDirection("up"),
                () => setScrollDirection("down")
              )
        }
        onScrollBeginDrag={
          Platform.OS === "web"
            ? null
            : event => {
                init = event.nativeEvent.contentOffset.y;
              }
        }
        onScrollEndDrag={
          Platform.OS === "web"
            ? null
            : event =>
                init > event.nativeEvent.contentOffset.y
                  ? setScrollDirection("up")
                  : setScrollDirection("down")
        }
        contentContainerStyle={{
          flex: !!hasItems ? undefined : 1,
          marginHorizontal: theme.spacing.xl,
          paddingTop: theme.spacing.xl,
        }}
        // fetchPolicy="no-cache" //TODO fix this. has something to do with the apollo client
        // counter={counter}
        // fetchPolicy="no-cache"
        query={dataListQuery}
        onFetchItems={handleItems}
        renderItem={dataListRenderItem}
        ListEmptyComponent={
          <EmptySign
            title="Learn How To Sell"
            subheading="You Don't Have Any Tickets For Sale."
            navLabel="Learn Now"
            onNavPress={() => navigation.push("ListingLearn")}
          />
        }
      />
    </Screen>
  );
};
