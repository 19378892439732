import * as React from "react";
import Svg, { Defs, G, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83.34 59.38"
      {...props}
      height={props.size}
      width={props.size}
    >
      <Defs></Defs>
      <G id="Layer_2" data-name="Layer 2">
        <G id="Layer_1-2" data-name="Layer 1">
          <Path
            fill={props?.color ?? "#666"}
            className="cls-1"
            d="M71.1 15.53l.24.76v-.76z"
          />
          <Path
            fill={props?.color ?? "#666"}
            className="cls-2"
            d="M46.34 25.03H71.34V29.03H46.34z"
          />
          <Path
            fill={props?.color ?? "#666"}
            className="cls-2"
            d="M71.34 15.53L71.34 16.29 71.1 15.53 71.34 15.53z"
          />
          <Path
            fill={props?.color ?? "#666"}
            className="cls-1"
            d="M71.1 15.53l.24.76v-.76z"
          />
          <Path
            fill={props?.color ?? "#666"}
            className="cls-2"
            d="M46.34 25v4h25v-4zm0 0v4h25v-4zm0 0v4h25v-4zm0 0v4h25v-4zm24.76-9.5l.24.76v-.76zm0 0l.24.76v-.76zM46.34 25v4h25v-4zm0 0v4h25v-4zm24.76-9.5l.24.76v-.76zm0 0l.24.76v-.76zM46.34 25v4h25v-4zm0 0v4h25v-4zm24.76-9.5l.24.76v-.76zm0 0l.24.76v-.76zM46.34 25v4h25v-4zm0 0v4h25v-4zm24.76-9.5l.24.76v-.76zm0 0l.24.76v-.76zM46.34 25v4h25v-4zm0 0v4h25v-4zm24.76-9.5l.24.76v-.76zm0 0l.24.76v-.76zM46.34 25v4h25v-4zm24.76-9.5l.24.76v-.76zM46.34 25v4h25v-4zm0 0v4h25v-4zm24.76-9.5l.24.76v-.76zm0 0l.24.76v-.76zM46.34 25v4h25v-4zm0 0v4h25v-4zm24.76-9.5l.24.76v-.76zm0 0l.24.76v-.76zM46.34 25v4h25v-4zm0 0v4h25v-4zm24.76-9.5l.24.76v-.76zm0 0l.24.76v-.76zM46.34 25v4h25v-4zm0 0v4h25v-4zm24.76-9.5l.24.76v-.76zm0 0l.24.76v-.76zM46.34 25v4h25v-4zm0 0v4h25v-4zm0 0v4h25v-4zm0 0v4h25v-4zm0 0v4h25v-4zm0 0v4h25v-4z"
          />
          <Path
            fill={props?.color ?? "#666"}
            className="cls-1"
            d="M72.5 6.53L70.48 0 49.42 6.53H3.34v14.28L0 21.85l3.34 10.78v14.9H8l3.67 11.85 25.75-8 41.15 5.71 1.13-9.58h3.68v-41zM67.84 5l.47 1.52h-5.38zM14.27 54.37l-2-6.46-.12-.38h24.18L28 50.1zM75 52.59l-28.66-4 3.47-1.08h25.83zm4.3-9.06h-72v-33h72z"
          />
          <Path
            fill={props?.color ?? "#666"}
            className="cls-1"
            d="M34.34 31.11c0 2.93-2 5.42-5.81 6v3.38h-3.25v-3.07A13.88 13.88 0 0119.71 36l1-3.84a11.67 11.67 0 005.45 1.45c1.9 0 3.18-.76 3.18-2s-1-2-3.5-2.85c-3.52-1.19-5.92-2.83-5.92-6a5.42 5.42 0 011.89-4.18 7.57 7.57 0 013.66-1.67v-3.16h3.21v2.89a11.9 11.9 0 014.8 1.08l-1 3.71a10.25 10.25 0 00-4.73-1.15c-2.13 0-2.81.95-2.81 1.87s1.14 1.78 4 2.79c3.86 1.38 5.4 3.19 5.4 6.17z"
          />
          <Path
            fill={props?.color ?? "#666"}
            className="cls-1"
            d="M71.34 16.29L71.34 19.53 46.34 19.53 46.34 15.53 71.1 15.53 71.34 16.29z"
          />
          <Path
            fill={props?.color ?? "#666"}
            className="cls-1"
            d="M71.34 15.53L71.34 16.29 71.1 15.53 71.34 15.53z"
          />
          <Path
            fill={props?.color ?? "#666"}
            className="cls-1"
            d="M46.34 25.03H71.34V29.03H46.34z"
          />
          <Path
            fill={props?.color ?? "#666"}
            className="cls-1"
            d="M46.34 34.53H71.34V38.53H46.34z"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
