import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { Button } from "react-native-paper";

export default ({ styles, theme, onPress }) => (
  <Button
    style={styles.icon}
    onPress={onPress}
    icon={() => (
      <Ionicons name="arrow-back-outline" size={24} color={theme.colors.text} />
    )}
  />
);
