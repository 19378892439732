import { MaterialCommunityIcons, AntDesign } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";
import Buy from "Components/Icons/Buy";
import Sell from "Components/Icons/Sell";
import Tickets from "Components/Icons/Tickets";
import SidebarRight from "Components/Sidebar/Right";
import VerticalNavBar from "Components/VerticalNavBar";
import { TabBarContext } from "Contexts/TabBar";
import useNavigationMode from "Hooks/useNavigationMode";
import { useLayoutEffect, useContext } from "react";

import AccountStack from "./AccountStack";
import BuyStack from "./BuyStack";
import HomeStack from "./HomeStack";
import MyTicketsStack from "./MyTicketsStack";
import SellStack from "./SellStack";
import { View } from "react-native";

const getTabBarIcon =
  (IconSet, name) =>
  ({ color, size }) =>
    <IconSet name={name} color={color} size={size} />;

const Tabs = createBottomTabNavigator();

export default ({ navigation, route }) => {
  const routeName = getFocusedRouteNameFromRoute(route) ?? "Home";
  const tabBar = useContext(TabBarContext);
  const navigationMode = useNavigationMode();
  const showVertical = navigationMode === "vertical";

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
      title: routeName,
    });
  }, [navigation, routeName]);

  return (
    <View
      nativeID="big-wrapper"
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        // minHeight: 1200,
      }}
    >
      {!!showVertical && <VerticalNavBar />}
      <View
        style={{
          flex: 1,
          width: "100%",
          maxWidth: 601,
          // marginHorizontal: "auto",
        }}
      >
        <Tabs.Navigator
          tabBar={tabBar.visible && !showVertical ? undefined : () => null}
          screenOptions={{
            headerBackTitleVisible: false,
            tabBarLabelStyle: {
              lineHeight: 20,
            },
          }}
        >
          <Tabs.Screen
            name="HomeStack"
            component={HomeStack}
            options={{
              title: "Home",
              tabBarIcon: getTabBarIcon(AntDesign, "home"),
            }}
          />
          <Tabs.Screen
            name="MyTickets"
            component={MyTicketsStack}
            options={{
              title: "My Tickets",
              tabBarIcon: ({ color, size }) => (
                <Tickets color={color} size={size} />
              ),
            }}
          />
          <Tabs.Screen
            name="Buy"
            component={BuyStack}
            options={{
              title: "Buy",
              tabBarIcon: ({ color, size }) => (
                <Buy color={color} size={size} />
              ),
            }}
          />
          <Tabs.Screen
            name="Sell"
            component={SellStack}
            options={{
              title: "Sell",
              tabBarIcon: ({ color, size }) => (
                <Sell color={color} size={size} />
              ),
            }}
          />
          <Tabs.Screen
            name="Account"
            component={AccountStack}
            options={{
              title: "Account",
              tabBarIcon: getTabBarIcon(
                MaterialCommunityIcons,
                "account-outline"
              ),
            }}
          />
          {/* {
          !!showVertical &&
          <Tabs.Screen
            name="NotificationsStack"
            component={NotificationsStack}
            options={{
              title: 'Notifications',
              tabBarIcon: getTabBarIcon(MaterialCommunityIcons, "account-outline"),
            }}
          />
        }
        {
          !!showVertical &&
          <Tabs.Screen
            name="Watchlist"
            component={Notifications}
            options={{
              title: 'Watch List',
              tabBarIcon: getTabBarIcon(MaterialCommunityIcons, "account-outline"),
            }}
          />
        } */}
        </Tabs.Navigator>
      </View>
      {!!showVertical && <SidebarRight />}
    </View>
  );
};
