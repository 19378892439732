export default theme => ({
  buttonsWrapper: {
    flexDirection: "row",
    marginTop: theme.spacing.xl,
  },
  button: {
    flex: 1,
    marginRight: theme.spacing.sm,
  },
  inputWrapper: {
    marginTop: theme.spacing.xl,
  },
  controlInner: {
    alignItems: "center",
    flexDirection: "row",
  },
  bidOddsWrapper: {
    justifyContent: "center",
    marginLeft: theme.spacing.xl,
  },
});
