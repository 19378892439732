import Screen from "Components/Screen";
import { useContext } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import Confirmation from "Components/Confirmation";
import { Text, Title, useTheme } from "react-native-paper";
import { userStatusLabels } from "shared/Config";
import { Octicons } from "@expo/vector-icons";
import { View } from "react-native";
import { addCommas } from "shared/Util/money";

export default () => {
  const currentUser = useContext(CurrentUserContext);
  const theme = useTheme();

  return (
    <Screen
      gatedTo="member"
      title="My Rating"
      fullHeight
      backgroundColor={theme.colors.surface}
    >
      <Confirmation
        type="award"
        headline={userStatusLabels[(currentUser?.status ?? 1) - 1].label}
        title="Congratulations!"
        subtitle={`You have achieved ${
          userStatusLabels[(currentUser?.status || 1) - 1].label
        } Level!`}
        More={
          <Title
            style={{
              color: theme.colors.primary,
              textAlign: "center",
              padding: theme.spacing.md,
              borderColor: theme.colors.primary,
              borderWidth: 1,
              borderStyle: "solid",
              width: "100%",
            }}
          >
            {addCommas(currentUser?.points ?? 0)}
          </Title>
        }
        Footer={userStatusLabels.map((mapping, i) => (
          <View
            style={{ flexDirection: "row", justifyContent: "center" }}
            key={i}
          >
            <View
              style={{
                justifyContent: "center",
                marginRight: theme.spacing.sm,
              }}
            >
              <Octicons
                name="dot-fill"
                size={14}
                color={theme.colors[mapping.color]}
              />
            </View>
            <Text>
              {mapping.label}: {mapping.range[0]}
              {!mapping.range[1] ? "+" : ` - ${mapping.range[1]}`}
            </Text>
          </View>
        ))}
      />
    </Screen>
  );
};
