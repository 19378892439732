import React from "react";

import { useContext, useCallback } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useStyles from "Hooks/useStyles";
import UserW9FormView from "./UserW9FormView";

const schema = yup.object().shape({
  recipientSsn: yup
    .string()
    .matches(/^\d\d\d\d\d\d\d\d\d+$/i, "Must be a valid Social Security Number")
    .required("Can't be blank"),
});

export default ({ onSubmit: passedOnSubmit }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
      isSubmitting,
      touchedFields,
      dirtyFields,
      ...rest
    },
    setError,
    setFocus,
    watch,
    ...others
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    ({ recipientSsn }) => passedOnSubmit(recipientSsn),
    [passedOnSubmit]
  );

  const doSubmit = handleSubmit(onSubmit);
  const { theme, styles } = useStyles();
  const currentUser = useContext(CurrentUserContext);

  return (
    <UserW9FormView
      theme={theme}
      styles={styles}
      onSubmit={doSubmit}
      control={control}
      errors={errors}
      isValid={isValid}
      isSubmitting={isSubmitting}
    />
  );
};
