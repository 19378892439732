import { MaterialCommunityIcons } from "@expo/vector-icons";
import BidHighBidLabel from "Components/Bid/HighBidLabel";
import EventOptionOddsGraph from "Components/Event/Option/Odds/Graph";
import ListingBidBuyForm from "Components/Listing/BidBuyForm";
import ListingSuggestedPrice from "Components/Listing/SuggestedPrice";
import ListingValueMeter from "Components/Listing/ValueMeter";
import TicketSellSimilarButton from "Components/Ticket/SellSimilarButton";
import TicketTopRow from "Components/Ticket/TopRow";
import UserInfo from "Components/User/Info";
import formatOdds from "Util/formatOdds";
import moment from "moment";
import { View } from "react-native";
import { Subheading, Text, Title, Button, Paragraph } from "react-native-paper";
import { formatPennies } from "shared/Util/money";

export default ({
  ticket,
  option,
  event,
  listing,
  theme,
  minBid,
  hasBids,
  currentUser,
  mode,
  onModeButtonPress,
  onEditButtonPress,
  onCancelButtonPress,
  onUserPress,
  onSubmit,
  pendingBid,
  onNavigateToBid,
  highBid,
  onBidderPress,
  styles,
}) => (
  <View style={styles.wrapper}>
    <TicketTopRow
      ticket={ticket}
      listing={listing}
      theme={theme}
      currentUser={currentUser}
    />
    <Title style={styles.textUpper}>
      {!!ticket.cachedBets?.length
        ? ticket.cachedBets
            .slice(0)
            .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
            .map(cb => cb.subject)
            .join(", ")
        : ticket.subject}
    </Title>
    {ticket?.cachedBets?.length > 1 ? (
      ticket.cachedBets
        .slice(0)
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
        .map(bet => (
          <View
            key={bet.id}
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <MaterialCommunityIcons
              name={
                bet.result === "WIN"
                  ? "check-circle"
                  : bet.result === "LOSS"
                  ? "close-circle"
                  : "circle-outline"
              }
              size={24}
              color={
                bet.result === "WIN"
                  ? theme.colors.primary
                  : bet.result === "LOSS"
                  ? theme.colors.error
                  : theme.colors.text
              }
              style={{
                marginRight: 4,
              }}
            />
            <Subheading key={bet.id}>{bet.eventDescription}</Subheading>
          </View>
        ))
    ) : (
      <Subheading>{ticket.eventDescription}</Subheading>
    )}
    {!["PENDING", "SOLD"].includes(listing?.status) && (
      <View style={styles.infoWrapper}>
        <View style={styles.f1}>
          <View style={styles.infoLabelWrapper}>
            <Text style={styles.textBold}>Odds: </Text>
            <Text>
              {!!listing.askingPrice
                ? formatOdds(
                    ticket.collectAmount,
                    listing.salesPrice ?? listing.askingPrice
                  )
                : ticket.odds}
            </Text>
          </View>
          <View style={styles.infoLabelWrapper}>
            <Text style={styles.textBold}>Price: </Text>
            <Text>
              $
              {formatPennies(
                !!listing.askingPrice
                  ? listing.salesPrice ?? listing.askingPrice
                  : ticket.betAmount
              )}
            </Text>
          </View>
          {!listing?.seller?.providerId && (
            <View style={styles.infoLabelWrapper}>
              <Text style={styles.textBold}>Min/High Bid: </Text>
              <Text>
                {!minBid || minBid <= 0 ? "n/a" : `$${formatPennies(minBid)}`}
              </Text>
            </View>
          )}
        </View>
        <View style={styles.f1}>
          <View style={styles.infoLabelWrapper}>
            <Text style={styles.textBold}>Collect: </Text>
            <Text>${formatPennies(ticket.collectAmount)}</Text>
          </View>

          <ListingValueMeter
            disableValues={ticket?.disableValues}
            valueRating={listing?.valueRating}
          />

          {!!ticket?.collectAmount &&
            ((!!event?.bovadoEventId &&
              !!event?.bovadoGroupId &&
              (option?.bovadoParticipantId || option?.bovadoParticipantName)) ||
              (!!event?.fanDuelMarketId && !!option?.fanDuelSelectionId)) && (
              <View style={styles.infoLabelWrapper}>
                <Text style={styles.textBold}>Suggested: </Text>
                <ListingSuggestedPrice
                  fanDuelMarketId={event?.fanDuelMarketId}
                  fanDuelSelectionId={option?.fanDuelSelectionId}
                  wagerInPennies={ticket?.collectAmount}
                  groupId={event?.bovadoGroupId}
                  eventId={event?.bovadoEventId}
                  participantId={option?.bovadoParticipantId}
                  participantName={option?.bovadoParticipantName}
                  event={event}
                />
              </View>
            )}
        </View>
      </View>
    )}

    {!["PENDING", "SOLD"].includes(listing?.status) &&
      !!listing.expiresAt &&
      moment(listing.expiresAt).isValid() && (
        <View style={[styles.infoLabelWrapper, styles.expiresWrapper]}>
          <Text style={styles.textBold}>Expires: </Text>
          <Text>{moment(listing.expiresAt).format("MM/DD/YYYY hh:mm a")}</Text>
        </View>
      )}

    {["PENDING", "SOLD"].includes(listing?.status) && (
      <View style={styles.infoWrapper}>
        <View style={styles.f1}>
          <Text style={styles.textBold}>Sold: </Text>
          <Text>
            ${formatPennies(listing.salesPrice ?? listing.askingPrice)}
          </Text>
        </View>

        <View style={styles.f1}>
          <Text style={styles.textBold}>Collect: </Text>
          <Text>${formatPennies(ticket.collectAmount)}</Text>
        </View>

        <View style={styles.f1}>
          <Text style={styles.textBold}>Odds: </Text>
          <Text>
            {formatOdds(
              ticket.collectAmount,
              listing.salesPrice ?? listing.askingPrice
            )}
          </Text>
        </View>

        <View style={styles.f1}>
          <Text style={styles.textBold}>Date: </Text>
          <Text>
            {moment(listing?.soldAt ?? listing?.updatedAt).format("MM/DD/YYYY")}
          </Text>
        </View>
      </View>
    )}

    {!mode && (
      <View style={styles.usersWrapper}>
        <View style={styles.f1}>
          <UserInfo
            userObj={listing?.seller}
            onPress={onUserPress}
            textColor={theme.colors.text}
          />
          {!!listing?.notes && (
            <View style={styles.forceWrap}>
              <Paragraph style={styles.textWrap}>{listing.notes}</Paragraph>
            </View>
          )}
        </View>
        <View style={styles.f1}>
          {listing?.status === "ACTIVE" &&
            currentUser?.id === listing?.listingSellerId && (
              <BidHighBidLabel
                listing={listing}
                showUser
                onPress={onBidderPress}
              />
            )}
          {listing?.status === "INACTIVE" &&
            currentUser?.id !== listing?.listingSellerId && (
              <Text style={styles.unlistedNotice}>
                This ticket has been unlisted by the seller.
              </Text>
            )}
        </View>
      </View>
    )}

    {!!highBid &&
      currentUser?.id === listing?.listingSellerId &&
      listing?.status === "ACTIVE" && (
        <View style={styles.bidButtonsWrapper}>
          <Button
            compact
            onPress={onNavigateToBid({
              action: "COUNTERED",
              bidId: highBid.id,
            })}
            mode="outlined"
            style={styles.button}
          >
            Counter
          </Button>
          <Button
            compact
            onPress={onNavigateToBid({ action: "ACCEPTED", bidId: highBid.id })}
            mode="contained"
            style={styles.button}
          >
            Accept
          </Button>
          <Button
            compact
            onPress={onNavigateToBid({ action: "REJECTED", bidId: highBid.id })}
            mode="outlined"
            style={styles.button}
          >
            Reject
          </Button>
        </View>
      )}

    {["buy", "bid"].includes(mode) && (
      <ListingBidBuyForm
        hasPendingBid={!!pendingBid}
        listing={listing}
        defaultAmount={
          mode === "buy"
            ? listing.askingPrice
            : !!hasBids
            ? minBid + 100
            : minBid
        }
        onCancelPress={onModeButtonPress(null)}
        onSubmit={onSubmit}
        minBid={!!hasBids ? minBid + 100 : minBid}
        fundsAvailable={currentUser?.balance + currentUser?.promotionalBalance}
        ticket={ticket}
        mode={mode}
      />
    )}

    {currentUser?.id === listing?.listingSellerId &&
      ["SOLD", "PENDING"].includes(listing?.status) && (
        <TicketSellSimilarButton listing={listing} bets={ticket?.cachedBets} />
      )}

    {!mode && (
      <View style={styles.editButtonsWrapper}>
        {!!currentUser?.id &&
        listing?.listingSellerId === currentUser?.id &&
        ["ACTIVE", "INACTIVE", "NEW"].includes(listing?.status) ? (
          <Button
            mode="contained"
            style={styles.editButton}
            onPress={onEditButtonPress}
          >
            {["INACTIVE", "NEW"].includes(listing?.status)
              ? "List For Sale"
              : "Edit"}
          </Button>
        ) : !!pendingBid ? (
          <>
            <Button
              mode="outlined"
              style={styles.editButton}
              onPress={onCancelButtonPress}
            >
              Cancel Bid
            </Button>
            <Button
              mode="contained"
              style={styles.editButton}
              onPress={onModeButtonPress("buy")}
            >
              Buy Now
            </Button>
          </>
        ) : listing?.status === "ACTIVE" ? (
          <>
            {!listing?.seller?.providerId && (
              <Button
                mode="outlined"
                style={styles.editButton}
                onPress={onModeButtonPress("bid")}
              >
                {!!currentUser ? "Bid" : "Login to Bid"}
              </Button>
            )}
            <Button
              mode="contained"
              style={styles.editButton}
              onPress={onModeButtonPress("buy")}
            >
              {!!currentUser ? "Buy" : "Login to Buy"}
            </Button>
          </>
        ) : null}
      </View>
    )}

    {!mode && !!pendingBid && (
      <View style={styles.pendingBidWrapper}>
        <Text>
          You have an Active bid of ${formatPennies(pendingBid.offer)} on this
          ticket
          {!!pendingBid.expiresAt
            ? ` that expires on ${moment(pendingBid.expiresAt).format(
                "MM/DD/YYYY"
              )} at ${moment(pendingBid.expiresAt).format("hh:mm a")}`
            : ""}
          . If you wish to change your bid, you can do so by clicking the Cancel
          Bid button and submitting a new one. You may also purchase this ticket
          for the full asking price by clicking the Buy Now button.
        </Text>
      </View>
    )}

    {!mode && (
      <EventOptionOddsGraph
        listingId={listing?.id}
        ticketId={ticket?.id}
        style={{
          marginTop: theme.spacing.xl * 4,
          marginLeft: theme.spacing.xl * -1,
          marginRight: theme.spacing.xl * -1,
        }}
      />
    )}
    <View style={{ marginBottom: 30 }} />
  </View>
);
