export default theme => ({
  titleWrapper: {
    flex: 1,
    justifyContent: "flex-end",
    marginBottom: theme.spacing.xl,
  },
  bodyWrapper: {
    justifyContent: "center",
    flex: 3,
  },
  successWrapper: {
    width: "100%",
    alignItems: "center",
    marginTop: theme.spacing.xl * 4,
    marginBottom: theme.spacing.xl * 2,
  },
  awardWrapper: {
    position: "absolute",
    zIndex: 1,
    width: "100%",
    height: "100%",
  },
  award: {
    maxWidth: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  headingsWrapper: {
    position: "relative",
    alignSelf: "center",
    alignItems: "center",
    width: "50%",
    zIndex: 2,
    marginBottom: theme.spacing.xl * 2,
  },
  footerWrapper: {
    justifyContent: "flex-end",
    marginBottom: theme.spacing.lg,
  },
  buttonsWrapper: {
    minHeight: 130,
    justifyContent: "flex-end",
    marginBottom: theme.spacing.lg,
  },
  button: {
    marginBottom: theme.spacing.lg,
  },
});
