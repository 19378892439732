import { View, ScrollView, Keyboard, Platform, TextInput } from "react-native";
import {
  Subheading,
  Text,
  Title,
  Button,
  Checkbox,
  HelperText,
} from "react-native-paper";
import { formatPennies, formatValue, addCommas } from "shared/Util/money";
import ListingValueMeter from "Components/Listing/ValueMeter";
import UserInfo from "Components/User/Info";
import ScreenBottom from "Components/Screen/Bottom";

import { Controller } from "react-hook-form";
import TicketTopRow from "Components/Ticket/TopRow";
import formatOdds from "Util/formatOdds";
import moment from "moment";
import DateTimePicker from "Components/DateTimePicker";
import { commissionWaiverRedemption } from "shared/Config";

export default ({
  ticket,
  listing,
  theme,
  onCancelButtonPress,
  onUserPress,
  onSubmit,
  onSave,
  valueRating,
  isValid,
  isSubmitting,
  control,
  errors,
  askingPriceInPennies,
  currentUser,
  styles,
}) => (
  <>
    <ScrollView style={styles.wrapper}>
      <TicketTopRow
        ticket={ticket}
        listing={listing}
        theme={theme}
        currentUser={currentUser}
      />
      <Title style={[styles.textUpper, { lineHeight: 20 }]}>
        {ticket.subject}
      </Title>
      <Subheading>{ticket.eventDescription}</Subheading>
      <View style={styles.row}>
        <View style={styles.f1}>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              marginBottom: theme.spacing.sm,
            }}
          >
            <Text style={styles.label}>Odds: </Text>
            <Text>
              {formatOdds(
                ticket.collectAmount,
                askingPriceInPennies ?? listing.newOdds
              )}
            </Text>
          </View>
          <View style={styles.controlWrapper}>
            <Text style={styles.label}>Price: </Text>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value, ...mre },
                ...rest
              }) => (
                <>
                  <TextInput
                    selectTextOnFocus
                    autoCorrect={false}
                    autoCapitalize="none"
                    autoCompleteType={
                      Platform.OS === "web" ? "new-password" : "off"
                    }
                    keyboardType="decimal-pad"
                    textContentType="none"
                    returnKeyLabel="Done"
                    returnKeyType="done"
                    onBlur={onBlur}
                    onChangeText={value =>
                      onChange(
                        formatValue((value ?? "").replace(/[^0-9.]/gi, "") || 0)
                      )
                    }
                    value={`$${
                      value.toString() === "0"
                        ? ""
                        : addCommas(value.toString())
                    }`}
                    style={[
                      {
                        borderColor: !!errors.askingPrice
                          ? theme.colors.error
                          : theme.colors.secondary,
                      },
                      styles.input,
                    ]}
                  />
                </>
              )}
              name="askingPrice"
              defaultValue={listing.askingPrice / 100.0}
            />
          </View>
          {!!errors.askingPrice && (
            <View style={styles.helperWrapper}>
              <HelperText type="error" visible={!!errors.askingPrice}>
                {errors.askingPrice?.message}
              </HelperText>
            </View>
          )}

          <View style={styles.controlWrapper}>
            <Text style={styles.label}>Min Bid: </Text>
            <Controller
              control={control}
              render={({
                field: { onChange, onBlur, value, ...mre },
                ...rest
              }) => (
                <>
                  <TextInput
                    selectTextOnFocus
                    autoCorrect={false}
                    autoCapitalize="none"
                    autoCompleteType={
                      Platform.OS === "web" ? "new-password" : "off"
                    }
                    keyboardType="decimal-pad"
                    textContentType="none"
                    returnKeyLabel="Done"
                    returnKeyType="done"
                    // onSubmitEditing={doSubmit}

                    onBlur={onBlur}
                    onChangeText={value =>
                      onChange(
                        formatValue((value ?? "").replace(/[^0-9.]/gi, "") || 0)
                      )
                    }
                    value={`${
                      !value || value.toString() === "0"
                        ? ""
                        : `$${addCommas(value.toString())}`
                    }`}
                    style={[
                      {
                        borderColor: !!errors.minimumBidPrice
                          ? theme.colors.error
                          : theme.colors.secondary,
                      },
                      styles.input,
                    ]}
                  />
                </>
              )}
              name="minimumBidPrice"
              defaultValue={
                !!listing.minimumBidPrice ? listing.minimumBidPrice / 100.0 : 0
              }
            />
          </View>
          {!!errors.minimumBidPrice && (
            <View style={styles.helperWrapper}>
              <HelperText type="error" visible={!!errors.minimumBidPrice}>
                {errors.minimumBidPrice?.message}
              </HelperText>
            </View>
          )}
        </View>
        <View style={styles.valueWrapper}>
          <View style={styles.helperWrapper}>
            <Text style={styles.label}>Collect: </Text>
            <Text>${formatPennies(ticket.collectAmount)}</Text>
          </View>
          {!!valueRating && (
            <ListingValueMeter
              disableValues={ticket.disableValues}
              valueRating={valueRating}
            />
          )}
        </View>
      </View>

      <View style={[{ marginBottom: theme.spacing.md }, styles.pointer]}>
        <View style={styles.controlWrapper}>
          <Text style={styles.label}>Expires: </Text>
          <Controller
            control={control}
            render={({
              field: { onChange, onBlur, value, ...mre },
              ...rest
            }) => (
              <View
                style={{
                  ...Platform.select({
                    default: {
                      flex: 1,
                    },
                    web: {},
                  }),
                }}
              >
                <DateTimePicker
                  buttonCompact
                  onChange={val => onChange(val)}
                  value={!value ? "" : moment(value).format("YYYY-MM-DDTHH:mm")}
                  style={{
                    //HAVE TO LEAVE THIS INLINE
                    borderColor: !!errors.expiresAt
                      ? theme.colors.error
                      : theme.colors.secondary,
                    color: theme.colors.text,
                    borderWidth: 1,
                    padding: theme.spacing.xs,
                    borderStyle: "solid",
                    marginRight: theme.spacing.md,
                    backgroundColor: "transparent",
                    fontSize: 14,
                    minWidth: 186,
                  }}
                />
              </View>
            )}
            name="expiresAt"
            defaultValue={
              !!listing.expiresAt
                ? moment(listing.expiresAt).format("YYYY-MM-DDTHH:mm")
                : ""
            }
          />
        </View>
        {!!errors.expiresAt && (
          <View style={styles.helperWrapper}>
            <HelperText type="error" visible={!!errors.expiresAt}>
              {errors.expiresAt?.message}
            </HelperText>
          </View>
        )}
      </View>

      {currentUser?.availablePoints >= commissionWaiverRedemption && (
        <View style={[{ marginBottom: theme.spacing.md }, styles.pointer]}>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.controlInner}>
                <Checkbox.Android
                  color={theme.colors.primary}
                  status={!!value ? "checked" : "unchecked"}
                  onPress={() => onChange(!value)}
                />
                <Text
                  style={(styles.pointer, styles.textWrap)}
                  onPress={() => onChange(!value)}
                >
                  Waive PropSwap's 10% Commission, up to $100. (Cost: 5,000
                  Points)
                </Text>
              </View>
            )}
            name="commissionWaiverApplied"
            defaultValue={!!listing.commissionWaiverApplied}
          />
        </View>
      )}

      <View style={styles.notesWrapper}>
        <UserInfo
          userObj={listing?.seller}
          onPress={onUserPress}
          textColor={theme.colors.text}
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect
                placeholder="Seller Notes (optional)"
                placeholderTextColor={theme.colors.disabled}
                autoCapitalize="sentences"
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                textContentType="none"
                returnKeyLabel="Done"
                returnKeyType="done"
                onBlur={onBlur}
                blurOnSubmit
                onSubmitEditing={() => Keyboard.dismiss()}
                onChangeText={value => onChange(value)}
                value={value}
                multiline
                numberOfLines={4}
                style={[
                  {
                    borderColor: !!errors.notes
                      ? theme.colors.error
                      : theme.colors.secondary,
                  },
                  styles.input,
                  styles.multilineInput,
                ]}
              />
            </>
          )}
          name="notes"
          defaultValue={listing.notes ?? ""}
        />
        {!!errors.notes && (
          <View style={styles.helperWrapper}>
            <HelperText type="error" visible={!!errors.notes}>
              {errors.notes?.message}
            </HelperText>
          </View>
        )}
      </View>
    </ScrollView>
    {["ACTIVE", "NEW", "INACTIVE"].includes(listing?.status) && (
      <ScreenBottom adjustPadding>
        <View style={[styles.buttonsWrapper, styles.pointer]}>
          <Button
            disabled={!!isSubmitting}
            loading={!!isSubmitting}
            mode="outlined"
            compact
            style={styles.button}
            onPress={onCancelButtonPress}
          >
            Cancel
          </Button>
          {listing.status === "ACTIVE" && (
            <Button
              disabled={!isValid || !!isSubmitting}
              compact
              loading={!!isSubmitting}
              mode="contained"
              style={styles.button}
              onPress={onSave}
            >
              Save
            </Button>
          )}
          <Button
            disabled={!isValid || !!isSubmitting}
            loading={!!isSubmitting}
            compact
            mode={
              listing.status === "ACTIVE"
                ? "outlined"
                : listing.status === "INACTIVE"
                ? "contained"
                : "contained"
            }
            style={styles.button}
            onPress={onSubmit}
          >
            {listing.status === "ACTIVE"
              ? "Unlist"
              : listing.status === "INACTIVE"
              ? "Relist"
              : "List For Sale"}
          </Button>
        </View>
      </ScreenBottom>
    )}
  </>
);
