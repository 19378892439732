import React, { useCallback, useMemo, useState } from "react";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import DataList from "Components/DataList";
import FilterScreenView from "Components/FilterScreen";
import Screen from "Components/Screen";
import ScreenHeader from "Components/Screen/Header";
import TicketItem from "Components/Ticket/Item";
import UserItem from "Components/User/Item";
import { View } from "react-native";
import { useTheme } from "react-native-paper";
import Activity from "shared/api/Activity";
import { activityLabelMap } from "shared/Config";

const filterItems = [
  { label: "All", value: false },
  ...Object.entries(activityLabelMap).map(([value, label]) => ({
    value,
    label,
  })),
];

// const scrollEnabled = Platform.select({ web: true, default: false });

const extractListingFromActivity = activity => {
  let parsedData = JSON.parse(activity.data);

  parsedData =
    typeof parsedData === "string" ? JSON.parse(parsedData) : parsedData;

  if (!!parsedData.listingSellerId) {
    return parsedData;
  } else {
    return parsedData?.listing;
  }
};

export default ({ navigation }) => {
  const theme = useTheme();

  const [selectedValue, setSelectedValue] = React.useState(false);

  const [showBottomSheet, setShowBottomSheet] = useState(false);

  const handleBottomSheetDismiss = useCallback(
    () => setShowBottomSheet(false),
    []
  );

  const handleFilterButtonPress = useCallback(
    () => setShowBottomSheet(true),
    []
  );

  const handleValueChange = useCallback(
    value => [setSelectedValue(value), setShowBottomSheet(false)],
    []
  );

  const dataListQuery = useMemo(
    () => ({
      dataKey: !!selectedValue
        ? "listActivitiesByType"
        : "listActivitiesByGlobalEligible",
      query: !!selectedValue
        ? Activity.queries.listByType
        : Activity.queries.listByGlobalEligible,
      variables: !!selectedValue
        ? { sortDirection: "DESC", type: selectedValue }
        : { sortDirection: "DESC", globalEligible: "YES" },
    }),
    [selectedValue]
  );

  const UserRenderItem = ({ user, activity }) => (
    <View style={{ marginBottom: theme.spacing.md }}>
      <UserItem user={user} activity={activity} theme={theme} />
    </View>
  );

  const TicketRenderItem = ({ listing, ticket, activity }) => (
    <View style={{ marginBottom: theme.spacing.md }}>
      <TicketItem
        ticketObj={ticket}
        listingObj={listing}
        activityObj={activity}
      />
    </View>
  );

  const dataListRenderItem = useCallback(
    ({ item, index }) =>
      !extractListingFromActivity(item)?.cachedTicket ? (
        <UserRenderItem user={item.user} activity={item} />
      ) : (
        <TicketRenderItem
          listing={extractListingFromActivity(item)}
          ticket={extractListingFromActivity(item).cachedTicket}
          activity={item}
        />
      ),

    []
  );

  return (
    <Screen fullHeight fullWidth>
      <ScreenHeader
        title="Activity"
        icon={
          <MaterialCommunityIcons
            size={24}
            name="lightning-bolt-circle"
            color={theme.colors.onSurface}
          />
        }
        rightButton={
          <MaterialCommunityIcons
            name="filter-variant"
            size={24}
            color={theme.colors.onSurface}
            onPress={handleFilterButtonPress}
          />
        }
        navigation={navigation}
        theme={theme}
      />

      <DataList
        query={dataListQuery}
        renderItem={dataListRenderItem}
        contentContainerStyle={{
          marginHorizontal: theme.spacing.xl,
          paddingTop: theme.spacing.xl,
        }}
      />

      <FilterScreenView
        items={filterItems}
        theme={theme}
        visible={showBottomSheet}
        onDismiss={handleBottomSheetDismiss}
        title="Filter Activities"
        selectedValue={selectedValue}
        onValueChange={handleValueChange}
      />
    </Screen>
  );
};
