import gql from "graphql-tag";
import environment from "environment";
import Option from "./Option";
import Group from "./Group";

const OptionGroup = {};

OptionGroup.fragments = {
  public: gql`
    fragment OptionGroupPublic on OptionGroup {
      id
      optionId
      groupId

      option {
        ...OptionPublic
      }

      group {
        ...GroupPublic
      }
    }
    ${Option.fragments.public}
    ${Group.fragments.public}
  `,
};

OptionGroup.mutations = {
  update: gql`
    mutation UpdateOptionGroup($input: UpdateOptionGroupInput!) {
      updateOptionGroup(input: $input) {
        ...OptionGroupPublic
      }
    }
    ${OptionGroup.fragments.public}
  `,
  create: gql`
    mutation CreateOptionGroup($input: CreateOptionGroupInput!) {
      createOptionGroup(input: $input) {
        ...OptionGroupPublic
      }
    }
    ${OptionGroup.fragments.public}
  `,
  delete: gql`
    mutation DeleteOptionGroup($input: DeleteOptionGroupInput!) {
      deleteOptionGroup(input: $input) {
        ...OptionGroupPublic
      }
    }
    ${OptionGroup.fragments.public}
  `,
};

OptionGroup.queries = {
  get: gql`
    query GetOptionGroup($id: ID!) {
      getOptionGroup(id: $id) {
        ...OptionGroupPublic
      }
    }
    ${OptionGroup.fragments.public}
  `,
  list: gql`
    query ListOptionGroups(
      $filter: ModelOptionGroupFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...OptionGroupPublic
        }
        nextToken
      }
    }
    ${OptionGroup.fragments.public}
  `,
  listByGroupId: gql`
    query ListOptionGroupsByGroupId(
      $groupId: ID${environment.v2_bang}
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelOptionGroupFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listOptionGroupsByGroupId(
        groupId: $groupId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...OptionGroupPublic
        }
      }
    }
    ${OptionGroup.fragments.public}
  `,
};

export default OptionGroup;
