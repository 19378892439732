import React from "react";
import { Button, HelperText, useTheme, TextInput } from "react-native-paper";
import { useCallback } from "react";

import * as yup from "yup";
import Screen from "Components/Screen";

import valid from "card-validator"; //import statement

import { HeaderButtons, Item } from "react-navigation-header-buttons";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Platform } from "react-native";
import ScreenBottom from "Components/Screen/Bottom";

const schema = yup.object().shape({
  mailingAddress1: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(200, "Too long - should be 200 chars maximum.")
    .required("Can't be blank"),
  mailingAddress2: yup
    .string()
    .max(200, "Too long - should be 25 chars maximum.")
    .optional()
    .nullable(),
  mailingCity: yup.string().required(),
  mailingState: yup
    .string()
    .matches(/^[A-Z][A-Z]$/, "Must be a valid state abbrev")
    .required("Can't be blank"),
  mailingZip: yup
    .string()
    .test(
      "test-number", // this is used internally by yup
      "Zipcode is invalid", //validation message
      value => valid.postalCode(value).isValid
    ) // return true false based on validation
    .required(),
});

export default ({
  navigation,
  route: {
    params: {
      mailingAddress1,
      mailingAddress2,
      mailingCity,
      mailingState,
      mailingZip,
      accountType,
      accountNumber,
      routingNumber,
      amount,
    } = {},
  } = {},
}) => {
  const theme = useTheme();
  const inputs = React.useRef({});

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      /*isValid,*/ isSubmitting,
      touchedFields,
      dirtyFields,
      ...rest
    },
    setError,
    setFocus,
    watch,
    ...others
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const isValid = !Object.keys(errors).length;

  const onSubmit = useCallback(
    ({
      mailingAddress1,
      mailingAddress2,
      mailingCity,
      mailingState,
      mailingZip,
    }) =>
      navigation.navigate("PaperSummary", {
        mailingAddress1,
        mailingAddress2,
        mailingCity,
        mailingState,
        mailingZip,
        accountType,
        routingNumber,
        accountNumber,
        amount,
      }),
    [navigation, accountType, routingNumber, accountNumber, amount]
  );

  const doSubmit = handleSubmit(onSubmit);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons size={48}>
          <Item
            title="Submit"
            disabled={!isValid || !!isSubmitting}
            onPress={doSubmit}
          />
        </HeaderButtons>
      ),
    });
  }, [navigation, doSubmit, isValid]);

  return (
    <Screen
      gatedTo="member"
      title="Check Info"
      fullHeight
      hasKeyboard
      backgroundColor={theme.colors.surface}
    >
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
          <>
            <TextInput
              autoFocus
              autoCorrect={false}
              autoCapitalize={"words"}
              ref={e => (inputs.current["mailingAddress1"] = e)}
              autoCompleteType={
                Platform.OS === "web" ? "new-password" : "street-address"
              }
              keyboardType="default"
              textContentType="streetAddressLine1"
              label="Address 1"
              error={!!errors.mailingAddress1}
              returnKeyLabel={"Next"}
              returnKeyType={"next"}
              onSubmitEditing={() => inputs.current.mailingAddress2.focus()}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value ?? ""}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.mailingAddress1}>
              {errors.mailingAddress1?.message}
            </HelperText>
          </>
        )}
        name="mailingAddress1"
        defaultValue={mailingAddress1 ?? ""}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextInput
              autoCorrect={false}
              autoCapitalize={"words"}
              ref={e => (inputs.current["mailingAddress2"] = e)}
              autoCompleteType={
                Platform.OS === "web" ? "new-password" : "street-address"
              }
              keyboardType="default"
              textContentType="streetAddressLine2"
              label="Address 2"
              error={!!errors.mailingAddress2}
              returnKeyLabel={"Next"}
              returnKeyType={"next"}
              onSubmitEditing={() => inputs.current.mailingCity.focus()}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.mailingAddress2}>
              {errors.mailingAddress2?.message}
            </HelperText>
          </>
        )}
        name="mailingAddress2"
        defaultValue={mailingAddress2 ?? ""}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
          <>
            <TextInput
              autoCorrect={false}
              autoCapitalize={"words"}
              ref={e => (inputs.current["mailingCity"] = e)}
              autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
              keyboardType="default"
              textContentType="addressCity"
              label="City"
              error={!!errors.mailingCity}
              returnKeyLabel={"Next"}
              returnKeyType={"next"}
              onSubmitEditing={() => inputs.current.mailingState.focus()}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.mailingCity}>
              {errors.mailingCity?.message}
            </HelperText>
          </>
        )}
        name="mailingCity"
        defaultValue={mailingCity ?? ""}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
          <>
            <TextInput
              autoCorrect={false}
              autoCapitalize={"none"}
              ref={e => (inputs.current["mailingState"] = e)}
              autoCompleteType={Platform.OS === "web" ? "new-password" : "off"}
              keyboardType="default"
              textContentType="addressState"
              label="State"
              error={!!errors.mailingState}
              returnKeyLabel={"Next"}
              returnKeyType={"next"}
              onSubmitEditing={() => inputs.current.mailingZip.focus()}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.mailingState}>
              {errors.mailingState?.message}
            </HelperText>
          </>
        )}
        name="mailingState"
        defaultValue={mailingState ?? ""}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
          <>
            <TextInput
              maxLength={5}
              autoCorrect={false}
              autoCapitalize={"none"}
              ref={e => (inputs.current["mailingZip"] = e)}
              autoCompleteType={
                Platform.OS === "web" ? "new-password" : "postal-code"
              }
              keyboardType="number-pad"
              textContentType="mailingZip"
              label="Zip Code"
              error={!!errors.mailingZip}
              returnKeyLabel={"Done"}
              returnKeyType={"done"}
              onSubmitEditing={doSubmit}
              mode="flat"
              onBlur={onBlur}
              onChangeText={value => onChange(value)}
              value={value}
              style={{
                backgroundColor: "transparent",
              }}
            />
            <HelperText type="error" visible={!!errors.mailingZip}>
              {errors.mailingZip?.message}
            </HelperText>
          </>
        )}
        name="mailingZip"
        defaultValue={mailingZip ?? ""}
      />

      <ScreenBottom theme={theme}>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          {!!isSubmitting ? "Please Wait..." : "Submit"}
        </Button>
      </ScreenBottom>
    </Screen>
  );
};
