/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "9b3cd034734c4bb79b42f1c8b0c4cde4",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "9b3cd034734c4bb79b42f1c8b0c4cde4",
            "region": "us-east-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://4d7ajwppfnfhncnyl22k2qwjmi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-aa75uut2wrbrzmbisamvruuona",
    "aws_cognito_identity_pool_id": "us-east-1:47f00f55-1d30-4522-9740-5cb622bc013f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_p9jV4M5zA",
    "aws_user_pools_web_client_id": "1p1mom5evkicr60odsgmqmsqam",
    "oauth": {
        "domain": "propswapcc73e73d-cc73e73d-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19006/oauth/",
        "redirectSignOut": "http://localhost:19006/oauth/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "propswap2adc7fd0109a445ebddbc6784526179d-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
