import { Platform } from "react-native";

export default theme => ({
  wrapper: {
    backgroundColor: theme.colors.navHeaderBackground,
    paddingVertical: theme.spacing.sm,
    paddingHorizontal: theme.spacing.xl,
    justifyContent: "center",
    flexDirection: "row",
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
      default: {},
    }),
  },
  text: {
    color: theme.colors.textReverse,
    fontSize: theme.fonts.p.fontSize,
    paddingRight: theme.spacing.xl * 2,
    ...Platform.select({
      web: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      default: {},
    }),
  },
  icon: {
    position: "absolute",
    right: theme.spacing.xl,
    bottom: theme.spacing.sm / 2,
  },
});
