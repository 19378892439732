import { useTheme } from "react-native-paper";

import RegisterHeader from "Components/Register/Header";
import Screen from "Components/Screen";

import RegistrationInitial from "Components/Registration/Initial";
import AuthWrapper from "Components/AuthWrapper";
import { Platform } from "react-native";

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen
      gatedTo="guest"
      title="Register"
      hasKeyboard
      backgroundColor={theme.colors.surface}
      fullHeight
      fullWidth
    >
      {Platform.OS === "web" ? (
        <AuthWrapper>
          <>
            <RegisterHeader action="Register" theme={theme} />

            <RegistrationInitial navigation={navigation} />
          </>
        </AuthWrapper>
      ) : (
        <>
          <RegisterHeader action="Register" theme={theme} />

          <RegistrationInitial navigation={navigation} />
        </>
      )}
    </Screen>
  );
};
