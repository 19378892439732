import React, { useCallback } from "react";
import { List, Divider, useTheme, TouchableRipple } from "react-native-paper";

import Screen from "Components/Screen";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { HeaderButtons, Item } from "react-navigation-header-buttons";
import { Alert, Platform } from "react-native";
import { gql, useMutation, useQuery } from "@apollo/client";
import valid from "card-validator";

const userPaymentsQuery = gql`
  query CardSelectPaylianceListMyPaymentMethods {
    paylianceListMyPaymentMethods {
      cardId
      cardNumber
      expirationDate
    }
  }
`;

const deleteUserPaymentMethodMutation = gql`
  mutation CardSelectPaylianceDeletePaymentMethod(
    $input: PaylianceDeletePaymentMethodInput!
  ) {
    paylianceDeletePaymentMethod(input: $input) {
      message
    }
  }
`;

export default ({ navigation, route }) => {
  const amount = route?.params?.amount;
  const promotionId = route?.params?.promotionId;
  const theme = useTheme();
  // const doDelete = useGidxDeletePaymentMethod();
  // const gidxSession = useGidxSession();

  const { data: userPaymentData, error: userPaymentError } =
    useQuery(userPaymentsQuery);
  const [deleteUserPaymentMethod] = useMutation(
    deleteUserPaymentMethodMutation,
    {
      refetchQueries: [
        "CardSelectPaylianceListMyPaymentMethods",
        "PaylianceListMyPaymentMethods",
      ],
    }
  );

  console.log("userPaymentData", userPaymentData, userPaymentError);

  const navigateTo = useCallback(
    (screen, params) => () => [navigation.push(screen, params)],
    [navigation]
  );

  const navigateBackTo = useCallback(
    (screen, params) => () => [navigation.navigate(screen, params)],
    [navigation]
  );

  const handleDelete = useCallback(
    ({ cardId }) =>
      () =>
        Platform.OS === "web"
          ? confirm("Remove this card?") &&
            deleteUserPaymentMethod({ variables: { input: { cardId } } })
          : Alert.alert(
              "Remove this card?",
              "This will remove the card from your wallet.",
              [
                {
                  text: "Cancel",
                  onPress: () => null,
                  style: "cancel",
                },
                {
                  text: "Delete",
                  onPress: deleteUserPaymentMethod({
                    variables: { input: { cardId } },
                  }),
                  style: "destructive",
                },
              ]
            ),
    [deleteUserPaymentMethod]
  );

  React.useLayoutEffect(() => {
    navigation.setOptions({
      // in your app, you can extract the arrow function into a separate component
      // to avoid creating a new one every time you update the options
      headerRight: () => (
        <HeaderButtons size={48}>
          <Item
            title="Add Card"
            IconComponent={MaterialCommunityIcons}
            iconSize={24}
            iconName="plus"
            onPress={navigateTo("CreditCardAddAccount")}
          />
        </HeaderButtons>
      ),
    });
  }, [navigateTo, navigation]);

  return (
    <Screen
      gatedTo="member"
      title="Select Card"
      fullHeight
      backgroundColor={theme.colors.surface}
    >
      {(userPaymentData?.paylianceListMyPaymentMethods ?? []).map(info => (
        <React.Fragment key={info.cardId}>
          <List.Item
            title={
              valid.number(info.cardNumber.substring(0, 4))?.card?.niceType ??
              ""
            }
            description={info.cardNumber}
            right={props => (
              <TouchableRipple onPress={handleDelete({ cardId: info.cardId })}>
                <List.Icon {...props} icon="trash-can-outline" />
              </TouchableRipple>
            )}
            onPress={navigateBackTo("CreditCardSummary", {
              lastFour: info.cardNumber,
              amount,
              promotionId,
            })}
          />
          <Divider />
        </React.Fragment>
      ))}
    </Screen>
  );
};
