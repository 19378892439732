import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" {...props}>
      <G fill="none" fillRule="evenodd">
        <Path d="M0 0h20v20H0z" />
        <Path
          fill={props?.color ?? "#666"}
          d="M18.133 11.006c-.511 3.883-3.652 6.786-7.392 7.156-.37.014-1.793-.06-2.223-1.956-1.02-4.492-3.51-5.335-4.533-5.489-.86-.129-1.691.057-2.244.465a8.309 8.309 0 01-.024-2.337 8.23 8.23 0 013.169-5.488c.302-.232.619-.44.944-.627a3.771 3.771 0 00-.462 1.495c-.137 1.38.416 2.795 1.376 3.522.952.72 1.931.992 2.91.992 1.598 0 3.194-.724 4.669-1.393 2.032-.921 3.29.065 3.564.317a8.206 8.206 0 01.246 3.343zM3.357 14.965a8.243 8.243 0 01-1.467-3.04c.31-.402 1.058-.712 2-.57.891.134 3.067.89 3.999 4.994.196.863.572 1.423.994 1.788l-.038-.004a8.23 8.23 0 01-5.488-3.168zm6.551-13.32a8.281 8.281 0 017.622 5.018c-.74-.355-1.924-.607-3.474.096-2.764 1.253-4.878 2.021-6.923.474-.787-.597-1.239-1.78-1.123-2.944.103-1.04.645-1.87 1.487-2.281a8.161 8.161 0 012.411-.363zm1.182-.567a8.865 8.865 0 00-6.597 1.767A8.868 8.868 0 001.078 8.76a8.867 8.867 0 001.768 6.598 8.865 8.865 0 005.915 3.415 8.841 8.841 0 002.08.028l.062-.004v-.003c3.988-.44 7.323-3.552 7.87-7.704.642-4.878-2.805-9.37-7.683-10.012z"
        />
        <Path
          fill={props?.color ?? "#666"}
          d="M18.133 11.006c-.511 3.883-3.652 6.786-7.392 7.156-.37.014-1.793-.06-2.223-1.956-1.02-4.492-3.51-5.335-4.533-5.489-.86-.129-1.691.057-2.244.465a8.309 8.309 0 01-.024-2.337 8.23 8.23 0 013.169-5.488c.302-.232.619-.44.944-.627a3.771 3.771 0 00-.462 1.495c-.137 1.38.416 2.795 1.376 3.522.952.72 1.931.992 2.91.992 1.598 0 3.194-.724 4.669-1.393 2.032-.921 3.29.065 3.564.317a8.206 8.206 0 01.246 3.343zM3.357 14.965a8.243 8.243 0 01-1.467-3.04c.31-.402 1.058-.712 2-.57.891.134 3.067.89 3.999 4.994.196.863.572 1.423.994 1.788l-.038-.004a8.23 8.23 0 01-5.488-3.168zm6.551-13.32a8.281 8.281 0 017.622 5.018c-.74-.355-1.924-.607-3.474.096-2.764 1.253-4.878 2.021-6.923.474-.787-.597-1.239-1.78-1.123-2.944.103-1.04.645-1.87 1.487-2.281a8.161 8.161 0 012.411-.363zm1.182-.567a8.865 8.865 0 00-6.597 1.767A8.868 8.868 0 001.078 8.76a8.867 8.867 0 001.768 6.598 8.865 8.865 0 005.915 3.415 8.841 8.841 0 002.08.028l.062-.004v-.003c3.988-.44 7.323-3.552 7.87-7.704.642-4.878-2.805-9.37-7.683-10.012z"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
