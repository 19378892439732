import * as React from "react";
import { useCallback, useContext, useState, useEffect } from "react";
import gql from "graphql-tag";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { Keyboard, Platform, ScrollView } from "react-native";
import BottomSheet from "Components/BottomSheet";

import {
  Button,
  HelperText,
  Subheading,
  TextInput,
  Title,
  useTheme,
} from "react-native-paper";
import Screen from "Components/Screen";
import { CurrentUserContext } from "Contexts/CurrentUser";
import ScreenBottom from "Components/Screen/Bottom";
import Confirmation from "Components/Confirmation";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid email address")
    .required("Can't be blank"),
  firstName: yup.string().required("Can't be blank"),
  lastName: yup.string().required("Can't be blank"),
  subject: yup.string().required("Can't be blank"),
  message: yup.string().required("Can't be blank"),
});

export default ({ navigation }) => {
  const theme = useTheme();

  const currentUser = useContext(CurrentUserContext);
  const inputs = React.useRef({});
  const [submitted, setSubmitted] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [sendEmail] = useMutation(
    gql`
      mutation SendEmail($input: SendEmailInput!) {
        sendEmail(input: $input)
      }
    `,
    {
      variables: {
        input: {},
      },
    }
  );

  const handleSubmitError = useCallback(error => console.error(error), []);

  const onSubmit = useCallback(
    ({ email, subject, message, firstName, lastName }) =>
      sendEmail({
        variables: {
          input: {
            email,
            subject,
            message,
            firstName,
            lastName,
          },
        },
      })
        .then(() => setSubmitted(true))
        .catch(handleSubmitError),
    [sendEmail, handleSubmitError]
  );

  const doSubmit = handleSubmit(onSubmit);
  const onNext = field => () => inputs.current[field].focus();

  const handleDismiss = useCallback(
    () => !!submitted && navigation.goBack(),
    [submitted, navigation]
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener("blur", () =>
      setSubmitted(false)
    );

    return unsubscribe;
  }, [navigation]);

  return (
    <Screen title="Contact" hasKeyboard backgroundColor={theme.colors.surface}>
      <ScrollView keyboardShouldPersistTaps="handled" style={{ flex: 1 }}>
        <Title style={{ textAlign: "center", color: theme.colors.primary }}>
          Send us an email
        </Title>
        <Subheading style={{ textAlign: "center" }}>
          Have questions or feedback? We'd love to hear from you!
        </Subheading>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["email"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "email"
                }
                keyboardType="email-address"
                textContentType="emailAddress"
                label="Email"
                error={!!errors.email}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={onNext("firstName")}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value.toLowerCase())}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.email}>
                {errors.email?.message}
              </HelperText>
            </>
          )}
          name="email"
          defaultValue={currentUser?.email ?? ""}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize="sentences"
                ref={e => (inputs.current["firstName"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "name"
                }
                keyboardType="default"
                textContentType="givenName"
                label="First Name"
                error={!!errors.firstName}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={onNext("lastName")}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.firstName}>
                {errors.firstName?.message}
              </HelperText>
            </>
          )}
          name="firstName"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize="sentences"
                ref={e => (inputs.current["lastName"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "name"
                }
                keyboardType="default"
                textContentType="givenName"
                label="Last Name"
                error={!!errors.lastName}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={onNext("message")}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.lastName}>
                {errors.lastName?.message}
              </HelperText>
            </>
          )}
          name="lastName"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoFocus
                autoCorrect={false}
                autoCapitalize="sentences"
                ref={e => (inputs.current["subject"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "name"
                }
                keyboardType="default"
                // textContentType="familyName"
                label="Subject"
                error={!!errors.subject}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={onNext("message")}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.subject}>
                {errors.subject?.message}
              </HelperText>
            </>
          )}
          name="subject"
          defaultValue=""
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ...mre }, ...rest }) => (
            <>
              <TextInput
                autoCorrect
                autoCapitalize="sentences"
                ref={e => (inputs.current["message"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="default"
                label="Message"
                error={!!errors.message}
                returnKeyLabel="Done"
                returnKeyType="done"
                onSubmitEditing={Keyboard.dismiss}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                multiline
                numberOfLines={4}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.message}>
                {errors.message?.message}
              </HelperText>
            </>
          )}
          name="message"
          defaultValue=""
        />
      </ScrollView>

      <ScreenBottom adjustPadding>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          {!!isSubmitting ? "Please Wait..." : "Submit"}
        </Button>
      </ScreenBottom>
      <BottomSheet visible={!!submitted} onDismiss={handleDismiss} fullScreen>
        {!!submitted && (
          <Confirmation
            title="Message Received!"
            subtitle="A member of our support staff will respond as soon as possible."
            type="success"
            primaryButtonLabel="Close"
            onPrimaryButtonPress={handleDismiss}
          />
        )}
      </BottomSheet>
    </Screen>
  );
};
