import { useCallback, useContext } from "react";

import Screen from "Components/Screen";
import useStyles from "Hooks/useStyles";
import PhotoUploadStyles from "Components/PhotoUpload/PhotoUploadStyles";
import { CurrentUserContext } from "Contexts/CurrentUser";
import AccountForm from "Components/Account/Form";
import { Text, Title } from "react-native-paper";
import { View } from "react-native";
import { gql, useMutation, useReactiveVar } from "@apollo/client";
import { imageUrlsVar } from "./shared";
import { useFocusEffect } from "@react-navigation/core";

const TicketNewMobileConfirmStep2 = ({ navigation }) => {
  const imageUrls = useReactiveVar(imageUrlsVar);
  const currentUser = useContext(CurrentUserContext);
  const { theme, styles } = useStyles({ Styles: PhotoUploadStyles });
  const [sendEmail] = useMutation(
    gql`
      mutation SendMobileApprovalEmail($input: SendEmailInput!) {
        sendMobileApprovalEmail(input: $input)
      }
    `,
    {
      variables: {
        input: {},
      },
    }
  );

  const handleSubmit = useCallback(
    data => {
      return sendEmail({
        variables: {
          input: {
            email: currentUser?.email,
            subject: "",
            message: JSON.stringify({ ...data, images: imageUrls }, null, 2),
            firstName: data.firstName,
            lastName: data.lastName,
          },
        },
      }).then(() => navigation.push("TicketNewMobileConfirmStep3"));
    },
    [currentUser?.email, imageUrls, navigation, sendEmail]
  );

  useFocusEffect(
    useCallback(() => {
      if (!imageUrls.length) {
        console.log("No images, redirecting to step 1");
        setTimeout(
          () => navigation.replace("TicketNewMobileConfirmStep1"),
          1000
        );
      }
    }, [imageUrls.length, navigation])
  );

  return (
    <Screen
      gatedTo="member"
      title="Mobile Ticket Approval"
      hasKeyboard={false}
      backgroundColor={theme.colors.surface}
      fullWidth
      fullHeight
    >
      <>
        <View style={{ marginRight: 20, marginLeft: 20, marginBottom: 20 }}>
          <Title
            style={[
              styles.textPrimary,
              styles.textCenter,
              { marginBottom: 20 },
            ]}
          >
            Step 2 of 2: Confirm Info
          </Title>
          <Text style={[styles.textCenter]}>
            Please review &amp; confirm the information below is up-to-date and
            accurate.
          </Text>
        </View>
        <AccountForm
          view="MOBILE_APPROVAL"
          theme={theme}
          currentUser={currentUser}
          onSubmit={handleSubmit}
        />
      </>
    </Screen>
  );
};

export default TicketNewMobileConfirmStep2;
