import * as React from "react";
import Svg, { Defs, G, Path, Circle } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 805.34 749.56"
      {...props}
      height={62}
      width={62}
    >
      <G id="prefix__Layer_2" data-name="Layer 2">
        <G id="prefix__Layer_1-2" data-name="Layer 1">
          <Path
            className="prefix__cls-1"
            d="M353.6,51.79c-76.93,7.74-183.07,36.87-248.67,130.44C23.7,298.11,9,475.42,20.4,483.37s236.87-31.44,382.27-31.44,370.86,39.39,382.27,31.44-3.3-185.26-84.53-301.14C634.77,88.6,528.52,59.49,451.59,51.77"
            fill="none"
            stroke={props.stroke ?? "#000"}
            strokeWidth={34}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            className="prefix__cls-1"
            d="M42.85 488.59s-44.09 204.65 9.54 237.5 197.73-70.16 350.28-70.16S699.32 759 753 726.09s9.53-237.5 9.53-237.5M353.6 51.79c-76.93 7.74-183.07 36.87-248.67 130.44C23.7 298.11 9 475.42 20.4 483.37s236.87-31.44 382.27-31.44 370.86 39.39 382.27 31.44-3.3-185.26-84.53-301.14C634.77 88.6 528.52 59.49 451.59 51.77"
            fill="none"
            stroke={props.stroke ?? "#000"}
            strokeWidth={34}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            className="prefix__cls-1"
            d="M451.59 56.77c0 8.46-21.94 15.43-49 15.43s-49-5.39-49-15.43c0-15.34 21.93-39.77 49-39.77s49 24.43 49 39.77z"
            fill="none"
            stroke={props.stroke ?? "#000"}
            strokeWidth={34}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            className="prefix__cls-2"
            d="M292.91 63.2S144.38 164.83 140.47 473.63M510 63.2s148.49 101.63 152.4 410.43"
            fill="none"
            stroke={props.stroke ?? "#000"}
            strokeWidth={34}
            strokeMiterlimit={10}
          />
          <Path
            className="prefix__cls-2"
            d="M510,63.2S658.49,164.83,662.4,473.63"
            fill="none"
            stroke={props.stroke ?? "#000"}
            strokeWidth={34}
            strokeMiterlimit={10}
          />
          <Path
            className="prefix__cls-3"
            d="M29.29 645.62s66.07 10.68 130.33-8.87c62.64-19 84.92-46.35 171.92-54.72M773.81 645.62s-66.07 10.68-130.33-8.87c-62.64-19-84.92-46.35-171.92-54.72"
            fill="none"
            stroke={props.stroke ?? "#000"}
            strokeWidth={34}
            strokeLinecap="round"
            strokeMiterlimit={10}
          />
          <Path
            className="prefix__cls-3"
            d="M773.81,645.62s-66.07,10.68-130.33-8.87c-62.64-19-84.92-46.35-171.92-54.72"
            fill="none"
            stroke={props.stroke ?? "#000"}
            strokeWidth={34}
            strokeLinecap="round"
            strokeMiterlimit={10}
          />
          <Circle cx={402.46} cy={576.04} r={17.56} fill="#020202" />
          <Circle cx={402.85} cy={255.1} r={118.73} fill="#000" />
          <Path
            d="M400 215.18q-3.24-5.12-10.46-5.11h-48l1 9.48-29.54 80.8h21l9.66-26.35h19.11a26.36 26.36 0 0011.41-2.79 34.45 34.45 0 0010.37-7.6 30.32 30.32 0 006.64-10.53l9.37-25.58q2.64-7.19-.56-12.32zm-22.22 17.16l-7.11 19.41a5.21 5.21 0 01-1.88 2.48 4.75 4.75 0 01-2.84 1h-15.4l9.63-26.33h15.35a2.33 2.33 0 012.13 1 2.78 2.78 0 01.07 2.44z"
            fill="#fff"
          />
          <Path
            d="M428.65 242.46a2.75 2.75 0 002.69 3.34l29.18.14c5.44 0 9.23 1.73 11.58 5.28s2.56 8 .57 13.26l-5.77 15.33c-5.19 12-3.64 21.77-34 20.46l-2.9 9.57h-20.66l4-9.57h-17l-4.11-18.53 50.63-.14A3.27 3.27 0 00446 279l3.76-11.22a2.75 2.75 0 00-2.68-3.35h-29.4c-5.39 0-9.18-1.74-11.61-5.34s-2.79-8-.94-13.3l5.36-15.5c1.94-5.56 4.59-21.59 28.46-20.38h6.44l.28-.95.29-1 2.23-7.63h21.71l-2.48 5.81-.85 1.94-.78 1.83h26.91L475 228.48l-39.33.19a3.3 3.3 0 00-3.21 2.56z"
            fill="#4cb648"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
