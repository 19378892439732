import { Platform } from "react-native";

export default theme => ({
  wrapper: {
    backgroundColor: theme.colors.backgroundBlack,
  },
  contents: {
    height: 72,
    paddingVertical: theme.spacing.lg,
    paddingHorizontal: theme.spacing.xl,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconsWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  primaryHeading: {
    fontSize: theme.fonts.p.fontSize,
    color: theme.colors.primary,
    lineHeight: 22,
  },
  secondaryHeading: {
    fontSize: theme.fonts.p.fontSize,
    color: theme.colors.textReverse,
  },
  badge: {
    position: "absolute",
    left: "50%",
    ...Platform.select({
      web: {
        bottom: "50%",
      },
      default: {
        bottom: "35%",
      },
    }),
    zIndex: 300,
  },
});
