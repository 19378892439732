import { View } from "react-native";
import { Caption } from "react-native-paper";
import { valueMeter } from "shared/Config";

export default ({ valueRating, showValueMeter, theme, styles }) => (
  <View>
    {!!showValueMeter && (
      <>
        <View style={styles.wrapper}>
          <View
            style={[
              styles.meter,
              {
                backgroundColor:
                  theme.colors[valueMeter[(valueRating || 0) - 1]?.color],
                width: valueMeter[(valueRating || 0) - 1]?.width ?? 0,
              },
            ]}
          />
        </View>
        <Caption style={styles.textDefault}>
          {valueMeter[(valueRating || 0) - 1]?.label} price
        </Caption>
      </>
    )}
    {/* {
      !showValueMeter &&
      <>
        <View style={styles.wrapper}>
          <View style={[styles.meter]} />
        </View>
        <Caption style={styles.textDefault}>Value N/A</Caption>
      </>
    } */}
  </View>
);
