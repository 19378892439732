import { CurrentUserContext } from "Contexts/CurrentUser";
import { ScrollDirectionContext } from "Contexts/ScrollDirection";
import useNavigationMode from "Hooks/useNavigationMode";
import useStyles from "Hooks/useStyles";
import { useCallback, useContext } from "react";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import NavHeaderStyles from "./NavHeaderStyles";
import NavHeaderView from "./NavHeaderView";

export default ({
  scene,
  previous,
  navigation,
  mode,
  layout,
  styleInterpolator,
  ...rest
}) => {
  const { styles, theme } = useStyles({ Styles: NavHeaderStyles });
  const currentUser = useContext(CurrentUserContext);
  const insets = useSafeAreaInsets();
  const showUserInfo = useNavigationMode() !== "vertical";

  const { scrollDirection } = useContext(ScrollDirectionContext);

  const handleNotificationIconPress = useCallback(
    () => navigation.push("Notifications"),
    //navigation.push("Main", {screen: "HomeStack", params: {initial: false, screen: "Notifications"}})
    [navigation]
  );

  const handleWatchlistIconPress = useCallback(
    () => navigation.push("WatchList"),
    // navigation.push("Main", {screen: "HomeStack", params: {initial: false, screen: "WatchList"}})
    [navigation]
  );

  const handleBalancePress = useCallback(
    () => navigation.push("DepositStack"),
    // navigation.push("Main", {initial: false, screen: "Account", params: {initial: false, screen: "WalletStack", params: {screen: "DepositStack", initial: false,}}})
    [navigation]
  );

  const handlePointsPress = useCallback(
    () => navigation.push("PointsStack"),
    // navigation.push("Main", {initial: false, screen: "Account", params: {initial: false, screen: "PointsStack",}})
    [navigation]
  );

  const handleUserPress = useCallback(
    () => navigation.push("ProfileStack"),
    // navigation.push("Main", {initial: false, screen: "Account", params: {initial: false, screen: "ProfileStack",}})
    [navigation]
  );

  const handleOnLoginPress = useCallback(
    () => navigation.push("AuthStack", { screen: "Login" }),
    [navigation]
  );

  const handleOnRegisterPress = useCallback(
    () => navigation.navigate("AuthStack", { screen: "RegistrationInitial" }),
    [navigation]
  );

  // const { options } = scene?.descriptor ?? {};
  // const title =
  //   options?.headerTitle !== undefined
  //     ? options?.headerTitle
  //     : options?.title !== undefined
  //     ? options?.title
  //     : scene?.route?.name ?? "Nothing";

  return scrollDirection === "down" ? (
    <View style={{ width: "100%", height: insets.top }} />
  ) : (
    <NavHeaderView
      showUserInfo={showUserInfo}
      insets={insets}
      styles={styles}
      theme={theme}
      isLoggedIn={!!currentUser}
      onNotificationIconPress={handleNotificationIconPress}
      onWatchlistIconPress={handleWatchlistIconPress}
      onBalancePress={handleBalancePress}
      onLoginPress={handleOnLoginPress}
      onRegisterPress={handleOnRegisterPress}
      onPointsPress={handlePointsPress}
      onUserPress={handleUserPress}
    />
  );
};
