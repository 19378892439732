import { useMutation, useQuery } from "@apollo/client";
import { useFocusEffect } from "@react-navigation/native";
import ListingSearchbar from "Components/Listing/Searchbar";
import Screen from "Components/Screen";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { EventsContext } from "Contexts/Events";
import { OptionsContext } from "Contexts/Options";
import { useRef, useContext, useState, useEffect, useCallback } from "react";
import { ScrollView } from "react-native";
import { List, useTheme } from "react-native-paper";
import Search from "shared/api/Search";

const unique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export default ({ navigation }) => {
  const theme = useTheme();
  const currentUser = useContext(CurrentUserContext);
  const searchbar = useRef(null);

  const events = useContext(EventsContext);
  const options = useContext(OptionsContext);
  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const [showTopSearches, setShowTopSearches] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [doSearch, setDoSearch] = useState(0);
  const {
    error,
    data: { listSearchesByUserId: { items: searches = [] } = {} } = {},
  } = useQuery(Search.queries.listByUserId, {
    skip: !currentUser?.id,
    variables: {
      userId: currentUser?.id,
      sortDirection: "DESC",
    },
  });

  const [saveSearch] = useMutation(Search.mutations.create, {
    variables: {
      input: {
        text: searchText,
        unused: "y",
      },
    },
  });

  const handleSearchRequest = useCallback(
    type => saveSearch().finally(() => setDoSearch(type === 1 ? 1 : 2)),
    [saveSearch]
  );

  const handleSearchFocus = useCallback(() => {
    !!currentUser && setShowSearchHistory(true);

    setShowTopSearches(true);
  }, [currentUser]);

  // const handleSearchBlur = useCallback(() => {
  //   setShowSearchHistory(false);
  //   setShowTopSearches(false);
  // }, []);

  const handleOptionClick = useCallback(
    text => () =>
      Promise.resolve(setSearchText(text)).then(() => handleSearchRequest(1)),
    [handleSearchRequest]
  );

  useFocusEffect(useCallback(() => setDoSearch(false), []));

  useFocusEffect(useCallback(() => setShowSearchHistory(true), []));

  useFocusEffect(useCallback(() => setShowTopSearches(true), []));

  // useFocusEffect(
  //   useCallback(() => {
  //     !!searchbar.current && searchbar.current.focus()
  //   }
  //   , [searchbar])
  // );

  useEffect(() => {
    doSearch &&
      setTimeout(
        () =>
          navigation.navigate("Listings", {
            searchString: searchText,
            searchExact: doSearch === 1,
          }),
        100
      );
  }, [doSearch, navigation, searchText]);

  !!error && console.error(error);

  return (
    <Screen fullHeight fullWidth>
      <ListingSearchbar
        ref={searchbar}
        // autoFocus
        value={searchText}
        onChangeText={setSearchText}
        onSubmitEditing={handleSearchRequest}
        onFocus={handleSearchFocus}
        enabled
      />
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          // flex:1,
          backgroundColor: theme.colors.surface,
        }}
      >
        {!!showSearchHistory && !searchText && (
          <List.Section>
            <List.Subheader>History</List.Subheader>
            {searches
              .map(search => search.text)
              .filter((v, i, a) => a.indexOf(v) === i)
              .filter((v, i, a) => i < 5)
              .map(search => (
                <List.Item
                  onPress={handleOptionClick(search)}
                  key={search}
                  title={search}
                  titleStyle={{ color: theme.colors.secondary }}
                  style={{
                    paddingLeft: theme.spacing.xl,
                    backgroundColor: theme.colors.surface,
                    borderBottomWidth: 1,
                    borderColor: theme.colors.border,
                    borderStyle: "solid",
                  }}
                />
              ))}
          </List.Section>
        )}
        {!!searchText && (
          <List.Section>
            <List.Subheader>Matches</List.Subheader>
            {[...(events ?? []), ...(options ?? [])]
              .map(item => [
                item.name ?? item.description ?? "",
                ...(item.aliases ?? "").split(","),
              ])
              .filter(searches =>
                searches.some(search =>
                  new RegExp(`${searchText.trim()}`, "i").test(search.trim())
                )
              )
              .map(searches => searches[0])
              .filter((v, i, a) => a.indexOf(v) === i)
              .map(search => (
                <List.Item
                  onPress={handleOptionClick(search)}
                  key={search}
                  title={search}
                  titleStyle={{ color: theme.colors.secondary }}
                  style={{
                    paddingLeft: theme.spacing.xl,
                    backgroundColor: theme.colors.surface,
                    borderBottomWidth: 1,
                    borderColor: theme.colors.border,
                    borderStyle: "solid",
                  }}
                />
              ))}
          </List.Section>
        )}
        {!!showTopSearches && !searchText && (
          <List.Section>
            <List.Subheader>Top Events</List.Subheader>
            {[...(events ?? [])]
              .sort((a, b) => (a.viewCount > b.viewCount ? -1 : 1))
              .map(item => [
                item.name ?? item.description ?? "",
                ...(item.aliases ?? "").split(","),
              ])
              .map(searches => searches[0])
              .filter(unique)
              .filter((v, i, a) => i < 3)
              .map(search => (
                <List.Item
                  onPress={handleOptionClick(search)}
                  key={search}
                  title={search}
                  titleStyle={{ color: theme.colors.secondary }}
                  style={{
                    paddingLeft: theme.spacing.xl,
                    backgroundColor: theme.colors.surface,
                    borderBottomWidth: 1,
                    borderColor: theme.colors.border,
                    borderStyle: "solid",
                  }}
                />
              ))}
          </List.Section>
        )}
        {!!showTopSearches && !searchText && (
          <List.Section>
            <List.Subheader>Top Teams/Players</List.Subheader>
            {[...(options ?? [])]
              .sort((a, b) => (a.viewCount > b.viewCount ? -1 : 1))
              .map(item => [
                item.name ?? item.description ?? "",
                ...(item.aliases ?? "").split(","),
              ])
              .map(searches => searches[0])
              .filter(unique)
              .filter((v, i, a) => i < 3)
              .map(search => (
                <List.Item
                  onPress={handleOptionClick(search)}
                  key={search}
                  title={search}
                  titleStyle={{ color: theme.colors.secondary }}
                  style={{
                    paddingLeft: theme.spacing.xl,
                    backgroundColor: theme.colors.surface,
                    borderBottomWidth: 1,
                    borderColor: theme.colors.border,
                    borderStyle: "solid",
                  }}
                />
              ))}
          </List.Section>
        )}
        {/* <View style={{width: "100%", height: 400}}></View> */}
      </ScrollView>
    </Screen>
  );
};
