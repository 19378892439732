import { View } from "react-native";
import { Paragraph } from "react-native-paper";
import { Octicons } from "@expo/vector-icons";

export default ({ theme, children, dotSize = 12 }) => (
  <View style={{ flexDirection: "row", marginBottom: theme.spacing.md }}>
    <View
      style={{
        justifyContent: "flex-start",
        marginRight: theme.spacing.sm,
        marginTop: theme.spacing.sm,
      }}
    >
      <Octicons name="dot-fill" size={dotSize} color={theme.colors.text} />
    </View>
    <Paragraph>{children}</Paragraph>
  </View>
);
