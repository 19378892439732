import { useCallback, useState, useEffect, useRef, useContext } from "react";

import { Platform, View, useWindowDimensions, FlatList } from "react-native";
import { TouchableRipple } from "react-native-paper";
import ResponsiveImage from "Components/ResponsiveImage";
import { TutorialSwipperContext } from "Contexts/TutorialSwipper";
import GestureRecognizer from "react-native-swipe-gestures";

const images = [
  {
    src: require("assets/tutorial/0.png"),
  },
  {
    src: require("assets/tutorial/1.png"),
  },
  {
    src: require("assets/tutorial/2.png"),
  },
  {
    src: require("assets/tutorial/3.png"),
  },
];

const TutorialListContainer = () => {
  // const tutorialCount = images.length;
  const { endTutorial } = useContext(TutorialSwipperContext);
  const itemCount = images.length;
  const [currentIndex, setCurrentIndex] = useState(null);
  const windowWidth = useWindowDimensions().width;
  // const theme = useTheme();
  const maxWidth =
    Platform.OS === "web" ? Math.min(601, windowWidth) - 2 : windowWidth;
  const dataList = useRef();

  const handleItemPress = useCallback(
    (index, back) =>
      !back
        ? index + 1 < itemCount
          ? setCurrentIndex(index + 1)
          : endTutorial()
        : index - 1 >= 0
        ? setCurrentIndex(index - 1)
        : null,
    [itemCount, endTutorial]
  );

  const dataListRenderItem = useCallback(
    ({ item, index }) => (
      <View
        style={{
          width: maxWidth, // - theme.spacing.xl * 2,
        }}
      >
        {Platform.OS === "web" ? (
          <TouchableRipple onPress={() => handleItemPress(index)}>
            <View
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "black",
              }}
            >
              <ResponsiveImage
                source={item.src}
                maxWidth={maxWidth}
                width={maxWidth}
              />
            </View>
          </TouchableRipple>
        ) : (
          <TouchableRipple onPress={() => handleItemPress(index)}>
            <GestureRecognizer
              onSwipeLeft={_state => handleItemPress(index)}
              onSwipeRight={_state => handleItemPress(index, true)}
            >
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "black",
                }}
              >
                <ResponsiveImage
                  source={item.src}
                  maxWidth={maxWidth}
                  width={maxWidth}
                />
              </View>
            </GestureRecognizer>
          </TouchableRipple>
        )}
      </View>
    ),
    [maxWidth, handleItemPress]
  );

  useEffect(() => {
    !!dataList.current &&
      currentIndex !== null &&
      dataList.current.scrollToIndex({ animated: true, index: currentIndex });
  }, [currentIndex, dataList]);

  return (
    <View style={{ backgroundColor: "black", alignItems: "center" }}>
      <FlatList
        style={{
          width: maxWidth,
        }}
        data={images}
        keyExtractor={(item, i) => i}
        ref={dataList}
        renderItem={dataListRenderItem}
        horizontal
        scrollEnabled={false}
        // pagingEnabled={Platform.OS !== "web"}
        showsHorizontalScrollIndicator={false}
        onEndReached={() => null}
      />
    </View>
  );
};

export default TutorialListContainer;
