import * as React from "react";
import { useTheme } from "react-native-paper";
import Screen from "Components/Screen";
import PointsRedeemClothes from "./PointsRedeemClothes";

// const scrollEnabled = Platform.select({ web: true, default: false });

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen
      gatedTo="member"
      title="Redeem Points"
      hasKeyboard={true}
      backgroundColor={theme.colors.surface}
      fullHeight
      fullWidth
    >
      <PointsRedeemClothes
        style={{ marginTop: theme.spacing.xl }}
        theme={theme}
        navigation={navigation}
        type="hat"
      />
    </Screen>
  );
};
