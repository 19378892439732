import { useCallback, useState, useEffect } from "react";
import { Button } from "react-native-paper";

import { Platform } from "react-native";
import * as ImagePicker from "expo-image-picker";

export default ({
  loading,
  text,
  loadingText,
  onImageChange,
  theme,
  styles,
}) => {
  const [granted, setGranted] = useState(Platform.OS === "web");

  const pickImage = useCallback(
    () =>
      ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        quality: 1,
        base64: true,
      }).then(result => !result.cancelled && onImageChange(result)),
    [onImageChange]
  );

  useEffect(() => {
    Platform.OS !== "web" &&
      ImagePicker.requestMediaLibraryPermissionsAsync().then(
        ({ status, ...rest }) => status === "granted" && setGranted(true)
      );
  }, []);

  return !granted ? null : (
    <Button
      disabled={!!loading}
      loading={!!loading}
      style={styles.button}
      mode="outlined"
      onPress={pickImage}
    >
      {!!loading && loadingText ? loadingText : text ?? "Choose Existing"}
    </Button>
  );
};
